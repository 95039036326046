import { getCurrentAgreementRequest, acceptAgreementVersion } from '@services'
import { useValidate, useStatus, useAsync, addToast } from '@composables'
import { AGREEMENT_ALREADY_ACCEPTED } from '@config/errors.js'
import { setGlobalModal } from '@composables/useModal.js'
import { MODAL_AGREEMENT } from '@config/globalModals.js'
import { kycTermsConfig } from '@config/validation.js'
import { ref, computed, watch } from 'vue'
import {
  AGREEMENT_TYPE_PRIVACY,
  AGREEMENT_TYPE_MONETIZATION,
  AGREEMENT_TYPE_REFUND,
  AGREEMENT_TYPE_TOU,
} from '@config/options.js'

const monTermsAgreed = ref(0)
const validationErrors = ref({})
const termsAgreed = ref(0)

export default function () {
  const { validate, errors, hasErrors } = useValidate(kycTermsConfig.validation)

  watch(errors, (_errors) => (validationErrors.value = _errors), {
    immediate: true,
  })

  const {
    loading: loadingGetTOU,
    error: errorGetTOU,
    data: dataGetTOU,
    run: runGetTOU,
  } = useAsync(() => getCurrentAgreementRequest(AGREEMENT_TYPE_TOU))

  const {
    loading: loadingGetPayment,
    error: errorGetPayment,
    data: dataGetPayment,
    run: runGetPayment,
  } = useAsync(() => getCurrentAgreementRequest(AGREEMENT_TYPE_MONETIZATION))

  const {
    errorRef: errorRefAcceptPayment,
    loading: loadingAcceptPayment,
    error: errorAcceptPayment,
    run: runAcceptPayment,
  } = useAsync((version) => acceptAgreementVersion(version))

  const {
    errorRef: errorRefAcceptRefund,
    loading: loadingAcceptRefund,
    error: errorAcceptRefund,
    run: runAcceptRefund,
  } = useAsync((version) => acceptAgreementVersion(version))

  const {
    loading: loadingGetRefund,
    error: errorGetRefund,
    data: dataGetRefund,
    run: runGetRefund,
  } = useAsync(() => getCurrentAgreementRequest(AGREEMENT_TYPE_REFUND))

  const {
    errorRef: errorRefAcceptTOU,
    loading: loadingAcceptTOU,
    error: errorAcceptTOU,
    run: runAcceptTOU,
  } = useAsync((version) => acceptAgreementVersion(version))

  const {
    loading: loadingGetPrivacy,
    error: errorGetPrivacy,
    data: dataGetPrivacy,
    run: runGetPrivacy,
  } = useAsync(() => getCurrentAgreementRequest(AGREEMENT_TYPE_PRIVACY))

  const {
    loading: loadingAcceptPrivacy,
    error: errorAcceptPrivacy,
    run: runAcceptPrivacy,
  } = useAsync((version) => acceptAgreementVersion(version))

  const isLoading = computed(
    () =>
      loadingAcceptPrivacy.value ||
      loadingAcceptPayment.value ||
      loadingAcceptRefund.value ||
      loadingGetRefund.value ||
      loadingGetPrivacy.value ||
      loadingGetPayment.value ||
      loadingAcceptTOU.value ||
      loadingGetTOU.value
  )
  const isError = computed(
    () =>
      errorAcceptPrivacy.value ||
      errorAcceptPayment.value ||
      errorAcceptRefund.value ||
      errorGetPrivacy.value ||
      errorGetPayment.value ||
      errorAcceptTOU.value ||
      errorGetTOU.value
  )
  const { status, updateStatus, statusType } = useStatus({
    loading: isLoading,
  })

  function openTermsModal() {
    setGlobalModal({
      modal: MODAL_AGREEMENT,
      data: { agreementType: AGREEMENT_TYPE_TOU },
    })
  }

  function openPrivacyModal() {
    setGlobalModal({
      modal: MODAL_AGREEMENT,
      data: { agreementType: AGREEMENT_TYPE_PRIVACY },
    })
  }

  function openRefundPolicyModal() {
    setGlobalModal({
      modal: MODAL_AGREEMENT,
      data: { agreementType: AGREEMENT_TYPE_REFUND },
    })
  }

  function openPaymentTermsModal() {
    setGlobalModal({
      modal: MODAL_AGREEMENT,
      data: { agreementType: AGREEMENT_TYPE_MONETIZATION },
    })
  }

  function updateAgreed(input, monetization = false) {
    if (monetization) {
      monTermsAgreed.value = input
      validate('monTermsAgreed', input)
    } else {
      termsAgreed.value = input
      validate('termsAgreed', input)
    }
  }

  async function acceptTOU() {
    await runGetTOU()

    if (!errorGetTOU.value) {
      await runAcceptTOU(dataGetTOU.value.id)
    }
  }

  async function acceptPayment() {
    await runGetPayment()

    if (!errorGetPayment.value) {
      await runAcceptPayment(dataGetPayment.value.id)
    }
  }

  async function acceptRefund() {
    await runGetRefund()

    if (!errorGetRefund.value) {
      await runAcceptRefund(dataGetRefund.value.id)
    }
  }

  async function acceptPrivacy() {
    await runGetPrivacy()

    if (!errorGetPrivacy.value) {
      await runAcceptPrivacy(dataGetPrivacy.value.id)
    }
  }

  async function acceptTerms(termsOnly = false) {
    validate('termsAgreed', termsAgreed.value)
    if (hasErrors('termsAgreed')) return

    termsOnly
      ? await acceptTOU()
      : await Promise.all([acceptTOU(), acceptPrivacy()])

    if (
      !isError.value ||
      errorRefAcceptTOU.value === AGREEMENT_ALREADY_ACCEPTED
    ) {
      updateStatus(statusType.SUCCESS)
      return true
    } else {
      updateStatus(statusType.ERROR)
      addToast({
        title: 'Failed to accept terms',
        isError: true,
        text: isError.value,
        errorRef: errorRefAcceptTOU.value,
      })
    }

    return false
  }

  async function acceptMonTerms(termsOnly = false) {
    validate('monTermsAgreed', monTermsAgreed.value)
    if (hasErrors('monTermsAgreed')) return

    termsOnly
      ? await acceptPayment()
      : await Promise.all([acceptPayment(), acceptRefund()])

    const paymentAccepted =
      errorRefAcceptPayment.value === AGREEMENT_ALREADY_ACCEPTED ||
      !errorRefAcceptPayment.value

    const refundAccepted =
      errorRefAcceptRefund.value === AGREEMENT_ALREADY_ACCEPTED ||
      !errorRefAcceptRefund.value

    if (!isError.value || (paymentAccepted && refundAccepted)) {
      updateStatus(statusType.SUCCESS)
      return true
    } else {
      updateStatus(statusType.ERROR)
      if (!paymentAccepted) {
        addToast({
          title: 'Failed to accept monetization terms',
          isError: true,
          text: isError.value,
          errorRef: errorRefAcceptPayment.value,
        })
      }

      if (!refundAccepted) {
        addToast({
          title: 'Failed to accept refund terms',
          isError: true,
          text: isError.value,
          errorRef: errorRefAcceptRefund.value,
        })
      }
    }
  }

  function validateTermsAndMon() {
    validate('termsAgreed', termsAgreed.value)
    validate('monTermsAgreed', monTermsAgreed.value)
    return !hasErrors('monTermsAgreed') && !hasErrors('termsAgreed')
  }

  return {
    errors: validationErrors,
    openPaymentTermsModal,
    openRefundPolicyModal,
    validateTermsAndMon,
    openPrivacyModal,
    monTermsAgreed,
    acceptMonTerms,
    openTermsModal,
    updateAgreed,
    termsAgreed,
    acceptTerms,
    status,
  }
}
