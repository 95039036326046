import { ref, computed } from 'vue'

const stages = ref([])
const currentStage = ref('')
const currentStageIndex = computed(() =>
  stages.value.indexOf(currentStage.value)
)
const stageIndexReached = ref(0)

function _setMaxStageIndex(value) {
  stageIndexReached.value = Math.max(value, stageIndexReached.value)
}

export default function () {
  function setStages(stageArray) {
    stages.value = stageArray
    currentStage.value = stages.value[0]
  }

  function goToNextStage() {
    const index = currentStageIndex.value + 1
    currentStage.value = stages.value[index]
    _setMaxStageIndex(index)
  }

  function goToPreviousStage() {
    const index = currentStageIndex.value - 1
    currentStage.value = stages.value[index]
    _setMaxStageIndex(index)
  }

  function resetAddStage() {
    currentStage.value = stages.value[0]
    stageIndexReached.value = 0
  }

  return {
    goToPreviousStage,
    currentStageIndex,
    stageIndexReached,
    goToNextStage,
    resetAddStage,
    currentStage,
    setStages,
    stages,
  }
}
