import { DEFAULT_ERROR_TITLE, DEFAULT_ERROR_MESSAGE } from '@config'
import { computed, ref } from 'vue'

const state = ref('loading')
export default function () {
  function setOauthStatus(status) {
    state.value = status
  }

  const modalState = computed(() => {
    switch (state.value) {
      case 'loading':
        return {
          status: 'loading',
          message: 'Processing your log in...',
          buttonType: 'primary',
          buttonText: 'Logging in',
        }
      case 'success':
        return {
          status: 'success',
          message: 'You have successfully logged in',
          buttonType: 'success',
          buttonText: 'Success',
        }
      case 'error':
        return {
          status: 'error',
          message: DEFAULT_ERROR_MESSAGE,
          buttonType: 'danger',
          buttonText: DEFAULT_ERROR_TITLE,
        }
      default:
        return null
    }
  })

  return {
    setOauthStatus,
    modalState,
  }
}
