<template>
  <div
    class="tw-flex tw-flex-col tw-w-full lg:tw-flex-row lg:tw-space-x-6 3xl:tw-space-x-12 lg:tw-space-y-0"
  >
    <div class="tw-flex-1">
      <slot name="first" />
      <slot name="first-middle" />
      <slot name="first-bottom" />
    </div>
    <div
      v-if="$slots.second || $slots['second-bottom']"
      class="tw-w-80 tw-min-w-80"
    >
      <slot name="second" />
      <slot name="second-bottom" />
    </div>
  </div>
</template>
