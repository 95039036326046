import { useTheming } from '@composables'
import { clone } from '@helpers/utils.js'
import { ref, computed } from 'vue'

const originalData = ref(undefined)

export default function (game) {
  const { primaryColor, successColor, warningColor, dangerColor, darkColor } =
    useTheming()

  const updateData = computed(() => ({
    id: game.value.id,
    profile: {
      name: game.value.name,
      summary: game.value.summary,
      status: game.value.status,
      name_id: game.value.name_id,
    },
    theme: {
      primary: primaryColor.value,
      dark: darkColor.value,
      success: successColor.value,
      warning: warningColor.value,
      danger: dangerColor.value,
    },
    images: {
      icon: game.value.icon,
      header: game.value.header,
      logo: game.value.logo,
    },
    links: {
      other_urls: game.value.other_urls,
      email: game.value.email,
      support_url: game.value.support_url,
    },
  }))

  if (!originalData.value || game.value.id !== originalData.value.id) {
    originalData.value = clone(updateData.value)
  }

  return {
    originalData,
    updateData,
  }
}
