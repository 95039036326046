import { CARD_IMAGE_KEY } from '@config'
import { isArray } from '@helpers/utils.js'

// create accessible card image
export function extractCardImage(placeholder) {
  return function (resource) {
    resource.card_image =
      (resource.logo && resource.logo[CARD_IMAGE_KEY]) || placeholder

    return resource
  }
}

class Serializer {
  constructor({ transformations = [], options = {}, defaults = {} } = {}) {
    this.transformations = transformations
    this.options = options
    this.defaults = defaults
  }

  transform(initialData) {
    if (initialData.data) {
      const dataCollection = [...initialData.data]
      const data = dataCollection
        .map((data, index, array) => this._transform(data, index, array))
        .filter((item) => item !== null)

      return {
        ...initialData,
        data,
      }
    } else {
      return this._transform(initialData)
    }
  }

  addTransformation(transformation) {
    if (isArray) {
      this.transformations = transformation.concat(this.transformations)
    }
  }

  _transform(data, index, array) {
    let dataCopy = { ...data }

    if (this.transformations.length > 0) {
      this.transformations.forEach((func) => {
        dataCopy = func.call(this, dataCopy, index, array)
      })
    }
    if (dataCopy) {
      return { ...this.options, ...this.defaults, ...dataCopy }
    }

    return null
  }

  getNew(initialData) {
    return { ...this.options, ...this.defaults, ...initialData }
  }
}

export default Serializer
