import { LS_CACHE_END_TS } from '@config/localstorage.js'
import { secsToMs, isNumber } from '@helpers/utils.js'
import { useLocalStorage } from '@vueuse/core'
import { CACHE_TIME_SECONDS } from '@config'

const cacheEndTs = useLocalStorage(LS_CACHE_END_TS, null)
export default function () {
  function setCacheLimit() {
    cacheEndTs.value = Date.now() + secsToMs(CACHE_TIME_SECONDS)
  }

  function isCacheBusting() {
    const cacheEndTsInt = parseInt(cacheEndTs.value)

    return isNumber(cacheEndTsInt) && cacheEndTsInt > Date.now()
  }

  function urlMatch({ method, url }) {
    // Add/edit/delete game & add mod
    if (/\/games\/@[a-zA-Z0-9-_]+\/[^/]+$/.test(url)) return true
    else if (url === '/games') return true
    else if (method === 'del' && /\/games\/@[a-zA-Z0-9-_]+/.test(url)) {
      return true
    } else if (/\/games\/@[a-zA-Z0-9-_]+\/team/.test(url)) return true
    else if (/\/games\/@[a-zA-Z0-9-_]+\/emh/.test(url)) return true

    // Edit/delete mod, add/edit/delete mod file
    if (/\/games\/@[a-zA-Z0-9-_]+\/mods\/@[a-zA-Z0-9-_]+$/.test(url)) {
      return true
    } else if (
      /\/games\/@[a-zA-Z0-9-_]+\/mods\/@[a-zA-Z0-9-_]+\/(?!ratings)(?!subscribe).+$/.test(
        url
      )
    ) {
      return true
    } else if (
      /\/games\/@[a-zA-Z0-9-_]+\/mods\/@[a-zA-Z0-9-_]+\/files\/[0-9]+$/.test(
        url
      )
    ) {
      return true
    }

    // Add or delete preview user
    if (/\/games\/@[a-zA-Z0-9-_]+\/preview\/users\/@[a-zA-Z0-9-_]+$/.test(url))
      return true
    else if (/\/games\/@[a-zA-Z0-9-_]+\/preview\/users$/.test(url)) return true

    // Add, edit, delete guide
    if (/\/games\/@[a-zA-Z0-9-_]+\/guides$/.test(url)) return true
    else if (/\/games\/@[a-zA-Z0-9-_]+\/guides\/@[a-zA-Z0-9-_]+$/.test(url))
      return true
    else if (
      /\/games\/@[a-zA-Z0-9-_]+\/guides\/@[a-zA-Z0-9-_]+\/comments/.test(url)
    )
      return true

    // Accept agreement
    if (/agreements\/versions\/[0-9]+\/accept/.test(url)) return true

    // Monetization
    if (
      /(\/monetisation|\/monetization|\/marketplace|\/partner|\/me\/register)/.test(
        url
      ) &&
      !url.includes('/authenticate')
    )
      return true

    // Resolve, delete reports
    if (/\/reports\/[0-9]+\//.test(url)) return true

    // Sub/unsub to a mod
    if (/\/games\/@[a-zA-Z0-9-_]+\/mods\/@[a-zA-Z0-9-_]+\/subscribe$/.test(url))
      return true

    // Sub/unsub to user
    if (/\/users\/@[a-zA-Z0-9-_]+\/((un)?subscribe)/.test(url)) return true

    // ban/unban user
    if (/\/admin\/users\/@[a-zA-Z0-9-_]+\/status/.test(url)) return true

    // Privacy
    if (
      /\/me\/(data|key|close|info|displayname|connections|email|invite)/.test(
        url
      )
    ) {
      return true
    } else if (/\/oauth\/(client|token)/.test(url)) return true
    else if (url.includes('/token/callback')) return true

    return false
  }

  return {
    isCacheBusting,
    setCacheLimit,
    cacheEndTs,
    urlMatch,
  }
}
