<template>
  <div class="tw-space-y-4">
    <base-select
      :model-value="showInput ? trigger.toString() : selected"
      :label="label"
      :options="options"
      :hint="hint"
      :tip="tip"
      :disabled="disabled"
      :label-between="false"
      @input="(input) => updateInput(input)"
    />
    <slot v-if="showInput" name="trigger" />
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { toRefs } from '@vueuse/core'
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: [String, Number],
      default: '',
    },
    tip: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: '',
    },
    trigger: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { selected, trigger, options } = toRefs(props)
    const showInput = ref(false)

    onMounted(() => {
      if (
        selected.value.toString() === trigger.value ||
        !options.value.map((v) => v.value).includes(selected.value)
      ) {
        showInput.value = true
      }
    })

    function updateInput(input) {
      showInput.value = input.toString() === trigger.value
      emit('input', input)
    }

    return { updateInput, showInput }
  },
}
</script>
