import { readonly, ref } from 'vue'

const trendsChartRendering = ref(true)
// Delay other charts from rendering and prioritize trends chart.
const delay = 1000
let timeout

function refreshTrendsChartRenderState() {
  if (timeout) clearTimeout(timeout)
  trendsChartRendering.value = true

  timeout = setTimeout(() => {
    trendsChartRendering.value = false
  }, delay)
}

export default function () {
  return {
    trendsChartRendering: readonly(trendsChartRendering),
    refreshTrendsChartRenderState,
  }
}
