<template>
  <div v-if="recentGames.length" class="tw-pt-4">
    <div class="tw-w-full tw-h-px tw-bg-primary-text tw-opacity-20 tw-mb-4" />
    <p class="tw-text-xs tw-opacity-70 tw-text-center">Recent</p>
    <div class="recent-games">
      <tooltip-hover
        v-for="{ name_id, id, name, image_url, pinned } in recentGames"
        :key="id"
        position="right"
        background="primary-hover"
        :text="name"
        outside-main
        class="tw-mt-2"
      >
        <router-link
          :to="{ name: MOD_BROWSE_ROUTE, params: { game: name_id } }"
          class="tw-flex tw-h-11 tw-w-11 tw-group tw-justify-center tw-items-center tw-cursor-pointer tw-button-transition tw-outline-none tw-filter-game-icon tw-overflow-hidden tw-border-2 tw-p-0.5 tw-rounded-lg"
          :class="checkActiveGameID(name_id)"
        >
          <img
            :src="image_url"
            :alt="name"
            class="tw-w-full tw-h-full tw-object-cover tw-object-center tw-rounded"
          />
          <button
            class="tw-absolute -tw-left-1 -tw-top-1 tw-bg-black tw-border tw-border-white/70 tw-w-5 tw-h-5 tw-rounded-full tw-hidden group-hover:tw-flex tw-items-center tw-justify-center"
            @click.prevent="togglePin(name_id)"
          >
            <font-awesome-icon
              :icon="pinned ? 'lock' : 'lock-open'"
              class="tw-h-2.5 tw-text-white"
              :class="{ 'tw-w-2.5 tw-h-2.5': pinned }"
              :style="{ width: !pinned && '9px' }"
            />
          </button>
        </router-link>
      </tooltip-hover>
    </div>
  </div>
</template>

<script>
import TooltipHover from '@components/Message/TooltipHover.vue'
import { useRecentlyViewGames, useRoute } from '@composables'
import { MOD_BROWSE_ROUTE } from '@config/routeNames.js'
export default {
  components: {
    TooltipHover,
  },
  setup() {
    const { recentGames, togglePin } = useRecentlyViewGames()
    const { getGameId } = useRoute()
    const gameNameId = getGameId()

    function checkActiveGameID(name_id) {
      return name_id === gameNameId.value
        ? 'tw-border-primary-hover tw-filter-game-icon-active tw-opacity-100'
        : 'tw-border-transparent tw-filter-game-icon tw-opacity-70 hover:tw-opacity-100 focus:tw-opacity-100'
    }

    return {
      checkActiveGameID,
      MOD_BROWSE_ROUTE,
      recentGames,
      gameNameId,
      togglePin,
    }
  },
}
</script>

<style scoped lang="css">
.recent-games {
  @media screen and (max-height: 809px) {
    *:nth-child(n + 5) {
      display: none;
    }
  }
  @media screen and (max-height: 758px) {
    *:nth-child(n + 4) {
      display: none;
    }
  }
  @media screen and (max-height: 705px) {
    *:nth-child(n + 3) {
      display: none;
    }
  }
  @media screen and (max-height: 652px) {
    *:nth-child(n + 2) {
      display: none;
    }
  }
}
</style>
