<template>
  <div
    class="tw-p-5 md:tw-pt-8 tw-flex tw-flex-col tw-h-full tw-overflow-auto tw-util-scrollbar"
  >
    <div class="tw-flex tw-items-center tw-space-x-4 md:tw-space-x-0 tw-mb-6">
      <base-button
        class="md:tw-hidden"
        secondary
        icon="chevron-left"
        @click="backToSidebar"
      />
      <router-link
        :to="{ name: HOME_ROUTE }"
        class="tw-inline-flex tw-outline-none focus:tw-text-primary"
      >
        <div class="tw-relative">
          <base-icon
            icon="modio_reg_fullwhite"
            class="tw-w-32 tw-fill-current"
          />
        </div>
      </router-link>
    </div>

    <nav-links :links="NAV_LINKS" />

    <div class="tw-space-y-2 xs:tw-space-y-5 tw-mt-auto">
      <ul class="tw-flex tw-space-x-3">
        <li
          class="tw-flex tw-justify-center md:tw-justify-start tw-space-x-4 sm:tw-space-x-6"
        >
          <a
            v-for="(social, socialIndex) in SOCIAL_LINKS"
            :key="socialIndex"
            :href="social.link"
            target="_blank"
            rel="noreferrer noopener"
            class="tw-flex tw-items-center tw-justify-center tw-text-black tw-outline-none tw-home-button-transition tw-shrink-0"
            :alt="social.title"
          >
            <div
              class="tw-bg-white tw-rounded-full tw-size-8 tw-flex tw-items-center tw-justify-center hover:tw-bg-primary-hover focus:tw-bg-primary-hover"
            >
              <base-icon
                v-if="social.icon.includes('twitter')"
                icon="x-twitter_logo"
                class="tw-size-4"
              />
              <font-awesome-icon
                v-else
                :icon="social.icon"
                :size="social.icon.includes('facebook-f') ? 'xl' : 'lg'"
              />
            </div>
          </a>
        </li>
      </ul>
      <p class="tw-opacity-50 tw-leading-tight tw-text-xs">
        &copy; {{ copyText }}
      </p>
    </div>
  </div>
</template>

<script>
import { COPYRIGHT_TEXT, SOCIAL_LINKS, NAV_LINKS } from '@config/sidebar.js'
import NavLinks from '@components/Navbar/NavLinks.vue'
import { HOME_ROUTE } from '@config/routeNames.js'
import { useNavMenus } from '@composables'

export default {
  components: {
    NavLinks,
  },
  setup() {
    const copyText = `${new Date().getFullYear()} ${COPYRIGHT_TEXT}`
    const { backToSidebar } = useNavMenus()

    return {
      backToSidebar,
      SOCIAL_LINKS,
      HOME_ROUTE,
      NAV_LINKS,
      copyText,
    }
  },
}
</script>
