import { GAME_COMMUNITY_SHOW_ON_HOMEPAGE } from '@config/options.js'
import { createFormRequest } from '@helpers/createFormRequest.js'
import { requestAllLazy } from '@helpers/requestAll.js'
import { queryString } from '@helpers/utils.js'
import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { get, put, uploadFile, post, del } = createClient()

/* API request get all public games */
export function getPublicGamesRequest(
  dataRef,
  loadingRef,
  queries = {},
  superAdmin = false
) {
  if (superAdmin) {
    queries.show_admin_counts = true
    queries.show_review_status = true
    queries.with_user = true
  } else {
    queries['community_options-bitwise-and'] = GAME_COMMUNITY_SHOW_ON_HOMEPAGE
  }
  queries.show_hidden_tags = 1

  return requestAllLazy(
    `${PUBLIC_BASE_URL}/games`,
    dataRef,
    loadingRef,
    queries
  )
}

/* API request get game details from public API */
export async function getPublicGameRequest(gameId) {
  const prefix = typeof gameId === 'number' ? '' : '@'

  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/${prefix}${gameId}?show_hidden_tags=1`
  )
  return data
}

/* API request get game details from an OAuth client ID */
export async function getGameFromClientInfoRequest(client_id) {
  const { data } = await get(`/oauth/client/describe?client_id=${client_id}`)
  return data
}

/* API request contact the game's team */
export async function contactGameTeamRequest(gameNameId, contactForm) {
  const { data } = await post(
    `/games/@${gameNameId}/team/contact`,
    qs.stringify(contactForm)
  )
  return data
}

/* API request get game details from cogs API */
export async function getCogsGameRequest(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}`)
  return data
}

/* API request get game stats */
export async function getGameStatsRequest(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}/stats`)
  return data
}

/* API request to upload game's info */
export async function updateGameInfoRequest(gameNameId, updates) {
  const { data } = await put(
    `/games/@${gameNameId}/info`,
    qs.stringify(updates)
  )
  return data
}

/* API request to upload game's images */
export async function uploadGameImageRequest(
  gameNameId,
  upload,
  onUploadProgress
) {
  const { data } = await uploadFile(
    `/games/@${gameNameId}/media`,
    upload,
    onUploadProgress
  )
  return data
}

/* API request to update game's content */
export async function updateGameContentRequest(gameNameId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/content`,
    qs.stringify(update)
  )
  return data
}

/* API request to update game's link */
export async function updateGameLinksRequest(gameNameId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/links`,
    qs.stringify(update)
  )
  return data
}

/* API request to update game's connections */
export async function updateGameConnectionsRequest(gameNameId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/connections`,
    qs.stringify(update)
  )
  return data
}

/* API request to update game's community options */
export async function updateGameCommunityRequest(gameNameId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/community`,
    qs.stringify(update)
  )
  return data
}

/* API request to update game's platform options */
export async function updateGamePlatformsRequest(gameNameId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/platforms`,
    qs.stringify(update)
  )
  return data
}

/* API request to update game's documentation access options */
export async function updateGameDocsAccessRequest(gameNameId, update) {
  const { data } = await put(`/games/@${gameNameId}/docs`, qs.stringify(update))
  return data
}

/* API request to update game's revenue */
export async function updateGameRevenueRequest(gameNameId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/revenue`,
    qs.stringify(update)
  )
  return data
}

/* API request to update game's API key */
export async function updateGameApiKeyRequest(gameNameId, api_key) {
  const { data } = await put(
    `/games/@${gameNameId}/apikey`,
    qs.stringify({ api_key })
  )
  return data
}

/* API request to update game's OpenID config */
export async function updateGameOpenIdConfigRequest(gameNameId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/sso/openid`,
    qs.stringify(update)
  )
  return data
}

/* API request to test game's OpenID config */
export async function testGameOpenIdConfigRequest(gameNameId, update) {
  const { data } = await post(
    `/games/@${gameNameId}/sso/openidtest`,
    qs.stringify(update)
  )
  return data
}

/* API request to update game's OAuth config */
export async function updateGameOAuthConfigRequest(gameNameId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/sign-in-with-modio`,
    qs.stringify(update)
  )
  return data
}

/* API request to delete game */
export async function deleteGameRequest(gameNameId) {
  const { data } = await del(`/games/@${gameNameId}`)
  return data
}

/* API request to update game plan */
export async function updateGamePlanRequest(gameNameId, planId) {
  const { data } = await put(
    `/games/@${gameNameId}/plan`,
    qs.stringify({ plan: planId })
  )
  return data
}

/* API request to update game status */
export async function updateGameStatusRequest(gameNameId, status) {
  const { data } = await put(
    `/games/@${gameNameId}/status`,
    qs.stringify({ status })
  )
  return data
}

/* API request to add new game */
export async function addNewGameRequest(game) {
  const { data } = await post('/games', qs.stringify(game))
  return data
}

/* API request to get all denied user permissions */
export async function getDeniedUserGamePermissionsRequest(gameNameId, queries) {
  const { data } = await get(
    `/games/@${gameNameId}/users/permissions${queryString(queries)}`
  )
  return data
}

/* API request get user's permissions */
export async function getUserGamePermissionsRequest(gameNameId, userNameId) {
  const { data } = await get(
    `/games/@${gameNameId}/users/@${userNameId}/permissions`
  )
  return data
}

/* API request get all partners for a game */
export async function getGamePartnersRequest(gameNameId, queries) {
  const { data } = await get(
    `/games/@${gameNameId}/partners${queryString(queries)}`
  )
  return data
}

/* API request get all moderations for a game */
export async function getGameModerationsRequest(gameNameId, queries) {
  const { data } = await get(
    `/games/@${gameNameId}/moderations${queryString(queries)}`
  )
  return data
}

/* API request to add a moderation */
export async function addModerationRequest(gameNameId, modNameId, update) {
  const { data } = await post(
    `/games/@${gameNameId}/mods/@${modNameId}/moderations`,
    qs.stringify(update)
  )
  return data
}

/* API request to toggle a moderation */
export async function toggleModerationRequest(
  gameNameId,
  modNameId,
  effect,
  reason
) {
  const update = { effect }
  if (reason) {
    update.reason = reason
  }
  const { data } = await put(
    `/games/@${gameNameId}/mods/@${modNameId}/moderations/toggle`,
    qs.stringify(update)
  )
  return data
}

/* API request to get logs for a moderation */
export async function getModerationLogsRequest(gameNameId, modNameId) {
  const { data } = await get(
    `/games/@${gameNameId}/mods/@${modNameId}/moderations/logs`
  )
  return data
}

/* API request update user's permissions */
export async function updateUserGamePermissionsRequest(
  gameNameId,
  permissions
) {
  const { data } = await put(
    `/games/@${gameNameId}/permissions`,
    qs.stringify(permissions)
  )
  return data
}

/* API request to update Studio sso */
export async function updateStudioSSORequest(gameNameId, update) {
  const request = createFormRequest(update)

  const { data } = await post(`/games/@${gameNameId}/sso/oauth`, request, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
}

/* API request to update Studio sso mapping */
export async function updateStudioSSOMappingRequest(gameNameId, update) {
  const { data } = await put(`/games/@${gameNameId}/sso`, qs.stringify(update))

  return data
}

/* API request to get game's SSO config */
export async function getGameSSOConfigRequest(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}/sso/web-login`)
  return data
}

/* API request to get game's EMH config */
export async function getGameEMHConfig(gameNameId, id) {
  id = id ? `/${id}` : ''
  const { data } = await get(`/games/@${gameNameId}/emh${id}`)
  return data
}

/* API request to create a game's EMH config */
export async function createGameEMHConfig(gameNameId, update) {
  const { data } = await post(`/games/@${gameNameId}/emh`, qs.stringify(update))
  return data
}

/* API request to update a game's EMH config */
export async function updateGameEMHConfig(gameNameId, id, update) {
  const { data } = await put(
    `/games/@${gameNameId}/emh/${id}`,
    qs.stringify(update)
  )
  return data
}

/* API request to delete a game's EMH config */
export async function deleteGameEMHConfig(gameNameId, id) {
  const { data } = await del(`/games/@${gameNameId}/emh/${id}`)
  return data
}

/* API request to get game's word lists */
export async function getGameWordLists(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}/lists`)
  return data
}

/* API request to create a word list for a game */
export async function addGameWordList(gameNameId, update) {
  const { data } = await post(
    `/games/@${gameNameId}/lists`,
    qs.stringify(update)
  )
  return data
}

/* API request to edit a game's word list */
export async function updateGameWordList(gameNameId, listId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/lists/${listId}`,
    qs.stringify(update)
  )
  return data
}

/* API request to delete a game's word list */
export async function deleteGameWordList(gameNameId, listId) {
  const { data } = await del(`/games/@${gameNameId}/lists/${listId}`)
  return data
}

/* API request to link a game to a list feature */
export async function linkGameWordList(gameNameId, listId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/lists/${listId}/link`,
    qs.stringify(update)
  )
  return data
}
