<template>
  <template v-if="item.label">
    <div v-if="!top" class="tw-w-full tw-h-px tw-bg-dark-3 tw-my-6" />
    <p class="tw-uppercase tw-font-normal tw-opacity-50 tw-mb-6">
      {{ item.label }}
    </p>
  </template>
  <base-link
    v-if="to"
    :to="to"
    class="tw-block"
    :class="customClass"
    :target="item.external ? '_blank' : null"
    :link-primary="false"
    :hover-primary="true"
    @click="triggerModal"
  >
    {{ item.name }}
    <font-awesome-icon v-if="item.external" icon="external-link-alt" />
  </base-link>
</template>

<script>
import { setGlobalModal } from '@composables/useModal.js'
import * as MODALS from '@config/globalModals.js'
import { toRefs, computed } from 'vue'
import {
  MONETIZATION_TERMS_ROUTE,
  REFUND_POLICY_ROUTE,
  PRIVACY_ROUTE,
  COOKIES_ROUTE,
  REPORT_ROUTE,
  TERMS_ROUTE,
  AUP_ROUTE,
} from '@config/routeNames.js'
import {
  AGREEMENT_TYPE_MONETIZATION,
  AGREEMENT_TYPE_PRIVACY,
  AGREEMENT_TYPE_COOKIES,
  AGREEMENT_TYPE_REFUND,
  AGREEMENT_TYPE_AUP,
  AGREEMENT_TYPE_TOU,
} from '@config/options.js'

const agreementRouteTypes = {
  [TERMS_ROUTE]: AGREEMENT_TYPE_TOU,
  [PRIVACY_ROUTE]: AGREEMENT_TYPE_PRIVACY,
  [COOKIES_ROUTE]: AGREEMENT_TYPE_COOKIES,
  [MONETIZATION_TERMS_ROUTE]: AGREEMENT_TYPE_MONETIZATION,
  [REFUND_POLICY_ROUTE]: AGREEMENT_TYPE_REFUND,
  [AUP_ROUTE]: AGREEMENT_TYPE_AUP,
}

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    top: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { item, active } = toRefs(props)

    const to = computed(() => {
      if (item.value.external) return item.value.link
      if (item.value.modal) return `/${item.value.link}`
      return { name: item.value.link }
    })

    const customClass = computed(() => {
      if (item.value.external) {
        return 'tw-flex tw-w-full tw-items-center tw-justify-between'
      }
      return active.value ? 'tw-text-primary' : ''
    })

    function triggerModal(e) {
      if (!item.value.modal) {
        return
      }

      e.preventDefault()
      switch (item.value.link) {
        case REPORT_ROUTE:
          setGlobalModal({ modal: MODALS.MODAL_REPORT })
          break
        default:
          setGlobalModal({
            modal: MODALS.MODAL_AGREEMENT,
            data: {
              agreementType: agreementRouteTypes[item.value.link],
            },
          })
          break
      }
    }

    return {
      triggerModal,
      customClass,
      to,
    }
  },
}
</script>
