<template>
  <div ref="target" class="tw-flex tw-shrink-0 tw-relative">
    <transition name="fade" mode="out-in">
      <profile-popover
        v-if="hovered && !hidePopover && !inputLoading"
        :user="user"
      ></profile-popover>
    </transition>

    <div
      class="tw-flex tw-space-x-3 tw-items-center"
      :class="{
        'tw-filter-brightness-hover tw-button-transition': !hidePopover,
      }"
    >
      <avatar-icon
        :user="user"
        :size="size"
        :input-loading="inputLoading"
        :show-status="showStatus"
      ></avatar-icon>
      <span v-if="$slots.right">
        <slot name="right" />
      </span>
    </div>
  </div>
</template>

<script>
import ProfilePopover from '@components/Users/ProfilePopover.vue'
import AvatarIcon from '@components/Users/AvatarIcon.vue'
import { useHover } from '@composables'
import { ref } from 'vue'

export default {
  components: {
    ProfilePopover,
    AvatarIcon,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    hidePopover: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const target = ref(null)
    const { watchHover } = useHover()
    const hovered = watchHover(target)

    return {
      hovered,
      target,
    }
  },
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s 0.5s ease;
}
.fade-enter-from,
.fade-leave-active {
  opacity: 0;
  transition: none;
}
</style>
