import { clone } from '@helpers/utils'
import { ref, computed } from 'vue'

// Mod requires separate store compare to games
// because mod team is requested separately
export default function () {
  const state = ref(null)
  const team = computed(() => (state.value ? state.value : []))

  function addTeam(team) {
    state.value = team
  }

  function addMemberToTeam(member) {
    state.value.push(member)
  }

  function deleteTeamMember(member) {
    const stateCopy = clone(state.value)
    state.value = stateCopy.filter((m) => m.id !== member.id)
  }

  function updateTeamMember(memberAccess) {
    const memberIndex = state.value.findIndex((m) => m.id === memberAccess.id)
    if (memberIndex >= 0) {
      const stateCopy = clone(state.value)
      stateCopy.splice(memberIndex, 1, memberAccess)
      state.value = stateCopy
    }
  }

  return {
    deleteTeamMember,
    updateTeamMember,
    addMemberToTeam,
    addTeam,
    team,
  }
}
