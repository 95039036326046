import { createClient } from '@services'

const { get, put } = createClient()

export async function getAllNotificationsRequest() {
  const { data } = await get('/me/notifications?_sort=-id')
  return data
}

export async function markAllNotificationsAsReadRequest() {
  const { data } = await put('/me/notifications/all/read')
  return data
}

export async function markNotificationAsReadRequest(notificationId) {
  const { data } = await put(`/me/notifications/${notificationId}/read`)

  return data
}

// TODO: Add functionality to delete notifications
// For now users will only get 100 most recent notifications
export async function deleteNotificationRequest() {}
export async function deleteAllNotificationRequest() {}
