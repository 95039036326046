import { get as getStorage, set as setStorage } from 'idb-keyval'
import { trimSubscribedMod } from '@serializers/modConverter.js'
import modSerializer from '@serializers/modSerializer.js'
import { clone, isArray } from '@helpers/utils.js'
import { useSessionStorage } from '@vueuse/core'
import { ref, computed, watch } from 'vue'
import {
  SS_SUBSCRIPTIONS_REFRESHED,
  LS_SUBSCRIPTIONS,
} from '@config/localstorage.js'

const subscriptionState = ref([])
const fetchedSubscriptionsState = useSessionStorage(
  SS_SUBSCRIPTIONS_REFRESHED,
  null
)

async function init() {
  try {
    const state = await getStorage(LS_SUBSCRIPTIONS)
    if (state) {
      subscriptionState.value = state
    } else {
      // Temp migrate LS to IndexedDB
      // Remove after this has been deployed for a while
      const lsState = localStorage.getItem(LS_SUBSCRIPTIONS)
      if (lsState) {
        subscriptionState.value = JSON.parse(lsState)
        localStorage.removeItem(LS_SUBSCRIPTIONS)
      }
    }
  } catch (error) {
    console.error(error)
  }
}

if (process.env.NODE_ENV !== 'test') {
  init()

  watch(subscriptionState, (newState) => {
    setStorage(LS_SUBSCRIPTIONS, clone(newState))
  })
}

export default function () {
  const subscriptions = computed(() => subscriptionState.value)
  const fetchedSubscriptions = computed(() =>
    JSON.parse(fetchedSubscriptionsState.value || '[]')
  )

  function clearSubscriptions() {
    subscriptionState.value = []
  }

  function setMySubscriptions(_subscriptions, gameId) {
    const data = modSerializer
      .transform({ data: _subscriptions })
      .data.map(trimSubscribedMod)

    if (gameId) {
      const filtered = subscriptions.value.filter((m) => m.game_id !== gameId)
      subscriptionState.value = [...filtered, ...data]
    } else {
      subscriptionState.value = data
    }

    return data
  }

  // use game and mod id
  function addSubscription(modId, modData) {
    modData = trimSubscribedMod(modData)
    if (isArray(subscriptions.value)) {
      const subscription = subscriptions.value.find(
        (sub) => sub && sub.id === parseInt(modId)
      )

      if (!subscription) {
        setMySubscriptions([...subscriptions.value, modData])
      }
    } else {
      setMySubscriptions([modData])
    }
  }

  // use game and mod id
  function removeSubscription(modId) {
    const updated = subscriptions.value.filter(
      (subscription) => subscription && subscription.id !== parseInt(modId)
    )

    setMySubscriptions(updated)
  }

  function hasSubscriptionForUser(userNameId) {
    return computed(() =>
      subscriptions.value?.some(
        (mod) => mod.submitted_by.name_id === userNameId
      )
    )
  }

  function setFetchedSubscription(gameNameId) {
    fetchedSubscriptionsState.value = JSON.stringify([
      ...new Set([...fetchedSubscriptions.value, gameNameId]),
    ])
  }

  return {
    setFetchedSubscription,
    hasSubscriptionForUser,
    fetchedSubscriptions,
    setMySubscriptions,
    clearSubscriptions,
    removeSubscription,
    addSubscription,
    subscriptions,
  }
}
