<template>
  <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-text-center">
    <span>
      By continuing you agree to the

      <a
        class="tw-link"
        :href="`/${TERMS_ROUTE}`"
        @click.prevent="openTermsModal"
      >
        Terms of Use
      </a>

      and
      <a
        class="tw-link"
        :href="`/${PRIVACY_ROUTE}`"
        @click.prevent="openPrivacyModal"
      >
        Privacy Policy
      </a>
      and must be 13 years of age or older
    </span>
  </div>
</template>

<script>
import { AGREEMENT_TYPE_PRIVACY, AGREEMENT_TYPE_TOU } from '@config/options.js'
import { PRIVACY_ROUTE, TERMS_ROUTE } from '@config/routeNames.js'
import { setGlobalModal } from '@composables/useModal.js'
import { MODAL_AGREEMENT } from '@config/globalModals.js'

export default {
  setup() {
    function openTermsModal() {
      setGlobalModal({
        modal: MODAL_AGREEMENT,
        data: { agreementType: AGREEMENT_TYPE_TOU },
      })
    }

    function openPrivacyModal() {
      setGlobalModal({
        modal: MODAL_AGREEMENT,
        data: { agreementType: AGREEMENT_TYPE_PRIVACY },
      })
    }

    return {
      openPrivacyModal,
      openTermsModal,
      PRIVACY_ROUTE,
      TERMS_ROUTE,
    }
  },
}
</script>
