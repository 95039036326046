import { myTeamsStore, authStore, guideStore } from '@stores'
import { isNumber } from '@helpers/utils.js'
import { computed } from 'vue'
import {
  TEAM_MEMBER_LEVEL_ADMINISTRATOR,
  TEAM_MEMBER_LEVEL_MODERATOR,
  TEAM_MEMBER_LEVEL_MANAGER,
} from '@config/options.js'

export default function () {
  const { removeTeam, myTeams } = myTeamsStore()
  const { isSuperAdmin, isAccountClosed } = authStore()

  function _isInGameTeam(gameNameId) {
    return !!_getAccess({ resource: 'game', id: gameNameId })
  }

  function hasPermissionForGuideComputed(gameNameId, guideNameId) {
    const { getGuide } = guideStore()
    const { getUserId } = authStore()
    return computed(() => {
      const guide = getGuide(gameNameId, guideNameId)
      return (
        hasPermissionByNameId({
          resource: 'game',
          nameId: gameNameId,
          scope: 'moderator',
        }) || guide.value?.submitted_by?.id === getUserId()
      )
    })
  }

  /**
   * @param {string} modNameId
   * @param {string} gameNameId
   * @param {'leader' | 'admin' | 'manager' | 'moderator'} scope
   */
  function hasPermissionForMod(modNameId = '', gameNameId = '', scope) {
    return (
      _isInGameTeam(gameNameId) ||
      hasPermissionByNameId({
        resource: 'mod',
        nameId: modNameId,
        gameNameId,
        scope,
      })
    )
  }

  /**
   * @param {string} gameNameId
   * @param {'leader' | 'admin' | 'manager' | 'moderator'} scope
   */
  function hasPermissionForGame(gameNameId = '', scope) {
    return hasPermissionByNameId({
      resource: 'game',
      nameId: gameNameId,
      scope,
    })
  }

  /**
   * @param {{
   *  resource: String,
   *  nameId: String,
   *  scope: 'leader' | 'admin' | 'manager' | 'moderator'
   * }} options
   */
  function hasPermissionByNameId({ resource, nameId, gameNameId, scope }) {
    if (isSuperAdmin()) {
      return true
    }

    if (!resource || !nameId) {
      return false
    }

    const item = _getAccess({ resource, id: nameId, gameNameId })
    return !!item && _hasAccess(item, scope) && !isAccountClosed.value
  }

  function removeAccessByNameId({ resource, nameId }) {
    const team = myTeams.value.find((t) => t.name_id === nameId)
    if (team) removeTeam({ resource, id: team.resource_id })
  }

  function _hasAccess(item, scope) {
    if (scope === 'leader') {
      return !!item?.access?.is_leader
    }

    if (scope === 'moderator') scope = TEAM_MEMBER_LEVEL_MODERATOR
    else if (scope === 'manager') scope = TEAM_MEMBER_LEVEL_MANAGER
    else if (scope === 'admin') scope = TEAM_MEMBER_LEVEL_ADMINISTRATOR
    else throw new Error('Invalid scope')

    return item?.access?.level >= scope
  }

  // Pass in gameNameId to do explicit check, e.g. check mod of game.
  function _getAccess({ resource, id, gameNameId }) {
    const key = isNumber(id) ? 'resource_id' : 'name_id'

    return (
      myTeams.value &&
      myTeams.value.find((item) => {
        return (
          item[key] == id &&
          item.resource_type === resource &&
          item.access?.invite_pending === 0 &&
          (gameNameId ? gameNameId === item.game_name_id : true)
        )
      })
    )
  }

  function isApiPermissionError(errorCode) {
    return errorCode === 403
  }

  return {
    hasPermissionForGuideComputed,
    hasPermissionByNameId,
    removeAccessByNameId,
    hasPermissionForGame,
    isApiPermissionError,
    hasPermissionForMod,
  }
}
