<template>
  <div ref="target">
    <conversation-header-button icon="ellipsis-v" @click="toggle" />
    <conversation-dropdown
      :other-user-name-id="otherUserNameId"
      :other-user-id="otherUserId"
      :show="show"
      :group-chat="groupChat"
      :group-member="groupMember"
      @trigger:report="$emit('trigger:report', otherUserId)"
    />
  </div>
</template>

<script>
import ConversationDropdown from '@components/Conversation/OptionsDropdown/ConversationDropdown.vue'
import ConversationHeaderButton from '@components/Conversation/ConversationHeaderButton.vue'
import { onClickOutside } from '@vueuse/core'
import { ref, computed, toRefs } from 'vue'
import { useToggle } from '@composables'
export default {
  components: {
    ConversationHeaderButton,
    ConversationDropdown,
  },
  props: {
    otherUserId: {
      type: Number,
      default: 0,
    },
    otherUserNameId: {
      type: String,
      default: '',
    },
    participants: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['trigger:report'],
  setup(props) {
    const { show, toggle, close } = useToggle()
    const { participants } = toRefs(props)
    const target = ref(null)
    const groupChat = computed(() => participants.value.length > 2)
    const groupMember = computed(() => participants.value.length === 0)

    onClickOutside(target, close)

    return {
      groupMember,
      groupChat,
      toggle,
      target,
      show,
    }
  },
}
</script>
