import { queryString } from '@helpers/utils.js'
import { createClient } from '@services'
import qs from 'qs'

const { get, put, post, del } = createClient()

export async function getPreviewURLRequest(gameNameId, modNameId = undefined) {
  const url = modNameId
    ? `/games/@${gameNameId}/mods/@${modNameId}/preview`
    : `/games/@${gameNameId}/preview`

  const { data } = await get(url)

  return data
}

export async function regeneratePreviewURLRequest(
  gameNameId,
  modNameId = undefined
) {
  const url = modNameId
    ? `/games/@${gameNameId}/mods/@${modNameId}/preview/regenerate`
    : `/games/@${gameNameId}/preview/regenerate`

  const { data } = await put(url)

  return data
}

export async function getPreviewURLUsersRequest(
  gameNameId,
  modNameId = undefined,
  queries
) {
  const url = modNameId
    ? `/games/@${gameNameId}/mods/@${modNameId}/preview/users${queryString(
        queries
      )}`
    : `/games/@${gameNameId}/preview/users${queryString(queries)}`

  const { data } = await get(url)

  return data
}

export async function addPreviewURLUserRequest(
  gameNameId,
  userInput,
  modNameId = undefined
) {
  const query = {
    [isNaN(userInput) ? 'email' : 'user_id']: userInput,
  }

  const url = modNameId
    ? `/games/@${gameNameId}/mods/@${modNameId}/preview/users`
    : `/games/@${gameNameId}/preview/users`

  const { data } = await post(url, qs.stringify(query))

  return data
}

export function deletePreviewURLUserRequest(
  gameNameId,
  userNameId,
  modNameId = undefined
) {
  const url = modNameId
    ? `/games/@${gameNameId}/mods/@${modNameId}/preview/users/@${userNameId}`
    : `/games/@${gameNameId}/preview/users/@${userNameId}`

  return del(url)
}

export async function previewAccessRequest(
  gameNameId,
  previewHash,
  modNameId = undefined
) {
  const url = modNameId
    ? `/games/@${gameNameId}/mods/@${modNameId}/preview`
    : `/games/@${gameNameId}/preview`

  const { data } = await post(url, qs.stringify({ preview_hash: previewHash }))

  return data
}
