import { genRanId, secsToMs } from '@helpers/utils.js'
import { ref, readonly } from 'vue'
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_TITLE,
  TOAST_SECONDS,
} from '@config'

const queue = ref([])
// {
//   title: 'toast title',
//   text: 'toast text',
//   isError: true,
//   hasClose: false,
// }

export const activeToasts = readonly(queue)

export function addToast(
  {
    title,
    text,
    isSuccess,
    isInfo,
    isWarning,
    isError,
    hasClose,
    errorRef,
    animate = true,
    timeout = TOAST_SECONDS,
  } = {},
  name = genRanId()
) {
  const toast = {
    name,
    title,
    text,
    isSuccess,
    isInfo,
    isWarning,
    isError,
    hasClose,
    errorRef,
    animate,
    timeout,
  }

  queue.value.push(toast)

  if (!hasClose) {
    setTimeout(() => {
      closeToast(name)
    }, secsToMs(timeout))
  }
}

export function defaultErrorToast() {
  addToast({
    title: DEFAULT_ERROR_TITLE,
    text: DEFAULT_ERROR_MESSAGE,
    isError: true,
  })
}

export function closeToast(name) {
  queue.value = queue.value.filter((q) => q.name !== name)
}
