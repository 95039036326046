import { clone, isObjEmpty } from '@helpers/utils.js'
import { ref, computed, readonly } from 'vue'

const state = ref({ store: {} })
const onUpdateListeners = ref([])

export default function () {
  function getStore(key) {
    return computed(() =>
      state.value.store[key] ? readonly(state.value.store[key]) : null
    )
  }

  function generateId(key, ...ids) {
    return key.concat(`-${ids.join('-')}`)
  }

  function createStore(key, data, cloneData = true) {
    const _data = cloneData ? clone(data) : data
    state.value.store = {
      ...state.value.store,
      [key]: _data,
    }
    onUpdateListeners.value.forEach((cb) => cb(key, { update: _data }))
  }

  function updateStore(key, data = null) {
    createStore(key, data, false)
  }

  // delete or refactor to be reusable function
  function updateStoreData(key, input, update) {
    const data = Object.assign({}, state.value.store[key], {
      [input]: update,
    })
    updateStore(key, data)
  }

  function deleteStoreArrayData(key, cb) {
    const store = { ...state.value.store[key] }
    if (isObjEmpty(store)) return

    const data = [...store.data]

    let count = 0
    data.forEach((item, index) => {
      if (cb(item)) {
        data.splice(index, 1)
        count++
      }
    })

    if (
      store.result_count &&
      store.result_total &&
      ['_q', 'id', 'tags-in', 'platforms'].every((key) => !store.query?.[key])
    ) {
      store.result_count -= count
      store.result_total -= count
    }
    store.data = data

    updateStore(key, store)
  }

  function addToStoreData(key, item) {
    const store = state.value.store[key]
    if (store?.data?.length) {
      const storeData = [...store.data]
      const index = storeData.findIndex((resource) => resource.id === item.id)

      index !== -1 ? storeData.splice(index, 1, item) : storeData.push(item)

      if (index === -1 && store.result_count && store.result_total) {
        updateStore(key, {
          ...store,
          data: storeData,
          result_count: store.result_count + 1,
          result_total: store.result_total + 1,
        })
      } else {
        updateStore(key, { ...store, data: storeData })
      }
    } else {
      createStore(key, {
        ...store,
        data: [item],
      })
    }
  }

  function getKeys() {
    return Object.keys(state.value.store)
  }

  function addOnUpdateListener(callback) {
    onUpdateListeners.value.push(callback)
  }

  return {
    deleteStoreArrayData,
    addOnUpdateListener,
    updateStoreData,
    addToStoreData,
    updateStore,
    createStore,
    generateId,
    getStore,
    getKeys,
  }
}
