import { useEventListener, useBreakpoints } from '@vueuse/core'
import { ref, isRef, computed } from 'vue'
import { useNavMenus } from '@composables'
import { BREAKPOINTS } from '@config'

const MIN_SWIPE_X_DIST = 60
const MAX_SWIPE_Y_DIST = 40
const MIN_FAST_CLOSE_X_DIST = 50
const MAX_FAST_CLOSE_TIME_MS = 250
const CLASSES_TO_IGNORE = ['vue--picture-cropper__wrap']

export default function (target) {
  if (!isRef(target)) {
    throw new TypeError()
  }

  const { showMobMenu, mobMenuToggle } = useNavMenus()
  const { greaterOrEqual } = useBreakpoints(BREAKPOINTS)
  const mdAndUp = greaterOrEqual('md')
  const mobileMenuX = ref(0)
  const touchId = ref(-1)
  let startTouchX = 0
  let startTouchY = 0
  let startTouchTime = 0
  let mode = null
  let startOpen = false

  const swiping = computed(() => touchId.value >= 0)

  useEventListener('touchstart', (touchEvent) => {
    if (mdAndUp.value) return

    if (
      touchId.value === -1 &&
      touchEvent
        .composedPath()
        .every(
          (elem) =>
            !elem.scrollLeft &&
            elem.tagName !== 'CANVAS' &&
            CLASSES_TO_IGNORE.every((c) => !elem.classList?.contains(c))
        )
    ) {
      touchId.value = touchEvent.touches?.[0].identifier
      startTouchX = touchEvent.touches?.[0].clientX
      startTouchY = touchEvent.touches?.[0].clientY
      startTouchTime = Date.now()
      startOpen = showMobMenu.value
      mode = null
    }
  })

  useEventListener('touchmove', (touchEvent) => {
    const touch = Array.from(touchEvent.touches).find(
      (touch) => touch.identifier === touchId.value
    )
    if (touch) {
      const deltaX = touch.clientX - startTouchX
      const deltaY = Math.abs(touch.clientY - startTouchY)

      if (!mode) {
        if (deltaY > MAX_SWIPE_Y_DIST) {
          mode = 'scroll'
        } else if (
          deltaX > MIN_SWIPE_X_DIST ||
          (showMobMenu.value && deltaX < -MIN_SWIPE_X_DIST)
        ) {
          mode = 'swipe'
        }
      }

      if (mode === 'swipe') {
        if (deltaX >= MIN_SWIPE_X_DIST && !showMobMenu.value) {
          // Open menu if we swipe enough to the right
          mobMenuToggle()
        } else if (deltaX < -MIN_SWIPE_X_DIST && startOpen) {
          startOpen = false
        }

        const sidebarWidth = target.value.clientWidth
        // If we started swiping whilst the sidebar was open, clamp the
        // x position to the rightmost point unless swiping left.
        const touchX = startOpen && deltaX > 0 ? 9999 : touch.clientX
        // Since '0' is fully open, we need to invert the x position
        mobileMenuX.value = sidebarWidth - Math.min(touchX, sidebarWidth)
      }
    }
  })

  function touchUp(touchEvent) {
    if (touchId.value === -1) return

    const touch = Array.from(touchEvent.changedTouches).find(
      (touch) => touch.identifier === touchId.value
    )
    if (touch) {
      const deltaX = touch.clientX - startTouchX
      const deltaTime = Date.now() - startTouchTime
      if (
        showMobMenu.value &&
        (mobileMenuX.value > target.value.clientWidth * 0.6 || // close if less than 40% of sidebar is showing
          (deltaTime <= MAX_FAST_CLOSE_TIME_MS &&
            deltaX <= -MIN_FAST_CLOSE_X_DIST)) // close if swiped left quickly
      ) {
        mobMenuToggle()
      }

      touchId.value = -1
      mobileMenuX.value = 0
    }
  }

  useEventListener('touchend', touchUp)
  useEventListener('touchcancel', touchUp)

  return {
    mobileMenuX,
    swiping,
  }
}
