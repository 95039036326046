<template>
  <div
    class="tw-flex tw-items-center tw-h-4.5 tw-label-gap"
    :class="labelWidth"
  >
    <div
      class="tw-h-2 tw-rounded-full"
      :class="[labelWidth, altBg ? 'tw-bg-theme' : 'tw-bg-theme-1']"
    ></div>
  </div>
</template>
<script>
import { computed, toRefs } from 'vue'
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    random: {
      type: Boolean,
      default: false,
    },
    altBg: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { random, small } = toRefs(props)
    const sizes = ['12', '14', '18']

    const labelWidth = computed(() => {
      if (small.value) {
        return 'tw-w-10'
      }
      return random.value ? twSize() : 'tw-w-14'
    })

    function twSize() {
      const width = sizes[Math.floor(Math.random() * sizes.length)]
      return `tw-w-${width}`
    }

    return {
      labelWidth,
    }
  },
}
</script>
