import { MOD_BROWSE_ROUTE, GUIDE_BROWSE_ROUTE } from '@config/routeNames.js'
import { usePermissions, useRoute, useStorage } from '@composables'
import { STATUS_NOT_ACCEPTED } from '@config/options.js'
import { LS_TEST_ENV } from '@config/localstorage.js'
import { computed, ref, watch } from 'vue'
import { clone } from '@helpers/utils.js'
import { gameStore } from '@stores'
import { MODIO_ENV } from '@config'

export const BANNER_TEST_ENV = 'testEnv'
const hiddenGameBannerName = 'hiddenGameBanner'

const state = ref([])

export default function () {
  const routes = [MOD_BROWSE_ROUTE, GUIDE_BROWSE_ROUTE]
  const { hasPermissionForGame } = usePermissions()
  const { getGameId, routeName } = useRoute()
  const { getPublicGame } = gameStore()

  const gameNameId = getGameId()
  const activeBanners = computed(() => state.value.filter((b) => b.show))
  const showBanner = computed(() => routes.includes(routeName.value))

  function addWarningBanner(
    name,
    {
      title,
      text,
      status,
      cta,
      isWarning,
      isError,
      isSuccess,
      isInfo,
      noDismiss,
      customComponent,
      show = true,
    } = {}
  ) {
    const activeBanners = clone(state.value)
    activeBanners.push({
      name,
      title,
      text,
      status,
      cta,
      isWarning,
      isError,
      isSuccess,
      isInfo,
      noDismiss,
      customComponent,
      show,
    })
    state.value = activeBanners
  }

  function showHiddenGameBanner(show) {
    if (!show) {
      state.value = state.value.filter((b) => b.name !== hiddenGameBannerName)
      return
    }

    if (!state.value.some((banner) => banner.name === hiddenGameBannerName)) {
      const onGameTeam = hasPermissionForGame(gameNameId.value, 'moderator')

      const text = `This game is currently disabled/hidden. ${
        !onGameTeam
          ? 'You can see it as you have been granted preview access.'
          : MODIO_ENV === 'test'
            ? 'It must stay hidden on the test environment but you can create a game on the production environment when you are ready to go live.'
            : "We recommend you keep it hidden while you integrate support for mod.io in-game. Once you are ready for the community to begin submitting levels and apps, change the visibility field on your game's edit page to launch."
      }`

      addWarningBanner(hiddenGameBannerName, {
        title: 'Game status',
        status: 'Hidden',
        noDismiss: true,
        isError: true,
        text,
      })
    }
  }

  const isGameHidden = computed(() => {
    if (!showBanner.value || !gameNameId.value) return false

    const game = getPublicGame(gameNameId.value)
    return game.value?.status === STATUS_NOT_ACCEPTED
  })

  watch(isGameHidden, showHiddenGameBanner, { immediate: true })

  function toggle(name) {
    const banner = state.value.find((banner) => banner.name === name)

    if (banner) {
      banner.show = !banner.show

      if (name === BANNER_TEST_ENV) {
        useStorage(LS_TEST_ENV, true).value = 'true'
      }
    }
  }

  function clearBanners() {
    state.value = []
  }

  return {
    addWarningBanner,
    activeBanners,
    clearBanners,
    toggle,
  }
}
