import { separateCamelCase, isObjEmpty, isArray } from '@helpers/utils.js'
import { getCurrentInstance } from 'vue'

export default function () {
  const instance = getCurrentInstance()

  function getComponentList() {
    const store = {}
    const comps = {
      ...instance.type.components,
      ...instance.appContext.components,
    }

    for (const [key, value] of Object.entries(comps)) {
      if (key !== 'PropsList' && key !== 'RouterLink' && key !== 'RouterView') {
        if (value.props) {
          store[key] = { name: key, props: _formatProps(value.props) }
        } else {
          store[key] = { name: key, message: 'No props currently avalaible' }
        }
      }
    }
    return store
  }

  function _formatProps(props) {
    if (isObjEmpty(props)) {
      return null
    }
    let temp = []
    for (const [key, obj] of Object.entries(props)) {
      temp.push({
        name: separateCamelCase(key),
        default: _defaultOption(obj.default),
        type: _typeName(key),
      })
    }
    return { ...temp }
  }

  function _defaultOption(str) {
    if (str === '') {
      return `''`
    } else if (str === null) {
      return 'null'
    } else {
      return str
    }
  }

  function _typeName(type) {
    if (type !== null) {
      if (isArray(type)) {
        let temp = []
        type.forEach((item, index) => {
          if (index === type.length - 1) {
            temp += `${item.name}`
          } else {
            temp += `${item.name}, `
          }
        })
        return temp
      } else {
        return type.name
      }
    }
    return 'n/a'
  }

  return {
    getComponentList,
  }
}
