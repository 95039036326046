import { useStatus, useAsync, addToast, useTable, useSort } from '@composables'
import { isNumber, setBits, unsetBits, isString } from '@helpers/utils.js'
import { email as isEmail } from '@helpers/validationRules.js'
import { baseSchema } from '@config/schemas/previewUsers.js'
import getGameContentForm from '@helpers/gameContentForm.js'
import { mergeApiResultData } from '@helpers/requestAll.js'
import getModContentForm from '@helpers/modContentForm.js'
import { cogsGameStore, cogsModStore } from '@stores'
import { ref, computed } from 'vue'
import {
  allowGamePreviewURLs,
  allowModPreviewURLs,
  allowGamePreview,
  allowModPreview,
} from '@helpers/bitHelper.js'
import {
  GAME_COMMUNITY_ALLOW_PREVIEW_URLS,
  MOD_COMMUNITY_ALLOW_PREVIEW_URLS,
  GAME_COMMUNITY_ALLOW_PREVIEW,
  MOD_COMMUNITY_ALLOW_PREVIEW,
} from '@config/options.js'
import {
  regeneratePreviewURLRequest,
  deletePreviewURLUserRequest,
  getPreviewURLUsersRequest,
  updateGameContentRequest,
  addPreviewURLUserRequest,
  getPreviewURLRequest,
  updateModRequest,
} from '@services'

const PREVIEW_OPTIONS = {
  DISABLED: 0,
  ACCESS_ONLY: 1,
  ANYONE: 2,
}

const TABLE_ID = 'preview-users-table'

export default function (game, mod) {
  const { saveCogGame, updateGameField } = cogsGameStore()
  const { updateModField, saveMod } = cogsModStore()
  const savedEnabled = ref(getPreviewValue())
  const addUserErrors = ref([])
  const users = ref([])

  const userTotal = computed(() => users.value?.result_total)

  const { defaultSortQuery } = useTable()
  const { getSortQuery } = useSort(TABLE_ID)

  const {
    loading: loadingGetURL,
    run: runGetURL,
    data: dataGetURL,
  } = useAsync(() =>
    getPreviewURLRequest(game.value.name_id, mod.value?.name_id)
  )

  const {
    loading: loadingPreviewUsers,
    error: previewUsersError,
    data: previewUsersData,
    run: runGetPreviewUsers,
  } = useAsync(
    (query) =>
      getPreviewURLUsersRequest(game.value.name_id, mod.value?.name_id, query),
    'Error getting users with preview access'
  )

  const {
    loading: loadingAddUser,
    error: errorAddUser,
    run: runAddUser,
  } = useAsync(
    (userInput) =>
      addPreviewURLUserRequest(
        game.value.name_id,
        userInput,
        mod.value?.name_id
      ),
    'Failed to add user'
  )

  const {
    loading: loadingContent,
    error: errorContent,
    run: runContent,
  } = useAsync(
    (update) => updateGameContentRequest(game.value.name_id, update),
    'Failed to update preview link access'
  )

  const {
    loading: loadingRegenURL,
    error: errorRegenURL,
    data: dataRegenURL,
    run: runRegenURL,
  } = useAsync(
    () => regeneratePreviewURLRequest(game.value.name_id, mod.value?.name_id),
    'Failed to regenerate preview link'
  )

  const {
    loading: loadingDeleteUser,
    error: deleteUserError,
    run: runDeleteUser,
  } = useAsync(
    (userNameId) =>
      deletePreviewURLUserRequest(
        game.value.name_id,
        userNameId,
        mod.value?.name_id
      ),
    'Failed to remove user from preview access'
  )

  const {
    loading: loadingModUpdate,
    error: modUpdateError,
    run: runModUpdate,
  } = useAsync(
    (update) =>
      updateModRequest(game.value.name_id, mod.value?.name_id, update),
    'Failed to update preview link access'
  )

  const {
    updateStatus: updateUserStatus,
    status: userStatus,
    statusType,
  } = useStatus({
    loading: loadingAddUser,
    error: errorAddUser,
  })

  const { updateStatus: updateContentStatus, status: contentStatus } =
    useStatus({
      loading: loadingContent,
      error: errorContent,
    })

  const { updateStatus: updateModContentStatus, status: modContentStatus } =
    useStatus({
      loading: loadingModUpdate,
      error: modUpdateError,
    })

  const { updateStatus: updateRegenStatus, status: regenStatus } = useStatus({
    loading: loadingRegenURL,
    error: errorRegenURL,
  })

  const { status: deleteStatus, updateStatus: updateDeleteStatus } = useStatus({
    loading: loadingDeleteUser,
    error: deleteUserError,
  })

  async function addUser(userInput) {
    if (!addUserHasErrors(userInput)) {
      await runAddUser(userInput)

      if (!errorAddUser.value) {
        fetchUsers()
        updateUserStatus(statusType.SUCCESS)
        addToast({
          title: 'Added new user successfully',
          isSuccess: true,
        })
      }
    }
  }

  async function deleteUser(removeUserId) {
    await runDeleteUser(removeUserId)

    if (!deleteUserError.value) {
      fetchUsers()
      addToast({
        title: 'Successfully removed user from preview access',
        isSuccess: true,
      })

      updateDeleteStatus(statusType.SUCCESS)
    }
  }

  async function fetchUsers(query) {
    const _query = getTableQuery(query)

    await runGetPreviewUsers(_query)

    if (!previewUsersError.value) {
      users.value = previewUsersData.value
    }
  }

  async function fetchUsersPaginatePrev(query) {
    await runGetPreviewUsers(getTableQuery(query))
    if (!previewUsersError.value) {
      users.value = mergeApiResultData(previewUsersData.value, users.value)
    }
  }

  async function fetchUsersPaginateNext(query) {
    await runGetPreviewUsers(getTableQuery(query))
    if (!previewUsersError.value) {
      users.value = mergeApiResultData(users.value, previewUsersData.value)
    }
  }

  async function regen() {
    await runRegenURL()

    if (!errorRegenURL.value) {
      updateRegenStatus(statusType.SUCCESS)
      dataGetURL.value = dataRegenURL.value
      addToast({
        title: 'Regenerated preview link successfully',
        isSuccess: true,
      })
    }
  }

  async function updateContent() {
    const update = mod.value
      ? getModContentForm(mod.value)
      : getGameContentForm(game.value, false)

    if (!mod.value) {
      update.allow_previews = allowGamePreview(game.value.community_options)

      update.allow_preview_url = allowGamePreviewURLs(
        game.value.community_options
      )

      await runContent(update)

      if (!errorContent.value) {
        updateContentStatus(statusType.SUCCESS)
        updateGameField('allow_previews', update.allow_previews)
        updateGameField('allow_preview_url', update.allow_preview_url)
        saveCogGame()
        savedEnabled.value = getPreviewValue()
        addToast({
          title: 'Updated preview link access successfully',
          isSuccess: true,
        })
      } else {
        updateContentStatus(statusType.ERROR)
      }
    } else {
      await runModUpdate(update)

      if (!modUpdateError.value) {
        updateModContentStatus(statusType.SUCCESS)
        saveMod(game.value.name_id)
        savedEnabled.value = getPreviewValue()
        addToast({
          title: 'Updated preview link access successfully',
          isSuccess: true,
        })
      } else {
        updateModContentStatus(statusType.ERROR)
      }
    }
  }

  function updatePreview(value) {
    let result = mod.value
      ? mod.value.community_options
      : game.value.community_options
    const previewBits = mod.value
      ? MOD_COMMUNITY_ALLOW_PREVIEW
      : GAME_COMMUNITY_ALLOW_PREVIEW
    const previewURLBits = mod.value
      ? MOD_COMMUNITY_ALLOW_PREVIEW_URLS
      : GAME_COMMUNITY_ALLOW_PREVIEW_URLS
    const updateField = mod.value ? updateModField : updateGameField

    switch (value) {
      case PREVIEW_OPTIONS.DISABLED:
        result = unsetBits(result, previewURLBits)
        result = unsetBits(result, previewBits)
        break

      case PREVIEW_OPTIONS.ACCESS_ONLY:
        result = unsetBits(result, previewURLBits)
        result = setBits(result, previewBits)
        break

      case PREVIEW_OPTIONS.ANYONE:
        result = setBits(result, previewBits)
        result = setBits(result, previewURLBits)
        break
    }

    updateField('community_options', result)
  }

  function getPreviewValue() {
    const preview = mod.value
      ? allowModPreview(mod.value.community_options)
      : allowGamePreview(game.value.community_options)

    const previewUrl = mod.value
      ? allowModPreviewURLs(mod.value.community_options)
      : allowGamePreviewURLs(game.value.community_options)

    if (preview) {
      return previewUrl ? PREVIEW_OPTIONS.ANYONE : PREVIEW_OPTIONS.ACCESS_ONLY
    }

    return PREVIEW_OPTIONS.DISABLED
  }

  function addUserHasErrors(userInput) {
    if (!userInput) {
      addUserErrors.value = ['Display name is required']
      return true
    }

    if (isNumber(userInput)) return false

    if (userInput.includes('@')) {
      const _error = isEmail({ input: userInput.value })
      if (isString(_error)) {
        addUserErrors.value = [
          'A valid email address or display name is required',
        ]
        return true
      } else {
        return false
      }
    }

    addUserErrors.value = ['You must select a user from the dropdown']
    return true
  }

  function getTableQuery(q) {
    const query = { ...q }

    if (!query._sort) {
      query._sort =
        getSortQuery.value?._sort || defaultSortQuery(baseSchema)._sort
    }

    return query
  }

  return {
    fetchUsersPaginatePrev,
    fetchUsersPaginateNext,
    loadingPreviewUsers,
    loadingDeleteUser,
    previewUsersError,
    previewUsersData,
    modContentStatus,
    deleteUserError,
    PREVIEW_OPTIONS,
    addUserErrors,
    updatePreview,
    updateContent,
    loadingGetURL,
    contentStatus,
    errorAddUser,
    deleteStatus,
    savedEnabled,
    regenStatus,
    dataGetURL,
    userStatus,
    deleteUser,
    fetchUsers,
    runGetURL,
    userTotal,
    TABLE_ID,
    addUser,
    regen,
    users,
  }
}
