<template>
  <textarea
    show-count
    :value="modelValue"
    type="textarea"
    :placeholder="placeholder"
    :maxlength="max"
    v-bind="$attrs"
    class="tw-relative tw-w-full tw-flex tw-input--p tw-resize-none tw-global--border-radius tw-button-transition tw-bg-input-group-hover tw-leading-normal tw-outline-none tw-appearance-none tw-input--text-size tw-overflow-auto tw-util-scrollbar"
    :class="[
      focusHeight,
      hasErrors ? 'tw-border-danger tw-border-2' : 'tw-input--focus',
    ]"
    @input="$emit('input', $event.target.value)"
    @change="$emit('change', $event.target.value)"
    @focus="$emit('focus')"
    @keypress.ctrl.enter="$emit('submit')"
  />

  <div
    class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-space-y-4 lg:tw-space-y-0 lg:tw-space-x-4 tw-mt-4"
    :class="
      expanded
        ? 'tw-opacity-100 tw-button-transition tw-flex'
        : 'tw-opacity-0 tw-hidden'
    "
  >
    <div class="tw-flex tw-flex-col tw--mt-2">
      <character-count
        v-if="expanded"
        class="tw-opacity-50"
        :count="modelValue.length"
        :min="min"
        :max="max"
      />
      <slot name="errors" />
    </div>

    <div
      class="tw-flex tw-flex-col sm:tw-flex-row tw-gap-3 tw-ml-auto tw-mt-1.5 tw-w-full sm:tw-w-auto"
    >
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
import CharacterCount from '@components/Input/CharacterCount.vue'
import { computed, toRefs } from 'vue'
export default {
  components: {
    CharacterCount,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input', 'change', 'focus', 'submit'],
  setup(props) {
    const { errors, expanded } = toRefs(props)
    const hasErrors = computed(() => !!errors.value?.length)

    const focusHeight = computed(() => {
      const classList = 'tw-py-1.5 xs:tw-py-7px tw-px-4'

      return expanded.value
        ? `tw-h-20 xs:tw-h-28 ${classList}`
        : `tw-h-20 xs:tw-h-10 ${classList}`
    })

    return {
      focusHeight,
      hasErrors,
    }
  },
}
</script>
