import { LS_NOTIFICATIONS } from '@config/localstorage.js'
import { useLocalStorage } from '@vueuse/core'
import { isObj } from '@helpers/utils.js'
import { computed } from 'vue'

const notificationState = useLocalStorage(LS_NOTIFICATIONS, null)
const MIN_REFRESH_TIME = 5 * 60 * 1000 // 5 mins
let prevNotificationState

export default function () {
  const notifications = computed(() => {
    try {
      const _notifications = JSON.parse(notificationState.value)
      return isObj(_notifications) ? _notifications : { data: [], time: 0 }
    } catch {
      return { data: [], time: 0 }
    }
  })

  const unreadCount = computed(
    () => notifications.value.data?.filter((n) => !n.read).length
  )

  const canRefresh = computed(
    () => Date.now() - (notifications.value.time || 0) > MIN_REFRESH_TIME
  )

  function addNotifications(_notifications) {
    _setNotifications({ ..._notifications, time: Date.now() })
  }

  function markNotificationAsRead(notificationId) {
    prevNotificationState = notifications.value
    _setNotifications({
      ...notifications.value,
      data: notifications.value.data.map((n) => ({
        ...n,
        read: n.id === notificationId || n.read,
      })),
    })
  }

  function markAllAsRead() {
    prevNotificationState = notifications.value
    _setNotifications({
      ...notifications.value,
      data: notifications.value.data.map((n) => ({
        ...n,
        read: true,
      })),
    })
  }

  function _setNotifications(_notifications) {
    notificationState.value = JSON.stringify(_notifications)
  }

  function rollback() {
    _setNotifications(prevNotificationState)
  }

  function clearNotifications() {
    _setNotifications(null)
  }

  return {
    markNotificationAsRead,
    clearNotifications,
    addNotifications,
    markAllAsRead,
    notifications,
    unreadCount,
    canRefresh,
    rollback,
  }
}
