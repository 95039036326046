<template>
  <p>
    You are using the mod.io test environment. It is a private environment for
    experimenting with mod.io. Manage
    <base-link :to="{ name: PROFILE_CONTENT_ROUTE }">your test games</base-link
    >, or
    <base-link :to="{ name: GAME_ADD_ROUTE }">add a test game</base-link> to get
    started. Once your testing is complete, you will need to create a
    <base-link to="https://mod.io">production environment</base-link> account
    and <base-link to="https://mod.io/g/add">setup your game</base-link>
    there to get a production API key you can use to go live. You can also
    <base-link to="mailto:developers@mod.io">contact us</base-link>
    if you would like to discuss a custom white-label solution.
  </p>
</template>

<script>
import { PROFILE_CONTENT_ROUTE, GAME_ADD_ROUTE } from '@config/routeNames.js'
export default {
  setup() {
    return {
      PROFILE_CONTENT_ROUTE,
      GAME_ADD_ROUTE,
    }
  },
}
</script>
