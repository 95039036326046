<template>
  <div
    class="tw-animate-pulse tw-w-full tw-h-full tw-relative"
    :class="{ 'tw-bg-theme-1': hasBg, 'tw-rounded-full': rounded }"
  >
    <font-awesome-icon
      class="tw-absolute tw-fill-current tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-text-5xl md:tw-text-6xl tw-opacity-30"
      icon="image"
    />
  </div>
</template>

<script>
export default {
  props: {
    hasBg: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
