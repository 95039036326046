<template>
  <div>
    <div
      class="tw-flex tw-items-center tw-justify-between tw-h-14 tw-pl-4 tw-pr-2.5 tw-relative"
    >
      <span class="tw-font-bold">Chat</span>
      <conversation-header-button
        sr-only="compose new message"
        icon="edit"
        @click="composeNewMessage"
      />
      <div
        class="tw-w-full tw-absolute tw-bottom-0 tw-left-0 tw-border-b tw-border-theme-2"
      />
    </div>

    <div
      class="tw-max-h-mob--nav md:tw-max-h-full--nav tw-util-scrollbar tw-overflow-y-auto tw-input--text-size"
    >
      <loading-chat-inbox v-if="loading" />
      <no-profile-items
        v-else-if="!messages.data.length"
        text="You do not have any messages"
        class="tw-text-xs tw-py-3"
      />
      <div v-else class="tw-h-full tw-overflow-y-auto tw-util-scrollbar">
        <message-row
          v-for="(thread, index) in messages.data"
          :key="index"
          :message="thread"
          :is-unread="unreadThreadIds.includes(thread.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ConversationHeaderButton from '@components/Conversation/ConversationHeaderButton.vue'
import NoProfileItems from '@components/Navbar/ProfileDropdown//NoProfileItems.vue'
import LoadingChatInbox from '@components/Loading/LoadingChatInbox.vue'
import MessageRow from '@components/Conversation/MessageRow.vue'
import { messageStore } from '@stores'
import { onMounted, ref } from 'vue'
import {
  useMessagePoll,
  useMessenger,
  useNavMenus,
  useThread,
} from '@composables'
export default {
  components: {
    ConversationHeaderButton,
    LoadingChatInbox,
    NoProfileItems,
    MessageRow,
  },
  setup() {
    const { unreadThreadIds, fetchInbox } = useMessagePoll()
    const { clear } = useThread()
    const { closeAllMenus } = useNavMenus()
    const { changeComponent, showHide, CONVERSATION_COMPONENTS } =
      useMessenger()
    const { messages, canRefresh } = messageStore()
    const loading = ref(false)

    onMounted(async () => {
      if (canRefresh.value) {
        loading.value = true

        await fetchInbox()

        loading.value = false
      }
    })

    function composeNewMessage() {
      clear()
      closeAllMenus()
      changeComponent(CONVERSATION_COMPONENTS.MESSAGE_COMPOSE)
      showHide(true)
    }

    return {
      composeNewMessage,
      unreadThreadIds,
      messages,
      loading,
    }
  },
}
</script>
