import { queryString } from '@helpers/utils.js'
import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { get, post, put, del } = createClient()

export async function getGameReportsRequest(gameNameId, query) {
  const { data } = await get(
    `/games/@${gameNameId}/reports${queryString(query)}`
  )
  return data
}

export async function getGameReportsSummaryRequest(gameNameId, query) {
  const { data } = await get(
    `/games/@${gameNameId}/reports/summary${queryString(query)}`
  )
  return data
}

export async function getModAdminReportsRequest(gameNameId, modNameId, query) {
  const { data } = await get(
    `/games/@${gameNameId}/mods/@${modNameId}/reports${queryString(query)}`
  )
  return data
}

export async function bulkResolveModAdminReportsRequest(
  gameNameId,
  modNameId,
  reportIds
) {
  const { data } = await put(
    `/games/@${gameNameId}/mods/@${modNameId}/reports/resolve`,
    qs.stringify({ report_ids: reportIds })
  )
  return data
}

export async function submitReportRequest(report) {
  const { data } = await post(`${PUBLIC_BASE_URL}/report`, qs.stringify(report))
  return data
}

export async function resolveReportRequest(gameNameId, reportId, via) {
  const { data } = await post(
    `/games/@${gameNameId}/reports/${reportId}/resolve?via=${via}`,
    null,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
  return data
}

export async function gameBulkResolveReportRequest(gameNameId, reportIds) {
  const { data } = await put(
    `/games/@${gameNameId}/reports/resolve`,
    qs.stringify({ report_ids: reportIds })
  )
  return data
}

export async function adminBulkResolveReportRequest(reportIds) {
  const { data } = await put(
    `/admin/reports/resolve`,
    qs.stringify({ report_ids: reportIds })
  )
  return data
}

export async function deleteReportRequest(gameNameId, reportId) {
  const { data } = await del(
    `/games/@${gameNameId}/reports/${reportId}`,
    null,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
  return data
}

export async function getAdminReportsRequest(query) {
  const { data } = await get(`/admin/reports${queryString(query)}`)
  return data
}

export async function getAdminReportsSummaryRequest(query) {
  const { data } = await get(`/admin/reports/summary${queryString(query)}`)
  return data
}
