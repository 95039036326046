<template>
  <div
    v-for="(row, index) in 2"
    :key="index"
    class="tw-flex tw-flex-col tw-w-full tw-animate-pulse"
  >
    <div class="tw-py-2 tw-pl-4 tw-pr-3 tw-space-y-1 tw-relative">
      <div
        class="tw-absolute tw-top-0 tw-left-0 md:tw-left-auto md:tw-right-0 tw-h-full tw-w-2 tw-bg-theme-2"
      ></div>
      <div class="tw-flex">
        <span class="tw-w-4/6">
          <div class="tw-flex tw-items-center tw-h-4.5" :class="twSize()">
            <div class="tw-w-full tw-h-2 tw-rounded-full tw-bg-theme-2"></div>
          </div>
        </span>
        <span class="tw-flex tw-justify-end tw-w-2/6">
          <div class="tw-flex tw-items-center tw-w-14 tw-h-4.5">
            <div class="tw-w-12 tw-h-2 tw-rounded-full tw-bg-theme-2"></div>
          </div>
        </span>
      </div>

      <div class="tw-flex tw-items-center tw-h-4.5" :class="twSizeContent()">
        <div class="tw-w-full tw-h-2 tw-rounded-full tw-bg-theme-2"></div>
      </div>
    </div>
    <div class="tw-w-full tw-h-px tw-bg-theme-2"></div>
  </div>
</template>

<script>
export default {
  setup() {
    const sizes = ['1/6', '1/5', '1/4']
    const content = ['3/6', '3/5', '3/4']

    function twSize() {
      let obj = sizes[Math.floor(Math.random() * sizes.length)]
      if (obj === '1/6') {
        return 'tw-w-1/6'
      } else if (obj === '1/5') {
        return 'tw-w-1/5'
      } else {
        return 'tw-w-1/4'
      }
    }
    function twSizeContent() {
      let obj = content[Math.floor(Math.random() * content.length)]
      if (obj === '3/6') {
        return 'tw-w-3/6'
      } else if (obj === '3/5') {
        return 'tw-w-3/5'
      } else {
        return 'tw-w-3/4'
      }
    }
    return {
      twSizeContent,
      twSize,
    }
  },
}
</script>
