<template>
  <div
    v-if="show && !showMobMenu && !showMenu && !showOverlay"
    class="tw-fixed sm:tw-w-100 tw-bottom-3 tw-inset-x-3 md:tw-left-21 tw-z-10"
  >
    <div
      class="tw-border-2 tw-rounded tw-border-primary tw-p-4 tw-bg-theme-3 tw-text-theme"
    >
      <p class="tw-mb-4">
        Our
        <a
          v-if="showTerms"
          class="tw-font-bold tw-underline hover:tw-text-primary focus:tw-text-primary"
          :link-primary="false"
          hover-primary
          :href="`/${TERMS_ROUTE}`"
          @click.prevent="openTermsModal(AGREEMENT_TYPE_TOU)"
        >
          Terms of Use</a
        >
        <span> and </span>
        <a
          v-if="showPrivacy"
          class="tw-font-bold tw-underline hover:tw-text-primary focus:tw-text-primary"
          :href="`/${PRIVACY_ROUTE}`"
          @click.prevent="openTermsModal(AGREEMENT_TYPE_PRIVACY)"
        >
          Privacy Policy</a
        >
        have been updated{{
          effectiveDate > msToSecs(Date.now())
            ? `, effective from ${humanDateFormat(secsToMs(effectiveDate))}`
            : ''
        }}. By continuing to use mod.io{{
          effectiveDate > msToSecs(Date.now()) ? ' after this date' : ''
        }}, you confirm you have read and agree to the new terms.
      </p>
      <base-button class="tw-m-auto" :status="status" primary @click="accept"
        >Accept</base-button
      >
    </div>
  </div>
</template>

<script>
import { AGREEMENT_TYPE_TOU, AGREEMENT_TYPE_PRIVACY } from '@config/options.js'
import { humanDateFormat, secsToMs, msToSecs } from '@helpers/utils.js'
import { PRIVACY_ROUTE, TERMS_ROUTE } from '@config/routeNames.js'
import { useNavMenus, useAsync, useStatus } from '@composables'
import { setGlobalModal } from '@composables/useModal.js'
import { MODAL_AGREEMENT } from '@config/globalModals.js'
import { authStore, agreementStore } from '@stores'
import { acceptAgreementVersion } from '@services'
import { computed, ref } from 'vue'
import {
  LATEST_PRIVACY_DATE_LIVE,
  LATEST_PRIVACY_VERSION,
  LATEST_TERMS_DATE_LIVE,
  LATEST_TERMS_VERSION,
} from '@config'

export default {
  setup() {
    const { showMobMenu, showMenu, showOverlay } = useNavMenus()
    const { agreementPopupState } = agreementStore()
    const { user, isLoggedIn } = authStore()
    const agreed = ref(false)

    const {
      loading: loadingAcceptTerms,
      run: runAcceptTerms,
      error: errorAcceptTerms,
    } = useAsync(
      () => acceptAgreementVersion(LATEST_TERMS_VERSION),
      'Unable to accept terms of use'
    )

    const {
      loading: loadingAcceptPrivacy,
      run: runAcceptPrivacy,
      error: errorAcceptPrivacy,
    } = useAsync(
      () => acceptAgreementVersion(LATEST_PRIVACY_VERSION),
      'Unable to accept privacy policy'
    )

    const { status } = useStatus({
      loading: computed(
        () => loadingAcceptPrivacy.value || loadingAcceptTerms.value
      ),
    })

    const timeCheck = computed(() =>
      isLoggedIn.value ? user.value?.info?.date_online : msToSecs(Date.now())
    )

    const showTerms = computed(
      () =>
        LATEST_TERMS_DATE_LIVE > timeCheck.value &&
        (!agreementPopupState.value.accept_terms_version ||
          LATEST_TERMS_VERSION > agreementPopupState.value.accept_terms_version)
    )

    const showPrivacy = computed(
      () =>
        LATEST_PRIVACY_DATE_LIVE > timeCheck.value &&
        (!agreementPopupState.value.accept_privacy_version ||
          LATEST_PRIVACY_VERSION >
            agreementPopupState.value.accept_privacy_version)
    )

    const effectiveDate = computed(() => {
      if (
        showPrivacy.value &&
        LATEST_PRIVACY_DATE_LIVE < LATEST_TERMS_DATE_LIVE
      ) {
        return LATEST_PRIVACY_DATE_LIVE
      } else {
        return LATEST_TERMS_DATE_LIVE
      }
    })

    const show = computed(() => {
      if (agreed.value) return false

      return showTerms.value || showPrivacy.value
    })

    async function accept() {
      if (isLoggedIn.value) {
        const agreements = []
        if (showTerms.value) {
          agreements.push(runAcceptTerms())
        }
        if (showPrivacy.value) {
          agreements.push(runAcceptPrivacy())
        }
        await Promise.all(agreements)
      }

      if (!errorAcceptPrivacy.value && !errorAcceptTerms.value) {
        agreementPopupState.value = {
          accept_terms_version: showTerms.value ? LATEST_TERMS_VERSION : null,
          accept_privacy_version: showPrivacy.value
            ? LATEST_PRIVACY_VERSION
            : null,
        }
      }
      agreed.value = true
    }

    function openTermsModal(type) {
      setGlobalModal({
        modal: MODAL_AGREEMENT,
        data: {
          agreementType: type,
          versionId:
            type === AGREEMENT_TYPE_PRIVACY
              ? LATEST_PRIVACY_VERSION
              : LATEST_TERMS_VERSION,
        },
      })
    }

    return {
      AGREEMENT_TYPE_PRIVACY,
      AGREEMENT_TYPE_TOU,
      humanDateFormat,
      openTermsModal,
      PRIVACY_ROUTE,
      effectiveDate,
      showMobMenu,
      showOverlay,
      showPrivacy,
      TERMS_ROUTE,
      showTerms,
      secsToMs,
      msToSecs,
      showMenu,
      accept,
      status,
      show,
    }
  },
}
</script>
