import { PREFERENCE } from '@components/Modals/CookieModal.vue'
import { useStorage as vueuseStorage } from '@vueuse/core'
import { LS_COOKIE } from '@config/localstorage.js'
import { useLocalStorage } from '@vueuse/core'
import { areBitsSet } from '@helpers/utils.js'

export const cookieState = useLocalStorage(LS_COOKIE, null)

export default function (key, isPreference = false) {
  const cookieState = vueuseStorage(LS_COOKIE, null, localStorage)
  const cookieValue = parseInt(cookieState.value) || 0
  const preferenceEnabled = areBitsSet(cookieValue, PREFERENCE)

  const data = vueuseStorage(
    key,
    '',
    !isPreference || preferenceEnabled ? localStorage : sessionStorage
  )

  return data
}
