class HttpError extends Error {
  constructor(response) {
    const errors = response?.data?.error
    super()
    this.code = response?.status
    this.message = errors?.message
    this.errors = errors?.errors
    this.status = response?.status
    this.errorRef = errors?.error_ref ?? 'N/A'
    this.method = response?.config?.method
  }
}

export default HttpError
