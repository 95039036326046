<template>
  <div
    class="tw-flex tw-flex-col tw-shadow-2xl tw-transform tw-transition-all tw-shrink-0 tw-border tw-border-grey tw-border-opacity-20 tw-global--border-radius-t tw-h-chatcalc tw-mb-14 md:tw-mb-0 tw-w-screen md:tw-h-auto md:tw-max-h-auto tw-bg-theme-3"
    :class="[
      expandChatWindow ? 'md:tw-w-chat-xl' : 'md:tw-w-80',
      show
        ? 'tw-translate-x-0 tw-opacity-100'
        : 'tw-translate-x-72 tw-opacity-0',
    ]"
  >
    <div
      class="tw-flex tw-justify-between tw-w-full tw-h-14 tw-items-center tw-border-b tw-border-theme-2 tw-shrink-0 tw-pl-4 tw-pr-2.5"
    >
      <slot name="header" />
    </div>
    <div
      class="tw-w-full tw-transition-all tw-max-h-mob--nav"
      :class="expandChatWindow ? 'md:tw-h-chat-xl' : 'md:tw-h-96'"
    >
      <slot v-if="!loading" name="body" />
    </div>
  </div>
</template>

<script>
import { useMessenger } from '@composables'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { expandChatWindow } = useMessenger()

    return {
      expandChatWindow,
    }
  },
}
</script>
