import { addToast, closeToast } from '@composables/useToast.js'
import { MAX_MULTIPART_PART_SIZE } from '@config'
import { genRanId } from '@helpers/utils.js'
import { useAsync } from '@composables'
import { computed, ref } from 'vue'
import {
  completeMultipartUploadSessionRequest,
  startMultipartUploadSessionRequest,
  addMultipartUploadPartRequest,
} from '@services'

const ERRORREF_PART_ALREADY_UPLOADED = 29015
const MAX_CONCURRENT_PART_UPLOADS = 3

export default function useMultipartUpload(gameNameId, modNameId, modName) {
  const loading = ref(false)
  const totalSize = ref(0)
  const partsProgress = ref({})

  const progress = computed(
    () =>
      Object.values(partsProgress.value).reduce(
        (sum, value) => sum + value,
        0
      ) / totalSize.value
  )

  const {
    error: errorStartSession,
    data: dataStartSession,
    run: runStartSession,
  } = useAsync(
    (filename) =>
      startMultipartUploadSessionRequest(gameNameId, modNameId, filename),
    'Failed to upload file'
  )

  const { error: errorCompleteSession, run: runCompleteSession } = useAsync(
    (uploadId) =>
      completeMultipartUploadSessionRequest(gameNameId, modNameId, uploadId),
    'Failed to upload file'
  )

  async function uploadMultipartFile(file) {
    const toastName = genRanId()

    try {
      loading.value = true
      partsProgress.value = {}
      totalSize.value = file.size

      // Start the session and retrieve the upload id
      await runStartSession(file.name)

      if (errorStartSession.value) {
        throw errorStartSession.value
      }

      const uploadId = dataStartSession.value.upload_id

      addToast(
        {
          title: 'File processing',
          isInfo: true,
          text: `Processing ${file.name} on ${modName}`,
          hasClose: true,
        },
        toastName
      )

      // Upload parts
      await _uploadParts(file, uploadId)

      // Complete the upload process
      await runCompleteSession(uploadId)

      if (errorCompleteSession.value) {
        throw errorCompleteSession.value
      }

      return uploadId
    } finally {
      loading.value = false
      closeToast(toastName)
    }
  }

  async function _uploadPart(uploadId, index, file, onComplete) {
    try {
      const startRange = index * MAX_MULTIPART_PART_SIZE
      const endRange = Math.min(
        (index + 1) * MAX_MULTIPART_PART_SIZE,
        file.size
      )
      const blob = file.slice(startRange, endRange)

      const result = await addMultipartUploadPartRequest(
        gameNameId,
        modNameId,
        uploadId,
        blob,
        `bytes ${startRange}-${endRange - 1}/${file.size}`,
        (_progress) => (partsProgress.value[index] = _progress.loaded)
      )

      onComplete(index, !!result?.data)
    } catch (error) {
      onComplete(index, error?.errorRef === ERRORREF_PART_ALREADY_UPLOADED)
    }
  }

  function _uploadParts(file, uploadId) {
    const numOfParts = Math.ceil(file.size / MAX_MULTIPART_PART_SIZE)
    // Create a queue of all the part indices
    const queue = [...new Array(numOfParts)].map((_, i) => i)
    let partsCompleted = 0

    return new Promise((resolve) => {
      const onPartComplete = (index, success) => {
        if (success) {
          if (++partsCompleted === numOfParts) {
            resolve()
          } else if (queue.length) {
            _uploadPart(uploadId, queue.shift(), file, onPartComplete)
          }
        } else {
          // Part failed - add it to the back of the queue to retry
          queue.push(index)
          _uploadPart(uploadId, queue.shift(), file, onPartComplete)
        }
      }

      // Start uploading the parts
      const startQueueSize = queue.length
      for (
        let i = 0;
        i < Math.min(startQueueSize, MAX_CONCURRENT_PART_UPLOADS);
        i++
      ) {
        _uploadPart(uploadId, queue.shift(), file, onPartComplete)
      }
    })
  }

  return {
    uploadMultipartFile,
    progress,
    loading,
  }
}
