import { SCROLL_TO_ID } from '@config/htmlIDs.js'
import { ref, computed } from 'vue'

const showProfileDropdown = ref(false)
const menuComponent = ref(null)
const showDropdown = ref(false)
const mobileMenu = ref(false)
const overlay = ref(false)

const MENU_COMPONENTS = {
  PROFILE_DROPDOWN: 'ProfileDropdown',
  NOTIFICATION_DROPDOWN: 'NotificationDropdown',
  MESSAGE_LIST: 'MessageList',
  MODIO_INFO: 'ModioInfo',
}

const PROFILE_COMPONENTS = [
  MENU_COMPONENTS.NOTIFICATION_DROPDOWN,
  MENU_COMPONENTS.PROFILE_DROPDOWN,
  MENU_COMPONENTS.MESSAGE_LIST,
]

export default function () {
  const showProfileMenu = computed(() => showProfileDropdown.value)
  const showMobMenu = computed(() => mobileMenu.value)
  const showMenu = computed(() => showDropdown.value)
  const showOverlay = computed(() => overlay.value)
  const menu = computed(() => menuComponent.value)

  function changeMenu(component) {
    if (!showDropdown.value && !PROFILE_COMPONENTS.includes(component)) {
      _openDropdown(component)
    } else if (
      !showProfileDropdown.value &&
      PROFILE_COMPONENTS.includes(component)
    ) {
      _openDropdown(component)
    } else if (menuComponent.value === component) {
      _closeDropdown()
    }
    menuComponent.value = component
  }

  function _openDropdown(component) {
    if (!PROFILE_COMPONENTS.includes(component)) {
      showDropdown.value = true
      showProfileDropdown.value = false
    } else {
      showDropdown.value = false
      showProfileDropdown.value = true
      if (mobileMenu.value) {
        _toggleMobMenu()
      }
    }

    _showOverlayComp()
  }

  function _closeDropdown() {
    showDropdown.value = false
    showProfileDropdown.value = false
    _hideOverlayComp()
  }

  function _toggleMobMenu() {
    mobileMenu.value = !mobileMenu.value
  }

  function closeMobMenu(hideOverlay = true) {
    if (hideOverlay && mobileMenu.value) {
      _hideOverlayComp()
    }
    mobileMenu.value = false
  }

  // overlay
  function _showOverlayComp() {
    overlay.value = true
    _addClass()
  }

  function _hideOverlayComp() {
    overlay.value = false
    _removeClass()
  }

  function _toggleOverlay() {
    overlay.value = !overlay.value
  }

  // misc
  function _addClass() {
    const overflowTarget = document.getElementById(SCROLL_TO_ID)
    if (overflowTarget) {
      overflowTarget.classList.add('tw-overflow-hidden')
    }
  }

  function _removeClass() {
    const overflowTarget = document.getElementById(SCROLL_TO_ID)
    if (overflowTarget) {
      overflowTarget.classList.remove('tw-overflow-hidden')
    }
  }

  function closeAllMenus() {
    closeMobMenu()
    _closeDropdown()
  }

  // main menu
  function mobMenuToggle() {
    if (showMenu.value || showProfileMenu.value) {
      closeAllMenus()
    } else {
      _toggleMobMenu()
      _toggleOverlay()
    }
  }

  // dropdown
  function showUserDropdown() {
    changeMenu(MENU_COMPONENTS.PROFILE_DROPDOWN)
  }

  function showNotificationDropdown() {
    changeMenu(MENU_COMPONENTS.NOTIFICATION_DROPDOWN)
  }

  function showMessagesDropdown() {
    changeMenu(MENU_COMPONENTS.MESSAGE_LIST)
  }

  function showModioFooterDropdown() {
    changeMenu(MENU_COMPONENTS.MODIO_INFO)
  }

  function backToSidebar() {
    showDropdown.value = false
    showProfileDropdown.value = false
  }

  return {
    showNotificationDropdown,
    showModioFooterDropdown,
    showMessagesDropdown,
    showUserDropdown,
    MENU_COMPONENTS,
    showProfileMenu,
    backToSidebar,
    menuComponent,
    mobMenuToggle,
    closeAllMenus,
    closeMobMenu,
    showOverlay,
    showMobMenu,
    showMenu,
    menu,
  }
}
