import { KEYS_MESSAGE_THREAD } from '@config/storeKeys.js'
import { isObj, isEqual, clone } from '@helpers/utils.js'
import { LS_MESSAGES } from '@config/localstorage.js'
import { useLocalStorage } from '@vueuse/core'
import { useMessagePoll } from '@composables'
import inboxEntry from '@types/inboxEntry.js'
import { authStore, store } from '@stores'
import { computed } from 'vue'

const { addToStoreData, getStore, createStore } = store()
const messageState = useLocalStorage(LS_MESSAGES, null)
const MIN_REFRESH_TIME = 5 * 60 * 1000 // 5 mins

function _createInboxEntry(thread, participants) {
  const temp = clone(inboxEntry)
  temp.id = thread.thread_id
  temp.last_message = thread
  temp.participants = participants
  temp.name = thread.name
  return temp
}

export default function () {
  const { getUserId } = authStore()

  const messages = computed(() => {
    try {
      const _messages = JSON.parse(messageState.value)
      return isObj(_messages) ? _messages : { data: [], time: 0 }
    } catch {
      return { data: [], time: 0 }
    }
  })

  const canRefresh = computed(
    () => Date.now() - (messages.value.time || 0) > MIN_REFRESH_TIME
  )

  function getThread(threadId) {
    return getStore(`${KEYS_MESSAGE_THREAD}-${threadId}`)
  }

  function addMessages(messages) {
    _setMessages({
      ...messages,
      // Sort threads newest - oldest
      data: messages.data.sort(
        (a, b) => b.last_message.date_published - a.last_message.date_published
      ),
      time: Date.now(),
    })
    useMessagePoll().addUnreadThreadIds(
      messages.data.filter((m) => !m.has_read).map((m) => m.id)
    )
  }

  function removeThreadFromInbox(threadId) {
    _setMessages({
      ...messages.value,
      data: messages.value.data.filter((entry) => entry.id !== threadId),
    })
  }

  function addThreadToInbox(thread, participants) {
    if (!thread || !participants) {
      throw new TypeError()
    }

    const newMessage = _createInboxEntry(thread, participants)

    _setMessages({
      ...messages.value,
      data: [newMessage, ...messages.value.data],
    })
  }

  function updateInboxThread(message) {
    if (!message) {
      throw new TypeError()
    }

    _setMessages({
      ...messages.value,
      data: messages.value.data.map((entry) =>
        entry.id === message.thread_id
          ? _createInboxEntry(message, entry.participants)
          : entry
      ),
    })
  }

  function getMessageThread(threadId) {
    return computed(() => {
      const threadMessages = getStore(`${KEYS_MESSAGE_THREAD}-${threadId}`)
      if (threadMessages.value?.data) {
        return threadMessages.value.data
      } else {
        return []
      }
    })
  }

  function addMessageThread(threadId, thread) {
    createStore(`${KEYS_MESSAGE_THREAD}-${threadId}`, {
      ...thread,
      // Sort messages oldest - newest
      data: [...thread.data].sort(
        (a, b) => a.date_published - b.date_published
      ),
    })
  }

  function addMessageToThread(threadId, data) {
    data.thread_id = parseInt(data.thread_id)
    addToStoreData(`${KEYS_MESSAGE_THREAD}-${threadId}`, data)
    return data
  }

  function updateMessageThreads(threads) {
    threads.forEach((t) => {
      addMessageThread(t.threadId, t.thread)
      updateInboxThread(t.thread.data[0])
    })
  }

  function getThreadIdForUsers(userIds) {
    const currentUserId = getUserId()
    const users = new Set([...userIds, currentUserId])
    const thread = messages.value.data.find((entry) => {
      const threadUsers = entry.participants.map((u) => u.id)
      return isEqual([...users].sort(), threadUsers.sort())
    })

    return thread ? thread.id : null
  }

  function _setMessages(_messages) {
    messageState.value = JSON.stringify(_messages)
  }

  function clearInbox() {
    _setMessages(null)
  }

  return {
    removeThreadFromInbox,
    updateMessageThreads,
    getThreadIdForUsers,
    addMessageToThread,
    updateInboxThread,
    addThreadToInbox,
    getMessageThread,
    addMessageThread,
    addMessages,
    clearInbox,
    canRefresh,
    getThread,
    messages,
  }
}
