import { MOD_ADMIN_MONETIZATION_STARTED_INDIVIDUAL_ROUTE } from '@config/routeNames.js'
import { useMonetization, useValidate, useRoute } from '@composables'
import { gameMarketplaceConfig } from '@config/validation.js'
import { ref, computed, provide } from 'vue'
import { monetizationStore } from '@stores'
import {
  isForcedScarcityEnabled,
  isScarcityEnabled,
} from '@helpers/bitHelper.js'
import {
  requiredNotZero,
  validateIf,
  required,
  between,
  checked,
} from '@helpers/validationRules.js'

/**
 * @param game ref
 * @param mod ref or null
 */
export default function (game, mod) {
  const { monetization } = monetizationStore()
  const modAmounts = ref({ min: 0, max: 0 })
  const { scarcity } = useMonetization()
  const { routeName } = useRoute()

  const onboardingMod = computed(
    () => routeName.value === MOD_ADMIN_MONETIZATION_STARTED_INDIVIDUAL_ROUTE
  )

  const marketplaceAmounts = computed(() =>
    mod
      ? modAmounts.value
      : {
          min: game.value.monetization_marketplace?.min,
          max: game.value.monetization_marketplace?.max,
          default: game.value.monetization_marketplace?.default,
        }
  )

  function minAmount(gameDefault) {
    return computed(() =>
      mod || gameDefault
        ? marketplaceAmounts.value.min
        : monetization.value.min_marketplace
    )
  }
  function maxAmount(gameDefault) {
    return computed(() =>
      mod || gameDefault
        ? marketplaceAmounts.value.max
        : monetization.value.max_marketplace
    )
  }

  function setModMarketplaceAmounts(val) {
    modAmounts.value = val
  }

  const gameScarcityEnabled = isScarcityEnabled(game.value.monetization_options)
  const gameScarcityForced = isForcedScarcityEnabled(
    game.value.monetization_options
  )

  function _betweenMinMax(gameDefault) {
    return function ({ input }) {
      return !marketplaceAmounts.value.max ||
        (input === 0 && !onboardingMod.value) ||
        (input >= minAmount(gameDefault).value &&
          input <= maxAmount(gameDefault).value)
        ? true
        : `Field must be between ${minAmount(gameDefault).value} & ${maxAmount(gameDefault).value}`
    }
  }

  function _isMinLowerThanMax({ input }) {
    if (input < maxAmount(false).value) return true
    return 'Min must be lower than max amount'
  }

  function _isMaxLowerThanMin({ input }) {
    if (input > marketplaceAmounts.value.min) return true
    return 'Max must be greater than min amount'
  }

  const validations = {
    default: [required, _betweenMinMax(!mod)],
    min: [required, _isMinLowerThanMax, _betweenMinMax(false)],
    max: [required, _isMaxLowerThanMin, _betweenMinMax(false)],
  }

  if (mod) {
    validations.amount = [
      validateIf(
        () =>
          gameScarcityEnabled && (gameScarcityForced || scarcity.value.enabled),
        between(0, game.value.max_stock),
        `Amount cannot exceed the maximum value of ${game.value.max_stock}`
      ),
      validateIf(
        () =>
          gameScarcityEnabled &&
          (gameScarcityForced || scarcity.value.enabled) &&
          mod.value?.price > 0,
        requiredNotZero,
        'Quantity is required'
      ),
    ]
    validations.scarcity = [
      validateIf(
        () => gameScarcityEnabled && gameScarcityForced && mod.value?.price > 0,
        checked,
        `Limited quantity is required by ${game.value.name} so you must enable this and set a quantity amount`
      ),
    ]
  } else {
    validations.token_name = gameMarketplaceConfig.validation.token_name
    validations.maxstock = [
      validateIf(
        () => scarcity.value.enabled,
        between(...gameMarketplaceConfig.maxstock.between)
      ),
    ]
  }

  const { errors: validationErrors, validate } = useValidate(validations)

  provide('validate', validate)
  provide('validationErrors', validationErrors)

  return {
    setModMarketplaceAmounts,
    marketplaceAmounts,
  }
}
