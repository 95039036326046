import { WIDGET_PREFIX_ROUTE } from '@config/routeNames.js'
import { clone, isArray } from '@helpers/utils.js'
import { publicRoutes } from './publicRoutes.js'
import { superAdmin } from './superAdmin.js'
import { loggedIn } from './loggedIn.js'
import { auth } from './auth.js'

let routes = [...auth, ...publicRoutes]

const allTransformations = [
  _parentMetaFieldToChildren('error'),
  _parentMetaFieldToChildren('seo'),
  _addWidgetRoutes,
  _childrenToMeta,
]
const superAdminTransformations = [_superAdminMeta, _requireAuth]
const loggedInTransformations = [_requireAuth]

// TODO: make transforms operate on a single route to avoid mutiple traversals
function getRoutes() {
  let _routes = clone(routes)
  let _superAdmin = clone(superAdmin)
  let _loggedIn = clone(loggedIn)

  superAdminTransformations.forEach(
    (callbackfn) => (_superAdmin = callbackfn(_superAdmin))
  )

  loggedInTransformations.forEach(
    (callbackfn) => (_loggedIn = callbackfn(_loggedIn))
  )

  _routes = _routes.concat(_superAdmin, _loggedIn)
  allTransformations.forEach((callbackfn) => (_routes = callbackfn(_routes)))

  return _routes
}

function _superAdminMeta(_routes) {
  _routes.forEach((r) => {
    if (r.children) {
      r.children.forEach((childRoute) => {
        childRoute.meta = {
          superAdmin: true,
          ...childRoute.meta,
        }
      })
    }
    r.meta = { superAdmin: true, ...r.meta }
  })

  return _routes
}

function _requireAuth(_routes) {
  _routes.forEach((r) => {
    if (r.children) {
      r.children.forEach((childRoute) => {
        childRoute.meta = {
          requiresAuth: true,
          ...childRoute.meta,
        }
      })
    }
    r.meta = { requiresAuth: true, ...r.meta }
  })
  return _routes
}

function _parentMetaFieldToChildren(field) {
  return (_routes) => {
    _routes.forEach((r) => {
      if (r.children) {
        r.children.forEach((childRoute) => {
          childRoute.meta = {
            [field]: clone(r.meta[field]),
            ...childRoute.meta,
          }
        })
      }
    })

    return _routes
  }
}

function _childrenToMeta(_routes) {
  _routes.forEach((r) => {
    if (r.children) {
      const childrenRouteNames = r.children.map((r) => r.name)
      if (r.name) {
        childrenRouteNames.push(r.name)
      }

      r.children.forEach((childRoute) => {
        childRoute.meta = {
          children: childrenRouteNames,
          ...childRoute.meta,
        }
      })
      r.meta = { children: childrenRouteNames, ...r.meta }
    }
  })
  return _routes
}

function _addWidgetRoutes(_routes) {
  let widgetRoutes = clone(
    _routes.filter(
      (r) =>
        r.meta?.widget || (r.children && r.children.some((r) => r.meta?.widget))
    )
  )
  let nonWidgetRoute = clone(
    _routes.filter((r) => !r.children?.some((r) => r.meta?.widget))
  )

  widgetRoutes = widgetRoutes.map((r) => {
    if (r.children && isArray(r.children)) {
      let children = clone(
        r.children.filter((childrenRoute) => childrenRoute.meta?.widget)
      )
      children = children.map((r) => _generateWidgetRoute(r))

      r.children = [...r.children, ...children]
    } else {
      r = _generateWidgetRoute(r)
    }
    return r
  })

  return [...widgetRoutes, ...nonWidgetRoute]
}

function _generateWidgetRoute(route) {
  delete route.breadcrumb
  route.name = `${route.name}-${WIDGET_PREFIX_ROUTE}`
  route.path = `${route.path}/${WIDGET_PREFIX_ROUTE}`
  if (route.props) {
    route.meta.widget.props = { ...route.meta.widget.props, ...route.props }
  }
  route.component = () =>
    import(/* webpackChunkName: 'widget' */ '@views/Widget.vue')
  return route
}

routes = getRoutes()

export default routes
