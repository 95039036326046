import { isObj, isString } from '@helpers/utils.js'
import { DEFAULT_ERROR_MESSAGE } from '@config'
import errorTable from '@config/errors.js'

class ErrorMessages {
  constructor(errorTable) {
    this.errorMap = isObj(errorTable)
      ? new Map(Object.entries(errorTable))
      : new Map()
  }

  getErrorMessage(key) {
    if (!key) {
      return DEFAULT_ERROR_MESSAGE
    }

    if (isString(key) && key.startsWith('error.')) {
      const message = this.errorMap.get(key)
      return message ? message : DEFAULT_ERROR_MESSAGE
    }

    return key
  }
}

export default new ErrorMessages(errorTable)
