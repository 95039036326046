import { BEHAVIOUR_TYPES, RULE_TYPES } from '@config/permissionsOptions.js'
import { useAsync, addToast, useRoute } from '@composables'
import { GAME_PLATFORM_LABELS } from '@config/options.js'
import { ALL_LOGIN_METHODS } from '@config/auth.js'
import { getCountryName } from '@helpers/utils.js'
import { ref, computed, readonly } from 'vue'
import {
  deleteRulesForGameRequest,
  getWebhookHistoryRequest,
  getRulesetHistoryRequest,
  postRetryWebhookRequest,
  postRulesForGameRequest,
  getRulesForGameRequest,
  getWebhookTokenRequest,
  putWebhookTokenRequest,
  postTestWebhookRequest,
  getRulesHistoryRequest,
  getRuleTypesRequest,
} from '@services/permissionsService.js'
import {
  GAME_ADMIN_MODERATION_ROUTE,
  ADMIN_MODERATION_ROUTE,
} from '@config/routeNames.js'
import {
  capitalisedFirstLetter,
  isNullOrUndefined,
  clone,
} from '@helpers/utils.js'

// config
const action = ref('')
const ruleTypes = ref([])

// game / site
const savedRules = ref([])
const notifyEmail = ref(null)
const gameNameIdRef = ref('')
const securityToken = ref('')
const accounts = Object.fromEntries(
  ALL_LOGIN_METHODS.map((x) => [x.type, x.label])
)

export default function () {
  const { getGameId } = useRoute()
  const gameNameId = getGameId().value
  const admin = !gameNameId

  const behaviours = computed(() => ruleTypes.value?.behaviors || [])
  const endpoints = computed(() => ruleTypes.value?.endpoints || [])
  const newSetRules = computed(() => getRulesWithPayloadFields(action.value))
  const actions = computed(() =>
    endpoints.value.map((x) => ({ text: x.name, value: x.id }))
  )

  const baseRule = {
    type: RULE_TYPES.USER_STATS,
    field: 'reports',
    operator: '=',
    value: '',
    field_option_value: '',
    connector: 'none',
    state: 1,
  }

  const baseBehaviour = {
    type: BEHAVIOUR_TYPES.ALLOW_DENY,
    action: 'allow',
    response_code: 200,
    message: '',
    state: 1,
  }

  if (admin) {
    gameNameIdRef.value = undefined
  }
  if (gameNameId && gameNameId !== gameNameIdRef.value) {
    gameNameIdRef.value = gameNameId
  }

  // Async calls & result variables
  const {
    loading: loadingRuleTypes,
    data: ruleTypesData,
    error: ruleTypesError,
    run: runGetRuleTypes,
  } = useAsync(
    () => getRuleTypesRequest(!gameNameIdRef.value),
    'Failed to get permission rule types'
  )

  const {
    loading: loadingGetRules,
    data: getRulesData,
    error: getRulesError,
    run: runGetRules,
  } = useAsync(
    () => getRulesForGameRequest(gameNameIdRef.value),
    'Failed to get permission rules'
  )

  const {
    loading: loadingDeleteRules,
    data: deleteRulesData,
    error: deleteRulesError,
    run: runDeleteRules,
  } = useAsync(
    () => deleteRulesForGameRequest(gameNameIdRef.value),
    'Failed to delete permission rules'
  )

  const {
    loading: loadingPostRules,
    data: postRulesData,
    error: postRulesError,
    run: runPostRules,
  } = useAsync(
    (rules) => postRulesForGameRequest(rules, gameNameIdRef.value),
    'Failed to update permission rules'
  )

  const {
    loading: loadingGetToken,
    data: ruleGetTokenData,
    error: ruleGetTokenError,
    run: runGetToken,
  } = useAsync(
    () => getWebhookTokenRequest(gameNameIdRef.value),
    'Failed to get security token'
  )

  const {
    loading: loadingPutToken,
    data: rulePutTokenData,
    error: rulePutTokenError,
    run: runPutToken,
  } = useAsync(
    () => putWebhookTokenRequest(gameNameIdRef.value),
    'Failed to refresh security token'
  )

  const {
    loading: loadingGetWebhookHistory,
    data: getWebhookHistoryData,
    run: runGetWebhookHistory,
  } = useAsync(
    (query) => getWebhookHistoryRequest(gameNameIdRef.value, query),
    'Failed to retrieve webhook history'
  )

  const {
    loading: loadingPostTestWebhook,
    data: testWebhookData,
    error: testWebhookError,
    run: runTestWebhook,
  } = useAsync(
    (url) => postTestWebhookRequest(gameNameIdRef.value, url),
    'Failed to send webhook test'
  )

  const {
    loading: loadingPostRetryWebhook,
    error: postRetryWebhookError,
    run: runPostRetryWebhook,
  } = useAsync(
    (eventUuid) => postRetryWebhookRequest(gameNameIdRef.value, eventUuid),
    'Failed to retry webhook'
  )

  const {
    loading: loadingGetRulesHistory,
    data: getRulesHistoryData,
    run: runGetRulesHistory,
  } = useAsync(
    (query) => getRulesHistoryRequest(gameNameIdRef.value, query),
    'Failed to retrieve rules history'
  )

  const { data: getRulesetHistoryData, run: runGetRulesetHistory } = useAsync(
    (uuid, query) => getRulesetHistoryRequest(gameNameIdRef.value, uuid, query),
    'Failed to retrieve ruleset history'
  )

  // function wrappers and error handling
  async function getRuleTypes() {
    await runGetRuleTypes()

    if (!ruleTypesError.value) {
      action.value = ruleTypesData.value.endpoints[0]?.id
      ruleTypes.value = ruleTypesData.value
    }
  }

  async function getRules() {
    await runGetRules()

    if (!getRulesError.value) {
      savedRules.value = getRulesData.value
      notifyEmail.value = getRulesData.value.webhooks?.notify
    }
  }

  async function deleteRules() {
    await runDeleteRules()

    if (!deleteRulesError.value) {
      savedRules.value.config = []

      addToast({
        title: 'Rules deleted successfully',
        isSuccess: true,
      })
    }
  }

  function changeAction(newAction) {
    action.value = newAction
  }

  async function updateRules(rules, notifyChange = false) {
    await runPostRules(rules)

    if (!postRulesError.value) {
      savedRules.value = postRulesData.value
      notifyEmail.value = postRulesData.value.webhooks.notify

      addToast({
        title: `${notifyChange ? 'Recipient' : 'Rules'} updated successfully`,
        isSuccess: true,
      })
    }
  }

  async function getToken() {
    await runGetToken()

    if (!ruleGetTokenError.value) {
      securityToken.value = ruleGetTokenData.value.token
    }
  }

  async function refreshToken() {
    await runPutToken()

    if (!rulePutTokenError.value) {
      addToast({
        title: 'Security token refreshed successfully',
        isSuccess: true,
      })

      securityToken.value = rulePutTokenData.value.token
    }
  }

  async function getWebhookHistory(query) {
    await runGetWebhookHistory(query)
  }

  async function testWebhook(url) {
    await runTestWebhook(url)

    if (!testWebhookError.value) {
      testWebhookData.value.success
        ? addToast({
            title: 'Webhook tested successfully',
            isSuccess: true,
          })
        : addToast({
            title: 'Failed to test webhook with given URL',
            isError: true,
          })
    }
  }

  async function postRetryWebhook(eventUuid) {
    await runPostRetryWebhook(eventUuid)

    if (!postRetryWebhookError.value) {
      addToast({
        title: 'Webhook retried successfully',
        isSuccess: true,
      })
    }
  }

  async function getRulesHistory(query) {
    await runGetRulesHistory(query)
  }

  async function getRulesetHistory(uuid, query) {
    await runGetRulesetHistory(uuid, query)
  }

  function createPayload(
    behaviours,
    rules,
    endpoint,
    name = undefined,
    rulesetIndex = undefined,
    state = 1,
    uuid = undefined
  ) {
    const rulesList = clone(savedRules.value.config || [])
    if (isNullOrUndefined(rulesetIndex)) {
      // saving new rule
      rulesList.push({ endpoint, rules, behaviors: behaviours, name, state })
    } else {
      rulesList[rulesetIndex] = {
        endpoint,
        rules,
        behaviors: behaviours,
        name,
        state,
        uuid,
      }

      savedRules.value.config[rulesetIndex]['state'] = state
    }

    // used to remove old, unsupported fields and prevent breaking change
    rulesList.forEach((set) =>
      set.behaviors.forEach((x) => {
        delete x['if_outcome_equals']
      })
    )

    return {
      config: rulesList,
      options: savedRules.value?.options,
      webhooks: { notify: savedRules.value?.webhooks?.notify || null },
    }
  }

  function createNamePayload(rulesetIndex, rulesetName) {
    const rulesList = clone(savedRules.value.config || [])
    rulesList[rulesetIndex].name = rulesetName

    // used to remove old, unsupported fields and prevent breaking change
    rulesList.forEach((set) =>
      set.behaviors.forEach((x) => {
        delete x['if_outcome_equals']
      })
    )

    return {
      config: rulesList,
      options: savedRules.value?.options,
      webhooks: { notify: savedRules.value?.webhooks?.notify || null },
    }
  }

  function createStatePayload(rulesetIndex, rulesetState) {
    const rulesList = clone(savedRules.value.config || [])
    rulesList[rulesetIndex].state = rulesetState

    // used to remove old, unsupported fields and prevent breaking change
    rulesList.forEach((set) =>
      set.behaviors.forEach((x) => {
        delete x['if_outcome_equals']
      })
    )

    return {
      config: rulesList,
      options: savedRules.value?.options,
      webhooks: { notify: savedRules.value?.webhooks?.notify || null },
    }
  }

  function createDeletePayload(rulesetIndex) {
    const rulesList = clone(savedRules.value.config)
    rulesList.splice(rulesetIndex, 1)

    return {
      config: rulesList,
      options: savedRules.value?.options,
      webhooks: { notify: savedRules.value?.webhooks?.notify || null },
    }
  }

  function createNotifyEmailPayload() {
    const rulesList = clone(savedRules.value.config)

    return {
      config: rulesList,
      options: savedRules.value?.options,
      webhooks: { notify: notifyEmail.value },
    }
  }

  function createOptionsPayload(options) {
    const rulesList = clone(savedRules.value.config)

    return {
      config: rulesList,
      options,
      webhooks: { notify: savedRules.value?.webhooks?.notify || null },
    }
  }

  function getRulesWithPayloadFields(setAction) {
    const finalRules = clone(ruleTypes.value?.rules || [])

    if (finalRules.length > 0) {
      const payLoadFields =
        endpoints.value.find((x) => x.id === setAction)?.attributes || []

      const payloadRule = finalRules.find((x) => x.type === RULE_TYPES.PAYLOAD)
      payloadRule.field_map.field = {}

      Object.entries(payLoadFields).forEach(([key, value]) => {
        payloadRule.field_map.field[key] = {
          type: value,
          label: capitalisedFirstLetter(
            key.replace('.', ' ').replace('_', ' ')
          ),
          allowedOperators: endpoints.value.find((x) => x.id === setAction)
            ?.operators[value],
        }
      })
    }

    return finalRules
  }

  function generateRuleName(fromName, rulesets, newRule = false) {
    let count = newRule ? 0 : 1
    let name

    while (count++ < rulesets.length) {
      const copy = `${fromName}${count}`
      if (!rulesets.find((rule) => rule.name === copy)) {
        name = copy
        break
      }
    }

    return name ? name : `${fromName}${count}`
  }

  function formatValue(rule) {
    switch (rule.field) {
      case 'country':
        return getCountryName(rule.value)
      case 'connected_accounts':
        return accounts[rule.value]
      case 'platforms':
        return GAME_PLATFORM_LABELS[rule.value]
      default:
        return rule.value
    }
  }

  function cleanText(text) {
    return text.replaceAll('_', ' ').replaceAll('.', ' ')
  }

  async function copyToken() {
    await navigator.clipboard.writeText(securityToken.value)
  }

  function toRule(uuid) {
    return {
      name: admin ? ADMIN_MODERATION_ROUTE : GAME_ADMIN_MODERATION_ROUTE,
      game: gameNameId,
      hash: '#rules',
      query: { rulesetRedirect: uuid },
    }
  }

  function getNewBehaviourType(type) {
    const behaviourConfig = behaviours.value.find((x) => x.type === type)

    let fields = Object.keys(behaviourConfig?.validation_rules)
    let validationRules = behaviourConfig?.validation_rules

    const result = {
      type,
      state: 1,
    }

    fields.forEach((x) => {
      if (validationRules[x].includes('in:')) {
        result[x] = validationRules[x].split('in:')[1].split(',')[0]
      } else if (validationRules[x].includes('integer')) {
        result[x] = 0
      } else if (validationRules[x].includes('boolean')) {
        result[x] = 1
      } else {
        result[x] = ''
      }
    })

    return result
  }

  function updateRuleOrder(input, rulesList) {
    const newOrder = clone(input)
    newOrder.forEach((row, index) => {
      if (index + 1 === rulesList.value?.length && row.connector !== 'none') {
        input[index].connector = 'none'
      } else if (
        index + 1 < rulesList.value?.length &&
        row.connector === 'none'
      ) {
        input[index].connector = 'and'
      }
    })

    rulesList.value = input
  }

  function setNotifyEmail(email) {
    notifyEmail.value = email
  }

  return {
    notifyEmail: readonly(notifyEmail),
    getRulesWithPayloadFields,
    loadingGetWebhookHistory,
    createNotifyEmailPayload,
    loadingPostRetryWebhook,
    loadingGetRulesHistory,
    loadingPostTestWebhook,
    getRulesetHistoryData,
    getWebhookHistoryData,
    postRetryWebhookError,
    createOptionsPayload,
    createDeletePayload,
    getRulesHistoryData,
    getNewBehaviourType,
    loadingDeleteRules,
    createStatePayload,
    getWebhookHistory,
    createNamePayload,
    rulePutTokenError,
    getRulesetHistory,
    deleteRulesError,
    loadingPostRules,
    loadingRuleTypes,
    testWebhookError,
    ruleGetTokenData,
    rulePutTokenData,
    generateRuleName,
    postRetryWebhook,
    updateRuleOrder,
    deleteRulesData,
    loadingGetToken,
    getRulesHistory,
    loadingPutToken,
    loadingGetRules,
    testWebhookData,
    setNotifyEmail,
    postRulesError,
    createPayload,
    ruleTypesData,
    postRulesData,
    baseBehaviour,
    securityToken,
    getRuleTypes,
    changeAction,
    refreshToken,
    deleteRules,
    testWebhook,
    updateRules,
    newSetRules,
    formatValue,
    gameNameId,
    behaviours,
    savedRules,
    cleanText,
    copyToken,
    endpoints,
    baseRule,
    getToken,
    getRules,
    actions,
    toRule,
    action,
    admin,
  }
}
