import { KEYS_MUTED_USERS } from '@config/storeKeys.js'
import { useLocalStorage } from '@vueuse/core'
import { isArray } from '@helpers/utils.js'
import { computed } from 'vue'

const state = useLocalStorage(KEYS_MUTED_USERS, [])

export default function () {
  const mutedUsers = computed(() =>
    isArray(state.value) ? state.value : JSON.parse(state.value)
  )

  function getMutedUser(nameId) {
    return computed(
      () => mutedUsers.value?.find((x) => x.name_id === nameId) || null
    )
  }

  function addMutedUser(user) {
    const newUserArray = [...mutedUsers.value, user]
    setMutedUsers(newUserArray)
  }

  function removeMutedUser(user) {
    const index = mutedUsers.value.findIndex((x) => x.id === user.id)
    const newUserArray = [...mutedUsers.value]
    newUserArray.splice(index, 1)
    setMutedUsers(newUserArray)
  }

  function clearMutedUsers() {
    setMutedUsers([])
  }

  function setMutedUsers(users) {
    state.value = JSON.stringify(users)
  }

  return {
    clearMutedUsers,
    removeMutedUser,
    setMutedUsers,
    getMutedUser,
    addMutedUser,
    mutedUsers,
  }
}
