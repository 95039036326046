import { MODAL_LOGIN } from '@config/globalModals.js'
import { SS_REDIRECT } from '@config/localstorage.js'
import { ref, readonly } from 'vue'

const globalModalState = ref([])

export const globalModals = readonly(globalModalState)

export function setGlobalModal({ modal, data } = {}) {
  if (!modal) return
  globalModalState.value.push({ modal, data })
}

export function clearGlobalModals() {
  globalModalState.value = []
}

export function closeGlobalModal() {
  globalModalState.value.pop()
}

export function updateGlobalModalData(newData) {
  if (globalModalState.value.length) {
    globalModalState.value[globalModalState.value.length - 1].data = newData
  }
}

export default function useModal() {
  const state = ref(false)

  function showModal() {
    state.value = true
    _addBodyClass()
  }

  function hideModal() {
    state.value = false
    _removeBodyClass()
  }

  const triggerModal = () => {
    state.value === true ? hideModal() : showModal()
  }

  function _addBodyClass() {
    document.body.classList.add('tw-overflow-hidden')
  }

  function _removeBodyClass() {
    document.body.classList.remove('tw-overflow-hidden')
  }

  function showUserLogin(route) {
    setGlobalModal({ modal: MODAL_LOGIN })
    if (route) sessionStorage.setItem(SS_REDIRECT, route.fullPath)
    else sessionStorage.removeItem(SS_REDIRECT)
  }

  return {
    isModalOpen: readonly(state),
    showUserLogin,
    triggerModal,
    showModal,
    hideModal,
  }
}
