<template>
  <loading-image v-if="inputLoading && altLoading" rounded />
  <div
    v-else-if="inputLoading"
    class="tw-animate-pulse tw-rounded-full tw-bg-theme"
    :class="avatarSize"
  ></div>

  <div v-else class="tw-relative">
    <div
      v-if="avatarUrl"
      class="tw-overflow-hidden tw-rounded-full"
      :class="!inheritSize ? avatarSize : 'tw-w-full tw-h-full'"
    >
      <img
        :src="avatarUrl"
        class="tw-object-cover tw-object-center tw-button-transition tw-h-full tw-w-full"
        :alt="user?.display_name_portal || user?.username"
      />
    </div>

    <div v-else :class="{ 'tw-h-full tw-w-full': inheritSize }">
      <span
        class="tw-font-extrabold tw-rounded-full tw-flex tw-items-center tw-justify-center tw-button-transition tw-bg-theme-1 tw-text-theme tw-pointer-events-none"
        :class="!inheritSize ? avatarSize : 'tw-w-full tw-h-full'"
      >
        <span :class="customFontSize ? customFontSize : initialSize">
          {{ userInitials }}
        </span>
      </span>
    </div>

    <div
      v-if="showStatus && onlineStatus"
      class="tw-absolute tw-rounded-full tw-border-theme-3"
      :class="[onlineIconStyles, onlineStatus ? 'tw-bg-success' : 'tw-bg-grey']"
    />

    <div
      v-if="hasNotification"
      class="tw-absolute tw-rounded-full tw-border-danger tw-bg-danger"
      :class="onlineIconStyles"
    />
  </div>
</template>

<script>
import LoadingImage from '@components/Loading/LoadingImage.vue'
import { isOnlineUser } from '@helpers/utils.js'
import { toRefs, computed } from 'vue'
export default {
  components: {
    LoadingImage,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'md',
      validator: function (value) {
        return ['sm', 'md', 'lg', 'xl'].indexOf(value) !== -1
      },
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    hasNotification: {
      type: Boolean,
      default: false,
    },
    inheritSize: {
      type: Boolean,
      default: false,
    },
    customFontSize: {
      type: String,
      default: '',
    },
    altLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { user, size, inheritSize } = toRefs(props)

    const userInitials = computed(() => {
      if (user.value?.display_name_portal) {
        return user.value.display_name_portal.substring(0, 1).toUpperCase()
      }
      if (user.value?.username) {
        return user.value.username.substring(0, 1).toUpperCase()
      } else if (user.value?.info?.display_name) {
        return user.value.info.display_name.substring(0, 1).toUpperCase()
      }
      return ''
    })
    const avatarUrl = computed(
      () =>
        user.value &&
        user.value.avatar.thumb_100x100?.search('avatar_100x100') === -1 &&
        user.value.avatar.thumb_100x100
    )
    const avatarSize = computed(() => {
      if (inheritSize.value) {
        return ''
      } else if (size.value === 'sm') {
        return 'tw-size-7'
      } else if (size.value === 'lg') {
        return 'tw-size-12'
      } else if (size.value === 'xl') {
        return 'tw-size-20 sm:tw-size-28'
      }
      return 'tw-size-8'
    })
    const onlineIconStyles = computed(() => {
      if (size.value === 'xl') {
        return 'tw-h-6 tw-w-6 tw--top-2 tw--right-2 tw-border-2'
      }
      return 'tw-h-3 tw-w-3 tw--top-px tw--right-px tw-border-1.5'
    })
    const initialSize = computed(() =>
      size.value === 'xl' ? 'tw-text-h3' : 'tw-text-h6'
    )
    const onlineStatus = computed(() => isOnlineUser(user.value?.date_online))

    return {
      onlineIconStyles,
      userInitials,
      onlineStatus,
      initialSize,
      avatarSize,
      avatarUrl,
    }
  },
}
</script>
