<template>
  <base-panel full-width :no-padding="modal" :no-margin="modal">
    <panel-header v-if="widget" text="Report" large class="tw-mb-4" />
    <div class="tw-w-full">
      <div class="tw-space-y-4">
        <p class="tw-text-p-md">
          You can report
          {{
            resource === RESOURCE_USERS ? 'a user for' : 'issues with content,'
          }}
          violations of mod.io
          <a
            class="tw-link"
            :href="`/${TERMS_ROUTE}`"
            @click.prevent="triggerTermsModal"
            >Terms of Use</a
          >
          <template v-if="resource !== RESOURCE_USERS">
            or submit a
            <base-link to="#" @click.prevent="handleDmca"
              >DMCA complaint</base-link
            >
          </template>
          using the form below.
        </p>

        <p class="tw-text-p-md">
          Make sure you include all relevant information to facilitate the
          resolution of your report. Please note that this information will be
          shared with game moderators and may be shared with the user who posted
          the allegedly infringing content.
        </p>

        <p v-if="resource === RESOURCE_MODS" class="tw-text-p-md">
          "Not working" reports will be shared directly with the content
          creator.
        </p>

        <base-select
          v-if="resource !== RESOURCE_USERS"
          :ref="(val) => updateErrRef(val, 'type')"
          label="Reason for reporting"
          :errors="validationErrors.type"
          :options="
            REPORT_TYPE_OPTIONS.filter(
              (t) =>
                resource !== RESOURCE_GUIDES ||
                t.value !== REPORT_TYPE_NOT_WORKING
            )
          "
          required
          :model-value="formData.type"
          @change="(input) => updateField('type', input)"
        />

        <base-select
          v-if="
            formData.type === REPORT_TYPE_NOT_WORKING &&
            (!resource || resource === RESOURCE_MODS)
          "
          :ref="(val) => updateErrRef(val, 'reason')"
          label="Reason for not working"
          :errors="validationErrors.reason"
          :options="
            REPORT_NOT_WORKING_REASONS.map((r) => ({
              ...r,
              text: r.text.replace('{ugc}', ugcName(null, false, true).value),
            }))
          "
          required
          :model-value="formData.reason || 0"
          @change="(input) => updateField('reason', input)"
        />

        <base-input
          v-if="!resource || !resourceId"
          :ref="(val) => updateErrRef(val, 'urls')"
          show-count
          type="textarea"
          rows="3"
          :model-value="formData.urls"
          :required="!formData.id"
          :errors="validationErrors.urls"
          placeholder="URLs (1 per line)"
          label="Offending URLs"
          full-width
          :max="formValidation.urls.max"
          @input="(input) => updateField('urls', input)"
        />

        <template v-if="isDMCA">
          <div class="tw-space-y-4">
            <p>
              <strong class="tw-text-primary">mod.io</strong> respects the
              intellectual property rights of others, and we ask that everyone
              using our internet sites and services do the same. Anyone who
              believes that their work has been reproduced in one of our
              internet sites or services in a way that constitutes copyright
              infringement may notify mod.io's copyright agent using the form
              below.
            </p>

            <p>
              Submitting a claim of copyright infringement is a serious legal
              matter. Before you proceed, you might consider contacting the
              individual directly to address the complaint. It might be a simple
              misunderstanding and possible to address without involving proper
              legal process.
            </p>

            <p>
              Be aware that under Section 512(f) of the Digital Millennium
              Copyright Act, any person who knowingly materially misrepresents
              that material or activity is infringing may be liable for damages.
              Please also note that the information provided in this legal
              notice may be shared with third-parties or made public.
            </p>
          </div>
        </template>

        <base-select
          v-if="!isDMCA && (!resource || resource === RESOURCE_MODS)"
          :ref="(val) => updateErrRef(val, 'platforms')"
          label="Platform"
          :required="formData.type === REPORT_TYPE_NOT_WORKING"
          :errors="validationErrors.platforms"
          :options="platformOptions"
          :model-value="formData.platforms"
          @change="(input) => updateField('platforms', input)"
        />

        <base-grid :lg-cols="isDMCA ? 2 : 1">
          <base-input
            :ref="(val) => updateErrRef(val, 'contact')"
            :model-value="formData.contact"
            label="Email"
            type="email"
            :required="isDMCA"
            :errors="validationErrors.contact"
            :max="formValidation.email.max"
            @input="(input) => updateField('contact', input)"
            @enter="submitReport"
          />
          <base-input
            v-if="isDMCA"
            :ref="(val) => updateErrRef(val, 'name')"
            :model-value="formData.name"
            label="Company or your Name"
            :required="isDMCA"
            :errors="validationErrors.name"
            :max="formValidation.name.max"
            @input="(input) => updateField('name', input)"
            @enter="submitReport"
          />
        </base-grid>

        <base-two-thirds-column v-if="isDMCA">
          <template #first>
            <base-input
              :ref="(val) => updateErrRef(val, 'address')"
              :model-value="formData.address"
              label="Address"
              required
              :errors="validationErrors.address"
              :max="formValidation.address.max"
              @input="(input) => updateField('address', input)"
              @enter="submitReport"
            />
          </template>
          <template #second>
            <base-select
              :ref="(val) => updateErrRef(val, 'country')"
              label="Country"
              required
              :errors="validationErrors.country"
              :options="[{ text: 'Select a country', value: '' }, ...countries]"
              :model-value="formData.country"
              @input="(input) => updateField('country', input)"
            />
          </template>
        </base-two-thirds-column>

        <base-input
          :ref="(val) => updateErrRef(val, 'summary')"
          show-count
          type="textarea"
          rows="7"
          :model-value="formData.summary"
          required
          :errors="validationErrors.summary"
          placeholder="In order to help with resolving your report, please provide as much detail and context as possible."
          label="Details of issue"
          full-width
          :max="formValidation.summary.max"
          @input="(input) => updateField('summary', input)"
          @enter="submitReport"
        />
      </div>

      <template v-if="isDMCA">
        <base-divider class="tw-mb-6 tw-mt-8" />

        <p>
          <base-input-label
            label="By checking the following boxes and submitting this claim, I state UNDER
          PENALTY OF PERJURY that:"
            :required="true"
          />
        </p>

        <base-spacer height="2" />

        <base-radio-group
          :ref="(val) => updateErrRef(val, 'owner')"
          :options="radioOptions"
          :model-value="formData.owner"
          :errors="validationErrors.owner"
          @input="(index) => updateField('owner', radioOptions[index])"
        />

        <base-spacer height="2" />

        <base-checkbox
          :ref="(val) => updateErrRef(val, 'authorized')"
          top-label="Authorization"
          label="I have a good faith belief that the use of the material noted in this claim is not authorized by the copyright owner, its agent, or the law."
          :required="isDMCA"
          :model-value="formData.authorized"
          :errors="validationErrors.authorized"
          :background="false"
          @input="
            (input) =>
              updateField('authorized', input, 'Authorization required.')
          "
        />

        <base-spacer height="4" />

        <base-checkbox
          :ref="(val) => updateErrRef(val, 'acknowledge')"
          label="I acknowledge that under Section 512(f) of the DMCA any person who knowingly materially misrepresent that material or activity is infringing may be liable for damages."
          :model-value="formData.acknowledge"
          top-label="Acknowledgement"
          :required="isDMCA"
          :errors="validationErrors.acknowledge"
          :background="false"
          @input="
            (input) =>
              updateField('acknowledge', input, 'Acknowledgement required.')
          "
        />

        <base-spacer height="4" />

        <base-input
          v-if="isDMCA"
          :ref="(val) => updateErrRef(val, 'signature')"
          label="Signature"
          required
          :model-value="formData.signature"
          :errors="validationErrors.signature"
          placeholder="Type your full name into this box, acting as your digital signature."
          :max="MAX_SIGNATURE_LENGTH"
          @input="(input) => updateField('signature', input)"
          @enter="submitReport"
        />
      </template>
    </div>
    <template #footer>
      <div
        class="tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-w-full tw-gap-3"
      >
        <base-button
          v-if="modal"
          full-width-at="sm"
          secondary
          @click="$emit('close:modal')"
        >
          Cancel
        </base-button>
        <base-button
          primary
          full-width-at="sm"
          :status="status"
          :errors="validationErrors"
          @click="submitReport"
        >
          Report
        </base-button>
      </div>
    </template>
  </base-panel>
</template>

<script>
import { reportConfig as formValidation } from '@config/validation.js'
import { HEADER_SWITCHER_HEIGHT, MODAL_HEADER_HEIGHT } from '@config'
import { computed, provide, toRefs, watch, ref } from 'vue'
import { MODAL_AGREEMENT } from '@config/globalModals.js'
import { setGlobalModal } from '@composables/useModal.js'
import PanelHeader from '@components/PanelHeader.vue'
import { REPORT_MODAL_ID } from '@config/htmlIDs.js'
import { TERMS_ROUTE } from '@config/routeNames.js'
import { countries } from '@config/countries.js'
import { submitReportRequest } from '@services'
import { authStore, gameStore } from '@stores'
import { clone } from '@helpers/utils.js'
import {
  requiredNotZero,
  validateIf,
  required,
  checked,
  email,
  url,
} from '@helpers/validationRules.js'
import {
  REPORT_NOT_WORKING_REASONS,
  REPORT_TYPE_NOT_WORKING,
  GAME_PLATFORM_LABELS,
  REPORT_TYPE_OPTIONS,
  AGREEMENT_TYPE_TOU,
  REPORT_TYPE_DMCA,
  RESOURCE_OPTIONS,
  RESOURCE_GUIDES,
  GAME_PLATFORMS,
  RESOURCE_USERS,
  RESOURCE_MODS,
} from '@config/options.js'
import {
  useScrollToError,
  useResourceValue,
  useValidate,
  useStatus,
  useAsync,
  addToast,
  useRoute,
} from '@composables'

const radioOptions = [
  'I am the Copyright Owner',
  "I am authorized to act on the copyright owner's behalf in this situation.",
]

const MAX_SIGNATURE_LENGTH = 100

export default {
  components: {
    PanelHeader,
  },
  props: {
    resource: {
      type: String,
      default: null,
      validator(value) {
        return Object.keys(RESOURCE_OPTIONS).includes(value)
      },
    },
    resourceId: {
      type: Number,
      default: null,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    widget: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close:modal'],
  setup(props, { emit }) {
    const { resource, resourceId, modal } = toRefs(props)
    const { user } = authStore()
    const { getPublicGame } = gameStore()
    const gameNameId = useRoute().getGameId().value
    const { ugcName } = useResourceValue()
    const isURL = url(true)
    const game = getPublicGame(gameNameId)

    const DEFAULT_REPORT_FORM = {
      resource: resource.value && resourceId.value ? resource.value : '',
      id: resource.value && resourceId.value ? resourceId.value : '',
      type: 0,
      reason: null,
      platforms: '',
      urls: '',
      name: '',
      contact: user.value?.info?.email || '',
      address: '',
      country: user.value?.info?.country || '',
      summary: '',
      signature: '',
      authorized: 0,
      acknowledge: 0,
      owner: 0,
    }

    const formData = ref(clone(DEFAULT_REPORT_FORM))

    const isDMCA = computed(() => formData.value.type === REPORT_TYPE_DMCA)

    const platformOptions = computed(() => [
      { text: '', value: '' },
      ...GAME_PLATFORMS.slice(1)
        .filter(
          (p) =>
            !game.value?.platforms.length ||
            game.value.platforms.some((p2) => p === p2.platform)
        )
        .map((p) => ({
          text: GAME_PLATFORM_LABELS[p],
          value: p,
        })),
    ])

    const {
      updateErrors,
      resetErrors,
      validateAll,
      hasErrors,
      validate,
      errors: validationErrors,
    } = useValidate({
      type: [
        validateIf(() => resource.value !== RESOURCE_USERS, requiredNotZero),
      ],
      urls: [
        _validateUrls,
        validateIf(
          () => !formData.value.id && resource.value !== RESOURCE_MODS,
          required
        ),
      ],
      name: [validateIf(() => isDMCA.value, required)],
      contact: [email, validateIf(() => isDMCA.value, required)],
      address: [validateIf(() => isDMCA.value, required)],
      country: [validateIf(() => isDMCA.value, required)],
      owner: [validateIf(() => isDMCA.value, requiredNotZero)],
      reason: [
        validateIf(
          () => formData.value.type === REPORT_TYPE_NOT_WORKING,
          requiredNotZero
        ),
      ],
      platforms: [
        validateIf(
          () => formData.value.type === REPORT_TYPE_NOT_WORKING,
          requiredNotZero
        ),
      ],
      summary: formValidation.validation.summary,
      signature: [
        validateIf(() => isDMCA.value, required, 'Signature required'),
      ],
      authorized: [
        validateIf(() => isDMCA.value, checked, 'Authorization required'),
      ],
      acknowledge: [
        validateIf(() => isDMCA.value, checked, 'Acknowledgement required'),
      ],
    })

    const headerHeight = modal.value
      ? MODAL_HEADER_HEIGHT
      : HEADER_SWITCHER_HEIGHT

    const { scrollToError, updateErrRef } = useScrollToError(
      validationErrors,
      headerHeight,
      modal.value ? REPORT_MODAL_ID : undefined
    )

    const { run, loading, error, setError } = useAsync(
      (report) => submitReportRequest(report),
      'Failed to submit report'
    )
    provide('saving', loading)

    const { updateStatus, status, statusType } = useStatus({
      loading,
      error,
    })

    watch(error, (_error) => {
      updateErrors(_error)
      scrollToError()
    })

    function handleDmca() {
      formData.value.type = REPORT_TYPE_DMCA
      validate('type', REPORT_TYPE_DMCA)
    }

    function _validateUrls({ input }) {
      if (!input) return true

      const urls = input.split('\n')
      if (urls.length > 10) {
        return 'Maximum 10 URLs'
      }

      if (urls.some((u) => u.length > 255)) {
        return 'Maximum URL length is 255 characters'
      }

      return (
        urls.every((u) => isURL({ input: u }) === true) ||
        'The URLs provided must be valid'
      )
    }

    function updateField(field, input, customError = null) {
      if (field === 'type') {
        setError()
        resetErrors()
      } else {
        validate(field, input, customError)
      }
      formData.value[field] = input
    }

    async function submitReport() {
      validateAll(formData.value)
      if (loading.value) return

      if (hasErrors()) {
        scrollToError()
        return
      }

      const report = {
        ...formData.value,
        reason:
          formData.value.type === REPORT_TYPE_NOT_WORKING
            ? formData.value.reason
            : null,
        urls: formData.value.urls ? formData.value.urls.split('\n') : undefined,
        contact: `Email: ${formData.value.contact}`,
      }

      if (user.value?.info?.name_id) {
        report.contact += `\nMember: ${user.value?.info?.name_id}`
      }

      if (isDMCA.value) {
        report.contact += `\nName: ${formData.value.name}\n`
        report.contact += `Address: ${formData.value.address}\n`
        report.contact += `Country: ${formData.value.country}\n`

        if (formData.value.urls) {
          report.summary += `\n\nURLs:\n${formData.value.urls}\n`
        }

        report.summary += '\nSIGNED BY:\n'
        report.summary += `${formData.value.signature} - ${formData.value.contact}\n`
        report.summary += formData.value.owner + '\n\n'
        report.summary +=
          'Who certified that all information provided is accurate, they are acting in good faith that a copyright violation has occurred and are aware under Section 512(f) of the DMCA any person who knowingly materially misrepresent that material or activity is infringing may be liable for damages.'

        delete report.platforms
      }

      if (resource.value === RESOURCE_USERS && gameNameId) {
        report.game_name_id = gameNameId
      }

      await run(report)

      if (!error.value) {
        formData.value = clone(DEFAULT_REPORT_FORM)
        updateStatus(statusType.SUCCESS)
        addToast({
          title: 'Your report has been received',
          isSuccess: true,
          text: 'If your message requires a response, please be patient as we endeavour to reply to all reports within 48 hours of receiving them.',
        })

        emit('close:modal')
      }
    }

    function triggerTermsModal() {
      setGlobalModal({
        modal: MODAL_AGREEMENT,
        data: { agreementType: AGREEMENT_TYPE_TOU },
      })
    }

    return {
      REPORT_NOT_WORKING_REASONS,
      REPORT_TYPE_NOT_WORKING,
      MAX_SIGNATURE_LENGTH,
      REPORT_TYPE_OPTIONS,
      triggerTermsModal,
      validationErrors,
      platformOptions,
      RESOURCE_GUIDES,
      RESOURCE_USERS,
      formValidation,
      RESOURCE_MODS,
      updateErrRef,
      submitReport,
      radioOptions,
      updateField,
      TERMS_ROUTE,
      handleDmca,
      countries,
      formData,
      ugcName,
      isDMCA,
      status,
    }
  },
}
</script>
