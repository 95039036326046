import Serializer, { extractCardImage } from './Serializer.js'
import { PLATFORM_FILTERS_TO_HIDE } from '@config/options.js'
import { PLATFORM_OPTION } from '@composables/useFilter.js'
import { transformTags } from './cogsGameSerializer.js'
import { PLACEHOLDER_GAME_CARD_IMAGE } from '@config'
import { cleanHTMLEntities } from '@helpers/utils.js'

const transformations = [
  transformTags,
  extractCardImage(PLACEHOLDER_GAME_CARD_IMAGE),
  _UGCSingle,
  _clean,
  _generatePlatformFilters,
]

const gameSerializer = new Serializer({
  transformations,
})

function _UGCSingle(game) {
  game.ugc_name = game.ugc_name || 'Mods'
  game.ugc_name_single = game.ugc_name.replace(/s$/, '')
  return game
}

function _clean(game) {
  game.name = cleanHTMLEntities(game.name)
  game.summary = cleanHTMLEntities(game.summary)
  return game
}

function _generatePlatformFilters(game) {
  game.tag_options = game.tag_options || []

  if (game.platforms?.length) {
    game.tag_options = [
      {
        id: PLATFORM_OPTION.replace(' ', '-'),
        name: PLATFORM_OPTION,
        tags: game.platforms
          .filter((p) => !PLATFORM_FILTERS_TO_HIDE.includes(p.platform))
          .map((platformObj) => platformObj.label),
        type: 'checkboxes',
        selected: [],
      },
      ...game.tag_options.filter((option) => option.name !== PLATFORM_OPTION),
    ]
  }
  return game
}

export default gameSerializer
