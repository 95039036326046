import { isArray } from '@helpers/utils'
import { computed, ref } from 'vue'

// Used for messenger
// TODO: make generic
const state = ref(null)

export default function (validComponents) {
  if (!validComponents && !isArray(validComponents)) {
    throw Error('Array of valid component names required.')
  }
  const components = validComponents

  const currentComponent = computed(() => state.value)

  function changeComponent(component) {
    if (component === null) {
      state.value = null
    } else if (components.includes(component)) {
      state.value = component
    }
  }

  return {
    currentComponent,
    changeComponent,
  }
}
