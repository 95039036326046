import { KEYS_MODFILES } from '@config/storeKeys.js'
import { store } from '@stores'

const { updateStore, getStore } = store()

updateStore(KEYS_MODFILES, {})

export default function () {
  const modFiles = getStore(KEYS_MODFILES)

  function addModFiles(modId, files) {
    updateStore(KEYS_MODFILES, { ...modFiles.value, [modId]: files })
  }

  return {
    addModFiles,
    modFiles,
  }
}
