<template>
  <div
    class="tw-flex tw-justify-center tw-w-full tw-mb-4"
    :class="[
      {
        'tw-mb-6 xs:tw-mb-10': marginBottom,
      },
    ]"
  >
    <div
      class="tw-flex tw-flex-wrap tw-w-full tw-min-h-12 tw-justify-between tw-items-center"
    >
      <div
        class="tw-flex sm:tw-w-full tw-flex-auto sm:tw-flex-1 tw-flex-wrap tw-flex-col xs:tw-flex-row"
      >
        <slot name="left" />
        <div
          v-for="(item, index) in menu"
          :key="index"
          class="tw-flex tw-flex-nowrap tw-h-auto xs:tw-min-h-12 tw-px-6 tw-py-2 xs:tw-py-0 tw-items-center tw-justify-center tw-uppercase tw-border-r tw-border-theme-1 first:tw-border-l"
          :class="[
            {
              'tw-bg-primary tw-text-primary-text ': current === item.id,
              'tw-opacity-30 tw-cursor-default':
                disabled && current !== item.id,
              'hover:tw-bg-primary hover:tw-text-primary-text tw-cursor-pointer tw-button-transition':
                !disabled,
            },
          ]"
          @click="onItemClick(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        v-if="$slots.right"
        class="tw-flex tw-justify-end tw-w-full sm:tw-w-80"
        :class="$slots.tags ? 'tw-mb-2 sm:tw-mb-0' : 'tw-mb-0'"
      >
        <div class="tw-flex tw-w-full sm:tw-w-auto">
          <slot name="right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue'
export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    menu: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Number,
      default: 0,
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
    marginBottom: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click:item'],
  setup(props, { emit }) {
    const { disabled } = toRefs(props)
    function onItemClick(index) {
      if (!disabled.value) {
        emit('click:item', index)
      }
    }

    return { onItemClick }
  },
}
</script>
