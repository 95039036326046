import { queryString } from '@helpers/utils.js'
import { createClient } from '@services'

const { get } = createClient()

export async function getGameKeysRequest(query) {
  const { data } = await get(`/admin/keys/games${queryString(query)}`)
  return data
}

export async function getMemberKeysRequest(query) {
  const { data } = await get(`/admin/keys/users${queryString(query)}`)
  return data
}
