import { queryString } from '@helpers/utils.js'
import { createClient } from '@services'
import qs from 'qs'

const { del, get, post } = createClient()

/* API request to get user inbox */
export async function getUserInboxRequest() {
  const { data } = await get('/me/messages/inbox')

  return data
}

/* API request to get message thread */
export async function getMessageThreadRequest(
  threadId,
  { participants = true } = {}
) {
  const requests = [get(`/me/messages/inbox/${threadId}?ts=${Date.now()}`)]
  if (participants) {
    requests.push(get(`/me/messages/inbox/${threadId}/participants`))
  }
  const thread = await Promise.all(requests)

  if (participants && thread[1].data) {
    thread[0].data.participants = thread[1].data.data
  }

  return thread[0].data
}

/* API request to get reply to thread */
export async function postMessageToThreadRequest(threadId, message) {
  const { data } = await post(
    `/me/messages/inbox/${threadId}/reply`,
    qs.stringify(message)
  )

  return data
}

/* API request to lookup user */
export async function lookupUserRequest(username) {
  const { data } = await get(`/lookup/user${queryString({ u: username })}`)

  return data
}

/* API request to compose new message thread */
export async function composeMessageRequest(to, name, message) {
  const { data } = await post(
    '/me/messages',
    qs.stringify({ to, name, message })
  )

  return data
}

/* API request to leave a message thread */
export async function leaveMessageThread(threadId) {
  const { data } = await del(`/me/messages/inbox/${threadId}`)

  return data
}
