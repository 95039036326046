import { KEYS_USERS_STATS, KEYS_USERS_GAME_STATS } from '@config/storeKeys.js'
import { store } from '@stores'
import { computed } from 'vue'

const { getStore, createStore } = store()

export default function () {
  function getAllUserStats(gameRoute = false) {
    const key = gameRoute ? KEYS_USERS_GAME_STATS : KEYS_USERS_STATS
    return getStore(key)
  }

  function getUserStats(nameId, gameRoute = false) {
    const stats = getAllUserStats(gameRoute)
    return computed(() => stats.value?.[nameId] || null)
  }

  function addUserStats(nameId, stats, gameRoute = false) {
    const key = gameRoute ? KEYS_USERS_GAME_STATS : KEYS_USERS_STATS
    const allStats = getStore(key)
    if (!allStats.value) createStore(key, {})
    const userStats = { ...allStats.value[nameId], ...stats }
    const newAllStats = { ...allStats.value, [nameId]: userStats }
    createStore(key, newAllStats)
  }

  return {
    getAllUserStats,
    getUserStats,
    addUserStats,
  }
}
