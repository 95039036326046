export const AGREEMENT_TYPE_TOU = 1
export const AGREEMENT_TYPE_PRIVACY = 2
export const AGREEMENT_TYPE_GAME = 3
export const AGREEMENT_TYPE_API = 4
export const AGREEMENT_TYPE_MONETIZATION = 5
export const AGREEMENT_TYPE_AUP = 6
export const AGREEMENT_TYPE_COOKIES = 7
export const AGREEMENT_TYPE_REFUND = 8

export const STATUS_NOT_ACCEPTED = 0
export const STATUS_ACCEPTED = 1
export const STATUS_DELETED = 3
export const STATUS_BANNED = 4

export const VISIBLE_HIDDEN = 0
export const VISIBLE_PUBLIC = 1

export const CURATION_NONE = 0
export const CURATION_PAID = 1
export const CURATION_FULL = 2

export const TEAM_MEMBER_LEVEL_MODERATOR = 1
export const TEAM_MEMBER_LEVEL_MANAGER = 4
export const TEAM_MEMBER_LEVEL_ADMINISTRATOR = 8

export const MEMBER_LEVEL_GUEST = 0
export const MEMBER_LEVEL_MEMBER = 1
export const MEMBER_LEVEL_CONTRIBUTOR = 2
export const MEMBER_LEVEL_MANAGER = 4
export const MEMBER_LEVEL_LEADER = 8

export const GAME_COMMUNITY_ALLOW_MOD_COMMENTS = 1
export const GAME_COMMUNITY_ALLOW_GUIDES = 2
export const GAME_COMMUNITY_PIN_TO_BROWSE = 4
export const GAME_COMMUNITY_SHOW_ON_HOMEPAGE = 8
export const GAME_COMMUNITY_SHOW_MORE_ON_HOMEPAGE = 16
export const GAME_COMMUNITY_CHANGE_STATUS = 32
export const GAME_COMMUNITY_ALLOW_PREVIEW = 64
export const GAME_COMMUNITY_ALLOW_PREVIEW_URLS = 128
export const GAME_COMMUNITY_ALLOW_NEGATIVE_RATINGS = 256
export const GAME_COMMUNITY_ALLOW_EDITS_VIA_WEB = 512
export const GAME_COMMUNITY_ALLOW_GUIDE_COMMENTS = 2048
export const GAME_COMMUNITY_ALLOW_UGC_PERMISSIONS = 4096

export const GAME_DISALLOW_DEPENDENCIES = 0
export const GAME_ALLOW_DEPENDENCIES_OPT_IN = 1
export const GAME_ALLOW_DEPENDENCIES_OPT_OUT = 2
export const GAME_ALLOW_DEPENDENCIES = 3

export const MOD_COMMUNITY_ALLOW_COMMENTS = 1
export const MOD_COMMUNITY_ALLOW_PREVIEW = 64
export const MOD_COMMUNITY_ALLOW_PREVIEW_URLS = 128
export const MOD_COMMUNITY_ALLOW_DEPENDENCY = 1024

export const MOD_NO_FILE = 0

export const GUIDE_COMMUNITY_ALLOW_COMMENTS = 2048

export const GAME_PRESENTATION_GRID = 0
export const GAME_PRESENTATION_TABLE = 1

export const GAME_PLAN_FREE = 1
export const GAME_PLAN_PRO = 2

export const GAME_MOD_SUBMISSION_TOOLS_ONLY = 0
export const GAME_MOD_SUBMISSION_TOOLS_AND_WEBSITE = 1

export const PAGINATION_DEFAULT_PAGE = 1
export const PAGINATION_DEFAULT_TOTAL = 0
export const PAGINATION_DEFAULT_LIMIT = 30

export const RATINGS_LIKE = 1
export const RATINGS_DISLIKE = -1
export const RATINGS_UNDECIDED = 0
export const RATINGS_FEW = 5
export const RATINGS_MANY = 50

export const API_THIRD_PARTY_ACCESS = 1
export const API_DIRECT_DOWNLOAD = 2
export const API_AUTHORISED_DOWNLOADS = 4
export const API_PAID_DOWNLOADS = 8

export const FILE_VIRUS_SCAN_NOT_STARTED = 0
export const FILE_VIRUS_SCAN_COMPLETE = 1
export const FILE_VIRUS_SCAN_IN_PROGRESS = 2
export const FILE_VIRUS_SCAN_ERROR_FILE_TOO_LARGE = 3
export const FILE_VIRUS_SCAN_ERROR_FILE_NOT_FOUND = 4
export const FILE_VIRUS_SCAN_ERROR_SCANNING = 5

export const FILE_VIRUS_NONE = 0
export const FILE_VIRUS_FOUND = 1
export const FILE_VIRUS_RISK = 2

export const COMMENT_PINNED = 1
export const COMMENT_LOCKED = 2
export const COMMENT_SCRUBBED = 4

export const SHOW_CREDITS = 1
export const ORIGINAL_OR_PERMITTED_ASSETS = 2
export const ALLOW_REDISTRIBUTION = 4
export const ALLOW_PORTING = 8
export const ALLOW_PATCHING_NO_CREDIT = 16
export const ALLOW_PATCHING_WITH_CREDIT = 32
export const ALLOW_PATCHING_WITH_PERMISSION = 64
export const ALLOW_REPACKAGE_NO_CREDIT = 128
export const ALLOW_REPACKAGE_WITH_CREDIT = 256
export const ALLOW_REPACKAGE_WITH_PERMISSION = 512
export const ALLOW_USERS_TO_RESELL = 1024

export const GAME_LIST_TYPE_STRING = 0
export const GAME_LIST_TYPE_IP = 1

export const GAME_LIST_FEATURE_ALLOW = 1
export const GAME_LIST_FEATURE_DENY = 2

export const AGREEMENT_TYPE_OPTIONS = [
  {
    value: AGREEMENT_TYPE_TOU,
    text: 'Terms of Use',
    short: 'Terms',
    paths: ['terms'],
  },
  {
    value: AGREEMENT_TYPE_AUP,
    text: 'Acceptable Use Policy',
    short: 'AUP',
    paths: ['aup'],
  },
  {
    value: AGREEMENT_TYPE_PRIVACY,
    text: 'Privacy Policy',
    short: 'Privacy',
    paths: ['privacy'],
  },
  {
    value: AGREEMENT_TYPE_COOKIES,
    text: 'Cookies Policy',
    short: 'Cookies',
    paths: ['cookies'],
  },
  {
    value: AGREEMENT_TYPE_REFUND,
    text: 'Refund Policy',
    short: 'Refund',
    paths: ['refund'],
  },
  {
    value: AGREEMENT_TYPE_MONETIZATION,
    text: 'Monetization Terms',
    short: 'Monetization',
    paths: ['monetization'],
  },
  {
    value: AGREEMENT_TYPE_API,
    text: 'API Access Terms',
    short: 'API',
    paths: ['apiterms'],
  },
  {
    value: AGREEMENT_TYPE_GAME,
    text: 'Game Terms',
    short: 'Game',
    paths: ['gameterms'],
  },
]

export const STATUS_OPTIONS = [
  { value: STATUS_NOT_ACCEPTED, text: 'Pending' },
  { value: STATUS_ACCEPTED, text: 'Live' },
  { value: STATUS_DELETED, text: 'Deleted' },
  { value: STATUS_BANNED, text: 'Banned' },
]

export const TEAM_MEMBER_LEVELS_OPTIONS = [
  { value: TEAM_MEMBER_LEVEL_ADMINISTRATOR, text: 'Administrator' },
  { value: TEAM_MEMBER_LEVEL_MANAGER, text: 'Manager' },
  { value: TEAM_MEMBER_LEVEL_MODERATOR, text: 'Moderator' },
]

export const STAFF_MEMBER_LEVELS_OPTIONS = [
  { value: MEMBER_LEVEL_GUEST, text: 'Guest' },
  { value: MEMBER_LEVEL_MEMBER, text: 'Member' },
  { value: MEMBER_LEVEL_CONTRIBUTOR, text: 'Contributor' },
  { value: MEMBER_LEVEL_MANAGER, text: 'Manager' },
  { value: MEMBER_LEVEL_LEADER, text: 'Leader' },
]

// export const STAFF_MEMBER_DEPARTMENT_OPTIONS = [
//   { value: 'all', text: 'All staff' },
//   { value: 'managment', text: 'Managment' },
//   { value: 'webapi', text: 'Web and Api' },
//   { value: 'intergration', text: 'Intergration' },
//   { value: 'monetization', text: 'Monetization' },
//   { value: 'outreach', text: 'Outreach' },
//   { value: 'marketing', text: 'Marketing' },
// ]

export const USER_PRIVACY_EVERYONE = 0
export const USER_PRIVACY_SUBSCRIBERS = 1
export const USER_PRIVACY_NONE = 2
export const USER_PRIVACY_EVERYONE_NO_COMMENTS = 3

export const USER_PRIVACY_OPTIONS = [
  { value: USER_PRIVACY_EVERYONE, text: 'Everyone can see my profile' },
  {
    value: USER_PRIVACY_EVERYONE_NO_COMMENTS,
    text: 'Everyone can see my profile, but my comment history is hidden',
  },
  {
    value: USER_PRIVACY_SUBSCRIBERS,
    text: 'Only subscribers can see my profile',
  },
  { value: USER_PRIVACY_NONE, text: 'Only I can see my profile' },
]

export const USER_EMAIL_FLAG_OPTIONS = [
  { label: 'My content is accepted or deleted', value: 'email_content' },
  { label: 'My content receives comments', value: 'email_resource_comment' },
  { label: 'My comments receive replies', value: 'email_comment' },
  { label: 'I am @mentioned', value: 'email_mention' },
  { label: 'I am added as a previewer', value: 'email_preview' },
  { label: 'I am invited to a team', value: 'email_team_invite' },
  {
    label: 'Any marketplace updates occur',
    value: 'email_monetization_update',
  },
]

export const USER_REQUEST_DATA_OPTIONS = [
  { value: 'member', text: 'Account details' },
  { value: 'games', text: 'Games' },
  { value: 'mods', text: 'Mods' },
  { value: 'media', text: 'Media' },
  { value: 'files', text: 'Files' },
  { value: 'comments', text: 'Comments' },
  { value: 'messages', text: 'Private messages' },
  { value: 'guides', text: 'Guides' },
]

export const GAME_UGC_NAME_OPTIONS = [
  { value: 'Mods', text: 'Mods' },
  { value: 'Items', text: 'Items' },
  { value: 'Addons', text: 'Addons' },
  { value: 'Apps', text: 'Apps' },
  { value: 'UGC', text: 'UGC' },
  { value: 'Levels', text: 'Levels' },
  { value: 'Other', text: 'Other' },
]

export const GAME_DEPENDENCY_OPTIONS = [
  { value: GAME_DISALLOW_DEPENDENCIES, text: 'Disallow dependencies' },
  { value: GAME_ALLOW_DEPENDENCIES_OPT_IN, text: 'Allow dependencies opt-in' },
  {
    value: GAME_ALLOW_DEPENDENCIES_OPT_OUT,
    text: 'Allow dependencies opt-out',
  },
  {
    value: GAME_ALLOW_DEPENDENCIES,
    text: 'Allow dependencies with no restrictions',
  },
]

export const GAME_TAG_TYPE_OPTIONS = [
  { value: '', text: 'Select Type' },
  { value: 'dropdown', text: 'Dropdown' },
  { value: 'checkboxes', text: 'Checkboxes' },
]

export const GAME_PRESENTATION_OPTIONS = [
  { value: GAME_PRESENTATION_GRID, text: 'Grid' },
  { value: GAME_PRESENTATION_TABLE, text: 'Table' },
]

export const GAME_MOD_SUBMISSION_OPTIONS = [
  { value: GAME_MOD_SUBMISSION_TOOLS_ONLY, text: 'Tools only' },
  { value: GAME_MOD_SUBMISSION_TOOLS_AND_WEBSITE, text: 'Tools and Website' },
]

export const GAME_MOD_CURATION_OPTIONS = [
  { value: CURATION_NONE, text: 'No Curation' },
  { value: CURATION_FULL, text: 'Full Curation' },
]

export const BULK_RESOLVE_LIMIT = 50
export const REPORT_TYPE_DMCA = 1
export const REPORT_TYPE_NOT_WORKING = 2

export const REPORT_TYPE_LABELS = {
  DMCA: 'DMCA',
  NOT_WORKING: 'Not Working',
  RUDE_CONTENT: 'Rude Content',
  ILLEGAL_CONTENT: 'Illegal Content',
  STOLEN_CONTENT: 'Stolen Content',
  FALSE_INFO: 'False Information',
  OTHER: 'Other',
}

export const REPORT_TYPE_OPTIONS = [
  // API 0=generic, however it should not be a selectable option on report
  { value: 0, text: '' },
  { value: REPORT_TYPE_DMCA, text: REPORT_TYPE_LABELS.DMCA },
  { value: REPORT_TYPE_NOT_WORKING, text: REPORT_TYPE_LABELS.NOT_WORKING },
  { value: 3, text: REPORT_TYPE_LABELS.RUDE_CONTENT },
  { value: 4, text: REPORT_TYPE_LABELS.ILLEGAL_CONTENT },
  { value: 5, text: REPORT_TYPE_LABELS.STOLEN_CONTENT },
  { value: 6, text: REPORT_TYPE_LABELS.FALSE_INFO },
  { value: 7, text: REPORT_TYPE_LABELS.OTHER },
]

export const REPORT_TYPE_OPTIONS_ADMIN = [
  ...REPORT_TYPE_OPTIONS,
  { value: 9, text: 'Virus Detected' },
  { value: 10, text: 'Autoban' },
  { value: 11, text: 'Potentially harmful' },
]

export const REPORT_NOT_WORKING_REASONS = [
  { value: 0, text: '' },
  { value: 1, text: 'Crashes game' },
  { value: 2, text: 'Does not load' },
  { value: 3, text: 'Conflicts with other {ugc}' },
  { value: 4, text: 'Missing dependencies' },
  { value: 5, text: 'Installation issues' },
  { value: 6, text: 'Buggy behavior' },
  { value: 7, text: 'Incompatible with game version' },
  { value: 8, text: 'Corrupted files' },
]

export const REPORT_VIA_SITE_ADMIN = 'site'
export const REPORT_VIA_GAME_ADMIN = 'team'

// These values are consistent with the API
export const RESOURCE_GAMES = 'games'
export const RESOURCE_MODS = 'mods'
export const RESOURCE_GUIDES = 'articles'
export const RESOURCE_USERS = 'users'

export const RESOURCE_OPTIONS = {
  [RESOURCE_GAMES]: 'Game',
  [RESOURCE_MODS]: 'Mod',
  [RESOURCE_GUIDES]: 'Guide',
  [RESOURCE_USERS]: 'User',
}

export const RATINGS_TEXT_OPTIONS = {
  UNRATED: 'Unrated',
  OVERWHELMINGLY_POSITIVE: 'Overwhelmingly Positive',
  VERY_POSITIVE: 'Very Positive',
  POSITIVE: 'Positive',
  MOSTLY_POSITIVE: 'Mostly Positive',
  MIXED: 'Mixed',
  MOSTLY_NEGATIVE: 'Mostly Negative',
  NEGATIVE: 'Negative',
  VERY_NEGATIVE: 'Very Negative',
  OVERWHELMINGLY_NEGATIVE: 'Overwhelmingly Negative',
}

export const MATURITY_NONE = 0
export const MATURITY_ALCOHOL = 1
export const MATURITY_DRUGS = 2
export const MATURITY_VIOLENCE = 4
export const MATURITY_EXPLICIT = 8

export const MOD_MATURITY_OPTIONS = [
  { value: MATURITY_ALCOHOL, text: 'Alcohol' },
  { value: MATURITY_DRUGS, text: 'Drugs' },
  { value: MATURITY_VIOLENCE, text: 'Violence' },
  { value: MATURITY_EXPLICIT, text: 'Explicit' },
]

export const GAME_MATURE_MODS_DENY = 0
export const GAME_MATURE_MODS_ALLOW = 1
export const GAME_ADULTS_ONLY = 2

export const GAME_MATURITY_OPTIONS = [
  { value: GAME_MATURE_MODS_DENY, text: 'Disallow mature content' },
  { value: GAME_MATURE_MODS_ALLOW, text: 'Allow mature content' },
  { value: GAME_ADULTS_ONLY, text: 'Mature audiences only' },
]

export const PLATFORMS_BITWISE = {
  1: 'windows',
  2: 'mac',
  4: 'linux',
  8: 'android',
  16: 'ios',
  32: 'xboxone',
  64: 'xboxseriesx',
  128: 'ps4',
  256: 'ps5',
  512: 'switch',
  1024: 'oculus',
  2048: 'source',
}

export const GAME_PLATFORMS = [
  'source',
  'windows',
  'mac',
  'linux',
  'android',
  'ios',
  'oculus',
  'xboxone',
  'xboxseriesx',
  'ps4',
  'ps5',
  'switch',
]

export const GAME_PLATFORM_LABELS = {
  source: 'Source',
  windows: 'Windows',
  mac: 'Mac',
  linux: 'Linux',
  android: 'Android',
  ios: 'iOS',
  xboxone: 'Xbox One',
  xboxseriesx: 'Xbox Series X/S',
  ps4: 'PlayStation 4',
  ps5: 'PlayStation 5',
  switch: 'Nintendo Switch',
  oculus: 'Oculus',
}

export const SHORT_GAME_PLATFORM_LABELS = {
  ...GAME_PLATFORM_LABELS,
  windows: 'Win',
  xboxone: 'XB1',
  xboxseriesx: 'XSX',
  ps4: 'PS4',
  ps5: 'PS5',
  switch: 'Switch',
}

export const PLATFORM_FILTERS_TO_HIDE = ['source']

export const PLATFORM_STATUS_PENDING = 0
export const PLATFORM_STATUS_APPROVED = 1
export const PLATFORM_STATUS_DENIED = 2
export const PLATFORM_STATUS_TARGETED = 3

export const RELEASE_SCHEDULE_DRAFT = 'draft'
export const RELEASE_SCHEDULE_NOW = 'now'
export const RELEASE_SCHEDULE_FUTURE = 'future'

export const RELEASE_SCHEDULE_OPTIONS = [
  { value: RELEASE_SCHEDULE_DRAFT, text: 'Draft' },
  { value: RELEASE_SCHEDULE_NOW, text: 'Release now' },
  { value: RELEASE_SCHEDULE_FUTURE, text: 'Schedule release' },
]

export const SCOPE_OPTIONS = [
  { value: 'read', text: 'Read' },
  { value: 'write', text: 'Write' },
  { value: 'web-rw', text: 'Read + Write' },
]

export const PAGINATION_LIMIT_OPTIONS = [
  { value: 30, text: '30/Page' },
  { value: 40, text: '40/Page' },
  { value: 50, text: '50/Page' },
]

export const DISCUSSION_TYPE_MODS = 'mods'
export const DISCUSSION_TYPE_GUIDES = 'guides'
export const DISCUSSION_TYPES = [DISCUSSION_TYPE_MODS, DISCUSSION_TYPE_GUIDES]

export const TRANSACTION_TYPE_TRANSACTION = 'transaction'
export const TRANSACTION_TYPE_ITEM = 'item'
export const TRANSACTION_TYPE_PAYOUT = 'payout'
export const TRANSACTION_TYPE_INVOICE = 'invoice'
export const TRANSACTION_TYPES = [
  TRANSACTION_TYPE_TRANSACTION,
  TRANSACTION_TYPE_ITEM,
  TRANSACTION_TYPE_PAYOUT,
  TRANSACTION_TYPE_INVOICE,
]

export const GUIDE_DASHBOARD_TABS = {
  SCHEDULED: 'Scheduled',
  DRAFT: 'Draft',
  LIVE: 'Live',
  DELETED: 'Deleted',
}

export const PERMISSIONS_MODAL_TABS = {
  COMMUNITY: 'Community',
}

export const MOD_DASHBOARD_TABS = {
  PENDING_FILES: 'Pending files',
  PENDING_MOD: 'Pending approval',
  LIVE: 'Live',
  DRAFT: 'Draft',
  DELETED: 'Deleted',
}

export const REPORTS_DASHBOARD_TABS = {
  ALL: 'All reports',
  DMCA: 'DMCA',
  NOT_WORKING: 'Not working',
  UNRESOLVED: 'Unresolved',
  RESOLVED: 'Resolved',
}

export const USER_DASHBOARD_TABS = {
  REVOKED: 'Revoked',
}

export const DASHBOARD_FILTER_TYPES = {
  USER: 'user',
  USER_SITE_ADMIN: 'user-site-admin',
  REPORT_SITE_ADMIN: 'report-site-admin',
  REPORT_GAME_ADMIN: 'report-game-admin',
  REPORT_MOD_ADMIN: 'report-mod-admin',
  GAME: 'game',
  PARTNER: 'partner',
  QUEUE: 'queue',
}

export const VISIBILITY_FILTER = {
  ALL: 'All',
  HIDDEN: 'Hidden',
  VISIBLE: 'Visible',
}

export const PLATFORM_STATUS_FILTER = {
  ALL: 'All',
  LIVE: 'Live only',
  PENDING: 'Pending only',
}

export const USER_PERMISSIONS = {
  ADD_MODS: 'add_mods',
  ADD_GUIDES: 'add_guides',
  ADD_COMMENTS: 'add_comments',
  ADD_RATINGS: 'add_ratings',
  ALL: 'toggle_all',
}

export const USER_PERMISSIONS_STATUS = {
  NOT_REVOKED: '',
  PARTIALLY_REVOKED: 'partial',
  FULLY_REVOKED: 'full',
}

export const USER_RESOURCE_OPTIONS = {
  MODS: 'mods',
  COMMENT: 'comment',
  RATINGS: 'ratings',
  GUIDES: 'articles',
}

export const DASHBOARD_REPORT_TYPES = {
  MODS: 'mods',
  GUIDES: 'articles',
  USERS: 'members',
}

export const GAME_DASHBOARD_TABS = {
  HIDDEN: 'Unlisted',
  PENDING: 'Pending',
  LIVE: 'Live',
  DELETED: 'Deleted',
}

export const ADD_GAME_STAGES = {
  GAME_PROFILE: 'Game profile',
  CATEGORY_TAGS: 'Category tags',
  CONTENT_SETTINGS: 'Content settings',
  PLATFORM_APPROVALS: 'Platform approvals',
  APIKEY: 'API access',
}

export const ADD_GAME_STAGES_HEADLINES = {
  [ADD_GAME_STAGES.GAME_PROFILE]: 'Create your game profile',
  [ADD_GAME_STAGES.CATEGORY_TAGS]: 'Set up the category tags for your game',
  [ADD_GAME_STAGES.CONTENT_SETTINGS]: 'Content settings',
  [ADD_GAME_STAGES.PLATFORM_APPROVALS]: 'Manage approvals for each platform',
  [ADD_GAME_STAGES.APIKEY]: 'API access',
}

export const ADD_GAME_STAGES_COMPONENTS = {
  [ADD_GAME_STAGES.GAME_PROFILE]: 'new-game',
  [ADD_GAME_STAGES.CATEGORY_TAGS]: 'tag-form',
  [ADD_GAME_STAGES.CONTENT_SETTINGS]: 'content-form',
  [ADD_GAME_STAGES.PLATFORM_APPROVALS]: 'platform-form',
  [ADD_GAME_STAGES.APIKEY]: 'api-key',
}

export const GAME_PARTNERS_TABS = {
  APPLIED: 'Applied',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
}

export const GAME_MARKETPLACE_QUEUE_TABS = {
  PENDING: 'Pending',
  ACCEPTED: 'Approved',
  REJECTED: 'Rejected',
}

export const GAME_HUB_FEATURED_TAG = 'featured'
export const GAME_HUB_OFFICIAL_TAG = 'official'

export const SIZE_OPTIONS = [
  { text: 'px', value: 'px' },
  { text: 'rem', value: 'rem' },
  { text: 'em', value: 'em' },
  { text: '%', value: '%' },
]

export const RESET_TYPE_OPTIONS = {
  ACCOUNT_PURCHASES: 'account-purchases',
  MOD_PURCHASES: 'mod-purchases',
  GAME_INVOICE: 'game-invoice',
  WALLET: 'wallet',
}

export const RESET_OPTION_TITLES = {
  [RESET_TYPE_OPTIONS.ACCOUNT_PURCHASES]: 'Reset all purchases',
  [RESET_TYPE_OPTIONS.MOD_PURCHASES]: 'Reset {ugc} purchase',
  [RESET_TYPE_OPTIONS.GAME_INVOICE]: 'Reset paid invoices',
  [RESET_TYPE_OPTIONS.WALLET]: 'Reset token balance',
}

export const PURCHASE_STEPS = {
  CHECKOUT: 'checkout',
  RECEIPT: 'receipt',
  CART: 'cart',
}

export const ADMIN_TESTING_OPTIONS = {
  CLEAR_PENDING_ITEMS: 'clear-pending-items',
  GENERATE_INVOICE: 'generate-invoice',
  FUND_GAME_WALLET: 'fund-game-wallet',
  FUND_MY_WALLET: 'fund-my-wallet',
  RESET_INVOICES: 'reset-invoices',
  RESET_WALLETS: 'reset-wallets',
  REFUND_THIS: 'refund-this',
  SQL_PROFILER: 'profiler',
  REFUND_ALL: 'refund-all',
}

export const PAYOUT_TYPES = {
  CONVERSION: 'conversion',
  PAYOUT: 'payout',
}

export const PLATFORM_ICONS = {
  windows: 'windows_logo',
  ps4: 'playstation_logo',
  ps5: 'playstation_logo',
  xboxone: 'xbox_logo',
  xboxseriesx: 'xbox_logo',
  switch: 'switch_logo',
  oculus: 'oculus_logo',
  ios: 'apple_logo',
  mac: 'apple_logo',
  android: 'android_logo',
  source: 'steam_logo',
  linux: 'linux_logo',
}

export const PLATFORM_ICONS_OTHER = {
  ...PLATFORM_ICONS,
  unknown: 'modio_cog_unreg_black',
}
