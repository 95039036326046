export const TITLE_POSTFIX = 'mod.io'

export const HOMEPAGE_TITLE = 'Cross Platform Mod Support for Games'
export const HOMEPAGE_DESCRIPTION =
  'Support modding on PC, console and mobile with mod.io, a platform which makes it easy to grow, manage and integrate UGC in-game, using our API, SDK and plugins for Unity, Unreal Engine and more.'
// end

export const GAME_BROWSE_ROUTE_TITLE = 'Browse games'
export const GAME_BROWSE_ROUTE_DESCRIPTION_META =
  'Browse games created by professional and indie development teams at mod.io.'
// end

export const GUIDE_BROWSE_ROUTE_DESCRIPTION_META =
  'Browse :game.name guides posted by the mod.io community.'
// end

export const ABOUT_ROUTE_TITLE = 'About us'
export const ABOUT_ROUTE_DESCRIPTION_META =
  'Meet the mod.io team and grab our logo for us in your game and apps.'
// end

export const CAREERS_ROUTE_TITE = 'Careers'
export const CAREERS_ROUTE_DESCRIPTION_META =
  "Join the mod.io team and let's work together enabling the next generation of game creators through mods."
//end

export const GAMESCOM_ROUTE_TITLE = 'mod.io at Gamescom 2024'
export const GAMESCOM_ROUTE_DESCRIPTION_META =
  'mod.io joins gamescom 2024 to deliver UGC solutions on all platforms.'
// end

export const NECRODANCER_ROUTE_TITLE = 'Case Study - Crypt of the Necrodancer'
export const NECRODANCER_ROUTE_DESCRIPTION_META =
  'Brace yourself Games and Vortex buffer brought scriptable, multiplayer mod support to all platforms for Crypt of the Necrodancer, powered by mod.io.'
// end

export const SNOWRUNNER_ROUTE_TITLE = 'Case Study - SnowRunner'
export const SNOWRUNNER_ROUTE_DESCRIPTION_META =
  'mod.io partnered with Saber and Focus Entertainment to bring cross-platform muiltiplayer mods to SnowRunner.'
// end

export const TERMS_ROUTE_TITLE = 'Terms of Use'
export const TERMS_ROUTE_DESCRIPTION_META =
  'The Terms of Use which all members must agree to, to access and use the site and services provided by mod.io.'
//end

export const PRIVACY_ROUTE_TITLE = 'Privacy Policy'
export const PRIVACY_ROUTE_DESCRIPTION_META =
  'The Privacy Policy which governs the data we collect and how we use that data to provide the mod.io site and services.'
//end

export const AUP_ROUTE_TITLE = 'Acceptable Use Policy'
export const AUP_ROUTE_DESCRIPTION_META = "Community do's and don'ts"
//end

export const GAME_TERMS_ROUTE_TITLE = 'Game Terms'
export const GAME_TERMS_ROUTE_DESCRIPTION_META =
  'All publishers and studios agree to be bound by the Game Terms, to access and use the mod.io site and services in their games.'
//end

export const MONETIZATION_TERMS_ROUTE_TITLE = 'Monetization Terms'
export const MONETIZATION_TERMS_ROUTE_DESCRIPTION_META =
  'The Monetization Terms that govern the purchase of User Generated Content and creator revenue.'
//end

export const API_TERMS_ROUTE_TITLE = 'API Access Terms'
export const API_TERMS_ROUTE_DESCRIPTION_META =
  'The API Access terms must be agreed to by members before requesting an API key. They govern access to and usage of the mod.io API.'
//end

export const COOKIES_ROUTE_TITLE = 'Cookies Policy'
export const COOKIES_ROUTE_DESCRIPTION_META =
  'The Cookies Policy which explains the cookies we use to provide the mod.io site and services.'
//end

export const REFUND_ROUTE_TITLE = 'Refund Policy'
export const REFUND_ROUTE_DESCRIPTION_META =
  'The Refund Policy which outlines refund process details for virtual currency packs and premium ugc.'
//end

export const CONTACT_ROUTE_TITLE = 'Contact us'
export const CONTACT_ROUTE_DESCRIPTION_META =
  'Contact the mod.io team for help and biz dev opportunities using our API.'
//end

export const DOCS_ROUTE_TITLE = 'Documentation'
export const DOCS_ROUTE_DESCRIPTION_META =
  'It takes just a few simple steps to offer your players a seamless, cross-platform UGC experience.'
//end

export const USER_ROUTE_DESCRIPTION_META =
  'Browse mods created by :user.name at mod.io.'
//end

export const REPORT_ROUTE_TITLE = 'Report'
export const REPORT_ROUTE_DESCRIPTION_META =
  'Report content on mod.io that is infringing your rights, or the sites terms of use.'
//end

export const STATUS_ROUTE_TITLE = 'Status'
export const STATUS_ROUTE_DESCRIPTION_META =
  'View our API status, availability, responsivness and statistics.'
//end

export const LOGIN_ROUTE_TITLE = 'Login'
export const LOGIN_ROUTE_DESCRIPTION_META =
  'Login to mod.io and enter a world of game development. Share your thoughts and ideas with the community.'
//end

export const PRICING_ROUTE_TITLE = 'Pricing'
//end

export const GAME_ADD_ROUTE_TITLE = 'Add a game'
export const GAME_ADD_ROUTE_DESCRIPTION_META =
  'Developing or publishing a game? Add it to mod.io to get access to a feature packed API with complete modding support built in.'
//end

export const GAME_ADMIN_ROUTE_TITLE = 'Edit :game-name'
//end

export const MOD_ADD_ROUTE_TITLE = 'Add a :game-name mod'
export const MOD_ADD_ROUTE_DESCRIPTION_META =
  'Developing a mod for :game-name? Add it to mod.io and share it with the community.'
//end

export const MOD_ADMIN_ROUTE_TITLE = 'Edit :mod-name for :game-name'
//end

export const GUIDE_ADD_ROUTE_TITLE = 'Post :game-name guide'
export const GUIDE_ADD_ROUTE_DESCRIPTION_META =
  'Post :game-name guide on mod.io'
//end

export const GUIDE_ADMIN_EDIT_ROUTE_TITLE = 'Edit :guide-name guide'
//end

export const PROFILE_ACCESS_ROUTE_DESCRIPTION = 'Edit your profile'
//end
