<template>
  <div class="tw-flex tw-items-center tw-space-x-2">
    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="globalMonetization">
        <div
          :class="[divStyles, setColour(globalMonetization)]"
          title="globalMonetization"
        >
          <span>AM</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-space-x-1">
      <tooltip-hover text="globalMarketplace">
        <div :class="[divStyles, setColour(globalMarketplace)]">
          <span>AMK</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-h-8 tw-w-0.5 tw-bg-gray-500"></div>

    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="gameMonetization">
        <div :class="[divStyles, setColour(teamData?.game.monetization)]">
          <span>GM</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="gameMarketplace">
        <div :class="[divStyles, setColour(teamData?.game.marketplace)]">
          <span>GMK</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="gameOnboarded">
        <div :class="[divStyles, setColour(teamData?.game.onboarded)]">
          <span>GO</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="gameInRevenueShare">
        <div :class="[divStyles, setColour(teamData?.game.inRevenueShare)]">
          <span>GRS</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="gameKYC">
        <div :class="[divStyles, setColour(teamData?.game.isKyc)]">
          <span>GKYC</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="gameKYB">
        <div :class="[divStyles, setColour(teamData?.game.isKyb)]">
          <span>GKYB</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-h-8 tw-w-0.5 tw-bg-gray-500"></div>

    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="modMonetization">
        <div :class="[divStyles, setColour(teamData?.mod.monetization)]">
          <span>MM</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="modMarketplace">
        <div :class="[divStyles, setColour(teamData?.mod.marketplace)]">
          <span>MMK</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="modOnboarded">
        <div :class="[divStyles, setColour(teamData?.mod.onboarded)]">
          <span>MO</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="userWallets">
        <div :class="[divStyles, setColour(hasMemberWallets)]">
          <span>UW</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-h-8 tw-w-0.5 tw-bg-gray-500"></div>

    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="gameOrModAdmin">
        <div :class="[divStyles, setColour(teamData?.isAdmin)]">
          <span>GMA</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-h-8 tw-w-0.5 tw-bg-gray-500"></div>

    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="userRegistered">
        <div :class="[divStyles, setColour(isMonRegistered)]">
          <span>UR</span>
        </div>
      </tooltip-hover>
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-1">
      <tooltip-hover text="onboardStatus">
        <div
          :class="[
            divStyles,
            `tw-bg-${statusText.textClass} tw-text-${statusText.textClass}-text`,
          ]"
        >
          <span>{{ statusText.text }}</span>
        </div>
      </tooltip-hover>
    </div>
  </div>
</template>

<script>
import TooltipHover from '@components/Message/TooltipHover.vue'
import { monetizationStore, authStore } from '@stores'
import { isString } from '@helpers/utils.js'
import { computed } from 'vue'

export default {
  components: {
    TooltipHover,
  },
  setup() {
    const { globalMonetization, globalMarketplace, teamData } =
      monetizationStore()

    const {
      hasMemberWallets,
      isMonRegistered,
      isOnboarded,
      isInReview,
      isRejected,
    } = authStore()

    const divStyles =
      'tw-text-xs tw-px-2 tw-h-8 tw-flex tw-items-center tw-justify-center tw-font-bold'

    function setColour(val) {
      if (isString(val)) {
        return 'tw-bg-info tw-text-info-text'
      }
      return val
        ? 'tw-bg-success tw-text-success-text'
        : 'tw-bg-danger tw-text-danger-text'
    }

    const statusText = computed(() => {
      if (isOnboarded.value) {
        return { textClass: 'success', text: 'Verified' }
      } else if (isInReview.value) {
        return { textClass: 'warning', text: 'Pending' }
      } else if (isRejected.value) {
        return { textClass: 'danger', text: 'Rejected' }
      }

      return { textClass: 'info', text: 'Unverified' }
    })

    return {
      globalMonetization,
      globalMarketplace,
      hasMemberWallets,
      isMonRegistered,
      statusText,
      divStyles,
      setColour,
      teamData,
    }
  },
}
</script>
