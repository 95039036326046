<template>
  <base-modal
    :show="show"
    heading="Report"
    :modal-id="REPORT_MODAL_ID"
    has-close-btn
    :back-button="hasBackButton"
    @close:modal="$emit('close:report')"
  >
    <report-form
      :resource="resource"
      :resource-id="resourceId"
      modal
      @close:modal="$emit('close:report')"
    />
  </base-modal>
</template>

<script>
import ReportForm from '@components/Reports/ReportForm.vue'
import { RESOURCE_OPTIONS } from '@config/options.js'
import { REPORT_MODAL_ID } from '@config/htmlIDs.js'

export default {
  components: {
    ReportForm,
  },
  props: {
    resource: {
      type: String,
      default: null,
      validator(value) {
        return Object.keys(RESOURCE_OPTIONS).includes(value)
      },
    },
    resourceId: {
      type: Number,
      default: null,
    },
    hasBackButton: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close:report'],
  setup() {
    return {
      REPORT_MODAL_ID,
    }
  },
}
</script>
