import { requestAll } from '@helpers/requestAll.js'
import { queryString } from '@helpers/utils.js'
import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'
import {
  STATUS_NOT_ACCEPTED,
  STATUS_ACCEPTED,
  STATUS_DELETED,
} from '@config/options.js'
import qs from 'qs'

const { get, put, post, del, uploadFile } = createClient()

/* API request get user details */
export async function getPublicUserRequest(userId) {
  const id = Number.isInteger(userId) ? userId : `@${userId}`

  const { data } = await get(`${PUBLIC_BASE_URL}/users/${id}`)
  return data
}

/* API request get user's mods */
export async function getPublicUserModsRequest(nameId, query) {
  const { data } = await get(`/users/@${nameId}/mods${queryString(query)}`)
  return data
}

/* API request get user's guides */
export async function getAllUserGuidesRequest(nameId, showAll, query = {}) {
  if (showAll) {
    query['status-in'] =
      `${STATUS_ACCEPTED},${STATUS_NOT_ACCEPTED},${STATUS_DELETED}`
  }

  const { data } = await get(`/users/@${nameId}/guides${queryString(query)}`)
  return data
}

/* API request get user's comments */
export async function getPublicUserCommentsRequest(nameId, query) {
  const { data } = await get(`/users/@${nameId}/comments${queryString(query)}`)
  return data
}

/* API request get user's followers */
export async function getPublicUserSubscribersRequest(nameId, query) {
  const { data } = await get(
    `/users/@${nameId}/subscribers${queryString(query)}`
  )
  return data
}

/* API request to subscribe to a user */
export function subscribeToUserRequest(nameId) {
  return post(`/users/@${nameId}/subscribe`, null, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* API request to unsubscribe from a user */
export function unsubscribeFromUserRequest(nameId) {
  return del(`/users/@${nameId}/unsubscribe`)
}

/* API request to get users with duplicate ip's */
export async function getDuplicateUsersRequest(userNameId) {
  const { data } = await get(`/users/@${userNameId}/duplicates`)
  return data
}

/* API request to get user */
export async function getUserRequest() {
  const { data } = await get('/me')
  return data
}

/* API request to update username */
export async function updateUsernameRequest(username) {
  const { data } = await post('/me/displayname', qs.stringify({ username }))
  return data
}

/* API request to update user info */
export async function updateUserInfoRequest(updates) {
  const { data } = await put('/me/info', qs.stringify(updates))
  return data
}

/* API request to close user's account */
export async function closeAccountRequest() {
  const { data } = await post('/me/close', qs.stringify({ noop: 'noop' }))
  return data
}

/* API request to delete user's account */
export async function deleteAccountRequest(email) {
  const { data } = await del('/me', qs.stringify({ email }))
  return data
}

/* API request to request retrieval of user data */
export async function getUserDataRequest(updates) {
  const { data } = await get('/me/data', qs.stringify(updates))
  return data
}

/* API request to request retrieval of user data */
export async function requestUserDataRequest(updates) {
  const { data } = await post('/me/data', qs.stringify(updates))
  return data
}

/* API request to download user data */
export async function downloadUserDataRequest(downloadId) {
  const { data } = await get(`/me/data/download/${downloadId}`, {
    responseType: 'blob',
  })
  return data
}

/* API request to upload user avatar */
export async function uploadUserAvatarRequest(upload, onUploadProgress) {
  const { data } = await uploadFile('/me/media', upload, onUploadProgress)
  return data
}

/* API request to get user subscriptions */
export function getMySubscriptionsRequest(query = {}) {
  return requestAll(`${PUBLIC_BASE_URL}/me/subscribed${queryString(query)}`)
}

/* API request to get my subscribers */
export async function getMyUserSubscriptionsRequest() {
  const { data } = await get('/me/subscribedto')
  return data
}

/* API request to get my previews */
export async function getMyUserPreviews() {
  const { data } = await get('/me/previews')
  return data
}

/* API request to get user's mods */
export async function getUserModsRequest(query) {
  const { data } = await get(`${PUBLIC_BASE_URL}/me/mods${queryString(query)}`)

  return data
}

/* API request to get user's games */
export async function getUserGamesRequest() {
  const { data } = await get(`${PUBLIC_BASE_URL}/me/games`)
  return data
}

/* API request to get user's ratings */
export function getAllUserRatings() {
  return requestAll(`${PUBLIC_BASE_URL}/me/ratings`)
}

/* API request to subscribe to mod */
export function subscribeToModRequest({ gameNameId, modNameId } = {}) {
  return post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/subscribe`,
    qs.stringify({ noop: 'noop' }),
    {
      metrics: {
        enabled: true,
        gameNameId,
        modNameId,
      },
    }
  )
}

/* API request to unsubscribe to mod */
export async function unsubscribeToModRequest({ gameNameId, modNameId } = {}) {
  const { data } = await del(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/subscribe`,
    null,
    {
      metrics: {
        enabled: true,
        gameNameId,
        modNameId,
      },
    }
  )
  return data
}

/* API request to update to mod rating */
export async function updateModRatingRequest({
  gameNameId,
  modNameId,
  rating,
} = {}) {
  await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/ratings`,
    qs.stringify({ rating }),
    {
      metrics: {
        enabled: true,
        gameNameId,
        modNameId,
      },
    }
  )
}

/* API request to get user's notification settings */
export async function getNotificationSettingsRequest(hash) {
  const { data } = await get(`/unsubscribe/${hash}`)
  return data
}

/* API request to update user's notification settings */
export async function updateNotificationSettingsRequest(hash, update) {
  const { data } = await put(`/unsubscribe/${hash}`, qs.stringify(update))
  return data
}

/* API request to respond to an email callback */
export async function getEmailCallbackRequest(token) {
  const { data } = await post('/token/callback', qs.stringify({ token }))
  return data
}

/* API request to get user's api keys */
export async function getUserApiKeys() {
  const { data } = await get('/me/keys')
  return data.data
}

/* API request to refresh user's api keys */
export async function refreshUserApiKey(key) {
  const { data } = await put(`/me/key/${key}`)
  return data
}

/* API request to create user's api keys */
export async function createNewApiKey() {
  const { data } = await post('/me/key', qs.stringify({ noop: 'noop' }))
  return data
}

/* API request to get user's oauth clients */
export async function getOauthClients() {
  const { data } = await get('/oauth/clients')
  return data
}

/* API request to create user's oauth client */
export async function createOauthClients(name) {
  const { data } = await post('/oauth/client', qs.stringify({ name }))
  return data
}

/* API request to get user's oauth access tokens */
export async function getOauthTokens() {
  const { data } = await get('/oauth/tokens')
  return data
}

/* API request to create user's oauth token */
export async function createOauthToken(tokenData) {
  const { data } = await post('/oauth/token', qs.stringify(tokenData))
  return data
}

/* API request to delete user's oauth token */
export async function deleteOauthToken(tokenId, clientId, clientToken) {
  const { data } = await del(
    `/oauth/tokens/${tokenId}`,
    qs.stringify({ client_id: clientId, client_secret: clientToken })
  )
  return data
}

/* API request to delete user's connected account */
export async function deleteUserConnection(service) {
  const { data } = await del(`/me/connections/${service}`)
  return data
}

/* API request to refresh email security code */
export async function refreshSecurityCodeRequest() {
  const { data } = await put('/me/email/refresh')
  return data
}

/* API request to refresh email security code */
export async function confirmEmailRequest(security_code) {
  const { data } = await post(
    '/me/email/confirm',
    qs.stringify({ security_code })
  )
  return data
}

export async function requestMergeAndCloseRequest(email) {
  const { data } = await post(
    'me/surrender/request',
    qs.stringify({
      email,
    })
  )

  return data
}

export async function exchangeCodeToMergeAndCloseRequest(code) {
  const { data } = await post(
    'me/surrender/exchange',
    qs.stringify({
      security_code: code,
    })
  )

  return data
}

/* API request to mute a specified user */
export async function muteUserRequest(userId) {
  const { data } = await post(`${PUBLIC_BASE_URL}/users/${userId}/mute`)
  return data
}

/* API request to unmute a specified user */
export async function unmuteUserRequest(userId) {
  const { data } = await del(`${PUBLIC_BASE_URL}/users/${userId}/mute`)
  return data
}

/* API request to get list of muted users for current user */
export async function getMyMutedUsersRequest() {
  const { data } = await get(`${PUBLIC_BASE_URL}/me/users/muted`)
  return data
}
