import guideSerializer from '@serializers/guideSerializer.js'
import { KEYS_COGS_GUIDE } from '@config/storeKeys.js'
import { guideStore, store } from '@stores'
import { clone } from '@helpers/utils.js'

const { updateStore, getStore, createStore } = store()

export default function ({ clear = false } = {}) {
  if (clear) {
    clearCogsGuide()
  }

  const { addGuide } = guideStore()

  const cogsGuide = getStore(KEYS_COGS_GUIDE)

  function addNewGuide(gameNameId) {
    createStore(
      KEYS_COGS_GUIDE,
      guideSerializer.transform({
        game_name_id: gameNameId,
      })
    )
  }

  function addCogGuide(guide) {
    createStore(KEYS_COGS_GUIDE, guideSerializer.transform(guide))
  }

  function updateGuideField(field, input) {
    if (!cogsGuide.value) return

    const updatedGuide = clone(cogsGuide.value)

    updatedGuide[field] = input
    updateStore(KEYS_COGS_GUIDE, updatedGuide)
  }

  function saveGuide(gameNameId, guide) {
    const _guide = addGuide(gameNameId, guide)
    addCogGuide(_guide)
  }

  function clearCogsGuide() {
    createStore(KEYS_COGS_GUIDE, null)
  }

  return {
    updateGuideField,
    clearCogsGuide,
    addNewGuide,
    addCogGuide,
    saveGuide,
    cogsGuide,
  }
}
