import { areBitsSet } from '@helpers/utils.js'
import {
  MONETIZATION_WEB_CURRENCY_PACKS,
  MONETIZATION_FORCED_SCARCITY,
  MONETIZATION_PARTNER_PROGRAM,
  MONETIZATION_MARKETPLACE,
  MONETIZATION_SCARCITY,
  TILIA_STATUS_APPROVED,
  MONETIZATION_FILELESS,
  MONETIZATION_WEB_UGC,
  MONETIZATION_ENABLED,
  TILIA_STATUS_MEMBER,
} from '@config/monetization.js'
import {
  GAME_COMMUNITY_ALLOW_NEGATIVE_RATINGS,
  GAME_COMMUNITY_SHOW_MORE_ON_HOMEPAGE,
  GAME_COMMUNITY_ALLOW_UGC_PERMISSIONS,
  GAME_COMMUNITY_ALLOW_GUIDE_COMMENTS,
  GAME_COMMUNITY_ALLOW_EDITS_VIA_WEB,
  GAME_COMMUNITY_ALLOW_MOD_COMMENTS,
  GAME_COMMUNITY_ALLOW_PREVIEW_URLS,
  MOD_COMMUNITY_ALLOW_PREVIEW_URLS,
  GAME_COMMUNITY_SHOW_ON_HOMEPAGE,
  ALLOW_REPACKAGE_WITH_PERMISSION,
  ALLOW_PATCHING_WITH_PERMISSION,
  GUIDE_COMMUNITY_ALLOW_COMMENTS,
  MOD_COMMUNITY_ALLOW_DEPENDENCY,
  GAME_COMMUNITY_PIN_TO_BROWSE,
  GAME_COMMUNITY_CHANGE_STATUS,
  GAME_COMMUNITY_ALLOW_PREVIEW,
  MOD_COMMUNITY_ALLOW_COMMENTS,
  ORIGINAL_OR_PERMITTED_ASSETS,
  MOD_COMMUNITY_ALLOW_PREVIEW,
  GAME_COMMUNITY_ALLOW_GUIDES,
  ALLOW_REPACKAGE_WITH_CREDIT,
  ALLOW_PATCHING_WITH_CREDIT,
  ALLOW_REPACKAGE_NO_CREDIT,
  ALLOW_PATCHING_NO_CREDIT,
  API_AUTHORISED_DOWNLOADS,
  API_THIRD_PARTY_ACCESS,
  ALLOW_USERS_TO_RESELL,
  ALLOW_REDISTRIBUTION,
  API_DIRECT_DOWNLOAD,
  API_PAID_DOWNLOADS,
  COMMENT_SCRUBBED,
  COMMENT_PINNED,
  COMMENT_LOCKED,
  CURATION_PAID,
  CURATION_FULL,
  ALLOW_PORTING,
  SHOW_CREDITS,
} from '@config/options.js'

export function allowAddGuide(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_GUIDES)
}

export function allowGameModComments(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_MOD_COMMENTS)
}

export function allowGameGuideComments(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_GUIDE_COMMENTS)
}

export function isPinnedToBrowse(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_PIN_TO_BROWSE)
}

export function isShowOnHomepage(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_SHOW_ON_HOMEPAGE)
}

export function allowNegativeRatings(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_NEGATIVE_RATINGS)
}

export function isEditableOnWeb(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_EDITS_VIA_WEB)
}

export function areUGCPermissionsAllowed(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_UGC_PERMISSIONS)
}

export function isShowMoreOnHomepage(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_SHOW_MORE_ON_HOMEPAGE)
}

export function allowChangeStatus(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_CHANGE_STATUS)
}

export function allowGamePreview(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_PREVIEW)
}

export function allowGamePreviewURLs(bits) {
  return areBitsSet(bits, GAME_COMMUNITY_ALLOW_PREVIEW_URLS)
}

export function isGameCurationPaid(bits) {
  return areBitsSet(bits, CURATION_PAID)
}

export function isGameCurationLive(bits) {
  return areBitsSet(bits, CURATION_FULL, true)
}

export function allowModPreview(bits) {
  return areBitsSet(bits, MOD_COMMUNITY_ALLOW_PREVIEW)
}

export function allowModPreviewURLs(bits) {
  return areBitsSet(bits, MOD_COMMUNITY_ALLOW_PREVIEW_URLS)
}

export function allowModComments(bits) {
  return areBitsSet(bits, MOD_COMMUNITY_ALLOW_COMMENTS)
}

export function allowModDependency(bits) {
  return areBitsSet(bits, MOD_COMMUNITY_ALLOW_DEPENDENCY)
}

export function allowGuideComments(bits) {
  return areBitsSet(bits, GUIDE_COMMUNITY_ALLOW_COMMENTS)
}

export function allowDirectDownloads(bits) {
  return areBitsSet(bits, API_DIRECT_DOWNLOAD)
}

export function allowThirdPartyAccess(bits) {
  return areBitsSet(bits, API_THIRD_PARTY_ACCESS)
}

export function isMonetizationEnabled(bits) {
  return areBitsSet(bits, MONETIZATION_ENABLED)
}

export function isMarketplaceEnabled(bits) {
  return areBitsSet(bits, MONETIZATION_MARKETPLACE)
}

export function isPartnerProgramEnabled(bits) {
  return areBitsSet(bits, MONETIZATION_PARTNER_PROGRAM)
}

export function isScarcityEnabled(bits) {
  return areBitsSet(bits, MONETIZATION_SCARCITY)
}

export function isForcedScarcityEnabled(bits) {
  return areBitsSet(bits, MONETIZATION_FORCED_SCARCITY)
}

export function isMonetizationFileless(bits) {
  return areBitsSet(bits, MONETIZATION_FILELESS)
}

export function isMonetizationWebPurchaseCurrencyPacks(bits) {
  return areBitsSet(bits, MONETIZATION_WEB_CURRENCY_PACKS)
}

export function isMonetizationWebPurchaseUgc(bits) {
  return areBitsSet(bits, MONETIZATION_WEB_UGC)
}

export function isUserOnboarded(bits) {
  return areBitsSet(bits, TILIA_STATUS_APPROVED)
}

export function userHasCreatorWallets(bits) {
  return areBitsSet(bits, TILIA_STATUS_MEMBER)
}

export function restrictDownloadsAuth(bits) {
  return areBitsSet(bits, API_AUTHORISED_DOWNLOADS)
}

export function restrictDownloadsPaid(bits) {
  return areBitsSet(bits, API_PAID_DOWNLOADS)
}

export function isCommentPinned(bits) {
  return areBitsSet(bits, COMMENT_PINNED)
}

export function isCommentLocked(bits) {
  return areBitsSet(bits, COMMENT_LOCKED)
}

export function isCommentScrubbed(bits) {
  return areBitsSet(bits, COMMENT_SCRUBBED)
}

export function isShowCredits(bits) {
  return areBitsSet(bits, SHOW_CREDITS)
}

export function isOriginalOrPermittedAssets(bits) {
  return areBitsSet(bits, ORIGINAL_OR_PERMITTED_ASSETS)
}

export function allowRedistribution(bits) {
  return areBitsSet(bits, ALLOW_REDISTRIBUTION)
}

export function allowPorting(bits) {
  return areBitsSet(bits, ALLOW_PORTING)
}

export function allowPatchingNoCredit(bits) {
  return areBitsSet(bits, ALLOW_PATCHING_NO_CREDIT)
}

export function allowPatchingWithCredit(bits) {
  return areBitsSet(bits, ALLOW_PATCHING_WITH_CREDIT)
}

export function allowPatchingWithPermission(bits) {
  return areBitsSet(bits, ALLOW_PATCHING_WITH_PERMISSION)
}

export function allowRepackageNoCredit(bits) {
  return areBitsSet(bits, ALLOW_REPACKAGE_NO_CREDIT)
}

export function allowRepackageWithCredit(bits) {
  return areBitsSet(bits, ALLOW_REPACKAGE_WITH_CREDIT)
}

export function allowRepackageWithPermission(bits) {
  return areBitsSet(bits, ALLOW_REPACKAGE_WITH_PERMISSION)
}

export function allowUsersToResell(bits) {
  return areBitsSet(bits, ALLOW_USERS_TO_RESELL)
}
