<template>
  <a
    href="#"
    title="mobile menu trigger"
    class="tw-w-7 tw-h-7 tw-top-0 tw-button-transition hover:tw-opacity-100 focus:tw-opacity-100 focus:tw-outline-white tw-outline-none tw-relative tw-shrink-0 tw-py-0.5"
    :class="menuOpen ? 'tw-opacity-100' : 'tw-opacity-80'"
    @click.prevent="mobMenuToggle"
  >
    <div class="tw-relative tw-w-full tw-h-full">
      <span
        class="tw-top-0"
        :class="[
          mobMenuStyles,
          menuBg,
          menuOpen ? 'tw-top-2.5 tw-w-0 tw-left-1/2 tw-opacity-0' : 'tw-w-full',
        ]"
      />
      <span
        class="tw-w-full tw-top-2.5"
        :class="[mobMenuStyles, menuBg, { 'tw-rotate-45': menuOpen }]"
      />
      <span
        class="tw-w-full tw-top-2.5"
        :class="[mobMenuStyles, menuBg, { 'tw--rotate-45': menuOpen }]"
      />
      <span
        class="tw-top-5"
        :class="[
          mobMenuStyles,
          menuBg,
          menuOpen ? 'tw-top-2.5 tw-w-0 tw-left-1/2 tw-opacity-0' : 'tw-w-full',
        ]"
      />
    </div>
  </a>
</template>

<script>
import { useNavMenus, useRoute } from '@composables'
import { computed } from 'vue'

export default {
  setup() {
    const { getGameId } = useRoute()
    const { showMobMenu, showMenu, mobMenuToggle, showProfileMenu } =
      useNavMenus()

    const mobMenuStyles =
      'tw-absolute tw-h-0.5 tw-rounded tw-left-0 tw-transform tw-transition'

    const menuBg = computed(() =>
      getGameId().value ? 'tw-bg-primary-text' : 'tw-bg-white'
    )

    const menuOpen = computed(
      () => showMobMenu.value || showMenu.value || showProfileMenu.value
    )

    return {
      mobMenuToggle,
      mobMenuStyles,
      showMobMenu,
      menuOpen,
      showMenu,
      menuBg,
    }
  },
}
</script>
