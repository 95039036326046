export default {
  'error.user_cant_get_data_recent_request':
    'Please wait at least 24 hours before making another retrieve data request.',
  'error.auth_too_many_attempts':
    'Please wait at least 3 hours before submitting another question.',
}

export const SSO_NOT_SETUP = 11114
export const GAME_PENDING_REVIEW = 14044
export const RESOURCE_ALREADY_SUBSCRIBED = 15004
export const RESOURCE_NOT_SUBSCRIBED = 15005
export const RATING_RESOURCE_ALREADY_RATED = 15028
export const RATING_RESOURCE_NOT_RATED = 15043
export const MOD_COMMENT_ALREADY_RATED = 15059
export const USER_NO_CONNECTION = 17031
export const AGREEMENT_NOT_ACCEPTED = 25002
export const AGREEMENT_ALREADY_ACCEPTED = 25003
export const MOD_ALREADY_OWNED = 900034
export const MONETIZATION_USER_HAS_FUNDS = 900038
