import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { del, post, get, put } = createClient()

/* API request to get agreements */
export async function getAgreementsRequest() {
  const { data } = await get('/agreements/versions')
  return data
}

/* API request to get agreement templates */
export async function getAgreementTemplatesRequest() {
  const { data } = await get('/agreements/templates')
  return data
}

/* API request to get agreement version */
export async function getAgreementVersionRequest(agreementVersionId) {
  const { data } = await get(
    `${PUBLIC_BASE_URL}/agreements/versions/${agreementVersionId}`
  )
  return data
}

/* API request to update agreement version */
export async function updateAgreementVersionRequest(
  agreementVersionId,
  agreementVersion
) {
  const { data } = await put(
    `/agreements/versions/${agreementVersionId}`,
    qs.stringify(agreementVersion)
  )

  return data
}

/* API request to get agreements template */
export async function getAgreementTemplateRequest(agreementTemplateId) {
  const { data } = await get(`/agreements/templates/${agreementTemplateId}`)
  return data
}

/* API request to update agreement template */
export async function updateAgreementTemplateRequest(
  agreementTemplateId,
  agreementTemplate
) {
  const { data } = await put(
    `/agreements/templates/${agreementTemplateId}`,
    qs.stringify(agreementTemplate)
  )
  return data
}

/* API request to add agreement  */
export async function addAgreementVersionRequest(agreementVersion) {
  const { data } = await post(
    `/agreements/versions`,
    qs.stringify(agreementVersion)
  )
  return data
}

/* API request to activate agreement version*/
export async function activateVersionRequest(agreementVersionId) {
  const { data } = await post(
    `/agreements/versions/${agreementVersionId}/activate`,
    qs.stringify({ noop: 'noop' })
  )
  return data
}

/* API request to delete agreement version*/
export async function deleteAgreementVersionRequest(agreementVersionId) {
  const { data } = await del(`/agreements/versions/${agreementVersionId}`)
  return data
}

/* API request to add agreement template */
export async function addAgreementTemplateRequest(agreementTemplate) {
  const { data } = await post(
    `/agreements/templates`,
    qs.stringify(agreementTemplate)
  )
  return data
}

/* API request to get current agreement */
export async function getCurrentAgreementRequest(agreementTemplateId) {
  const { data } = await get(
    `${PUBLIC_BASE_URL}/agreements/types/${agreementTemplateId}/current`
  )

  return data
}

/* API request to get latest accepted agreement version for a user */
export async function getLatestAcceptedVersionForUser(
  agreementTemplateId,
  userId
) {
  const { data } = await get(
    `/agreements/types/${agreementTemplateId}/user/${userId}/latest`
  )

  return data
}

/* API request to accept agreement version*/
export async function acceptAgreementVersion(agreementVersionId) {
  const { data } = await post(
    `/agreements/versions/${agreementVersionId}/accept`,
    qs.stringify({ noop: null })
  )

  return data
}
