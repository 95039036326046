export const continents = [
  {
    value: '002',
    text: 'Africa',
    subContinents: ['015', '011', '017', '014', '018'],
    countries: [
      'DZ',
      'EG',
      'EH',
      'LY',
      'MA',
      'SD',
      'SS',
      'TN',
      'BF',
      'BJ',
      'CI',
      'CV',
      'GH',
      'GM',
      'GN',
      'GW',
      'LR',
      'ML',
      'MR',
      'NE',
      'NG',
      'SH',
      'SL',
      'SN',
      'TG',
      'AO',
      'CD',
      'ZR',
      'CF',
      'CG',
      'CM',
      'GA',
      'GQ',
      'ST',
      'TD',
      'BI',
      'DJ',
      'ER',
      'ET',
      'KE',
      'KM',
      'MG',
      'MU',
      'MW',
      'MZ',
      'RE',
      'RW',
      'SC',
      'SO',
      'TZ',
      'UG',
      'YT',
      'ZM',
      'ZW',
      'BW',
      'LS',
      'NA',
      'SZ',
      'ZA',
    ],
  },
  {
    value: '019',
    text: 'Americas',
    subContinents: ['021', '029', '013', '005'],
    countries: [
      'BM',
      'CA',
      'GL',
      'PM',
      'US',
      'AG',
      'AI',
      'AN',
      'AW',
      'BB',
      'BL',
      'BS',
      'CU',
      'DM',
      'DO',
      'GD',
      'GP',
      'HT',
      'JM',
      'KN',
      'KY',
      'LC',
      'MF',
      'MQ',
      'MS',
      'PR',
      'TC',
      'TT',
      'VC',
      'VG',
      'VI',
      'BZ',
      'CR',
      'GT',
      'HN',
      'MX',
      'NI',
      'PA',
      'SV',
      'AR',
      'BO',
      'BR',
      'CL',
      'CO',
      'EC',
      'FK',
      'GF',
      'GY',
      'PE',
      'PY',
      'SR',
      'UY',
      'VE',
    ],
  },
  {
    value: '142',
    text: 'Asia',
    subContinents: ['143', '030', '034', '035', '145'],
    countries: [
      'TM',
      'TJ',
      'KG',
      'KZ',
      'UZ',
      'CN',
      'HK',
      'JP',
      'KP',
      'KR',
      'MN',
      'MO',
      'TW',
      'AF',
      'BD',
      'BT',
      'IN',
      'IR',
      'LK',
      'MV',
      'NP',
      'PK',
      'BN',
      'ID',
      'KH',
      'LA',
      'MM',
      'BU',
      'MY',
      'PH',
      'SG',
      'TH',
      'TL',
      'TP',
      'VN',
      'AE',
      'AM',
      'AZ',
      'BH',
      'CY',
      'GE',
      'IL',
      'IQ',
      'JO',
      'KW',
      'LB',
      'OM',
      'PS',
      'QA',
      'SA',
      'NT',
      'SY',
      'TR',
      'YE',
      'YD',
    ],
  },
  {
    value: '150',
    text: 'Europe',
    subContinents: ['154', '155', '151', '039'],
    countries: [
      'GG',
      'JE',
      'AX',
      'DK',
      'EE',
      'FI',
      'FO',
      'GB',
      'IE',
      'IM',
      'IS',
      'LT',
      'LV',
      'NO',
      'SE',
      'SJ',
      'AT',
      'BE',
      'CH',
      'DE',
      'DD',
      'FR',
      'FX',
      'LI',
      'LU',
      'MC',
      'NL',
      'BG',
      'BY',
      'CZ',
      'HU',
      'MD',
      'PL',
      'RO',
      'RU',
      'SU',
      'SK',
      'UA',
      'AD',
      'AL',
      'BA',
      'ES',
      'GI',
      'GR',
      'HR',
      'IT',
      'ME',
      'MK',
      'MT',
      'RS',
      'PT',
      'SI',
      'SM',
      'VA',
      'YU',
    ],
  },
  {
    value: '009',
    text: 'Oceania',
    subContinents: ['053', '054', '057', '061'],
    countries: [
      'AU',
      'NF',
      'NZ',
      'FJ',
      'NC',
      'PG',
      'SB',
      'VU',
      'FM',
      'GU',
      'KI',
      'MH',
      'MP',
      'NR',
      'PW',
      'AS',
      'CK',
      'NU',
      'PF',
      'PN',
      'TK',
      'TO',
      'TV',
      'WF',
      'WS',
    ],
  },
]

export const subContinents = [
  {
    value: '015',
    text: 'Northern Africa',
    countries: ['DZ', 'EG', 'EH', 'LY', 'MA', 'SD', 'SS', 'TN'],
  },
  {
    value: '011',
    text: 'Western Africa',
    countries: [
      'BF',
      'BJ',
      'CI',
      'CV',
      'GH',
      'GM',
      'GN',
      'GW',
      'LR',
      'ML',
      'MR',
      'NE',
      'NG',
      'SH',
      'SL',
      'SN',
      'TG',
    ],
  },
  {
    value: '017',
    text: 'Middle Africa',
    countries: ['AO', 'CD', 'ZR', 'CF', 'CG', 'CM', 'GA', 'GQ', 'ST', 'TD'],
  },
  {
    value: '014',
    text: 'Eastern Africa',
    countries: [
      'BI',
      'DJ',
      'ER',
      'ET',
      'KE',
      'KM',
      'MG',
      'MU',
      'MW',
      'MZ',
      'RE',
      'RW',
      'SC',
      'SO',
      'TZ',
      'UG',
      'YT',
      'ZM',
      'ZW',
    ],
  },
  {
    value: '018',
    text: 'Southern Africa',
    countries: ['BW', 'LS', 'NA', 'SZ', 'ZA'],
  },
  {
    value: '154',
    text: 'Northern Europe',
    countries: [
      'GG',
      'JE',
      'AX',
      'DK',
      'EE',
      'FI',
      'FO',
      'GB',
      'IE',
      'IM',
      'IS',
      'LT',
      'LV',
      'NO',
      'SE',
      'SJ',
    ],
  },
  {
    value: '155',
    text: 'Western Europe',
    countries: [
      'AT',
      'BE',
      'CH',
      'DE',
      'DD',
      'FR',
      'FX',
      'LI',
      'LU',
      'MC',
      'NL',
    ],
  },
  {
    value: '151',
    text: 'Eastern Europe',
    countries: [
      'BG',
      'BY',
      'CZ',
      'HU',
      'MD',
      'PL',
      'RO',
      'RU',
      'SU',
      'SK',
      'UA',
    ],
  },
  {
    value: '039',
    text: 'Southern Europe',
    countries: [
      'AD',
      'AL',
      'BA',
      'ES',
      'GI',
      'GR',
      'HR',
      'IT',
      'ME',
      'MK',
      'MT',
      'RS',
      'PT',
      'SI',
      'SM',
      'VA',
      'YU',
    ],
  },
  {
    value: '021',
    text: 'Northern America',
    countries: ['BM', 'CA', 'GL', 'PM', 'US'],
  },
  {
    value: '029',
    text: 'Caribbean',
    countries: [
      'AG',
      'AI',
      'AN',
      'AW',
      'BB',
      'BL',
      'BS',
      'CU',
      'DM',
      'DO',
      'GD',
      'GP',
      'HT',
      'JM',
      'KN',
      'KY',
      'LC',
      'MF',
      'MQ',
      'MS',
      'PR',
      'TC',
      'TT',
      'VC',
      'VG',
      'VI',
    ],
  },
  {
    value: '013',
    text: 'Central America',
    countries: ['BZ', 'CR', 'GT', 'HN', 'MX', 'NI', 'PA', 'SV'],
  },
  {
    value: '005',
    text: 'South America',
    countries: [
      'AR',
      'BO',
      'BR',
      'CL',
      'CO',
      'EC',
      'FK',
      'GF',
      'GY',
      'PE',
      'PY',
      'SR',
      'UY',
      'VE',
    ],
  },
  {
    value: '143',
    text: 'Central Asia',
    countries: ['TM', 'TJ', 'KG', 'KZ', 'UZ'],
  },
  {
    value: '030',
    text: 'Eastern Asia',
    countries: ['CN', 'HK', 'JP', 'KP', 'KR', 'MN', 'MO', 'TW'],
  },
  {
    value: '034',
    text: 'Southern Asia',
    countries: ['AF', 'BD', 'BT', 'IN', 'IR', 'LK', 'MV', 'NP', 'PK'],
  },
  {
    value: '035',
    text: 'South-Eastern Asia',
    countries: [
      'BN',
      'ID',
      'KH',
      'LA',
      'MM',
      'BU',
      'MY',
      'PH',
      'SG',
      'TH',
      'TL',
      'TP',
      'VN',
    ],
  },
  {
    value: '145',
    text: 'Western Asia',
    countries: [
      'AE',
      'AM',
      'AZ',
      'BH',
      'CY',
      'GE',
      'IL',
      'IQ',
      'JO',
      'KW',
      'LB',
      'OM',
      'PS',
      'QA',
      'SA',
      'NT',
      'SY',
      'TR',
      'YE',
      'YD',
    ],
  },
  {
    value: '053',
    text: 'Australia and New Zealand',
    countries: ['AU', 'NF', 'NZ'],
  },
  {
    value: '054',
    text: 'Melanesia',
    countries: ['FJ', 'NC', 'PG', 'SB', 'VU'],
  },
  {
    value: '057',
    text: 'Micronesia',
    countries: ['FM', 'GU', 'KI', 'MH', 'MP', 'NR', 'PW'],
  },
  {
    value: '061',
    text: 'Polynesia',
    countries: ['AS', 'CK', 'NU', 'PF', 'PN', 'TK', 'TO', 'TV', 'WF,', 'WS'],
  },
]

export const amchartsContinents = [
  {
    value: 'africa',
    text: 'Africa',
    subContinents: ['015', '011', '017', '014', '018'],
    countries: [
      'DZ',
      'EG',
      'EH',
      'LY',
      'MA',
      'SD',
      'SS',
      'TN',
      'BF',
      'BJ',
      'CI',
      'CV',
      'GH',
      'GM',
      'GN',
      'GW',
      'LR',
      'ML',
      'MR',
      'NE',
      'NG',
      'SH',
      'SL',
      'SN',
      'TG',
      'AO',
      'CD',
      'ZR',
      'CF',
      'CG',
      'CM',
      'GA',
      'GQ',
      'ST',
      'TD',
      'BI',
      'DJ',
      'ER',
      'ET',
      'KE',
      'KM',
      'MG',
      'MU',
      'MW',
      'MZ',
      'RE',
      'RW',
      'SC',
      'SO',
      'TZ',
      'UG',
      'YT',
      'ZM',
      'ZW',
      'BW',
      'LS',
      'NA',
      'SZ',
      'ZA',
    ],
  },
  {
    value: 'northAmerica',
    text: 'North America',
    subContinents: ['029', '013'],
    countries: [
      'BM',
      'CA',
      'GL',
      'PM',
      'US',
      'AG',
      'AI',
      'AN',
      'AW',
      'BB',
      'BL',
      'BS',
      'CU',
      'DM',
      'DO',
      'GD',
      'GP',
      'HT',
      'JM',
      'KN',
      'KY',
      'LC',
      'MF',
      'MQ',
      'MS',
      'PR',
      'TC',
      'TT',
      'VC',
      'VG',
      'VI',
      'BZ',
      'CR',
      'GT',
      'HN',
      'MX',
      'NI',
      'PA',
      'SV',
    ],
  },
  {
    value: 'southAmerica',
    text: 'South America',
    countries: [
      'AR',
      'BO',
      'BR',
      'CL',
      'CO',
      'EC',
      'FK',
      'GF',
      'GY',
      'PE',
      'PY',
      'SR',
      'UY',
      'VE',
    ],
  },
  {
    value: 'americas',
    text: 'Americas',
    subContinents: ['021', '029', '013', '005'],
    countries: [
      'BM',
      'CA',
      'GL',
      'PM',
      'US',
      'AG',
      'AI',
      'AN',
      'AW',
      'BB',
      'BL',
      'BS',
      'CU',
      'DM',
      'DO',
      'GD',
      'GP',
      'HT',
      'JM',
      'KN',
      'KY',
      'LC',
      'MF',
      'MQ',
      'MS',
      'PR',
      'TC',
      'TT',
      'VC',
      'VG',
      'VI',
      'BZ',
      'CR',
      'GT',
      'HN',
      'MX',
      'NI',
      'PA',
      'SV',
      'AR',
      'BO',
      'BR',
      'CL',
      'CO',
      'EC',
      'FK',
      'GF',
      'GY',
      'PE',
      'PY',
      'SR',
      'UY',
      'VE',
    ],
  },
  {
    value: 'asia',
    text: 'Asia',
    subContinents: ['143', '030', '034', '035', '145'],
    countries: [
      'TM',
      'TJ',
      'KG',
      'KZ',
      'UZ',
      'CN',
      'HK',
      'JP',
      'KP',
      'KR',
      'MN',
      'MO',
      'TW',
      'AF',
      'BD',
      'BT',
      'IN',
      'IR',
      'LK',
      'MV',
      'NP',
      'PK',
      'BN',
      'ID',
      'KH',
      'LA',
      'MM',
      'BU',
      'MY',
      'PH',
      'SG',
      'TH',
      'TL',
      'TP',
      'VN',
      'AE',
      'AM',
      'AZ',
      'BH',
      'CY',
      'GE',
      'IL',
      'IQ',
      'JO',
      'KW',
      'LB',
      'OM',
      'PS',
      'QA',
      'SA',
      'NT',
      'SY',
      'TR',
      'YE',
      'YD',
    ],
  },
  {
    value: 'europe',
    text: 'Europe',
    subContinents: ['154', '155', '151', '039'],
    countries: [
      'GG',
      'JE',
      'AX',
      'DK',
      'EE',
      'FI',
      'FO',
      'GB',
      'IE',
      'IM',
      'IS',
      'LT',
      'LV',
      'NO',
      'SE',
      'SJ',
      'AT',
      'BE',
      'CH',
      'DE',
      'DD',
      'FR',
      'FX',
      'LI',
      'LU',
      'MC',
      'NL',
      'BG',
      'BY',
      'CZ',
      'HU',
      'MD',
      'PL',
      'RO',
      'RU',
      'SU',
      'SK',
      'UA',
      'AD',
      'AL',
      'BA',
      'ES',
      'GI',
      'GR',
      'HR',
      'IT',
      'ME',
      'MK',
      'MT',
      'RS',
      'PT',
      'SI',
      'SM',
      'VA',
      'YU',
    ],
  },
  {
    value: 'oceania',
    text: 'Oceania',
    subContinents: ['053', '054', '057', '061'],
    countries: [
      'AU',
      'NF',
      'NZ',
      'FJ',
      'NC',
      'PG',
      'SB',
      'VU',
      'FM',
      'GU',
      'KI',
      'MH',
      'MP',
      'NR',
      'PW',
      'AS',
      'CK',
      'NU',
      'PF',
      'PN',
      'TK',
      'TO',
      'TV',
      'WF',
      'WS',
    ],
  },
]
