import { NEW_RESOURCE_ID } from '@config'
import zipObject from 'lodash/zipObject'
import { useRoute } from '@composables'
import { ref, watch } from 'vue'
import {
  GUIDE_ADMIN_ROUTE,
  GAME_ADMIN_ROUTE,
  MOD_ADMIN_ROUTE,
  PROFILE_ROUTE,
} from '@config/routeNames.js'
import {
  revenueSplitStore,
  cogsGuideStore,
  cogsGameStore,
  cogsModStore,
  guideStore,
  authStore,
  userStore,
  gameStore,
  modStore,
} from '@stores'

const acceptedRouteParams = ['game', 'mod', 'guide', 'user']
const { getPublicGame } = gameStore()
const { cogsGame } = cogsGameStore()
const { getModByNameId } = modStore()
const { cogsMod } = cogsModStore()
const { getGuide } = guideStore()
const { cogsGuide } = cogsGuideStore()
const { getUser } = userStore()
const { user } = authStore()

const waitingforDynamicResources = ref([])
// ensures only watch is running at a time
let watching = false
const triggerWatch = ref(0)

let stop = () => {}

export default function () {
  const { routeName, routeParams } = useRoute()

  // called once
  function init() {
    watchDynamic()
  }

  function registerParametersWatch(callback, skipVariableNumCheck = false) {
    waitingforDynamicResources.value.push({ callback, skipVariableNumCheck })
    if (!watching) {
      triggerWatch.value++
      watching = true
    }
  }

  function watchDynamic() {
    watch([routeParams, triggerWatch], (newVals) => {
      const _routeParams = newVals[0]
      if (_routeParams || (_isProfileRoute() && !watching)) {
        let _watch = _getWatches(_routeParams)
        watching = true
        stop && stop()
        stop = watch(
          _watch,
          (newVals) => {
            let values = newVals
            if (waitingforDynamicResources.value.length === 0) {
              watching = false
              stop && stop()
              return
            }
            const keys = []
            Object.entries(_routeParams).forEach(([key, value]) => {
              if (
                acceptedRouteParams.includes(key) &&
                value !== NEW_RESOURCE_ID
              )
                keys.push(key)
            })

            const result = zipObject(keys, values)

            if (result && Object.keys(result).length !== keys.length) return

            const canRunTasks = waitingforDynamicResources.value.filter(
              (t) => !!t.skipVariableNumCheck
            )

            if (canRunTasks.length) {
              canRunTasks.forEach((t) => t.callback(result))

              waitingforDynamicResources.value =
                waitingforDynamicResources.value.filter(
                  (t) => !t.skipVariableNumCheck
                )
            }

            if (
              !values ||
              Object.entries(result).some(
                ([key, value]) =>
                  !value ||
                  _routeParams[key].toLowerCase() !==
                    value.name_id?.toLowerCase()
              )
            )
              return

            waitingforDynamicResources.value.forEach((t) => t.callback(result))
            waitingforDynamicResources.value = []
            watching = false
            stop && stop()
          },
          { immediate: true }
        )
      }
    })
  }

  function watchForRevenueSplit(type, nameId, callback) {
    const { hasData } = revenueSplitStore(type, nameId)

    const stop = watch(
      hasData,
      (_hasData) => {
        if (_hasData) {
          callback()
          stop()
        }
      },
      {
        immediate: true,
      }
    )
  }

  function watchForCogsMod(gameNameId, modNameId, callback) {
    const stop = watch(
      cogsMod,
      (mod) => {
        if (mod?.game_name_id === gameNameId && mod?.name_id === modNameId) {
          callback()
          stop()
        }
      },
      { immediate: true }
    )
  }

  function _isProfileRoute() {
    return routeName.value?.includes(PROFILE_ROUTE)
  }

  function _getWatches(_routeParams) {
    const _watch = []
    Object.entries(_routeParams).forEach(([key, nameId]) => {
      if (key === 'pathMatch' || nameId === NEW_RESOURCE_ID) return

      if (key === 'game') {
        _watch.push(
          routeName.value?.includes(GAME_ADMIN_ROUTE)
            ? cogsGame
            : getPublicGame(nameId)
        )
      } else if (key === 'mod') {
        _watch.push(
          routeName.value?.includes(MOD_ADMIN_ROUTE)
            ? cogsMod
            : getModByNameId(_routeParams.game, nameId)
        )
      } else if (key === 'guide') {
        _watch.push(
          routeName.value?.includes(GUIDE_ADMIN_ROUTE)
            ? cogsGuide
            : getGuide(_routeParams.game, nameId)
        )
      } else if (key === 'user') {
        _watch.push(getUser(nameId))
      }
    })
    if (_isProfileRoute()) {
      _routeParams.user = 'me'
      _watch.push(user)
    }

    return _watch
  }

  function clearResourceWatchList() {
    waitingforDynamicResources.value = []
  }

  return {
    registerParametersWatch,
    clearResourceWatchList,
    watchForRevenueSplit,
    watchForCogsMod,
    init,
  }
}
