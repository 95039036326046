<template>
  <p v-if="text" class="tw-text-xs">
    {{ text }}
  </p>
</template>

<script>
import { humanNumberFormat } from '@helpers/utils.js'
import { toRefs, computed } from 'vue'

export default {
  props: {
    count: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { count } = toRefs(props)

    function plural(count) {
      return count === 1 ? '' : 's'
    }

    const text = computed(() => {
      if (props.min > 0 && count.value < props.min) {
        const req = props.min - count.value
        return `${req} more character${plural(req)} required.`
      } else if (props.max > 0) {
        if (count.value > props.max) {
          return `Too many characters. (${humanNumberFormat(
            count.value
          )}/${humanNumberFormat(props.max)})`
        } else {
          const rem = humanNumberFormat(props.max - count.value)
          return `${rem} character${plural(rem)} remaining.`
        }
      }

      return ''
    })

    return {
      text,
    }
  },
}
</script>
