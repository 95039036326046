import { isCommentPinned } from '@helpers/bitHelper.js'
import { KEYS_DISCUSSION } from '@config/storeKeys.js'
import { clone } from '@helpers/utils.js'
import { store } from '@stores'
import { computed } from 'vue'
import discussionSerializer, {
  mergeDiscussion,
} from '@serializers/discussionSerializer.js'

const { getStore, updateStore } = store()

export function recurseDiscussionCount(discussion) {
  let total = discussion?.replies?.length || 0
  if (discussion.replies) {
    discussion.replies.forEach((r) => {
      total += recurseDiscussionCount(r)
    })
  }

  return total
}

export default function (type) {
  function generateId(gameNameId, resourceNameId) {
    return `${KEYS_DISCUSSION}${type}${gameNameId}${resourceNameId}`
  }
  if (!type) {
    throw new Error('discussionStore requires type param')
  }

  function getComputedDiscussionsTotal(gameNameId, resourceNameId) {
    const discussions = getStore(generateId(gameNameId, resourceNameId))
    return computed(() => {
      let total = 0
      if (discussions.value) {
        discussions.value.data.forEach((d) => {
          total += recurseDiscussionCount(d)
        })
        total += discussions.value.data.length
      }
      return total
    })
  }

  function addDiscussions(gameNameId, resourceNameId, data) {
    updateStore(
      generateId(gameNameId, resourceNameId),
      discussionSerializer.transform(data)
    )
  }

  function getDiscussions(gameNameId, resourceNameId) {
    const discussion = getStore(generateId(gameNameId, resourceNameId))
    return computed(() =>
      discussion.value?.data?.length > 0 ? discussion.value.data : null
    )
  }

  function addDiscussion(gameNameId, resourceNameId, discussionData) {
    const discussions = getDiscussions(gameNameId, resourceNameId)
    if (discussions.value) {
      updateStore(generateId(gameNameId, resourceNameId), {
        data: mergeDiscussion(clone(discussions.value), discussionData),
      })
    } else {
      updateStore(
        generateId(gameNameId, resourceNameId),
        discussionSerializer.transform({ data: [discussionData] })
      )
    }
  }

  function updateDiscussion(gameNameId, resourceNameId, commentId, update) {
    const discussions = getDiscussions(gameNameId, resourceNameId)
    if (discussions.value) {
      const updatedDiscussions = clone(discussions.value)
      updatedDiscussions.forEach((d) => _updateReplies(d, commentId, update))
      updatedDiscussions.sort((a, b) => {
        const aPinned = isCommentPinned(a.options)
        const bPinned = isCommentPinned(b.options)
        if (aPinned === bPinned) {
          return b.date_added - a.date_added
        } else {
          return aPinned ? -1 : 1
        }
      })
      updateStore(generateId(gameNameId, resourceNameId), {
        data: updatedDiscussions,
      })
    }
  }

  function appendDiscussions(gameNameId, resourceNameId, newDiscussions) {
    const discussions = getDiscussions(gameNameId, resourceNameId)
    const updatedDiscussions = clone(discussions.value || [])
    newDiscussions.forEach((discussion) =>
      mergeDiscussion(updatedDiscussions, discussion, true)
    )
    updateStore(generateId(gameNameId, resourceNameId), {
      data: updatedDiscussions,
    })
  }

  function _filterReplies(discussion, commentId) {
    discussion.replies.forEach((r, i) => {
      if (r.id === commentId) {
        discussion.replies.splice(i, 1)
      } else {
        _filterReplies(r, commentId)
      }
    })
  }

  function _updateReplies(discussion, commentId, update) {
    if (discussion.id === commentId) {
      for (const key in update) {
        discussion[key] = update[key]
      }
    } else {
      discussion.replies.forEach((r) => _updateReplies(r, commentId, update))
    }
  }

  function removeDiscussion(gameNameId, resourceNameId, commentId) {
    const discussions = getDiscussions(gameNameId, resourceNameId)
    if (discussions.value) {
      const newDiscussions = clone(discussions.value).filter(
        (d) => d.id !== commentId
      )
      newDiscussions.forEach((d) => _filterReplies(d, commentId))
      updateStore(generateId(gameNameId, resourceNameId), {
        data: newDiscussions,
      })
    }
  }

  function _findChildComment(comments, commentId) {
    const comment = comments.find((c) => c.id === commentId)
    if (comment) return comment

    return comments.find((c) => _findChildComment(c.replies, commentId))
  }

  function getTopLevelComment(gameNameId, resourceNameId, commentId) {
    const comments = getDiscussions(gameNameId, resourceNameId).value

    return _findChildComment(comments, commentId)
  }

  return {
    getComputedDiscussionsTotal,
    getTopLevelComment,
    appendDiscussions,
    updateDiscussion,
    removeDiscussion,
    addDiscussions,
    getDiscussions,
    addDiscussion,
  }
}
