<template>
  <div
    class="tw-flex tw-items-center tw-animate-pulse"
    :class="[labelWidth, { 'tw-h-9': minHeight }]"
  >
    <div
      class="tw-w-20 tw-h-4 tw-rounded-full"
      :class="[labelWidth, altBg ? 'tw-bg-theme' : 'tw-bg-theme-1']"
    ></div>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue'
export default {
  props: {
    altBg: {
      type: Boolean,
      default: false,
    },
    random: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { random } = toRefs(props)
    const sizes = ['18', '24', '20']

    const labelWidth = computed(() => (random.value ? twSize() : 'tw-w-20'))

    function twSize() {
      let obj = sizes[Math.floor(Math.random() * sizes.length)]
      if (obj === '18') {
        return 'tw-w-18'
      } else if (obj === '24') {
        return 'tw-w-24'
      } else {
        return 'tw-w-20'
      }
    }

    return {
      labelWidth,
    }
  },
}
</script>
