import { LS_RECENTGAMES } from '@config/localstorage.js'
import useStorage from '@composables/useStorage.js'
import { RECENT_GAME_COUNT } from '@config'
import { isArray } from '@helpers/utils.js'
import { computed } from 'vue'

export default function () {
  const state = useStorage(LS_RECENTGAMES, true)
  const recentGames = computed(() => JSON.parse(state.value || '[]'))

  // {
  //   id,
  //   image_url,
  //   name
  //   name_id
  //   theme
  // }
  function addRecentGame(game, pinning = false) {
    let temp = [...recentGames.value]
    if (!temp || !isArray(temp) || !game?.id) {
      return
    }

    if (pinning) {
      temp = temp.filter((g) => g.id !== game.id)
      temp.unshift(game)
    } else {
      const index = temp.findIndex((g) => g.id === game.id)
      if (index >= 0) {
        temp[index] = {
          ...game,
          pinned: temp[index].pinned,
        }
      } else {
        let nextIndex = 0
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].pinned) {
            nextIndex++
          }
        }
        temp.splice(nextIndex, 0, game)
      }
    }

    state.value = JSON.stringify(temp.slice(0, RECENT_GAME_COUNT))
  }

  function updateRecentGameTheme(gameNameId, theme) {
    if (
      !gameNameId ||
      !theme ||
      !recentGames.value ||
      !isArray(recentGames.value)
    ) {
      return
    }

    const game = recentGames.value.find((g) => g.name_id === gameNameId)
    if (game) {
      addRecentGame({ ...game, theme })
    }
  }

  function togglePin(gameNameId) {
    const game = recentGames.value.find((g) => g.name_id === gameNameId)
    const pinned = !game.pinned
    if (game) {
      removeRecentGame(game.name_id)
      addRecentGame({ ...game, pinned }, pinned)
    }
  }

  function removeRecentGame(gameNameId) {
    state.value = JSON.stringify(
      recentGames.value.filter((g) => g.name_id !== gameNameId)
    )
  }

  return {
    updateRecentGameTheme,
    removeRecentGame,
    addRecentGame,
    recentGames,
    togglePin,
  }
}
