import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { get, post, del } = createClient()

/* API request to get dependencies */
export async function getDependenciesRequest(gameNameId, modNameId) {
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/dependencies`
  )
  return data
}

/* API request to get dependants */
export async function getDependantsRequest(gameNameId, modNameId) {
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/dependants`
  )
  return data
}

/* API request to add dependencies */
export async function addDependencyRequest(gameNameId, modNameId, update) {
  const { data } = await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/dependencies`,
    qs.stringify(update)
  )
  return data
}

/* API request to delete dependencies */
export async function deleteDependencyRequest(gameNameId, modNameId, update) {
  const { data } = await del(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/dependencies`,
    qs.stringify(update)
  )
  return data
}
