import { scrollToElementOffset } from '@helpers/utils.js'
import { HEADER_SWITCHER_HEIGHT } from '@config'
import { ref, unref } from 'vue'

// Error elements array: [{ [errorName]: HTMLElement }, ... ]
const errElements = ref({})

export default function (
  validationErrors,
  headerOffset = HEADER_SWITCHER_HEIGHT,
  containerId = undefined
) {
  function scrollToError() {
    const errors = unref(validationErrors)
    const errNames = Object.keys(errElements.value).filter(
      (x) => errors[x]?.length
    )

    if (!errNames.length) return

    let el = null
    let minTop = document.documentElement.scrollHeight
    for (const name of errNames) {
      const curEl = errElements.value[name]
      const elTop = curEl.getBoundingClientRect().top
      if (elTop < minTop) {
        minTop = elTop
        el = curEl
      }
    }

    scrollToElementOffset(el, -headerOffset, containerId)
  }

  function updateErrRef(value, errName) {
    if (value) errElements.value[errName] = value.$el
  }

  return {
    scrollToError,
    updateErrRef,
  }
}
