import { USER_GAME_ROUTE } from '@config/routeNames.js'

export const baseSchema = {
  config: [
    {
      label: 'Name',
      type: 'group',
      key: 'user.username',
      cellClass: 'tw-text-left tw-w-4/12 lg:tw-w-2/12',
      to: _onClick,
      group: [
        (item) => ({
          type: 'avatar',
          user: {
            username: item.user.username,
            avatar: item.user.avatar,
          },
        }),
        {
          type: 'string',
          key: 'user.username',
          format: 'string',
        },
      ],
    },
    {
      label: 'Date joined',
      type: 'string',
      format: 'date',
      key: 'user.date_joined',
      cellClass: 'tw-hidden lg:tw-table-cell tw-text-left',
    },
    {
      label: 'Last online',
      type: 'string',
      format: 'date',
      key: 'user.date_online',
      cellClass: 'tw-hidden xl:tw-table-cell tw-text-left',
      sort: {
        direction: 'desc',
      },
    },
  ],
  defaultSort: 'user.date_online',
}

const actions = {
  label: 'Actions',
  type: 'group',
  sortable: false,
  cellClass: 'tw-text-right',
  group: [
    {
      type: 'action',
      attrs: {
        secondary: true,
        icon: 'comment',
      },
      tooltip: 'Message user',
      onClick: 'openMessageBox',
    },
    {
      type: 'action',
      attrs: {
        danger: true,
        icon: 'times',
      },
      tooltip: 'Remove user',
      background: 'danger',
      onClick: 'removeUserPreview',
    },
  ],
}

const subscribed = {
  label: 'Subscribed',
  type: 'group',
  key: 'user.subscribed',
  cellClass: 'tw-text-center',
  group: [
    {
      type: 'icon',
      cellClass: 'tw-text-success tw-fill-current',
      condition: (user) => user.subscribed,
      icon: 'check-circle',
    },
    {
      type: 'icon',
      cellClass: 'tw-text-danger tw-fill-current',
      condition: (user) => !user.subscribed,
      icon: 'times-circle',
    },
  ],
  sortable: false,
}

export const gameSchema = {
  config: [...baseSchema.config, actions],
  defaultSort: baseSchema.defaultSort,
}

export const modSchema = {
  config: [...baseSchema.config, subscribed, actions],
  defaultSort: baseSchema.defaultSort,
}

function _onClick(row) {
  return {
    name: USER_GAME_ROUTE,
    params: {
      user: row.user.name_id,
    },
  }
}
