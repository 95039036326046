import { GUIDE_VIEW_ROUTE, MOD_VIEW_ROUTE } from '@config/routeNames.js'
import { useStoreWatcher, useRoute } from '@composables'
import { TITLE_POSTFIX } from '@config/seo.js'
import { MODIO_LOGO_URL } from '@config'
import { watch } from 'vue'
import {
  hasDynamicBreadcrumbName,
  defaultDocument,
  secsToMs,
  isClient,
  isString,
  clone,
} from '@helpers/utils.js'

export default function () {
  if (!isClient) throw new Error('Not in browser environment')

  const { registerParametersWatch } = useStoreWatcher()

  // cache metas in variable
  const metas = defaultDocument.getElementsByTagName('meta')

  const { routeName, routeParams, routeMeta, resolve } = useRoute()

  function handleSeo() {
    _setTag(
      'link',
      { attribute: 'rel', value: 'manifest' },
      `${window.location.origin}/manifest.json`
    )

    watch(routeParams, () => {
      _handleMetas()
      _handleTitle()
      _handleLinks()
      _handleStructuredData()
    })
  }

  function _handleLinks() {
    _setTag(
      'link',
      { attribute: 'rel', value: 'canonical' },
      window.location.href
    )
  }

  function _handleMetas() {
    _setInitial('meta')
    _setDynamic('meta')
  }

  function _handleTitle() {
    const title = routeMeta.value?.seo?.find((e) => e.tag === 'title')
    title ? _setInitial('title') : _setTag('title')

    _setDynamic('title')
  }

  function _setInitial(tag) {
    const tagsWithContent = routeMeta.value?.seo?.filter(
      (e) => e.tag === tag && e.content
    )

    if (tagsWithContent?.length) {
      tagsWithContent.forEach((m) =>
        _setTag(tag, tag === 'title' ? {} : m.name, m.content)
      )
    }
  }

  function _setDynamic(tag) {
    let dynamicText
    const tagswithDynamicContent = routeMeta.value?.seo?.filter(
      (e) => e.tag === tag && e.dynamicContent
    )
    if (tagswithDynamicContent?.length) {
      const _handle = (result) => {
        tagswithDynamicContent.forEach((m) => {
          try {
            dynamicText = m.dynamicContent(result)
          } catch (error) {
            dynamicText = null
          }
          const content = [dynamicText, m.content].filter(isString).join(', ')

          _setTag(tag, tag === 'title' ? {} : m.name, content)
        })
      }
      registerParametersWatch(_handle)
    }
  }

  function _isMetaDescription(attribute, value) {
    return attribute === 'name' && value === 'description'
  }

  function _setTag(tag, { value, attribute } = {}, content) {
    if (!content) return

    if (tag === 'meta') {
      if (_isMetaDescription(attribute, value)) _setExtraMetas(content)

      const node = _findNode({ nodes: metas, attribute, value })

      if (node) {
        node.content = content
      } else {
        const meta = defaultDocument.createElement('meta')
        meta[attribute] = value
        meta.content = content
        defaultDocument.head.appendChild(meta)
      }
    } else if (tag === 'title') {
      defaultDocument.title = [content, TITLE_POSTFIX]
        .filter(isString)
        .join(' - ')
    } else if (tag === 'link') {
      const nodes = defaultDocument.getElementsByTagName('link')
      const node = _findNode({ nodes, attribute, value })
      if (node) {
        node.href = content
      }
    }
  }

  function _findNode({ nodes, attribute, value }) {
    return Array.from(nodes).find((m) => m.getAttribute(attribute) === value)
  }

  function _setExtraMetas(content) {
    _setTag('meta', { attribute: 'property', value: 'og:description' }, content)
    _setTag(
      'meta',
      { attribute: 'property', value: 'twitter:description' },
      content
    )
  }

  // Breadcrumb structured data functions
  const SD_BREADCRUMB_LIST_ID = 'BreadcrumbList'
  const SD_ARTICLE_LIST_ID = 'NewsArticle'
  const SD_LIST = [SD_BREADCRUMB_LIST_ID, SD_ARTICLE_LIST_ID]
  const articleRoutes = [GUIDE_VIEW_ROUTE, MOD_VIEW_ROUTE]

  function _handleStructuredData() {
    _removeStructuredDataTag(SD_LIST)
    _handleBreadcrumbStructuredData()
    _handleArticleStructuredData()
  }

  function _handleArticleStructuredData() {
    if (!articleRoutes.includes(routeName.value)) return

    registerParametersWatch((result) => {
      let resource
      switch (routeName.value) {
        case MOD_VIEW_ROUTE:
          resource = result['mod']
          break
        case GUIDE_VIEW_ROUTE:
          resource = result['guide']
          break
      }

      if (!resource) return

      _setStructuredData(
        SD_ARTICLE_LIST_ID,
        _articleStructuredDataBody(_buildArticleStructuredData(resource))
      )
    })
  }

  function _buildArticleStructuredData(result) {
    return {
      headline: result.name,
      image: [result.card_image],
      datePublished: new Date(secsToMs(result.date_added)),
      dateModified: new Date(secsToMs(result.date_updated)),
      author: {
        '@type': 'Person',
        name: result.submitted_by.username,
        url: result.submitted_by.profile_url,
      },
      publisher: {
        '@type': 'Organization',
        name: 'mod.io',
        logo: {
          '@type': 'ImageObject',
          url: MODIO_LOGO_URL,
        },
      },
    }
  }

  function _articleStructuredDataBody(structuredData) {
    return {
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': 'https://google.com/article',
      },
      ...structuredData,
    }
  }

  function _handleBreadcrumbStructuredData() {
    const breadcrumbTos =
      routeMeta.value?.breadcrumb &&
      Object.values(clone(routeMeta.value?.breadcrumb))

    if (!breadcrumbTos) return

    if (breadcrumbTos.some((b) => hasDynamicBreadcrumbName(b.label))) {
      registerParametersWatch((result) => {
        try {
          let structuredData = breadcrumbTos
            .map((b) => {
              let name = b.label
              if (hasDynamicBreadcrumbName(name)) {
                const resource = result[name.substr(1)]
                if (!resource) return
                name =
                  name.substr(1) !== 'user' ? resource.name : resource.username
              }
              const resolveTo = resolve({ name: b.to.name, result })
              if (!resolveTo) {
                console.warn('unable to resolve name path')
                return
              }

              return {
                name,
                item: `${location.origin}${resolveTo?.fullPath}`,
                dynamic: hasDynamicBreadcrumbName(b.label),
              }
            })
            .reduce((prev, current, index) => {
              if (
                prev.length > 0 &&
                current?.item &&
                prev[index - 1]?.item &&
                current?.item === prev[index - 1]?.item
              ) {
                if (!prev[index - 1].dynamic) {
                  prev.pop()
                }
              }
              if (current) {
                prev.push(current)
              }
              return prev
            }, [])

          if (structuredData.length) {
            _setStructuredData(
              SD_BREADCRUMB_LIST_ID,
              _breadcrumbStructuredDataBody(structuredData)
            )
          }
        } catch (error) {
          console.error(error)
        }
      })
    } else {
      const structuredData = breadcrumbTos.map((b) => {
        const resolveTo = resolve({ name: b.to.name })
        return {
          name: b.label,
          item: `${location.origin}${resolveTo?.fullPath}`,
        }
      })

      _setStructuredData(
        SD_BREADCRUMB_LIST_ID,
        _breadcrumbStructuredDataBody(structuredData)
      )
    }
  }

  function _breadcrumbStructuredDataBody(structuredData) {
    let listItemCount = 1
    return {
      itemListElement: structuredData.map((s) => {
        delete s.dynamic
        return {
          '@type': 'ListItem',
          position: listItemCount++,
          ...s,
        }
      }),
    }
  }

  function _removeStructuredDataTag(ids) {
    ids.forEach((id) => {
      const prevTag = defaultDocument.getElementById(id)
      if (prevTag) prevTag.remove()
    })
  }

  function _setStructuredData(type, body) {
    _removeStructuredDataTag([type])
    const text = {
      '@context': 'https://schema.org',
      '@type': type,
      ...body,
    }

    const script = defaultDocument.createElement('script')
    script.id = type
    script.setAttribute('type', 'application/ld+json')
    script.textContent = JSON.stringify(text, null, ' ')
    defaultDocument.head.appendChild(script)
  }

  return { handleSeo }
}
