import { TEAM_MEMBER_LEVEL_MODERATOR } from '@config/options.js'
import { requestAll } from '@helpers/requestAll.js'
import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { get, put, post, del } = createClient()

/* API request to get team members for game */
export async function getGameTeamMembersRequest(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}/team`)
  return data
}

/* API request to add team member to game */
export async function addMemberToGameTeamRequest(gameNameId, emailOrUserId) {
  const defaultMember = {
    [isNaN(emailOrUserId) ? 'email' : 'to_user_id']: emailOrUserId,
    level: TEAM_MEMBER_LEVEL_MODERATOR,
    position: '',
  }
  const { data } = await post(
    `/games/@${gameNameId}/team`,
    qs.stringify(defaultMember)
  )
  return data
}

/* API request to update team member for game */
export async function updateGameTeamMemberRequest(
  gameNameId,
  memberId,
  member
) {
  const { data } = await put(
    `/games/@${gameNameId}/team/${memberId}`,
    qs.stringify(member)
  )
  return data
}

/* API request to update team leader for game */
export async function updateGameTeamLeaderRequest(gameNameId, memberId) {
  const { data } = await put(
    `/games/@${gameNameId}/team/${memberId}`,
    qs.stringify({ leader: 1 })
  )
  return data
}

/* API request to delete team member form game */
export async function deleteGameTeamMemberRequest(gameNameId, memberId) {
  const { data } = await del(`/games/@${gameNameId}/team/${memberId}`)
  return data
}

/* API request to get team members for mod*/
export async function getModTeamMembersRequest(
  gameNameId,
  modNameId,
  fetchAll
) {
  const url = `/games/@${gameNameId}/mods/@${modNameId}/team`
  let data
  if (fetchAll) {
    data = await requestAll(url)
  } else {
    data = (await get(url)).data
  }

  return data
}

/* API request to add member to a mod's team */
export async function addMemberToModTeamRequest(
  gameNameId,
  modNameId,
  emailOrUserId
) {
  const member = {
    [isNaN(emailOrUserId) ? 'email' : 'to_user_id']: emailOrUserId,
    level: TEAM_MEMBER_LEVEL_MODERATOR,
    position: '',
  }
  const { data } = await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/team`,
    qs.stringify(member)
  )
  return data
}

/* API request to update mod team member*/
export async function updateModTeamMemberRequest(
  gameNameId,
  modNameId,
  accessId,
  update
) {
  const { data } = await put(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/team/${accessId}`,
    qs.stringify(update)
  )
  return data
}

/* API request to update mod team leaderi */
export async function updateModTeamLeaderRequest(
  gameNameId,
  modNameId,
  teamMemberId
) {
  const { data } = await put(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/team/${teamMemberId}`,
    qs.stringify({ leader: 1 })
  )

  return data
}

/* API request to delete mod team member */
export async function deleteModTeamMemberRequest(
  gameNameId,
  modNameId,
  accessId
) {
  const { data } = await del(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/team/${accessId}`
  )

  return data
}

/* API request to get **all** user team summary */
export function getAllUserTeamSummaryRequest() {
  return requestAll('/me/teams')
}

/* API request to accept team invitation */
export async function acceptTeamInvitationRequest(hash) {
  const { data } = await post(`/me/invite/${hash}`)

  return data
}

/* API request to decline team invitation */
export async function declineTeamInvitationRequest(hash) {
  const { data } = await del(`/me/invite/${hash}`)

  return data
}
