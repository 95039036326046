import { STATUS_ACCEPTED } from '@config/options.js'

/**
 * Returns an object to send to updateGameContentRequest
 * @param {CogsGame} game
 * @param {boolean} isSuperAdmin
 */
function getGameContentForm(game, isSuperAdmin) {
  const data = {
    ugc_name: game.ugc_name,
    presentation_option: game.presentation_option,
    maturity_options: game.maturity_options,
    api_access_options: game.api_access_options,
    curation_option: game.curation_option,
    submission_option: game.submission_option,
    dependency_option: game.dependency_option,
    instructions: game.instructions,
    autoban: game.autoban,
    // content endpoint requires disabled field
    disabled: 0,
    allow_comments: Number(game.allow_comments || 0),
    allow_comments_guides: Number(game.allow_comments_guides || 0),
    allow_guides: Number(game.allow_guides || 0),
    show_on_homepage: Number(game.show_on_homepage || 0),
    allow_negative_ratings: Number(game.allow_negative_ratings || 0),
    allow_mods_to_be_edited_via_web: Number(
      game.allow_mods_to_be_edited_via_web || 0
    ),
    show_ugc_permissions: game.show_ugc_permissions ?? 1,
  }

  if (game.status !== STATUS_ACCEPTED) {
    data.allow_previews = Number(game.allow_previews || 0)
    data.allow_preview_url = Number(game.allow_preview_url || 0)
  }

  if (isSuperAdmin) {
    data.pin_homepage = Number(game.pin_homepage || 0)
    data.can_change_status = Number(game.can_change_status || 0)
    data.show_more_on_homepage = Number(game.show_more_on_homepage || 0)
    data.custom_agreement = Number(game.custom_agreement || 0)
  }

  return data
}

export default getGameContentForm
