<template>
  <div
    v-if="isLoggedIn"
    :class="
      sidebar
        ? 'tw-hidden md:tw-flex tw-flex-col tw-items-center tw-space-y-1'
        : 'tw-flex tw-items-center tw-space-x-6'
    "
  >
    <div v-if="isMonRegistered" :class="wrapStyles">
      <tooltip-hover
        text="Virtual currency balance"
        hide-on-sm
        position="right"
        background="primary-hover"
        outside-main
        @click="showWalletsModal"
      >
        <button class="tw-group tw-block tw-relative">
          <div :class="buttonStyles">
            <div class="tw-flex tw-items-center">
              <font-awesome-icon icon="wallet" fixed-width />
            </div>
          </div>
        </button>
      </tooltip-hover>
    </div>

    <div :class="wrapStyles">
      <tooltip-hover
        text="Notifications"
        hide-on-sm
        position="right"
        background="primary-hover"
        outside-main
        @click="showNotificationDropdown"
      >
        <button class="tw-group tw-block tw-relative">
          <div :class="buttonStyles">
            <div class="tw-flex tw-items-center">
              <font-awesome-icon icon="bell" fixed-width />
            </div>
          </div>
          <span class="sr-only">{{
            unreadNotificationCount
              ? 'You have unread notifications'
              : 'You have no new notifications'
          }}</span>
        </button>
      </tooltip-hover>

      <div v-if="unreadNotificationCount" :class="notificationStyles">
        <span
          class="tw-p-1"
          :class="{ 'tw-text-danger-text tw-invert': lowDangerWCAGContrast }"
        >
          {{ unreadNotificationCountText }}
        </span>
      </div>
    </div>

    <div :class="wrapStyles">
      <tooltip-hover
        text="Chat"
        hide-on-sm
        position="right"
        background="primary-hover"
        outside-main
        @click="showMessagesDropdown"
      >
        <button class="tw-group tw-block tw-relative">
          <div :class="buttonStyles">
            <div class="tw-flex tw-items-center">
              <font-awesome-icon icon="comment" fixed-width />
            </div>
          </div>
          <span class="sr-only">{{
            messageCount?.length
              ? 'You have unread messages'
              : 'You have no new messages'
          }}</span>
        </button>
      </tooltip-hover>

      <div v-if="messageCount?.length" :class="notificationStyles">
        <span
          :class="{ 'tw-text-danger-text tw-invert': lowDangerWCAGContrast }"
        >
          {{ messageCount.length }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { useMessagePoll, useNavMenus, useTheming } from '@composables'
import TooltipHover from '@components/Message/TooltipHover.vue'
import { setGlobalModal } from '@composables/useModal.js'
import { MODAL_WALLETS } from '@config/globalModals.js'
import { notificationStore, authStore } from '@stores'
import { computed } from 'vue'

export default {
  components: {
    TooltipHover,
  },
  props: {
    sidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { unreadCount: unreadNotificationCount } = notificationStore()
    const { isLoggedIn, isMonRegistered } = authStore()
    const { lowDangerWCAGContrast } = useTheming()

    const { showNotificationDropdown, showMessagesDropdown } = useNavMenus()

    const { unreadThreadIds: messageCount } = useMessagePoll()

    const wrapStyles = 'tw-flex tw-relative'
    const notificationStyles = computed(
      () =>
        `tw-absolute tw-top-px tw-right-px xs:tw-top-0.5 xs:tw-right-0.5 tw-rounded-full tw-h-4 tw-min-w-4 tw-text-xxs tw-flex tw-justify-center tw-items-center tw-pointer-events-none md:tw-border md:tw-border-primary ${lowDangerWCAGContrast.value ? 'tw-bg-danger-text' : 'tw-bg-danger tw-text-danger-text'} tw-shadow-md`
    )

    const buttonStyles =
      'tw-flex tw-items-center tw-justify-center tw-w-11 tw-h-11 tw-px-3 tw-global--border-radius tw-text-h5 group-hover:md:tw-bg-primary-hover group-focus:md:tw-bg-primary-hover'

    const unreadNotificationCountText = computed(() =>
      unreadNotificationCount.value < 100
        ? unreadNotificationCount.value
        : '99+'
    )

    function showWalletsModal() {
      setGlobalModal({ modal: MODAL_WALLETS })
    }

    return {
      unreadNotificationCountText,
      showNotificationDropdown,
      unreadNotificationCount,
      lowDangerWCAGContrast,
      showMessagesDropdown,
      notificationStyles,
      showWalletsModal,
      isMonRegistered,
      messageCount,
      wrapStyles,
      isLoggedIn,
      buttonStyles,
    }
  },
}
</script>
