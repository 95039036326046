import { COMPARISON_OPTIONS } from '@config/chartOptions.js'
import { useResourceValue, useRoute } from '@composables'
import { computed, ref } from 'vue'

const comparisonOptions = Object.values(COMPARISON_OPTIONS)
const comparison = ref(COMPARISON_OPTIONS.ACTIVE_USERS.value)

export default function () {
  const { ugcName } = useResourceValue()
  const { getModId } = useRoute()
  const options = computed(() => {
    let _options = comparisonOptions.map((x) => ({
      text:
        COMPARISON_OPTIONS.MODS_ADDED.value === x.value
          ? x.text.replace('Mods', ugcName().value)
          : x.text,
      value: x.value,
    }))

    if (getModId().value) {
      const modOnlyOptions = [
        COMPARISON_OPTIONS.DOWNLOADS.value,
        COMPARISON_OPTIONS.ACTIVE_USERS.value,
      ]
      _options = comparisonOptions.filter((x) =>
        modOnlyOptions.includes(x.value)
      )
    }

    return _options.filter(
      (o) => o.value !== COMPARISON_OPTIONS.SUBSCRIPTIONS.value
    )
  })

  const comparisonText = computed(
    () => options.value.find((x) => x.value === comparison.value).text
  )

  return {
    comparisonOptions,
    comparisonText,
    comparison,
    options,
  }
}
