import { LS_STATUS_BAR } from '@config/localstorage.js'
import { useLocalStorage } from '@vueuse/core'
import { computed, ref } from 'vue'

const statusBarState = useLocalStorage(LS_STATUS_BAR, null)

export default function () {
  const current = ref(0)
  const statusBar = computed(() => JSON.parse(statusBarState.value))

  function listenStatusBar() {
    window.addEventListener('keydown', _handleKeyPress)
  }

  function removeStatusBar() {
    window.removeEventListener('keydown', _handleKeyPress)
  }

  function _handleKeyPress(event) {
    const pattern = [
      'ArrowUp',
      'ArrowUp',
      'ArrowDown',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowLeft',
      'ArrowRight',
      'b',
      'a',
    ]
    // If the key isn't in the pattern, or isn't the current key in the pattern, reset
    if (
      pattern.indexOf(event.key) < 0 ||
      event.key !== pattern[current.value]
    ) {
      current.value = 0
      return
    }
    // Update how much of the pattern is complete
    current.value++

    // If complete, alert and reset
    if (pattern.length === current.value) {
      current.value = 0

      statusBarState.value = statusBarState.value ? null : true
    }
  }

  return {
    listenStatusBar,
    removeStatusBar,
    statusBar,
  }
}
