<template>
  <teleport to="body">
    <base-modal
      :show="true"
      :auto-focus-modal="false"
      size="md"
      :cancel-button="false"
    >
      <template #header>
        <div class="tw-flex tw-justify-center">
          <base-icon
            icon="modio_unreg_black"
            class="tw-w-36 xs:tw-w-40 tw-fill-current tw-shrink-0"
          />
        </div>
      </template>

      <p class="tw-mb-6">
        Welcome to mod.io and thank you for registering. Please choose a display
        name below:
      </p>

      <base-input
        focus
        label="Display name"
        :model-value="username"
        :required="formValidation.display_name.required"
        :max="formValidation.display_name.max"
        :errors="errors.display_name"
        @input="updateField"
        @enter="confirmUsername"
      />

      <template #footer>
        <base-button
          primary
          :status="status"
          :errors="errors"
          @click="confirmUsername"
        >
          Confirm
        </base-button>
      </template>
    </base-modal>
  </teleport>
</template>

<script>
import { usernameConfig as formValidation } from '@config/validation.js'
import { useValidate, useStatus, useAsync } from '@composables'
import { BUTTON_ANIMATION_DURATION } from '@config'
import { updateUsernameRequest } from '@services'
import { ref, watch, provide } from 'vue'
import { authStore } from '@stores'

export default {
  setup() {
    const { user, setInitialLogin, setUserInfo } = authStore()

    const { data, run, error, loading } = useAsync(
      updateUsernameRequest,
      'Failed to set display name'
    )
    const { status, updateStatus, statusType } = useStatus({ error, loading })
    provide('saving', loading)

    const username = ref('')
    const { errors, validate, hasErrors, updateErrors } = useValidate(
      formValidation.validation
    )

    function updateField(input) {
      validate('display_name', input)
      username.value = input
    }

    watch(error, (value) => {
      updateErrors(value)
    })

    async function confirmUsername() {
      if (username.value === user.value.info.display_name) {
        success()
        return
      }

      validate('display_name', username.value)
      if (hasErrors('display_name')) {
        return
      }

      await run(username.value)
      if (!error.value) {
        setUserInfo({
          ...user.value.info,
          display_name: data.value.username,
          name_id: data.value.nameid,
        })
        success()
      }
    }

    function success() {
      updateStatus(statusType.SUCCESS)
      setTimeout(() => setInitialLogin(false), BUTTON_ANIMATION_DURATION)
    }

    return {
      confirmUsername,
      formValidation,
      updateField,
      username,
      errors,
      status,
      user,
    }
  },
}
</script>
