<template>
  <svg
    width="1.5rem"
    height="1.5rem"
    viewbox="0 0 1.5rem 1.5rem"
    style="transform: rotate(-90deg)"
  >
    <circle
      cx="0.75rem"
      cy="0.75rem"
      r="calc(0.75rem - 2px)"
      stroke="rgba(0, 0, 0, 0.2)"
      fill="none"
      stroke-width="4"
    />
    <circle
      cx="0.75rem"
      cy="0.75rem"
      r="calc(0.75rem - 2px)"
      stroke="var(--primary-text)"
      fill="none"
      stroke-width="4"
      pathLength="100"
      stroke-dasharray="100"
      :stroke-dashoffset="value"
    />
  </svg>
</template>
<script>
import { computed } from 'vue'

export default {
  props: {
    percent: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const value = computed(() => {
      const val = Math.max(0, Math.min(100, Math.floor(props.percent * 100)))
      return 100 - val
    })
    return { value }
  },
}
</script>
