const inboxEntry = {
  has_read: true,
  has_received: true,
  has_replied: true,
  id: null,
  last_message: null,
  name: 'Hey!',
  participants: [],
}

export default inboxEntry
