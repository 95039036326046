<template>
  <inline-message
    v-if="showBanner"
    warning
    :has-icon="false"
    @close:message="clearSuggestions"
  >
    <div class="tw-space-y-2">
      <component :is="suggestionComponent"></component>
    </div>
  </inline-message>
</template>
<script>
import InlineMessage from '@components/Message/InlineMessage.vue'
import { scrollToElement } from '@helpers/utils.js'
import { defineAsyncComponent, watch } from 'vue'
import { useSuggestions } from '@composables'
export default {
  components: {
    InlineMessage,
    GameError: defineAsyncComponent(
      () => import('@components/Error/GameError.vue')
    ),
    ModError: defineAsyncComponent(
      () => import('@components/Error/ModError.vue')
    ),
    GuideError: defineAsyncComponent(
      () => import('@components/Error/GuideError.vue')
    ),
  },
  setup() {
    const { suggestionComponent } = useSuggestions()
    const { showBanner, clearSuggestions } = useSuggestions()

    watch(showBanner, (_showBanner) => {
      if (_showBanner) {
        scrollToElement('bodyTop')
      }
    })

    return {
      suggestionComponent,
      clearSuggestions,
      showBanner,
    }
  },
}
</script>
