import { computed, ref } from 'vue'

const conversionRatesState = ref([])

export default function () {
  const conversionRates = computed(() => conversionRatesState.value)

  function clearConversionRates() {
    conversionRates.value = []
  }

  function setConversionRates(data) {
    conversionRatesState.value = data
    return data
  }

  return {
    clearConversionRates,
    setConversionRates,
    conversionRates,
  }
}
