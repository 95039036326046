import { authStore } from '@stores'
import { computed, ref } from 'vue'
import { DateTime } from 'luxon'
import {
  useResourceValue,
  useMetricsDates,
  useRoute,
  useAsync,
} from '@composables'
import {
  getModsForGameRequest,
  getGameStatsRequest,
  getStatusRequest,
} from '@services'

const gameState = ref(undefined)

const gameStatsData = ref(undefined)
const modStatsData = ref(undefined)

const siteStatsData = ref(undefined)
const topModsData = ref(undefined)

export default function (game, mod) {
  const { dateRange } = useMetricsDates()
  const { getModId } = useRoute()
  const { getGameId } = useRoute()
  const { isSuperAdmin } = authStore()
  const { ugcName } = useResourceValue()

  const gameNameId = getGameId().value
  const modNameId = getModId().value
  const siteAdmin = isSuperAdmin() && !gameNameId
  const ugcNameSingle = ugcName(null, true).value
  const ugcNamePlural = ugcName(null, false).value

  if (game) gameState.value = game.value

  if (mod?.value) modStatsData.value = mod.value.stats

  const stats = computed(() =>
    !modNameId ? gameStatsData.value : modStatsData.value
  )

  const statsError = computed(() => gameStatsError.value || topModsError.value)

  const {
    loading: loadingSiteStats,
    data: siteStats,
    error: siteStatsError,
    run: runGetSiteStats,
  } = useAsync(() => getStatusRequest(), 'Failed to get site stats')

  const {
    loading: loadingGameStats,
    error: gameStatsError,
    data: gameStats,
    run: runGetGameStats,
  } = useAsync(
    () => getGameStatsRequest(getGameId().value),
    'Failed to get stats'
  )

  const {
    loading: loadingTopMods,
    error: topModsError,
    data: topMods,
    run: runGetTopMods,
  } = useAsync(
    (query) => getModsForGameRequest(gameNameId, query),
    'Failed to get top mod data'
  )

  const isLoadingTopMods = computed(
    () => loadingTopMods.value || loadingGameStats.value
  )

  const showDataWarning = computed(() => {
    if (!game.value) return false

    const metricsSubDataLimit = DateTime.fromISO('2023-02-08')
    const dateLive = DateTime.fromSeconds(
      mod.value ? mod.value?.date_live : game.value?.date_live
    ).startOf('day')

    return (
      dateLive < metricsSubDataLimit &&
      dateRange.value.startDate < metricsSubDataLimit
    )
  })

  async function getSiteStats() {
    await runGetSiteStats()
    if (!siteStatsError.value) {
      siteStatsData.value = siteStats.value
    }
  }

  async function getTopMods() {
    await runGetTopMods({ _sort: '-downloads_total' })
    if (!topModsError.value) {
      topModsData.value = topMods.value
    }
  }

  async function getGameStats() {
    await runGetGameStats()
    if (!gameStatsError.value) {
      gameStatsData.value = gameStats.value
    }
  }

  function clearData() {
    siteStatsData.value = undefined
    gameStatsData.value = undefined
    modStatsData.value = undefined
    topModsData.value = undefined
  }

  return {
    loadingSiteStats,
    loadingGameStats,
    isLoadingTopMods,
    showDataWarning,
    ugcNameSingle,
    ugcNamePlural,
    siteStatsData,
    getSiteStats,
    getGameStats,
    topModsData,
    getTopMods,
    statsError,
    gameNameId,
    modNameId,
    siteAdmin,
    clearData,
    stats,
  }
}
