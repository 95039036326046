<template>
  <div
    v-if="canPrev || canNext"
    class="tw-flex tw-items-center tw-justify-between"
    :class="
      tablePagination
        ? 'tw-text-xs tw-font-bold tw-h-10'
        : 'tw-py-4 tw-input--text-size'
    "
  >
    <div class="tw-flex tw-w-full tw-space-x-2 tw-input--height">
      <div class="tw-w-auto">
        <div
          class="tw-flex tw-h-full tw-items-center tw-global--border-radius tw-px-2 xs:tw-px-4"
          :class="
            tablePagination ? 'tw-space-x-0.5' : 'tw-bg-input tw-space-x-1'
          "
        >
          <span>
            {{ humanNumberFormat(start) }}
          </span>
          <span>-</span>
          <span>
            {{ humanNumberFormat(end) }}
          </span>
          <span v-if="!pagination.simple" class="tw-whitespace-nowrap"
            >of {{ humanNumberFormat(pagination.total) }}</span
          >
        </div>
      </div>
      <base-select
        v-if="limits"
        :full-width="false"
        :options="limitOptions"
        :model-value="pagination.limit"
        @change="(limit) => updateLimit(limit)"
      />
    </div>

    <nav
      v-if="canPrev || canNext"
      class="tw-relative tw-inline-flex"
      :class="tablePagination ? 'tw-mr-2' : 'tw-space-x-2'"
    >
      <base-button
        :text-link-hover="tablePagination"
        :subtle="tablePagination"
        :class="{ 'tw-px-2': tablePagination }"
        sr-only="Previous"
        icon="angle-left"
        :disabled="!canPrev"
        @click="prevPage"
      >
      </base-button>

      <base-button
        :text-link-hover="tablePagination"
        :subtle="tablePagination"
        :class="{ 'tw-px-2': tablePagination }"
        sr-only="Next"
        icon="angle-right"
        :disabled="!canNext"
        @click="nextPage"
      >
      </base-button>
    </nav>
  </div>
</template>

<script>
import { humanNumberFormat } from '@helpers/utils.js'
import { usePagination } from '@composables'
import { toRefs, computed } from 'vue'
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    limits: {
      type: Boolean,
      default: true,
    },
    tablePagination: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update', 'page'],
  setup(props, { emit }) {
    const { id } = toRefs(props)
    const {
      setPagination,
      limitOptions,
      totalPages,
      pagination,
      canNext,
      canPrev,
      next,
      prev,
    } = usePagination(id.value)

    const start = computed(() => {
      if (pagination.value.page === 0) {
        return 0
      } else if (pagination.value.page === 1) {
        return 1
      } else {
        return (pagination.value.page - 1) * pagination.value.limit
      }
    })

    const end = computed(() => {
      const end = pagination.value.page * pagination.value.limit
      if (
        (!pagination.value.simple || !pagination.value.hasNextPage) &&
        (pagination.value.total <= pagination.value.limit ||
          end >= pagination.value.total)
      ) {
        return pagination.value.total
      }

      return end
    })

    function nextPage() {
      if (next()) {
        emit('update', 'next')
      }
    }

    function prevPage() {
      if (prev()) {
        emit('update', 'prev')
      }
    }

    function updateLimit(limit) {
      setPagination({ limit })
      emit('update', 'limit')
    }

    function goToPage(page) {
      setPagination({ page })
      emit('update', 'page')
    }

    return {
      humanNumberFormat,
      limitOptions,
      updateLimit,
      pagination,
      totalPages,
      nextPage,
      goToPage,
      prevPage,
      canNext,
      canPrev,
      start,
      end,
    }
  },
}
</script>
