import {
  GUIDE_VIEW_ROUTE,
  MOD_BROWSE_ROUTE,
  MOD_VIEW_ROUTE,
} from '@config/routeNames.js'

export const MOD_CARD = {
  resource: 'mod',
  route: MOD_VIEW_ROUTE,
  footerComponent: 'ModCardFooter',
  footerProps: ['stats', 'date_live', 'date_updated', 'modfile'],
}

export const GAME_CARD = {
  resource: 'game',
  route: MOD_BROWSE_ROUTE,
  footerComponent: 'GameCardFooter',
  footerProps: [
    'resource_stats',
    'date_updated',
    'date_live',
    'access',
    'stats',
  ],
}

export const GUIDE_CARD = {
  resource: 'guide',
  route: GUIDE_VIEW_ROUTE,
  footerComponent: 'GuideCardFooter',
  footerProps: ['stats', 'date_live', 'date_updated'],
}
