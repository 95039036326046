import { isArray } from '@helpers/utils.js'

export function createFormRequest(data) {
  const form = new FormData()
  Object.keys(data).forEach((key) => {
    if (isArray(data[key])) {
      data[key].forEach((item) => {
        form.append(`${key}[]`, item)
      })
    } else {
      form.append(key, data[key])
    }
  })

  return form
}
