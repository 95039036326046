import { LS_INVITE } from '@config/localstorage.js'
import { useLocalStorage } from '@vueuse/core'
import { isString } from '@helpers/utils.js'
import { computed } from 'vue'

const state = useLocalStorage(LS_INVITE, null)
export default function () {
  const inviteHash = computed(() => state.value)

  function clearInviteHash() {
    state.value = null
  }

  function saveInviteHash(hash) {
    if (!isString(hash)) {
      return false
    }
    state.value = hash
    return true
  }

  return {
    clearInviteHash,
    saveInviteHash,
    inviteHash,
  }
}
