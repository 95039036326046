<template>
  <conversation-container>
    <template v-if="header" #header>
      <div class="tw-flex tw-w-full tw-fill-current">
        <div class="tw-flex tw-w-48 tw-items-center tw-justify-start">
          <conversation-header-button
            icon="chevron-left"
            @click="backToConvo"
          />
          <span
            class="tw-font-bold tw-text-md tw-leading-tight tw-util-truncate-two-lines dark:tw-text-light-1 tw-ml-4"
          >
            Chat members
          </span>
        </div>
        <div class="tw-flex tw-items-end tw-justify-end tw-ml-auto">
          <conversation-enlarge-button class="tw-hidden md:tw-block" />
          <conversation-header-button icon="times" @click="close" />
        </div>
      </div>
    </template>
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-justify-start tw-h-full tw-py-3 tw-px-6 tw-util-scrollbar tw-overflow-y-scroll"
      >
        <div
          v-for="user in participants"
          :key="user.id"
          class="tw-flex tw-flex-row tw-justify-start tw-py-2 tw-items-center"
        >
          <component
            :is="user.name_id ? 'BaseLink' : 'span'"
            :link-primary="false"
            :hover-primary="true"
            is-link
            :to="{ name: USER_ROUTE, params: { user: user.name_id } }"
            class="tw-flex tw-items-center tw-font-bold tw-truncate"
          >
            <avatar-icon
              class="tw-flex tw-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-object-cover tw-object-center tw-overflow-hidden tw-self-start tw-mr-1"
              show-status
              inherit-size
              :user="user"
            />
            <span>{{ user.username }}</span>
          </component>
          <conversation-extra-options-button
            class="tw-ml-auto"
            :other-user-id="user.id"
            :other-user-name-id="user.name_id"
            @trigger:report="triggerReportModal"
          />
        </div>
      </div>
    </template>
  </conversation-container>
</template>

<script>
import ConversationExtraOptionsButton from '@components/Conversation/ConversationExtraOptionsButton.vue'
import ConversationEnlargeButton from '@components/Conversation/ConversationEnlargeButton.vue'
import ConversationHeaderButton from '@components/Conversation/ConversationHeaderButton.vue'
import ConversationContainer from '@components/Conversation/ConversationContainer.vue'
import ReportModal from '@components/Modals/ReportModal.vue'
import { setGlobalModal } from '@composables/useModal.js'
import AvatarIcon from '@components/Users/AvatarIcon.vue'
import { MODAL_REPORT } from '@config/globalModals.js'
import { useMessenger, useThread } from '@composables'
import { RESOURCE_USERS } from '@config/options.js'
import { USER_ROUTE } from '@config/routeNames.js'
import { messageStore } from '@stores'
import { computed } from 'vue'
export default {
  components: {
    ConversationExtraOptionsButton,
    ConversationEnlargeButton,
    ConversationHeaderButton,
    ConversationContainer,
    ReportModal,
    AvatarIcon,
  },
  props: {
    header: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const {
      CONVERSATION_COMPONENTS,
      expandChatWindow,
      changeComponent,
      toggleExpand,
      showHide,
    } = useMessenger()
    const { getThread } = messageStore()
    const { threadId } = useThread()
    const thread = getThread(threadId.value)

    const participants = computed(() =>
      thread.value && thread.value.participants ? thread.value.participants : []
    ).value

    function backToConvo() {
      changeComponent(CONVERSATION_COMPONENTS.CONVERSATION_THREAD)
    }

    function close() {
      showHide(false)
      // Resets expand bool when closed
      expandChatWindow.value && toggleExpand()
      changeComponent(null)
    }

    function triggerReportModal(id) {
      setGlobalModal({
        modal: MODAL_REPORT,
        data: {
          resource: RESOURCE_USERS,
          resourceId: id,
        },
      })
    }

    return {
      triggerReportModal,
      participants,
      backToConvo,
      USER_ROUTE,
      close,
    }
  },
}
</script>
