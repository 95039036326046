import { LS_PURCHASES } from '@config/localstorage.js'
import modSerializer from '@serializers/modSerializer.js'
import { useLocalStorage } from '@vueuse/core'
import { isArray } from '@helpers/utils.js'
import { computed } from 'vue'

const purchasesState = useLocalStorage(LS_PURCHASES, null)

export default function () {
  const purchases = computed(() => JSON.parse(purchasesState.value))

  function clearPurchases() {
    purchasesState.value = JSON.stringify([])
  }

  function setMyPurchases(_purchases) {
    const { data } = modSerializer.transform({ data: _purchases })
    purchasesState.value = JSON.stringify(data)
    return data
  }

  function addPurchase(modId, modData) {
    if (isArray(purchases.value)) {
      const purchase = purchases.value.find((p) => p?.id === parseInt(modId))

      if (!purchase) {
        setMyPurchases([...purchases.value, modData])
      }
    } else {
      setMyPurchases([modData])
    }
  }

  function removePurchase(modId) {
    modId = Number(modId)
    if (isArray(purchases.value)) {
      setMyPurchases(purchases.value.filter((m) => m.id !== modId))
    }
  }

  return {
    removePurchase,
    clearPurchases,
    setMyPurchases,
    addPurchase,
    purchases,
  }
}
