<template>
  <table
    class="tw-w-full tw-relative tw-table-fixed"
    :class="hasError ? '' : 'tw-animate-pulse'"
    v-bind="$attrs"
  >
    <thead class="tw-table-header-group">
      <tr>
        <th
          v-for="thIndex in cols"
          :key="thIndex"
          class="tw-h-10 tw-px-4 tw-space-x-1.5 tw-whitespace-nowrap"
          :class="
            thIndex === 1 ? 'tw-hidden sm:tw-table-cell' : 'tw-table-cell'
          "
        >
          <div
            class="tw-flex"
            :class="thIndex === cols ? 'tw-justify-end' : 'tw-justify-center'"
          >
            <div
              class="tw-h-2 tw-rounded-full tw-bg-theme-1 tw-opacity-50"
              :class="twSize()"
            ></div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="tdindex in rows"
        :key="tdindex"
        :class="[
          defaultClass,
          background
            ? background === 'alt'
              ? 'tw-table-row-styles-alt'
              : 'tw-table-row-styles-alt-dark'
            : 'tw-table-row-styles',
          { 'tw-h-16 lg:tw-h-18': !smallRow },
        ]"
      >
        <td
          v-for="index in cols"
          :key="index"
          class="tw-px-4 tw-py-6 tw-align-middle tw-relative tw-h-9"
          :class="index === 1 ? 'tw-hidden sm:tw-table-cell' : 'tw-table-cell'"
        >
          <div
            class="tw-flex"
            :class="index === cols ? 'tw-justify-end' : 'tw-justify-center'"
          >
            <div
              class="tw-h-2 tw-w-24 tw-rounded-full tw-opacity-50"
              :class="[
                isOdd(tdindex) ? 'tw-bg-theme-1' : 'tw-bg-theme-2',
                twSize(),
              ]"
            ></div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div v-if="hasError" class="tw-flex tw-flex-col tw-mt-2 tw-space-y-1">
    <base-input-errors :errors="errorText" align="left" size="sm" />
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      default: 6,
    },
    cols: {
      type: Number,
      default: 4,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: 'There has been a problem loading the data',
    },
    smallRow: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: null,
      validator: (value) => ['alt', 'alt-dark'].includes(value),
    },
  },
  setup() {
    const sizes = ['1', '2', '3']
    const defaultClass =
      'tw-table-row tw-border-transparent tw-button-transition tw-group tw-p-0 tw-pr-2 tw-relative'

    function isOdd(num) {
      return num % 2
    }

    function twSize() {
      let obj = sizes[Math.floor(Math.random() * sizes.length)]
      if (obj === '1') {
        return 'tw-w-3/6'
      } else if (obj === '2') {
        return 'tw-w-2/6'
      } else {
        return 'tw-w-2/5'
      }
    }

    return {
      defaultClass,
      twSize,
      isOdd,
    }
  },
}
</script>
