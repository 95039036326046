<template>
  <div
    @dragenter.prevent="onDrag($event, true)"
    @dragleave.prevent="onDrag($event, false)"
    @dragover.prevent
    @drop.prevent="onDrop"
  >
    <slot :drag-over="dragOver" />
  </div>
</template>

<script>
import { ref, toRefs } from 'vue'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add:files'],
  setup(props, { emit }) {
    const { disabled } = toRefs(props)
    const dragOver = ref(false)
    let dragTarget

    function onDrop(e) {
      if (disabled.value) return

      dragOver.value = false

      if (e.dataTransfer.items) {
        const files = [...e.dataTransfer.items]
          .filter((i) => i.kind === 'file')
          .map((i) => i.getAsFile())

        if (files.length) {
          emit('add:files', files)
        }
      }
    }

    function onDrag(e, isDragging) {
      if (disabled.value) return

      if (isDragging) {
        if (e.dataTransfer.items.length) {
          dragOver.value = true
          dragTarget = e.target
        }
      } else if (e.target === dragTarget) {
        dragOver.value = false
      }
    }

    return {
      dragOver,
      onDrag,
      onDrop,
    }
  },
}
</script>
