import { defaultDocument } from '@helpers/utils.js'
import { SCROLL_TO_ID } from '@config/htmlIDs.js'
import { useEventListener } from '@vueuse/core'
import { ref, computed, watch } from 'vue'
import { HEADER_HEIGHT } from '@config'

export const HEADER_ID = 'guide'
export const DISCUSSION_ID = 'discussion'
const CHILD_HEADING_ID_PREFIX = 'heading'
const STARTING_INDEX = 1

export default function (guide, container) {
  const scrollContainer = defaultDocument.getElementById(SCROLL_TO_ID)

  const activeHeadingIndex = ref(0)

  const navHeaders = computed(() => {
    if (!guide.value?.description) return []

    // (<h\d>) matches <h2>, <h3>, etc...
    // (?:<[^<>]+>)* matches any number of other tags like <em> or <strong> but doesn't capture them
    // ([^<>]+?) matches the heading text
    const matches = guide.value.description.matchAll(
      /(<h\d>)(?:<[^<>]+>)*([^<>]+)/gi
    )
    const headings = []
    let id = STARTING_INDEX

    for (const match of matches) {
      const name = new DOMParser().parseFromString(match[2], 'text/html')
        .documentElement.innerText
      const level = parseInt(match[1][2])
      headings.push({ name: name || match[2], id: id++, level })
    }

    return headings
  })

  const lastHeaderIndex = computed(() => {
    if (!navHeaders.value.length) return STARTING_INDEX

    return navHeaders.value[navHeaders.value.length - 1].id + 1
  })

  const headingElements = computed(() => {
    return [
      defaultDocument.getElementById(HEADER_ID),
      ...navHeaders.value.map((heading) =>
        defaultDocument.getElementById(
          `${CHILD_HEADING_ID_PREFIX}-${heading.id}`
        )
      ),
      defaultDocument.getElementById(DISCUSSION_ID),
    ]
  })

  useEventListener(scrollContainer, 'scroll', () => {
    // This highlights the active heading in the sidebar
    if (!headingElements.value.length) return

    if (scrollContainer.scrollTop <= 100) {
      activeHeadingIndex.value = 0
    } else {
      for (let i = 1; i < headingElements.value.length; i++) {
        const rect = headingElements.value[i].getBoundingClientRect()
        if (rect.top >= HEADER_HEIGHT) {
          activeHeadingIndex.value = rect.top < window.innerHeight ? i : i - 1
          break
        }
      }
    }
  })

  if (container) {
    watch(activeHeadingIndex, (index) => {
      // This scrolls the sidebar to the active heading whenever it changes
      const link = container.value?.querySelector(
        `a[href="#${headingElements.value[index].id}"]`
      )
      if (link) {
        container.value.parentElement.scrollTo({
          top: link.offsetTop - 5,
          behavior: 'smooth',
        })
      }
    })
  }

  function generateChildHeadingId(index) {
    return `${CHILD_HEADING_ID_PREFIX}-${index}`
  }

  function insertHeadingIds(description) {
    let i = STARTING_INDEX
    return description.replace(
      /(<h\d)(>)/g,
      (_, m1) => `${m1} id="${generateChildHeadingId(i++)}">`
    )
  }

  return {
    generateChildHeadingId,
    activeHeadingIndex,
    insertHeadingIds,
    headingElements,
    lastHeaderIndex,
    DISCUSSION_ID,
    navHeaders,
    HEADER_ID,
  }
}
