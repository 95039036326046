module.exports = {
  BREAKPOINTS: {
    xxs: '376px',
    xs: '512px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1200px',
    '2xl': '1444px',
    '3xl': '1764px',
    '4xl': '2108px',
    '5xl': '2452px',
    '6xl': '2796px',
  },
}
