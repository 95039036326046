import { createClient } from '@services'
import qs from 'qs'

const { post } = createClient()

/* API request to submit contact form */
export async function submitContactFormRequest(contact) {
  const { data } = await post('/contact', qs.stringify(contact))
  return data
}
