import { SS_REDIRECT } from '@config/localstorage.js'
import { useRoute } from '@composables'
import { computed, ref } from 'vue'
import { authStore } from '@stores'
import {
  GUIDE_ADMIN_EDIT_ROUTE,
  GUIDE_BROWSE_ROUTE,
  GAME_BROWSE_ROUTE,
  GAME_ADMIN_ROUTE,
  MOD_BROWSE_ROUTE,
  GUIDE_VIEW_ROUTE,
  MOD_ADMIN_ROUTE,
  GAME_ADD_ROUTE,
  MOD_VIEW_ROUTE,
  LOGIN_ROUTE,
} from '@config/routeNames.js'

const RESOURCE_ERRORREF_404 = 14000
const GUIDE_ERRORREF_NO_PERMISSION = 14026
export const GUIDE_ERRORREF_NO_EDIT = 14036
export const MOD_ERRORREF_HIDDEN = 15024
export const GAME_ERRORREF_NO_EDIT = 14002
export const GAME_ERRORREF_DELETED = 14006
export const GAME_ERRORREF_NO_VIEW = 14007
export const GAME_ERRORREF_TOO_YOUNG = 14999
export const MOD_ERRORREF_NO_EDIT = 15013
export const MOD_ERRORREF_DELETED = 15023
export const MOD_ERRORREF_TOO_YOUNG = 15999
export const MONETIZATION_MOD_MONETIZATION_DISABLED = 900014
export const GUIDE_ERRORREF_DELETED = 19043
export const TOKEN_EXPIRED = 11005
export const RATE_LIMITED_GLOBAL = 11008
export const RATE_LIMITED_ENDPOINT = 11009
const PREVIEW_PUBLIC_MOD = 29402

const suggestionComponentState = ref(null)
const showBannerState = ref(false)
const suggestionRouteNameState = ref('')
const suggestionErrorState = ref(null)
const suggestionRequestedIdState = ref(null)

const ignoreList = [PREVIEW_PUBLIC_MOD]

export default function () {
  const { isLoggedIn } = authStore()

  const suggestionComponent = computed(() => suggestionComponentState.value)
  const showBanner = computed(() => showBannerState.value)
  const suggestionRouteName = computed(() => suggestionRouteNameState.value)
  const suggestionError = computed(() => suggestionErrorState.value)
  const suggestionRequestedId = computed(() => suggestionRequestedIdState.value)

  function setShowBanner(bool) {
    showBannerState.value = bool
  }

  function clearSuggestions() {
    setShowBanner(false)
    suggestionRouteNameState.value = ''
    suggestionComponentState.value = null
    suggestionErrorState.value = null
    suggestionRequestedIdState.value = null
  }

  function handleSuggestion(error) {
    const { routeName, routeParams, routePath, replace } = useRoute()
    clearSuggestions()

    if (error?.status === 500 || ignoreList.includes(error?.errorRef)) return

    suggestionErrorState.value = error
    let redirect = false

    if (
      !isLoggedIn.value &&
      (!error ||
        (error.status === 403 && error.errorRef !== MOD_ERRORREF_HIDDEN))
    ) {
      suggestionComponentState.value = 'Login'
      suggestionRouteNameState.value = LOGIN_ROUTE
      sessionStorage.setItem(
        SS_REDIRECT,
        `${routePath.value}${window.location.search}${window.location.hash}`
      )
      redirect = true
    } else {
      setShowBanner(true)
      if (
        error.errorRef === RATE_LIMITED_GLOBAL ||
        error.errorRef === RATE_LIMITED_ENDPOINT
      ) {
        suggestionComponentState.value = 'GameError'
      } else if (
        error.errorRef === GAME_ERRORREF_DELETED ||
        error.errorRef === GAME_ERRORREF_NO_VIEW ||
        error.errorRef === GAME_ERRORREF_TOO_YOUNG ||
        (error.errorRef === RESOURCE_ERRORREF_404 &&
          [MOD_BROWSE_ROUTE, GUIDE_BROWSE_ROUTE].includes(routeName.value))
      ) {
        if (routeParams.value.game) {
          suggestionRequestedIdState.value = routeParams.value.game
        }
        suggestionComponentState.value = 'GameError'
        suggestionRouteNameState.value =
          isLoggedIn.value &&
          error.errorRef !== GAME_ERRORREF_TOO_YOUNG &&
          error.errorRef !== GAME_ERRORREF_DELETED
            ? GAME_ADD_ROUTE
            : GAME_BROWSE_ROUTE
        redirect = true
      } else if (
        error.errorRef === MOD_ERRORREF_HIDDEN ||
        error.errorRef === MOD_ERRORREF_DELETED ||
        error.errorRef === MOD_ERRORREF_TOO_YOUNG ||
        error.errorRef === MOD_ERRORREF_NO_EDIT ||
        error.errorRef === GAME_ERRORREF_NO_EDIT ||
        error.errorRef === MONETIZATION_MOD_MONETIZATION_DISABLED ||
        (routeName.value.includes(MOD_ADMIN_ROUTE) && error.status === 403) ||
        (routeName.value.includes(GAME_ADMIN_ROUTE) && error.status === 403) ||
        (error.errorRef === RESOURCE_ERRORREF_404 &&
          routeName.value === MOD_VIEW_ROUTE)
      ) {
        if (routeParams.value.mod) {
          suggestionRequestedIdState.value = routeParams.value.mod
        } else if (routeParams.value.game) {
          suggestionRequestedIdState.value = routeParams.value.game
        }

        suggestionComponentState.value = 'ModError'
        suggestionRouteNameState.value = MOD_BROWSE_ROUTE
        redirect = true
      } else if (
        error.errorRef === GUIDE_ERRORREF_NO_PERMISSION ||
        error.errorRef === GUIDE_ERRORREF_DELETED ||
        (routeName.value.includes(GUIDE_ADMIN_EDIT_ROUTE) &&
          error.status === 403) ||
        (error.errorRef === RESOURCE_ERRORREF_404 &&
          routeName.value === GUIDE_VIEW_ROUTE)
      ) {
        if (routeParams.value.guide) {
          suggestionRequestedIdState.value = routeParams.value.guide
        }
        suggestionComponentState.value = 'GuideError'
        suggestionRouteNameState.value = GUIDE_BROWSE_ROUTE
        redirect = true
      }
    }

    if (redirect) {
      replace({ name: suggestionRouteNameState.value })
    }
  }

  function setSuggestion({ routeName, component }) {
    suggestionRouteNameState.value = routeName
    suggestionComponentState.value = component
  }

  return {
    suggestionRequestedId,
    suggestionRouteName,
    suggestionComponent,
    clearSuggestions,
    handleSuggestion,
    suggestionError,
    setShowBanner,
    setSuggestion,
    showBanner,
  }
}
