import { KEYS_USERS } from '@config/storeKeys.js'
import { store } from '@stores'
import { computed } from 'vue'

const { getStore, createStore, updateStore } = store()

export default function () {
  function getUsers() {
    return getStore(KEYS_USERS)
  }

  function getUser(nameId) {
    const users = getUsers()
    return computed(() => users.value?.[nameId?.toLowerCase()] || null)
  }

  function addUser(user) {
    const users = getStore(KEYS_USERS)
    const newUserObj = { ...users.value, [user.name_id.toLowerCase()]: user }
    createStore(KEYS_USERS, newUserObj)
  }

  function setUserKey(nameId, key, value) {
    const users = getUsers()
    const _nameId = nameId.toLowerCase()
    const newUserObj = {
      ...users.value,
      [_nameId]: {
        ...users.value[_nameId],
        [key]: value,
      },
    }
    updateStore(KEYS_USERS, newUserObj)
  }

  return {
    setUserKey,
    getUsers,
    getUser,
    addUser,
  }
}
