/* eslint-disable */
import { getMessageThreadRequest, getUserInboxRequest } from '@services'
import { POLL_SECONDS, POLL_INTERVAL_SECONDS, POLL_LOG } from '@config'
import { useLocalStorage, useIntervalFn } from '@vueuse/core'
import { LS_MESSAGE_POLL } from '@config/localstorage.js'
import { authStore, messageStore } from '@stores'
import { secsToMs } from '@helpers/utils.js'
import { useAsync } from '@composables'
import { computed, ref } from 'vue'

const unreadThreadIdState = ref(new Set())

const { resume, pause } = useIntervalFn(
  async () => {
    try {
      await poll()
    } catch (e) {
      pause()
    }
  },
  secsToMs(POLL_INTERVAL_SECONDS),
  false
)

const waitThreads = useLocalStorage(LS_MESSAGE_POLL, JSON.stringify([]))

const { getThread, updateMessageThreads, addMessages } = messageStore()

function getReadyThreads() {
  const threads = JSON.parse(waitThreads.value)
  return threads.filter((t) => {
    if (notExpired(t)) {
      return t
    }
  })
}

function notExpired(thread) {
  return Date.now() - thread.lastSend < secsToMs(POLL_SECONDS)
}

// main poll function
async function poll() {
  const { isLoggedIn, accessToken } = authStore()
  // guard to stop infinite loop on error
  if (!accessToken.value || !isLoggedIn.value) {
    pause()
    return
  }
  try {
    // retrieve threads that can be fetched and update localstorage
    const pollThreads = getReadyThreads()
    updateWaitThreadLS(pollThreads)
    if (pollThreads.length) {
      const requests = pollThreads.map((t) => fetchThread(t))
      const results = await Promise.all(requests)
      const successThreads = results
        .filter((r) => r?.status === 'SUCCESS')
        .map((t) => ({
          threadId: t.threadId,
          thread: t.thread,
        }))
      const failIds = results
        .filter((r) => r?.status === 'ERROR')
        .map((r) => r.threadId)
      if (successThreads.length) {
        // update thread store with threads that have had new messages
        updateMessageThreads(successThreads)
        // add thread id to unread array
        successThreads.forEach((s) => addToUnreadMessages(s.threadId))
      }
      if (failIds.length) {
        // if any errors occur remove them completely from future polls
        removeThreadsFromWait(failIds)
      }
    } else {
      // stop polling if no threads fits citeria
      stop()
    }
  } catch (e) {
    console.error(e)
    stop()
  }
}

async function fetchThread(thread) {
  try {
    const { run, data, error } = useAsync(() =>
      getMessageThreadRequest(thread.threadId, { participants: false })
    )
    await run()
    if (!error.value) {
      const messageCount = data.value.data.length
      const latest = data.value.data[0]
      if (
        latest.id !== thread.lastMessageId ||
        // handle initial interaction
        messageCount === 2
      ) {
        data.value.participants = thread.participants
        return {
          lastMessageId: data.value.data[0].id,
          status: 'SUCCESS',
          threadId: thread.threadId,
          thread: data.value,
        }
      }

      return null
    } else {
      throw new Error(error.value)
    }
  } catch (error) {
    console.error(error)
    return Promise.resolve({ status: 'ERROR', threadId: thread.threadId })
  }
}

function removeThreadsFromWait(ids) {
  const threads = JSON.parse(waitThreads.value)
  const tmp = threads.filter((t) => !ids.includes(t.threadId))
  updateWaitThreadLS(tmp)
}

function start() {
  if (POLL_LOG) {
    console.log('init message poll')
  }
  resume()
}

function stop() {
  if (POLL_LOG) {
    console.log('stopping message poll')
  }
  pause()
}

function updateWaitThreadLS(update) {
  waitThreads.value = JSON.stringify(update)
}

function addToUnreadMessages(id) {
  unreadThreadIdState.value.add(id)
}

export default function () {
  const unreadThreadIds = computed(() => [...unreadThreadIdState.value])

  // updates thread last send timestamp and start polling
  // is used to determine if thread is part of poll
  function updateThreadLastSend(threadId, lastMessageId) {
    const tmp = JSON.parse(waitThreads.value)

    const thread = tmp.find((t) => t.threadId === threadId)
    if (thread) {
      thread.lastSend = Date.now()
      thread.lastMessageId = lastMessageId
    } else {
      const t = getThread(threadId)
      tmp.push({
        participants: t.value.participants,
        threadId,
        lastSend: secsToMs(t.value.data.slice(-1).pop().date_published),
        lastMessageId: lastMessageId,
      })
    }
    updateWaitThreadLS(tmp)
    start()
  }

  // remove thread from polling pool
  function removeFromUnreadMessage(id) {
    const thread = getThread(id)
    if (thread.value) {
      const lastMessage = thread.value.data.slice(-1).pop()
      unreadThreadIdState.value.delete(id)
      if (notExpired({ lastSend: secsToMs(lastMessage.date_published) })) {
        updateThreadLastSend(id, lastMessage.id)
      }
    }
  }

  function addUnreadThreadIds(ids) {
    unreadThreadIdState.value = new Set(ids)
  }

  async function fetchInbox() {
    const { error, data, run } = useAsync(
      getUserInboxRequest,
      'Failed to get message list'
    )

    await run()

    if (!error.value) {
      addMessages(data.value)
    }
  }

  return {
    removeFromUnreadMessage,
    removeThreadsFromWait,
    updateThreadLastSend,
    addUnreadThreadIds,
    unreadThreadIds,
    fetchInbox,
  }
}
