// All keys referenced for in-memory cache
export const KEYS_ADMIN_USERS = 'admin-users'
export const KEYS_DISCUSSION = 'discussion'
export const KEYS_GAMES = 'games'
export const KEYS_ADMIN_GAMES = 'admin-games'
export const KEYS_GUIDES = 'guides'
export const KEYS_MESSAGE_THREAD = 'message-thread'
export const KEYS_MODS = 'mods'
export const KEYS_MODFILES = 'modfiles'
export const KEYS_MARKETPLACE_MODS = 'marketplace-mods'
export const KEYS_TAG_FILTER = 'tag-filter'
export const KEYS_USERS = 'users'
export const KEYS_MUTED_USERS = 'muted-users'
export const KEYS_REPORTS_SUMMARY = 'reports-summary'
export const KEYS_REPORTS_DETAILS = 'reports-details'
export const KEYS_COGS_GAME = 'cogs-game'
export const KEYS_COGS_MOD = 'cogs-mod'
export const KEYS_COGS_GUIDE = 'cogs-guide'
export const KEYS_MEDIA_LIBRARY = 'media-library'
export const KEYS_MONETIZATION = 'monetization'
export const KEYS_USERS_STATS = 'users-stats'
export const KEYS_USERS_GAME_STATS = 'users-game-stats'
