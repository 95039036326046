<template>
  <div class="tw-flex tw-flex-col tw-animate-pulse">
    <loading-label v-if="label"></loading-label>

    <textarea
      v-if="type === 'textarea'"
      :cols="cols"
      :rows="rows"
      disabled
      class="tw-flex tw-w-full tw-p-4 tw-rounded tw-resize-none"
      :class="altBg ? 'tw-bg-theme' : 'tw-bg-theme-1'"
    ></textarea>

    <div
      v-else
      class="tw-flex tw-items-center tw-w-full tw-input--pl tw-rounded tw-input--height-large"
      :class="altBg ? 'tw-bg-theme' : 'tw-bg-theme-1'"
    >
      <div
        class="tw-w-20 tw-h-2 tw-rounded-full"
        :class="altBg ? 'tw-bg-theme-1' : 'tw-bg-theme'"
      ></div>
    </div>
  </div>
</template>

<script>
import LoadingLabel from '@components/Loading/LoadingLabel.vue'
export default {
  components: {
    LoadingLabel,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    cols: {
      type: String,
      default: '30',
    },
    rows: {
      type: String,
      default: '10',
    },
    altBg: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
