import { useInterrupted, useRoute } from '@composables'
import useHandleError from '@errors/errorHandler.js'
import { readonly, ref } from 'vue'

export default function useAsync(
  cb = () => {},
  routeHandlerOrErrorMessage = null
) {
  const { routePath } = useRoute()
  const { handleError } = useHandleError()
  // If the api request was made in a different route to it being completed
  // log the new path so it can be checked to stop code running using interrupt()
  const { startInterrupt, endInterrupt } = useInterrupted(routePath.value)
  const data = ref(null)
  const errorState = ref(null)
  const errorCodeState = ref(null)
  const errorRefState = ref(null)
  const loading = ref(false)

  const run = async (...args) => {
    try {
      errorState.value = null
      loading.value = true
      startInterrupt(routePath.value)
      data.value = await cb(...args)
      endInterrupt(routePath.value)
    } catch (error) {
      handleError({
        error,
        errorState,
        errorCodeState,
        errorRefState,
        routeHandlerOrErrorMessage,
      })
    } finally {
      loading.value = false
    }
  }

  function setError(error) {
    errorState.value = error
  }

  return {
    errorCode: readonly(errorCodeState),
    errorRef: readonly(errorRefState),
    error: readonly(errorState),
    setError,
    loading,
    data,
    run,
  }
}
