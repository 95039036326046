import { computed, ref } from 'vue'

const KYB_DATA = {
  application_id: '',
  application_url: '',
  onboarding_status: '',
}
const formState = ref({
  firstname: '',
  lastname: '',
  email: '',
  contact: '',
  jurisdiction: '',
})
const kybState = ref({ ...KYB_DATA })

export default function () {
  const options = [
    { text: 'United States', value: 'domestic' },
    { text: 'Other', value: 'international' },
  ]

  const formData = computed(() => formState.value)
  const kybData = computed(() => kybState.value)
  const isKybApproved = computed(
    () => kybState.value.onboarding_status === 'approved'
  )

  function updateForm(field, input) {
    if (field === 'jurisdiction') {
      formState.value[field] = options[input].value
    } else {
      formState.value[field] = input
    }
  }

  function setFormData(data) {
    formState.value = {
      firstname: data.first_name,
      lastname: data.last_name,
      email: data.email,
      contact: data.contact,
      jurisdiction: data.jurisdiction,
    }
  }

  function setKybData(val) {
    kybState.value = val
  }

  function clearKybData() {
    kybState.value = { ...KYB_DATA }
  }

  return {
    isKybApproved,
    clearKybData,
    setFormData,
    setKybData,
    updateForm,
    formData,
    kybData,
    options,
  }
}
