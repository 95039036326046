<template>
  <div
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="options-menu"
    class="tw-fixed tw-right-0 tw-top-16 md:tw-top-0 md:tw-left-15 tw-w-full xxs:tw-w-72 tw-bottom-0 tw-flex tw-flex-col tw-transition-transform tw-duration-200 tw-z-22 tw-bg-dark xxs:tw-border-l md:tw-border-l-0 tw-border-r tw-border-dark-3 tw-overflow-auto tw-util-scrollbar"
    :class="[
      showMenu
        ? 'tw--translate-x-0'
        : 'tw-translate-x-[calc(100vw+9rem)] md:tw--translate-x-[calc(100%+4.5rem)]',
    ]"
  >
    <component :is="menu" v-if="showMenu" class="tw-text-theme" />
  </div>
</template>

<script>
import ProfileDropdown from '@components/Navbar/ProfileDropdown/ProfileDropdown.vue'
import NotificationDropdown from '@components/Notification/NotificationDropdown.vue'
import MessageList from '@components/Conversation/MessageList.vue'
import { useNavMenus } from '@composables'
export default {
  components: {
    NotificationDropdown,
    ProfileDropdown,
    MessageList,
  },
  setup() {
    const { menu, showProfileMenu: showMenu } = useNavMenus()

    return {
      showMenu,
      menu,
    }
  },
}
</script>
