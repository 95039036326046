<template>
  <div class="tw-animate-pulse">
    <div>
      <div
        v-if="!hideTitle"
        class="tw-w-20 tw-h-3 sm:tw-h-4 tw-rounded-full tw-mb-4"
        :class="[
          altBackground ? 'tw-bg-theme' : 'tw-bg-theme-1',
          { 'tw-m-auto': center },
        ]"
      ></div>
      <div
        class="tw-space-y-4"
        :class="{
          'tw-flex tw-flex-col tw-items-center': center,
        }"
      >
        <div
          class="tw-w-3/4 tw-h-2 sm:tw-h-3 tw-rounded-full"
          :class="altBackground ? 'tw-bg-theme' : 'tw-bg-theme-1'"
        ></div>
        <div
          class="tw-w-full tw-h-2 sm:tw-h-3 tw-rounded-full"
          :class="altBackground ? 'tw-bg-theme' : 'tw-bg-theme-1'"
        ></div>
        <div
          class="tw-w-1/4 tw-h-2 sm:tw-h-3 tw-rounded-full"
          :class="altBackground ? 'tw-bg-theme' : 'tw-bg-theme-1'"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    },
    altBackground: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
