import guideSerializer from '@serializers/guideSerializer.js'
import { KEYS_GUIDES } from '@config/storeKeys.js'
import { store } from '@stores'
import { computed } from 'vue'

const {
  deleteStoreArrayData,
  addToStoreData,
  updateStore,
  generateId,
  getStore,
} = store()

export default function () {
  function addGuides(gameNameId, guides, query) {
    updateStore(generateId(KEYS_GUIDES, gameNameId), {
      ...guideSerializer.transform(guides),
      query,
    })
  }

  function getGuides(gameNameId) {
    return getStore(generateId(KEYS_GUIDES, gameNameId))
  }

  function addGuide(gameNameId, guide) {
    const _guide = guideSerializer.transform(guide)
    addToStoreData(generateId(KEYS_GUIDES, gameNameId), _guide)
    return _guide
  }

  function getGuideId(guideNameId, gameNameId) {
    guideNameId = guideNameId?.toLowerCase()
    const guides = getStore(generateId(KEYS_GUIDES, gameNameId))
    const guide = guides.value?.data?.find(
      (g) => g.name_id.toLowerCase() === guideNameId
    )
    return guide ? guide.id : null
  }

  function getGuide(gameNameId, guideNameId) {
    const guides = getStore(generateId(KEYS_GUIDES, gameNameId))
    guideNameId = guideNameId?.toLowerCase()

    return computed(() =>
      guides.value?.data?.find(
        (guide) => guide.name_id.toLowerCase() === guideNameId
      )
    )
  }

  function removeGuides(gameNameId) {
    updateStore(generateId(KEYS_GUIDES, gameNameId))
  }

  function removeGuide(gameNameId, guideNameId) {
    deleteStoreArrayData(
      generateId(KEYS_GUIDES, gameNameId),
      (guide) => guide.name_id === guideNameId
    )
  }

  return {
    removeGuides,
    removeGuide,
    getGuideId,
    addGuides,
    getGuides,
    getGuide,
    addGuide,
  }
}
