import { provide } from 'vue'
export default function ({
  updateActiveId,
  updateField,
  activeId,
  state,
  status,
  errors,
  dirty,
  action,
  data,
}) {
  provide('updateField', updateField)
  provide('status', status)
  provide('state', state)
  provide('activeId', activeId)
  provide('updateActiveId', updateActiveId)
  provide('errors', errors)
  provide('dirty', dirty)
  provide('action', action)
  provide('data', data)
}
