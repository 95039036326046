import { ref, readonly } from 'vue'

const state = ref('')

export const OPTION_TYPES = {
  GAME_HUB: 'Game Hub',
  GUIDES: 'Guides',
  MARKETPLACE: 'Marketplace',
  TOKEN_PACKS: 'Currency Packs',
  PARTNER_PROGRAM: 'Partner Program',
}

export default function () {
  const activeTabName = readonly(state)

  function updateTab(val) {
    state.value = val
  }

  return {
    activeTabName,
    updateTab,
  }
}
