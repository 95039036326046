import { createClient } from '@services'
import { TRANSLATE_URL } from '@config'
import qs from 'qs'

const { put, post } = createClient()

export async function bulkGameTagsRequest(gameNameId, tagOptions) {
  const tag_options = tagOptions.map((option) => ({
    ...option,
    locked: option.locked ? 1 : 0,
    hidden: option.hidden ? 1 : 0,
  }))
  tag_options.forEach((tagOption) => {
    delete tagOption.id
    delete tagOption.selected
    delete tagOption.tag_count_map
  })

  const { data } = await put(`/games/@${gameNameId}/tags`, { tag_options })
  return data
}

export async function renameGameTagRequest(gameNameId, from, to) {
  const { data } = await put(
    `/games/@${gameNameId}/tags/rename`,
    qs.stringify({ from, to })
  )

  return data
}

export async function translate(input) {
  const { data } = await post(TRANSLATE_URL, JSON.stringify(input), {
    headers: { 'Content-Type': 'application/json' },
  })

  return data
}
