import { useMessagePoll } from '@composables'
import { computed, ref } from 'vue'
import { authStore } from '@stores'

const threadIdState = ref(null)
const recipientsState = ref([])

export default function () {
  const { user } = authStore()
  const { removeFromUnreadMessage } = useMessagePoll()
  const threadId = computed(() => threadIdState.value)

  const recipientsId = computed(() => recipientsState.value.map((r) => r.id))

  const recipients = computed(() => recipientsState.value)

  function clear() {
    threadIdState.value = null
    recipientsState.value = []
  }

  function updateThreadId(id) {
    if (id) {
      removeFromUnreadMessage(id)
    } else if (!recipientsState.value.length) {
      recipientsState.value = []
    }
    threadIdState.value = id ? parseInt(id) : null
  }

  function removeFromRecipients(user) {
    recipientsState.value = recipientsState.value.filter(
      (item) => item.username !== user.username
    )
  }

  function addToRecipients(user) {
    if (!recipientsState.value.find((item) => item.id === user.user_id)) {
      user.id = user.user_id
      recipientsState.value.push(user)
    }
  }

  function setRecipients(users) {
    recipientsState.value = users
  }

  function buildNewThread(message) {
    const userObj = {
      ...user.value.info,
      avatar: user.value.avatar,
      username: user.value.info.display_name,
    }
    message.participants.push(userObj)
    message.user = userObj

    return {
      data: [message],
      participants: message.participants,
    }
  }

  return {
    removeFromRecipients,
    addToRecipients,
    buildNewThread,
    updateThreadId,
    setRecipients,
    recipientsId,
    recipients,
    threadId,
    clear,
  }
}
