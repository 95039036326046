import { reactive } from 'vue'
import {
  isObjEmpty,
  isFunction,
  isString,
  isArray,
  isObj,
} from '@helpers/utils.js'

export default function (validations = {}) {
  const errors = reactive({})

  resetErrors()

  function validateAll(obj, skip = []) {
    resetErrors()
    Object.keys(validations)
      .filter((field) => !skip.includes(field))
      .forEach((field) => validate(field, obj[field]))

    return errors
  }

  /** @returns true if there are no validation errors */
  function validate(field, input, customError, lazy) {
    _removeError(field)

    const rules = validations?.[field]

    if (!rules) {
      return true
    }

    let errorCount = 0
    errors[field] = []

    rules.forEach((validation) => {
      let err = null
      if (isObj(validation)) {
        if (validation.rule && isFunction(validation.rule)) {
          err = validation.rule({
            input,
            error: validation?.errorMessage,
          })
        } else {
          return
        }
      } else {
        err = validation({
          input,
          error: customError,
        })
      }

      if (isString(err) && (input !== '' || !lazy)) {
        errors[field].push(err)
        errorCount++
      }
    })

    return errorCount === 0
  }

  function hasErrors(field) {
    if (field) {
      return !!errors[field]?.length
    }

    return Object.values(errors).some((errorArray) => errorArray?.length)
  }

  function updateErrors(postErrors) {
    if (!isObj(postErrors)) {
      return
    }

    resetErrors()

    Object.keys(postErrors).forEach((field) => {
      const err = postErrors[field]
      if (!errors[field]) {
        errors[field] = []
      }

      let errMessage
      if (isObj(err)) {
        if (!isObjEmpty(err)) {
          errMessage = Object.values(err)[0]
        }
      } else if (isArray(err)) {
        errMessage = err[0]
      } else {
        errMessage = err
      }

      if (errMessage) {
        errors[field].push(errMessage)
      }

      // If error is on an array, map it to the base key, eg. urls.0 => urls
      const arrayMatch = field.match(/(.+)\.([0-9]+)/)
      if (arrayMatch) {
        errors[arrayMatch[1]] = [errMessage]
      }
    })
  }

  function resetErrors(error) {
    if (hasErrors()) {
      if (error) {
        _removeError(error)
      } else {
        Object.keys(errors).forEach((field) => {
          _removeError(field)
        })
      }
    }

    if (validations) {
      Object.keys(validations).forEach((field) => {
        _removeError(field)
      })
    }
  }

  function _removeError(field) {
    if (field) {
      errors[field] = []
    }
  }

  return {
    updateErrors,
    validateAll,
    resetErrors,
    hasErrors,
    validate,
    errors,
  }
}
