import navigations, { alwaysHideRoutes } from '@config/navigation.js'
import { authStore, monetizationStore } from '@stores'
import { isString } from '@helpers/utils.js'
import { computed, unref } from 'vue'
import { MODIO_ENV } from '@config'
import {
  useResourceValue,
  useSuggestions,
  usePermissions,
  useRoute,
} from '@composables'

export default function () {
  const { suggestionRouteName } = useSuggestions()
  const { isAccountClosed, isLoggedIn, isSuperAdmin } = authStore()
  const { hasPermissionByNameId, hasPermissionForMod } = usePermissions()
  const { monetizationAccess } = monetizationStore()
  const { gameHasMatureOptions, gameToId } = useResourceValue()

  const { routeName, routeParams } = useRoute()
  const routeNameComputed = computed(
    () => suggestionRouteName.value || routeName.value
  )
  const visibleNavigations = computed(() => {
    // make sure route name is loaded
    if (
      !routeNameComputed.value ||
      alwaysHideRoutes.includes(routeNameComputed.value)
    ) {
      return []
    }

    return navigations.filter(navFilter)
  })

  function navFilter(nav) {
    // if user is not log in remove routes that require auth
    return (
      requireAuth(nav.scope) &&
      // remove routes that require resource permission
      hasPermission(nav.scope) &&
      matchRoute(nav.hide) &&
      // checks if account has not closed scope and checks if account is closed
      notClosed(nav.scope) &&
      _checkMonetizationAccess(nav.scope) &&
      idFilter(nav.scope) &&
      envFilter(nav.scope) &&
      // handles computed validator
      (!nav.validator || unref(nav.validator(routeParams.value))) &&
      (!isSuperAdminNav(nav.scope) ||
        isSuperAdmin(
          nav.scope
            .find((s) => s.startsWith('superAdmin'))
            .split(':')
            .pop()
        ))
    )
  }

  function notClosed(scopes) {
    if (scopes?.includes('notClosed')) {
      return !isAccountClosed.value
    }
    return true
  }

  function isSuperAdminNav(scopes) {
    return scopes?.some((s) => isString(s) && s.startsWith('superAdmin'))
  }

  function requireAuth(scopes) {
    if (scopes?.includes('auth')) {
      return isLoggedIn.value
    }
    return true
  }

  function hasPermission(scopes) {
    const scopesToCheck = ['admin', 'manager', 'moderator']

    const adminScopes = scopes?.filter(
      (scope) =>
        isString(scope) &&
        !scope.startsWith('superAdmin') &&
        scopesToCheck.some((s) => scope.includes(s))
    )
    if (adminScopes?.length > 0) {
      return adminScopes.some((adminScope) => {
        const [resource, scope] = adminScope.split(':')
        const resourceNameId = routeParams.value[resource]
        if (!resourceNameId) return false

        return resource === 'mod'
          ? hasPermissionForMod(resourceNameId, routeParams.value.game, scope)
          : hasPermissionByNameId({
              resource,
              nameId: resourceNameId,
              scope,
            })
      })
    }

    return true
  }

  function hasMaturityOptions(scopes) {
    if (scopes?.includes('matureGame')) {
      return gameHasMatureOptions(routeParams.value?.game).value
    }
    return true
  }

  function _checkMonetizationAccess(scopes) {
    return !scopes || monetizationAccess(scopes)
  }

  function envFilter(scopes) {
    const match = scopes?.find((s) => s.includes('env:'))
    if (match) {
      const envs = match.split(':').pop().split(',')
      return envs.includes(MODIO_ENV)
    }

    return true
  }

  function idFilter(scopes) {
    if (isSuperAdmin()) return true

    const match = scopes?.find((s) => s.includes('id:'))
    if (match) {
      const ids = match.split(':').pop().split(',')
      const gameId = gameToId(routeParams.value?.game).value
      return gameId && ids.some((id) => Number(id) === gameId)
    }
    return true
  }

  function matchRoute(hide) {
    if (!hide) return true

    if (hide.includes(routeNameComputed.value)) {
      return false
    }

    const wildCards = hide.filter((item) => item.includes('*'))
    if (wildCards.length === 1 && wildCards[0] === '*') {
      return false
    } else if (wildCards.length > 0) {
      return !wildCards.some(
        (item) => !!routeNameComputed.value?.startsWith(item.replace('*', ''))
      )
    } else {
      return true
    }
  }

  return {
    hasMaturityOptions,
    visibleNavigations,
    routeNameComputed,
    isSuperAdminNav,
    hasPermission,
    requireAuth,
    matchRoute,
    navFilter,
    notClosed,
  }
}
