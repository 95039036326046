import { queryString } from '@helpers/utils.js'
import { createClient } from '@services'
import qs from 'qs'

const { get, put, del, post } = createClient()

// API request to get list of staff
export async function getStaffListRequest() {
  const { data } = await get('/team')
  return data
}

/* API request to get all modio staff */
export async function getModioStaffListRequest(query) {
  const { data } = await get(`/staff${queryString(query)}`)
  return data
}

/* API request to add modio staff */
export async function addModioStaffRequest(user) {
  const { data } = await post('/staff', qs.stringify(user))
  return data
}

/* API request to update a modio staff */
export async function updateModioStaffRequest(userId, member) {
  const { data } = await put(`/staff/${userId}`, qs.stringify(member))
  return data
}

/* API request to delete a modio staff */
export async function deleteModioStaffRequest(userId) {
  const { data } = await del(`/staff/${userId}`)
  return data
}
