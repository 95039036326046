import { createClient } from '@services'
import qs from 'qs'

const { put } = createClient()

/* API request to set game theme */
export async function setGameThemeRequest({ gameNameId, theme }) {
  const { data } = await put(`/games/@${gameNameId}/theme`, qs.stringify(theme))

  return data
}
