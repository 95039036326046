import { GAME_PLATFORM_LABELS } from '@config/options.js'
import { ref, computed, watch } from 'vue'
import invert from 'lodash/invert'

const groupByText = ref('Platforms')

export default function (game, mod, seriesDims) {
  const platforms = computed(() =>
    mod?.value ? getModPlatforms() : game?.value ? getGamePlatforms() : []
  )

  function getGamePlatforms() {
    const result = game.value
      ? Object.values(game.value.platforms)
          .filter(
            (x) => x.is_supported && x.label !== GAME_PLATFORM_LABELS.source
          )
          .map((x) => ({
            text: x.label,
            value: invert(GAME_PLATFORM_LABELS)[x.label],
          }))
      : []

    result.unshift({ text: 'All Platforms', value: 'all' })
    result.sort((a, b) => a.value.localeCompare(b.value))

    return result
  }

  function getModPlatforms() {
    const result = game.value
      ? game.value.platforms
          .filter((x) => x.label !== GAME_PLATFORM_LABELS.source)
          .map((x) => ({
            text: x.label,
            value: x.platform,
          }))
      : []

    result.unshift({ text: 'All Platforms', value: 'all' })
    result.sort((a, b) => a.value.localeCompare(b.value))
    return result
  }

  // Handle dimension dropdown.
  const dimSelection = ref(0)
  const chosenSelections = ref([])
  if (seriesDims)
    watch(seriesDims, (_seriesDims) => {
      const selectedDim = _seriesDims.findIndex((dim) =>
        chosenSelections.value.includes(dim.text)
      )
      // Do not update in dimension is not in list
      if (selectedDim >= 0) updateDimSelection(selectedDim)
      dimSelection.value = Math.max(selectedDim, 0)
    })
  // Remove all texts in the same dimension list then add the current selected text
  function updateDimSelection(input) {
    let index = chosenSelections.value.indexOf(
      seriesDims.value[dimSelection.value]?.text
    )
    while (index !== -1) {
      chosenSelections.value.splice(index, 1)
      index = chosenSelections.value.indexOf(
        seriesDims.value[dimSelection.value].text
      )
    }

    chosenSelections.value.push(seriesDims.value[input].text)
    dimSelection.value = input
  }

  return {
    updateDimSelection,
    dimSelection,
    groupByText,
    platforms,
  }
}
