import { setupDevtoolsPlugin } from '@vue/devtools-api'
import { clone } from '@helpers/utils.js'
import { PROD_DEVTOOLS } from '@config'

// Dynamic import to avoid circular dependency
let store
import('@stores/store.js').then((data) => {
  store = data.default()
})

const PLUGIN_ID = 'io.mod.store'
const INSPECTOR_ID = 'store-inspector'
const STORE_ID = 'all-stores'
const TIMELINE_ID = 'store-timeline'

function onInspectorTree(payload) {
  if (payload.inspectorId !== INSPECTOR_ID) return

  const children = store
    .getKeys()
    .sort()
    .map((name) => ({
      id: name,
      label: name,
    }))

  payload.rootNodes = [
    {
      id: STORE_ID,
      label: 'Stores',
      children,
    },
  ]
}

function onInspectorState(payload) {
  if (payload.inspectorId === INSPECTOR_ID && payload.nodeId !== STORE_ID)
    payload.state = basicPayload(store.getStore(payload.nodeId).value)
}

function basicPayload(data) {
  if (!data) return null

  return {
    store: Object.keys(data).map((key) => {
      return { key: key, value: data[key], editable: true }
    }),
  }
}

function onEditInspectorState(payload) {
  if (payload.inspectorId !== INSPECTOR_ID) return

  const update = clone(store.getStore(payload.nodeId).value)
  let obj = update

  let i
  for (i = 0; i < payload.path.length - 1; i++) {
    obj = obj[payload.path[i]]
  }

  obj[payload.path[i]] = payload.state.value
  store.updateStore(payload.nodeId, update)
}

export default {
  name: 'storeDevToolsPlugin',
  install(app) {
    if (process.env.NODE_ENV !== 'development' && !PROD_DEVTOOLS) return

    setupDevtoolsPlugin(
      { app, id: PLUGIN_ID, label: 'Store Explorer' },
      (api) => {
        api.addInspector({
          id: INSPECTOR_ID,
          label: 'Store',
          icon: 'memory',
        })

        api.addTimelineLayer({
          id: TIMELINE_ID,
          color: 0x07c1d8,
          label: 'Store',
        })

        store?.addOnUpdateListener((key, payload) => {
          api.sendInspectorTree(INSPECTOR_ID)
          api.sendInspectorState(INSPECTOR_ID)

          api.addTimelineEvent({
            layerId: TIMELINE_ID,
            event: {
              time: Date.now(),
              data: { [key]: payload.update },
            },
          })
        })

        api.on.getInspectorTree(onInspectorTree)
        api.on.getInspectorState(onInspectorState)
        api.on.editInspectorState(onEditInspectorState)
      }
    )
  },
}
