<template>
  <div class="tw-flex tw-items-center tw-px-4 tw-space-x-2">
    <font-awesome-icon
      icon="info-circle"
      class="tw-text-info tw-pl-0.5"
    ></font-awesome-icon>
    <span>{{ text }}</span>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
</script>
