import { LS_KARMA } from '@config/localstorage.js'
import { useLocalStorage } from '@vueuse/core'
import { isObj } from '@helpers/utils.js'
import { computed } from 'vue'

const karmaState = useLocalStorage(LS_KARMA, null)

export default function () {
  const karma = computed(() => {
    const parsed = JSON.parse(karmaState.value)
    return isObj(parsed) ? parsed : {}
  })

  function addKarma(key, value) {
    karmaState.value = JSON.stringify({ ...karma.value, [key]: value })
  }

  function clearKarma() {
    karmaState.value = JSON.stringify({})
  }

  return {
    clearKarma,
    addKarma,
    karma,
  }
}
