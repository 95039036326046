import { clone, queryString } from '@helpers/utils.js'
import { PRIVATE_BASE_URL } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { get, del, post, put } = createClient()
const adminRulesetsURL = `${PRIVATE_BASE_URL}/admin/rules/rulesets`
const adminTokenURL = `${PRIVATE_BASE_URL}/admin/rules/webhook/token`

export async function getRuleTypesRequest(admin = false) {
  const { data } = await get(
    admin
      ? `${PRIVATE_BASE_URL}/admin/rules/config`
      : `${PRIVATE_BASE_URL}/rules/config`
  )
  return data
}

export async function getRulesForGameRequest(gameNameId) {
  const { data } = await get(
    gameNameId
      ? `${PRIVATE_BASE_URL}/rules/@${gameNameId}/rulesets`
      : adminRulesetsURL
  )
  return data
}

export async function postRulesForGameRequest(rules, gameNameId) {
  // remove front-end ids
  const saveData = clone(rules)
  saveData.config?.forEach((x) => delete x.id)

  const { data } = await post(
    gameNameId
      ? `${PRIVATE_BASE_URL}/rules/@${gameNameId}/rulesets`
      : adminRulesetsURL,
    qs.stringify(saveData)
  )

  return data
}

export async function deleteRulesForGameRequest(gameNameId) {
  const { data } = await del(
    gameNameId
      ? `${PRIVATE_BASE_URL}/rules/@${gameNameId}/rulesets`
      : adminRulesetsURL
  )
  return data
}

export async function getWebhookTokenRequest(gameNameId) {
  const { data } = await get(
    gameNameId
      ? `${PRIVATE_BASE_URL}/rules/@${gameNameId}/webhook/token`
      : adminTokenURL
  )
  return data
}

export async function putWebhookTokenRequest(gameNameId) {
  const { data } = await put(
    gameNameId
      ? `${PRIVATE_BASE_URL}/rules/@${gameNameId}/webhook/token`
      : adminTokenURL
  )
  return data
}

export async function postTestWebhookRequest(gameNameId, url) {
  const { data } = await post(
    gameNameId
      ? `${PRIVATE_BASE_URL}/rules/@${gameNameId}/webhook/test`
      : `${PRIVATE_BASE_URL}/admin/rules/webhook/test`,
    qs.stringify({ url })
  )
  return data
}

export async function getWebhookHistoryRequest(gameNameId, query) {
  const { data } = await get(
    gameNameId
      ? `${PRIVATE_BASE_URL}/rules/@${gameNameId}/webhook/history${queryString(
          query
        )}`
      : `${PRIVATE_BASE_URL}/admin/rules/webhook/history${queryString(query)}`
  )
  return data
}

export async function postRetryWebhookRequest(gameNameId, eventUuid) {
  const { data } = await post(
    gameNameId
      ? `${PRIVATE_BASE_URL}/rules/@${gameNameId}/webhook/retry/${eventUuid}`
      : `${PRIVATE_BASE_URL}/admin/rules/webhook/retry/${eventUuid}`
  )
  return data
}

export async function getRulesHistoryRequest(gameNameId, query) {
  const { data } = await get(
    gameNameId
      ? `${PRIVATE_BASE_URL}/rules/@${gameNameId}/history${queryString(query)}`
      : `${PRIVATE_BASE_URL}/admin/rules/history${queryString(query)}`
  )
  return data
}

export async function getRulesetHistoryRequest(gameNameId, uuid, query) {
  const { data } = await get(
    gameNameId
      ? `${PRIVATE_BASE_URL}/rules/@${gameNameId}/history/${uuid}${queryString(
          query
        )}`
      : `${PRIVATE_BASE_URL}/admin/rules/history/${uuid}${queryString(query)}`
  )
  return data
}
