import Serializer from './Serializer.js'

const transformations = [_transformDiscussions]

const discussionSerializer = new Serializer({ transformations })

export function mergeDiscussion(discussions, newDiscussion, append = false) {
  const discussion = _createDiscussion(newDiscussion)

  const parent =
    newDiscussion.reply_id &&
    _findParent({ replies: discussions }, newDiscussion.reply_id)

  const array = parent ? parent.replies : discussions
  if (append) {
    array.push(discussion)
  } else {
    array.unshift(discussion)
  }

  return discussions
}

function _transformDiscussions(discussion, _, dataCollection, deep) {
  if (
    (_getLevel(discussion.thread_position) === 1 || deep) &&
    discussion.replies === undefined
  ) {
    const transformedDiscussion = _createDiscussion(discussion, dataCollection)

    transformedDiscussion.replies.forEach((reply) =>
      _transformDiscussions(reply, null, dataCollection, true)
    )

    return transformedDiscussion
  } else {
    return null
  }
}

export function _createDiscussion(discussion, dataCollection) {
  discussion.replies = dataCollection
    ? _getReplies(discussion.id, dataCollection)
    : []
  discussion.position = _getPosition(discussion.thread_position)
  discussion.level = _getLevel(discussion.thread_position)

  return discussion
}

export function _getLevel(thread_position) {
  return thread_position.split('.').length
}

function _getPosition(thread_position) {
  return thread_position.split('.').pop()
}

function _getReplies(id, dataCollection) {
  return dataCollection.filter((item) => item.reply_id === id)
}

function _findParent(comment, id) {
  if (comment.id === id) return comment

  for (let i = 0; i < comment.replies.length; i++) {
    const result = _findParent(comment.replies[i], id)
    if (result) return result
  }

  return null
}

export default discussionSerializer
