import { countryCurrencies } from '@config/countries.js'
import { PORTAL_OPTIONS } from '@config/monetization.js'
import { computed, readonly, ref } from 'vue'
import { conversionRateStore } from '@stores'

const DEFAULT_PLATFORM_PCT = 30
const EPIC_PLATFORM_PCT = 12
const WEB_PLATFORM_PCT = 5

// Cents per token
const WHOLESALE_CHARGE = 0.52
const PLATFORM_SHARE = 0.12
const CREATOR_SHARE = 0.4

const COUNTRY_OPTIONS = [
  { value: '', text: 'Select a country', currency: '' },
  ...countryCurrencies,
]

const priceState = ref(0)
const amountState = ref(0)
const indexState = ref(0)

export default function () {
  const { conversionRates } = conversionRateStore()

  const selectedPlatform = ref(PORTAL_OPTIONS[0].value)
  const selectedCountry = ref(COUNTRY_OPTIONS[0].value)
  const selectedPlatformText = computed(
    () => PORTAL_OPTIONS.find((o) => o.value === selectedPlatform.value).text
  )
  const selectedCountryText = computed(
    () => COUNTRY_OPTIONS.find((o) => o.value === selectedCountry.value).text
  )
  const selectedCountryCurrency = computed(
    () =>
      COUNTRY_OPTIONS.find((o) => o.value === selectedCountry.value).currency
  )
  const conversionRate = computed(
    () =>
      conversionRates.value?.find(
        (o) => o.conversion_code === selectedCountryCurrency.value
      ).conversion_rate
  )
  const usdConversionRate = computed(
    () =>
      conversionRates.value?.find(
        (o) => o.base_code === selectedCountryCurrency.value
      ).conversion_rate
  )
  const creator = computed(() => amountState.value * CREATOR_SHARE)
  const modio = computed(() => amountState.value * PLATFORM_SHARE)
  const wholesaleCharge = computed(() => amountState.value * WHOLESALE_CHARGE)
  const platformPct = computed(() =>
    selectedPlatform.value === 'epicgames'
      ? EPIC_PLATFORM_PCT
      : DEFAULT_PLATFORM_PCT
  )
  const platformFee = computed(
    () => (platformPct.value / 100) * priceState.value
  )
  const processingFee = computed(
    () => (WEB_PLATFORM_PCT / 100) * priceState.value + 20
  )
  const suggestedPrice = computed(() => priceState.value * conversionRate.value)
  const amountPaid = computed(
    () => suggestedPrice.value * usdConversionRate.value
  )
  const fee = computed(() =>
    selectedPlatform.value === 'web' ? processingFee.value : platformFee.value
  )
  const publisher = computed(
    () => priceState.value - wholesaleCharge.value - fee.value
  )

  function updatePlatform(input) {
    selectedPlatform.value = input

    if (selectedPlatform.value === 'web') {
      selectedCountry.value = ''
    }
  }

  function updateActivePack(pack, index) {
    priceState.value = pack.price
    amountState.value = pack.amount
    indexState.value = index
  }

  return {
    selectedPackAmount: readonly(amountState),
    selectedPackPrice: readonly(priceState),
    selectedPackIndex: readonly(indexState),
    selectedCountryCurrency,
    selectedPlatformText,
    selectedCountryText,
    updateActivePack,
    selectedPlatform,
    selectedCountry,
    COUNTRY_OPTIONS,
    wholesaleCharge,
    suggestedPrice,
    updatePlatform,
    processingFee,
    platformFee,
    platformPct,
    amountPaid,
    publisher,
    creator,
    modio,
  }
}
