module.exports = {
  // themables
  dark: 'var(--dark)',
  'dark-1': 'var(--dark-1)',
  'dark-2': 'var(--dark-2)',
  'dark-3': 'var(--dark-3)',
  'dark-text': 'var(--dark-text)',
  light: 'var(--light)',
  'light-1': 'var(--light-1)',
  'light-2': 'var(--light-2)',
  'light-3': 'var(--light-3)',
  'light-text': 'var(--light-text)',
  primary: 'var(--primary)',
  'primary-hover': 'var(--primary-hover)',
  'primary-text': 'var(--primary-text)',
  success: 'var(--success)',
  'success-hover': 'var(--success-hover)',
  'success-text': 'var(--success-text)',
  warning: 'var(--warning)',
  'warning-hover': 'var(--warning-hover)',
  'warning-text': 'var(--warning-text)',
  danger: 'var(--danger)',
  'danger-hover': 'var(--danger-hover)',
  'danger-text': 'var(--danger-text)',

  // base
  grey: '#676976',
  info: '#5599FF',
  'info-hover': '#66A3FF',
  'info-text': '#ffffff',
  'mob-menu': '#1b1b25',

  // social
  email: '#212945',
  facebook: '#3261A3',
  twitter: '#00A3EE',
  reddit: '#FF3A14',
  steam: '#145C8F',
  xboxlive: '#027D00',
  playstation: '#0070CC',
  itchio: '#FA5C5C',
  google: '#EA4436',
  discord: '#738ADB',
  twitch: '#8B49F9',
  epicgames: '#333333',

  // Homepage
  'rich-black': '#212325',
  grey2: '#1D1D1F',
  'dark-grey': '#1a1a1b',
  'light-grey': '#e5e5e5',
  secondary: '#ffffff',
  'about-box': '#303030',
}
