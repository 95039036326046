import { BREAKPOINTS as _BREAKPOINTS } from './breakpoints.js'
/* eslint-disable */

// ENVIRONMENT
export const ENV = __ENV__
export const MODIO_ENV = __MODIO_ENV__
export const PROD_DEVTOOLS = MODIO_ENV === 'staging'
export const SITE_URL = __SITE_URL__

export const LATEST_TERMS_VERSION = __LATEST_TERMS_VERSION__
export const LATEST_TERMS_DATE_LIVE = __LATEST_TERMS_DATE_LIVE__
export const LATEST_PRIVACY_VERSION = __LATEST_PRIVACY_VERSION__
export const LATEST_PRIVACY_DATE_LIVE = __LATEST_PRIVACY_DATE_LIVE__

export const THEMING_DEBUG = __THEMING_DEBUG__
export const MONETIZATION_DEBUG = __MONETIZATION_DEBUG__
export const REPLICATION_DELAY_MS = __REPLICATION_DELAY_MS__
export const ORIGIN = window.location.origin
export const TILIA_WIDGET_URL = __TILIA_WIDGET_URL__
export const EMH_ID_FILTER = __EMH_ID_FILTER__
export const HIDE_MARKETPLACE_IDS = __HIDE_MARKETPLACE_IDS__ || ''
export const METRICS_DISABLED = __METRICS_DISABLED__ || false

// ENDPOINTS
export const PRIVATE_HIDDEN_BASE_URL = __PRIVATE_HIDDEN_BASE_URL__
export const MONETIZATION_BASE_URL = __MONETIZATION_BASE_URL__
export const PRIVATE_BASE_URL = __PRIVATE_BASE_URL__
export const PUBLIC_BASE_URL = __PUBLIC_BASE_URL__

export const AUTH_BASE_URL = __AUTH_BASE_URL__
export const TRANSLATE_URL = __TRANSLATE_URL__
export const SKETCHFAB_MODELS_ENDPOINT = 'https://api.sketchfab.com/v3/models'

// MONETIZATION SETTINGS
export const MONETIZATION_SETTINGS = {
  monetization_enabled: __MONETIZATION_ENABLED__,
  monetization_marketplace: __MONETIZATION_MARKETPLACE__,
  marketplace_default_platform_cut: __MARKETPLACE_DEFAULT_PLATFORM_CUT__,
  marketplace_default_game_cut: __MARKETPLACE_DEFAULT_GAME_CUT__,
  marketplace_default_mod_cut: __MARKETPLACE_DEFAULT_MOD_CUT__,
  min_marketplace: __MIN_MARKETPLACE__,
  max_marketplace: __MAX_MARKETPLACE__,
  default_marketplace: __DEFAULT_MARKETPLACE__,
  min_payout: __MIN_PAYOUT__,
  max_payout: __MAX_PAYOUT__,
}

// PLACEHOLDER IMAGES
export const PLACEHOLDER_GAME_CARD_IMAGE = '/images/placeholder/card.png'
export const PLACEHOLDER_GAME_ICON_IMAGE = '/images/placeholder/icon.png'
export const PLACEHOLDER_MOD_CARD_IMAGE = '/images/placeholder/card.png'
export const PLACEHOLDER_GUIDE_CARD_IMAGE = '/images/placeholder/card.png'
export const CARD_IMAGE_KEY = 'thumb_320x180'
export const PREVIEW_IMAGE_KEY = 'thumb_1280x720'
export const THUMBNAIL_IMAGE_KEY = 'thumb_128x128'

// THUMBNAIL BASE URL
export const THUMB_BASE_URL = __THUMB_BASE_URL__

// AUTH
export const LOGIN_REQUEST_CODE_ENDPOINT = `${AUTH_BASE_URL}/emailrequest`
export const LOGIN_EXCHANGE_CODE_ENDPOINT = `${AUTH_BASE_URL}/emailexchange`
export const LOGOUT_ENDPOINT = `${PUBLIC_BASE_URL}/oauth/logout`
export const MERGE_AND_CLOSE_EXCHANGE_ENDPOINT = `${PRIVATE_BASE_URL}/me/surrender/exchange`
export const MERGE_AND_CLOSE_REQUEST_ENDPOINT = `${PRIVATE_BASE_URL}/me/surrender/request`
export const MONETIZATION_STATUS_ENDPOINT = `${MONETIZATION_BASE_URL}/accounts/onboard/status`
export const INITIAL_LOGIN_TIME_SECONDS = 60

export const MODIO_LOGO_URL =
  'https://assets.modcdn.io/images/branding/modio-logo-bluewhite.svg'
export const LEGAL_AGE = 18
export const API_RESULT_LIMIT = 100
export const NEW_RESOURCE_ID = 'new'
export const IMAGE_CROP_SIZES = [
  '50x50',
  '64x64',
  '100x100',
  '128x128',
  '256x256',
  '320x180',
  '640x360',
  '1280x720',
  '1020x2000',
]

// GAMES
export const MAX_GAME_APP_IDS = 10

// TODO: Move into language file once translations are supported
export const DEFAULT_ERROR_TITLE = 'Error'
export const DEFAULT_ERROR_MESSAGE =
  'An error has occurred please try again soon.'

// LOCALSTORAGE
export const LOCALSTORAGE_EXPIRE_DAYS = 2

// PAGINATION
export const PAGINATION_LIMIT_OPTIONS = [
  { value: 30, text: '30/Page' },
  { value: 40, text: '40/Page' },
  { value: 50, text: '50/Page' },
]

export const PAGINATION_DEFAULT_PAGE = 1
export const PAGINATION_DEFAULT_TOTAL = 0
export const PAGINATION_DEFAULT_LIMIT = 30

// RATINGS
export const RATINGS_LIKE = 1
export const RATINGS_DISLIKE = -1
export const RATINGS_UNDECIDED = 0

// POLLING
export const POLL_SECONDS = 300
export const POLL_INTERVAL_SECONDS = 15
export const POLL_LOG = !['production', 'test'].includes(MODIO_ENV)

// MESSAGES
export const MESSAGE_LEFT_TEXT = 'Has left the thread.'

// TOAST
export const TOAST_SECONDS = 5

// ACTIVE ONLINE USER
export const ONLINE_USER_TIMEOUT_SECONDS = 600

// Use to redirect after icon animation completes
export const BUTTON_ANIMATION_DURATION = 2000
export const BUTTON_ICON_ANIMATION_DURATION = BUTTON_ANIMATION_DURATION - 500

// COMMENTS
export const MAX_DISCUSSION_LEVEL = 30
export const RECENT_GAME_COUNT = 5
export const MAX_PINNED_COMMENT_LIMIT = 3
export const HIDE_COMMENT_BELOW_KARMA = -5
export const CLEAN_COMMENT_LINKS_BELOW_KARMA = -2

// LOCALE
export const LOCALE_LANGUAGE = 'en-US'
export const LOCALE_TIMEZONE = 'America/Los_Angeles'

// HIDE FILTERS
export const MOD_FILTERS_TO_HIDE = {
  drg: ['requested category'],
}

export const BREAKPOINTS = _BREAKPOINTS
export const PHP_UNIXTIME_LEN = 10

// NOTIFICATIONS
export const NOTIFICATION_COMMENT_REPLY_ON_OWNED_THREAD = 10
export const NOTIFICATION_TEAM_INVITE = 11
export const NOTIFICATION_TEAM_RESOURCE_PENDING = 12
export const NOTIFICATION_MOD_MODERATION = 13
export const NOTIFICATION_MOD_SUBSCRIBER = 14
export const NOTIFICATION_MOD_PLATFORM_APPROVED = 15
export const NOTIFICATION_MOD_PLATFORM_DENIED = 16
export const NOTIFICATION_COMMENT_ON_TEAM_RESOURCE = 18
export const NOTIFICATION_PREVIEW_INVITE = 19

// INLINE MEDIA LIBRARY
export const MEDIA_LIBRARY_MAX_FOLDERS = 20

export const CACHE_TIME_SECONDS = 120
export const MEDIA_LIBRARY_MAX_FOLDER_LENGTH = 8

// MODFILES
export const MAX_STANDARD_MOD_FILE_SIZE = 500 * 1024 * 1024
export const MAX_MULTIPART_PART_SIZE = 50 * 1024 * 1024
export const PROGRESS_100_PERCENT = 1
export const MAX_FILE_COUNT = 10

// SQL & CACHE DEBUG
export const MODIO_DEBUG_HEADER = 'X-Modio-Debug'
export const MODIO_DEBUG = __MODIO_DEBUG__
export const PUSHER_API_KEY = __PUSHER_API_KEY__
export const PUSHER_CLUSTER = __PUSHER_CLUSTER__

export const IS_IOS_TOUCH_DEVICE =
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

// METRICS
export const METRIC_GAME_HEADER = 'X-Modio-Game'
export const METRIC_MOD_HEADER = 'X-Modio-Mod'
export const METRIC_GUIDE_HEADER = 'X-Modio-Guide'
export const ERROR_CODE_METRICS_TIMEOUT = 518

// SCROLL TO ERROR
export const MODAL_HEADER_HEIGHT = 198
export const HEADER_HEIGHT = 60
export const HEADER_SWITCHER_HEIGHT = 130
export const GAME_INFO_HEADER_HEIGHT = 128

export const STAGING_ENV = 'staging'
export const TEST_ENV = 'test'
export const UI_TEST_ENVS = [STAGING_ENV]

// MINIMUM AGE
export const MIN_AGE_DEFAULT_LIMIT = 13

