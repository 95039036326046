import { LS_USER_SUBSCRIPTIONS } from '@config/localstorage.js'
import { useLocalStorage } from '@vueuse/core'
import { isArray } from '@helpers/utils.js'
import { computed } from 'vue'

const subscriptionState = useLocalStorage(LS_USER_SUBSCRIPTIONS, '[]')

export default function () {
  const userSubscriptions = computed(() => JSON.parse(subscriptionState.value))

  function setUserSubscriptions(subscriptions) {
    subscriptionState.value = JSON.stringify(subscriptions)
  }

  function clearUserSubscriptions() {
    setUserSubscriptions([])
  }

  function addUserSubscription(user) {
    if (
      isArray(userSubscriptions.value) &&
      !userSubscriptions.value.find((sub) => sub.id === user.id)
    ) {
      setUserSubscriptions([...userSubscriptions.value, user])
    }
  }

  function removeUserSubscription(user) {
    const updated = userSubscriptions.value.filter(
      (subscription) => subscription.id !== user.id
    )
    setUserSubscriptions(updated)
  }

  function hasSubscriptionForUser(userNameId) {
    return computed(() =>
      userSubscriptions.value?.some(
        (subscriptions) => subscriptions.name_id === userNameId
      )
    )
  }

  return {
    clearUserSubscriptions,
    hasSubscriptionForUser,
    removeUserSubscription,
    setUserSubscriptions,
    addUserSubscription,
    userSubscriptions,
  }
}
