<template>
  <!-- Toasts -->
  <div
    class="tw-fixed tw-flex tw-flex-col-reverse tw-items-start tw-right-4 tw-top-20 tw-z-41"
  >
    <div
      v-for="(item, index) in activeToasts"
      :key="index"
      class="tw-w-80 tw-rounded tw-util-shadow-xl tw-px-5 tw-mb-4 tw-flex tw-overflow-hidden tw-relative tw-bg-theme-1 tw-text-theme tw-border-grey tw-border-opacity-40 tw-group"
      :class="item.text ? 'tw-py-4' : 'tw-py-5'"
    >
      <base-button
        v-if="item.hasClose"
        text-link-hover
        icon="times"
        sr-only="dismiss alert"
        class="tw-absolute tw-top-0 tw-right-0 tw-opacity-30 group-hover:tw-opacity-100"
        @click.prevent="closeToast(item.name)"
      />

      <div
        class="tw-absolute tw-top-0 tw-left-0 tw-w-2 tw-h-full"
        :class="typeBgStyle(item)"
      />

      <div
        class="tw-flex tw-space-x-3"
        :class="{ 'tw-items-center': !item.text }"
      >
        <font-awesome-icon
          :icon="typeIcon(item)"
          :class="typeTextStyle(item)"
          size="2x"
        />
        <div class="tw-space-y-1">
          <p v-if="item.title" class="tw-font-bold">
            {{ item.title }}
          </p>
          <p v-if="item.text" v-decode="item.text" class="tw-text-xs" />
          <div v-if="item.errorRef" class="tw-pt-2.5">
            <span
              class="tw-absolute tw-right-3 tw-bottom-2 tw-font-bold tw-text-xs"
              >Error code: {{ item.errorRef }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { activeToasts, closeToast } from '@composables/useToast.js'
export default {
  setup() {
    function typeTextStyle(name) {
      if (name.isSuccess) {
        return 'tw-text-success'
      } else if (name.isWarning) {
        return 'tw-text-warning'
      } else if (name.isError) {
        return 'tw-text-danger'
      } else {
        return 'tw-text-info'
      }
    }

    function typeIcon(name) {
      if (name.isSuccess) {
        return 'check-circle'
      } else if (name.isWarning) {
        return 'exclamation-circle'
      } else if (name.isError) {
        return 'times-circle'
      } else {
        return 'info-circle'
      }
    }

    function typeBgStyle(name) {
      if (name.isSuccess) {
        return 'tw-bg-success'
      } else if (name.isWarning) {
        return 'tw-bg-warning'
      } else if (name.isError) {
        return 'tw-bg-danger'
      } else {
        return 'tw-bg-info'
      }
    }

    return {
      typeTextStyle,
      activeToasts,
      typeBgStyle,
      closeToast,
      typeIcon,
    }
  },
}
</script>
