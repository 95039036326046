import { DASHBOARD_REPORT_TYPES } from '@config/options.js'
import { THEME_DEFAULT } from '@config/colorsTheme.js'
import * as routes from '@config/routeNames.js'

export const superAdmin = [
  {
    path: '/admin',
    name: routes.ADMIN_ROUTE,
    redirect: { name: routes.ADMIN_GAMES_ROUTE },
    meta: {
      seo: [
        {
          tag: 'title',
          content: 'Admin Dashboard',
        },
      ],
      theme: THEME_DEFAULT,
    },
    component: () =>
      import(/* webpackChunkName: 'site-admin' */ '@views/Admin.vue'),
    children: [
      {
        path: 'stats',
        children: [
          {
            path: '',
            name: routes.ADMIN_STATS_ROUTE,
            component: () =>
              import(
                /* webpackChunkName: 'site-admin' */ '@components/Metrics/Stats.vue'
              ),
          },
          {
            path: 'users',
            name: routes.ADMIN_STATS_USER_ROUTE,
            component: () =>
              import(
                /* webpackChunkName: 'site-admin' */ '@components/Metrics/Stats.vue'
              ),
          },
          {
            path: 'health',
            name: routes.ADMIN_STATS_HEALTH_ROUTE,
            component: () =>
              import(
                /* webpackChunkName: 'site-admin' */ '@components/Metrics/Stats.vue'
              ),
          },
          {
            path: 'revenue',
            name: routes.ADMIN_STATS_REVENUE_ROUTE,
            component: () =>
              import(
                /* webpackChunkName: 'site-admin' */ '@components/Metrics/Stats.vue'
              ),
          },
        ],
      },
      {
        path: 'games',
        name: routes.ADMIN_GAMES_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@components/Admin/AdminGames.vue'
          ),
      },
      {
        path: 'agreements',
        name: routes.ADMIN_AGREEMENT_BROWSE_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@components/Agreement/AgreementBrowse.vue'
          ),
      },
      {
        path: 'agreements/edit/:id',
        name: routes.ADMIN_AGREEMENT_EDIT_ROUTE,
        meta: {
          error: [
            {
              status: '*',
              errorRef: '*',
              method: 'get',
              redirect: routes.FOUROHFOUR_ROUTE,
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@components/Agreement/AgreementEdit.vue'
          ),
      },
      {
        path: 'agreements/add',
        name: routes.ADMIN_AGREEMENT_ADD_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@components/Agreement/AgreementAdd.vue'
          ),
      },
      {
        path: 'agreements/templates',
        name: routes.ADMIN_AGREEMENT_TEMPLATE_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@components/Agreement/TemplateBrowse.vue'
          ),
      },
      {
        path: 'agreements/templates/edit/:id',
        name: routes.ADMIN_AGREEMENT_TEMPLATE_EDIT_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@components/Agreement/TemplateEdit.vue'
          ),
      },
      {
        path: 'agreements/templates/add',
        name: routes.ADMIN_AGREEMENT_TEMPLATE_ADD_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@components/Agreement/TemplateAdd.vue'
          ),
      },
      {
        path: 'reports',
        redirect: { name: routes.ADMIN_REPORTS_MODS_ROUTE },
      },
      {
        path: 'reports/mods',
        name: routes.ADMIN_REPORTS_MODS_ROUTE,
        props: {
          resourceType: DASHBOARD_REPORT_TYPES.MODS,
        },
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@components/Admin/AdminReportsDashboard.vue'
          ),
      },
      {
        path: 'reports/guides',
        name: routes.ADMIN_REPORTS_GUIDES_ROUTE,
        props: {
          resourceType: DASHBOARD_REPORT_TYPES.GUIDES,
        },
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@components/Admin/AdminReportsDashboard.vue'
          ),
      },
      {
        path: 'reports/users',
        name: routes.ADMIN_REPORTS_USERS_ROUTE,
        props: {
          resourceType: DASHBOARD_REPORT_TYPES.USERS,
        },
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@components/Admin/AdminReportsDashboard.vue'
          ),
      },
      {
        path: 'staff',
        name: routes.ADMIN_STAFF_BROWSE_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@components/Staff/StaffBrowse.vue'
          ),
      },
      {
        path: 'keys',
        name: routes.ADMIN_KEYS_ROUTE,
        component: () =>
          import(/* webpackChunkName: 'site-admin' */ '@views/AdminKeys.vue'),
      },
      {
        path: 'users',
        name: routes.ADMIN_USER_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@components/Admin/AdminUserDashboard.vue'
          ),
      },
      {
        path: 'monetization',
        name: routes.ADMIN_MONETIZATION_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@views/AdminMonetizationDashboard.vue'
          ),
      },
      {
        path: 'monetization/settings',
        name: routes.ADMIN_MONETIZATION_SETTINGS_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@views/AdminMonetizationSettings.vue'
          ),
      },
      {
        path: 'monetization/transactions',
        name: routes.ADMIN_MONETIZATION_TRANSACTIONS_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@views/AdminTransactions.vue'
          ),
      },
      {
        path: 'moderation',
        name: routes.ADMIN_MODERATION_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'site-admin' */ '@views/GamePermissionsForm.vue'
          ),
      },
    ],
  },
]
