<template>
  <div
    ref="mainWrap"
    class="tw-dark tw-text-dark-text tw-h-full tw-max-screenheight tw-overflow-y-auto tw-util-scrollbar"
  >
    <component :is="widgetComponent" widget />
  </div>
</template>

<script>
import { ref, onMounted, nextTick, computed, defineAsyncComponent } from 'vue'
import { useRoute, useBoundaries } from '@composables'
export default {
  components: {
    ReportAdd: defineAsyncComponent(() => import('@views/ReportAdd.vue')),
    404: defineAsyncComponent(() => import('@views/404.vue')),
    AgreementWidget: defineAsyncComponent(
      () => import('@components/Widget/AgreementWidget.vue')
    ),
    Access: defineAsyncComponent(
      () => import('@components/Profile/Panels/Access.vue')
    ),
    LoginWidget: defineAsyncComponent(
      () => import('@components/Widget/LoginWidget.vue')
    ),
    ItemWidget: defineAsyncComponent(
      () => import('@components/Widget/ItemWidget.vue')
    ),
    InlineMediaForm: defineAsyncComponent(
      () => import('@components/Profile/Forms/InlineMediaForm.vue')
    ),
  },
  setup() {
    const { routeMeta } = useRoute()
    const mainWrap = ref(null)
    const { setBoundaryVars } = useBoundaries()

    const widgetComponent = computed(
      () => routeMeta.value.widget?.component || '404'
    )

    onMounted(() => {
      nextTick(() => {
        setBoundaryVars('main', mainWrap.value.getBoundingClientRect())
      })
    })

    return {
      widgetComponent,
      mainWrap,
    }
  },
}
</script>
