import { ref, computed } from 'vue'

const authorsState = ref([])

export default function () {
  const authors = computed(() => authorsState.value)

  function setAuthors(value) {
    authorsState.value = value
  }

  return {
    setAuthors,
    authors,
  }
}
