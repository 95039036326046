// route themes
export const THEME_DEFAULT = 'theme-default'
export const THEME_GAME = 'theme-game'
export const THEME_HOME = 'theme-home'

// default theme colors
export const DEFAULT_PRIMARY = '#07C1D8'
export const DEFAULT_SUCCESS = '#7EEF8C'
export const DEFAULT_WARNING = '#FEF376'
export const DEFAULT_DANGER = '#DB5355'
export const DEFAULT_LIGHT = '#F0F1F6'
export const DEFAULT_DARK = '#000000'

// default theme colors
export const LIGHT_TEXT = '#0E101A'
export const DARK_TEXT = '#FFFFFF'
