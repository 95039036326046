import { isObjEmpty, isArray, clone, msToSecs } from '@helpers/utils.js'
import { LS_TEAMS } from '@config/localstorage.js'
import { useLocalStorage } from '@vueuse/core'
import { computed, unref } from 'vue'
import {
  TEAM_MEMBER_LEVEL_ADMINISTRATOR,
  TEAM_MEMBER_LEVEL_MODERATOR,
} from '@config/options.js'

const teamState = useLocalStorage(LS_TEAMS, null)

export default function () {
  const myTeams = computed(() => {
    const teams = JSON.parse(teamState.value)
    return isArray(teams) ? teams : []
  })

  const myGames = computed(() =>
    myTeams.value.filter(
      (r) => r.resource_type === 'game' && r.access.invite_pending === 0
    )
  )

  const myGamesAll = computed(() =>
    myTeams.value.filter((r) => r.resource_type === 'game')
  )

  const myMods = computed(() =>
    myTeams.value.filter(
      (r) => r.resource_type === 'mod' && r.access.invite_pending === 0
    )
  )

  function setMyTeams(teams) {
    teamState.value = JSON.stringify(teams)
  }

  function updateGameTeam(game, user) {
    if (game && !isObjEmpty(game)) {
      const team = findTeam({ resource: 'game', id: game.id })

      let updated = _createGameTeam(game)

      let access = game.team.find(
        (member) => member.user.id === user.value?.info?.id
      )
      if (access) {
        access = clone(access)
        access.is_leader = game.user.id === user.value.info.id
        access.user = {}
      }

      if (team) {
        updated = {
          ...team,
          ...updated,
          access: access || team.access,
        }
      }

      if (!updated.access) {
        updated.access = {
          position: '',
          is_leader: false,
          invite_pending: 0,
          date_added: msToSecs(Date.now()),
          level: TEAM_MEMBER_LEVEL_MODERATOR,
        }
      }

      addTeamToMyTeams(updated)
    }
  }

  function _createGameTeam(game) {
    const temp = clone(game)
    return {
      logo: temp.logo,
      name: temp.name,
      name_id: temp.name_id,
      game_name: temp.name,
      game_name_id: temp.name_id,
      game_curation_option: temp.curation_option,
      game_status: temp.status,
      status: temp.status,
      profile_url: temp.profile_url,
      resource_id: temp.id,
      resource_type: 'game',
      monetization_team: temp.monetization_team,
      monetization_options: temp.monetization_options,
    }
  }

  function updateModTeam(mod, gameNameId, access) {
    if (mod && !isObjEmpty(mod)) {
      const team = findTeam({ resource: 'mod', id: mod.id })

      let updated = _createModTeam(mod, gameNameId)

      if (team) {
        updated = {
          ...team,
          ...updated,
          access: access || team.access,
        }
      } else if (access) {
        updated.access = access
      }

      addTeamToMyTeams(updated)
    }
  }

  function _createModTeam(mod, gameNameId) {
    const temp = clone(mod)
    return {
      access: {
        position: '',
        is_leader: false,
        invite_pending: 0,
        date_added: msToSecs(new Date().getTime()),
        level: TEAM_MEMBER_LEVEL_ADMINISTRATOR,
      },
      logo: temp.logo,
      name: temp.name,
      name_id: temp.name_id,
      game_name: temp.game_name,
      game_name_id: gameNameId,
      game_curation_option: temp.curation_option,
      profile_url: temp.profile_url,
      visible: temp.visible,
      status: temp.status,
      resource_id: temp.id,
      resource_type: 'mod',
      monetization: temp.monetization,
      monetization_options: temp.monetization_options,
    }
  }

  function addTeamToMyTeams(team) {
    if (
      (!team &&
        !team.resource_type &&
        !team.resource_id &&
        !team.access &&
        !team.access.level) ||
      !myTeams.value
    ) {
      return
    }

    const temp = clone(myTeams.value)
    const index = temp.findIndex((t) =>
      _findTeam(t, { resource: team.resource_type, id: team.resource_id })
    )

    if (index !== -1) {
      temp.splice(index, 1, team)
    } else {
      temp.push(team)
    }

    setMyTeams(temp)
  }

  function addTeamLeader(resourceType, id, nameId, position) {
    if (!resourceType || !id || !nameId) {
      throw new TypeError()
    }

    const team = findTeam({ resource: resourceType, id })
    if (!team) {
      throw new Error(`Unable to add ${resourceType} team leader`)
    }

    const defaultTeamLeaderPermission = {
      name_id: nameId,
      resource_id: id,
      resource_type: resourceType,
      access: {
        level: TEAM_MEMBER_LEVEL_ADMINISTRATOR,
        position: position || '',
        is_leader: true,
        date_added: msToSecs(new Date().getTime()),
        invite_pending: 0,
      },
    }

    addTeamToMyTeams({ ...team, ...defaultTeamLeaderPermission })
  }

  function removeTeamLeader(resourceType, id) {
    if (!resourceType || !id) {
      throw new TypeError()
    }

    const team = findTeam({ resource: resourceType, id })
    if (!team) {
      throw new Error(`Unable to remove ${resourceType} team leader`)
    }

    const temp = clone(team)
    temp.access.is_leader = false

    addTeamToMyTeams(temp)
  }

  function findTeam({ resource, id }) {
    if (!resource || !id) return null

    return (
      myTeams.value &&
      isArray(myTeams.value) &&
      myTeams.value.find((t) => _findTeam(t, { resource, id }))
    )
  }

  function _findTeam(t, { resource, id }) {
    return t.resource_type === resource && t.resource_id === id
  }

  function removeTeam({ resource, id }) {
    if (!resource || !id) return

    const temp = clone(myTeams.value)
    const team = temp.find((t) => _findTeam(t, { resource, id }))
    if (team && !_isPending(team)) {
      const index = temp.findIndex((t) => _findTeam(t, { resource, id }))
      if (index !== -1) {
        temp.splice(index, 1)
        setMyTeams(temp)
      }
    }
  }

  function _isPending(team) {
    return team?.access?.invite_pending
  }

  function clearMyTeamsStore() {
    teamState.value = null
  }

  function getMyGame(idOrNameId) {
    return computed(() => {
      let _idOrNameId = unref(idOrNameId)
      if (!idOrNameId) return null

      let key = 'id'

      if (typeof _idOrNameId === 'string') {
        _idOrNameId = _idOrNameId.toLowerCase()
        key = 'name_id'
      }

      return myGames.value.find((game) => game[key] === _idOrNameId)
    })
  }

  return {
    clearMyTeamsStore,
    removeTeamLeader,
    updateGameTeam,
    updateModTeam,
    addTeamLeader,
    removeTeam,
    myGamesAll,
    setMyTeams,
    getMyGame,
    findTeam,
    myGames,
    myTeams,
    myMods,
  }
}
