/**
 * This function removes any unnecessary properties from a mod
 * to store as a subscription
 */
export function trimSubscribedMod(mod) {
  return {
    id: mod.id,
    name: mod.name,
    name_id: mod.name_id,
    game_id: mod.game_id,
    game_name: mod.game_name,
    game_name_id: mod.game_name_id,
    profile_url: mod.profile_url,
    maturity_option: mod.maturity_option,
    tags: mod.tags,
    date_live: mod.date_live,
    date_updated: mod.date_updated,
    card_image: mod.card_image,
    price: mod.price,
    logo: {
      thumb_320x180: mod.logo?.thumb_320x180,
    },
    stats: {
      downloads_today: mod.stats?.downloads_today,
      downloads_total: mod.stats?.downloads_total,
      subscribers_total: mod.stats?.subscribers_total,
      ratings_percentage_positive: mod.stats?.ratings_percentage_positive,
    },
    modfile: {
      filesize: mod.modfile?.filesize,
    },
    submitted_by: {
      name_id: mod.submitted_by?.name_id,
    },
  }
}
