<template>
  <conversation-header-button
    :icon="expandChatWindow ? 'compress-alt' : 'expand-alt'"
    @click="toggleExpand"
  />
</template>

<script>
import ConversationHeaderButton from '@components/Conversation/ConversationHeaderButton.vue'
import { useMessenger } from '@composables'
export default {
  components: {
    ConversationHeaderButton,
  },
  setup() {
    const { toggleExpand, expandChatWindow } = useMessenger()

    return {
      expandChatWindow,
      toggleExpand,
    }
  },
}
</script>
