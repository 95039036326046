import { TOKEN_EXPIRED } from '@composables/useSuggestions.js'
import { isFunction, isArray } from '@helpers/utils.js'
import { useRoute, addToast } from '@composables'
import { create } from './axiosClient.js'
import { logoutRequest } from '@services'
import { authStore } from '@stores'
import { HttpError } from '@errors'
import {
  MERGE_AND_CLOSE_EXCHANGE_ENDPOINT,
  MERGE_AND_CLOSE_REQUEST_ENDPOINT,
  LOGOUT_ENDPOINT,
  AUTH_BASE_URL,
} from '@config'

const { isLoggedIn } = authStore()

export default function createClient(configs = []) {
  let client = {
    httpClient: create(),
  }

  const updateConfigs = isArray(configs) ? configs : Array.from([configs])

  updateConfigs.forEach((updateConfigFn) => {
    if (!isFunction(updateConfigFn)) {
      throw new TypeError('Argument must be a function')
    }
    updateConfigFn(client)
  })

  async function get(uri, config = {}) {
    try {
      return await client.httpClient.get(uri, config)
    } catch (err) {
      if (err.response) {
        await __handleUnauthorise(err)
        throw new HttpError(err.response)
      }
    }
  }

  async function post(uri, postData, config = {}) {
    try {
      return await client.httpClient.post(uri, postData, config)
    } catch (err) {
      if (err.response) {
        await __handleUnauthorise(err)
        throw new HttpError(err.response)
      }
    }
  }

  async function put(uri, postData = null, config = {}) {
    try {
      return await client.httpClient.put(uri, postData, config)
    } catch (err) {
      if (err.response) {
        await __handleUnauthorise(err)
        throw new HttpError(err.response)
      }
    }
  }

  async function del(uri, postData = null, config) {
    try {
      return await client.httpClient.delete(uri, { ...config, data: postData })
    } catch (err) {
      if (err.response) {
        await __handleUnauthorise(err)
        throw new HttpError(err.response)
      }
    }
  }

  async function uploadFile(uri, postData = {}, onUploadProgress) {
    try {
      return await client.httpClient.post(uri, postData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      })
    } catch (err) {
      if (err.response) {
        await __handleUnauthorise(err)
        throw new HttpError(err.response)
      }
    }
  }

  async function __handleUnauthorise(error) {
    if (!isLoggedIn.value) return

    // if unauthorise occurs during login out do not proceed
    // logout again
    const url = error?.request?.responseURL
    const allowedUrls = [
      AUTH_BASE_URL,
      LOGOUT_ENDPOINT,
      MERGE_AND_CLOSE_EXCHANGE_ENDPOINT,
      MERGE_AND_CLOSE_REQUEST_ENDPOINT,
    ]
    for (let _url of allowedUrls) {
      if (url.includes(_url)) {
        return
      }
    }

    if (parseInt(error.response.status) === 401) {
      if (error.response.data?.error?.error_ref === TOKEN_EXPIRED) {
        addToast({
          title: 'Your session has expired. Please login again.',
          isInfo: true,
        })

        await logoutRequest()

        useRoute().redirectOnLogout()
      }
    }
  }

  function setClient(c) {
    client.httpClient = c
  }

  function getClient() {
    return client.httpClient
  }

  return {
    uploadFile,
    getClient,
    setClient,
    post,
    del,
    put,
    get,
  }
}
