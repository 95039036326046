<template>
  <div>
    <div
      class="tw-z-20 tw-space-y-2 tw-justify-end tw-fixed tw-flex tw-flex-col tw-top-16 tw-bottom-auto tw-inset-x-0 md:tw-left-auto md:tw-top-auto md:tw--bottom-px md:tw-right-18"
      :class="show ? 'tw-max-h-full tw-h-full md:tw-h-auto' : 'tw-hidden'"
    >
      <component :is="currentComponent" :show="show" />
    </div>
  </div>
</template>

<script>
import ConversationMembers from '@components/Conversation/ConversationMembers.vue'
import ConversationThread from '@components/Conversation/ConversationThread.vue'
import MessageCompose from '@components/Conversation/MessageCompose.vue'
import { useMessenger } from '@composables'

export default {
  components: {
    ConversationMembers,
    ConversationThread,
    MessageCompose,
  },
  setup() {
    const { currentComponent, show } = useMessenger()

    return {
      currentComponent,
      show,
    }
  },
}
</script>
