<template>
  <div class="tw-flex tw-flex-col tw-justify-end tw-h-full">
    <div
      class="tw-flex-1 tw-p-3 tw-space-y-3 dark:tw-shadow-inset tw-animate-pulse"
    >
      <div class="tw-flex tw-w-full tw-items-end tw-justify-start tw-space-x-2">
        <div
          class="tw-shrink-0 tw-w-8 tw-input--height tw-rounded tw-bg-theme"
        ></div>
        <div class="tw-w-3/4">
          <div
            class="tw-flex tw-flex-col tw-items-start tw-p-2 tw-space-y-3 tw-w-full tw-bg-theme"
          >
            <div class="tw-w-4/5 tw-h-2 tw-rounded-full tw-bg-theme-1"></div>
            <div class="tw-w-2/3 tw-h-2 tw-rounded-full tw-bg-theme-1"></div>
            <div class="tw-w-5/6 tw-h-2 tw-rounded-full tw-bg-theme-1"></div>
            <div class="tw-w-4/6 tw-h-2 tw-rounded-full tw-bg-theme-1"></div>
            <div class="tw-w-4/5 tw-h-2 tw-rounded-full tw-bg-theme-1"></div>
          </div>
        </div>
      </div>
      <div class="tw-w-full tw-flex tw-justify-center">
        <div
          class="tw-w-8 tw-h-1.5 tw-rounded-full tw-bg-theme tw-shrink-0"
        ></div>
      </div>

      <div class="tw-space-y-2">
        <div class="tw-flex tw-flex-col tw-w-full tw-space-y-2">
          <div class="tw-flex tw-justify-end tw-w-full">
            <div
              class="tw-flex tw-flex-col tw-items-end tw-p-2 tw-space-y-3 tw-w-4/6 tw-bg-theme"
            >
              <div class="tw-w-full tw-h-2 tw-rounded-full tw-bg-theme-1"></div>
              <div class="tw-w-4/6 tw-h-2 tw-rounded-full tw-bg-theme-1"></div>
              <div class="tw-w-4/5 tw-h-2 tw-rounded-full tw-bg-theme-1"></div>
              <div class="tw-w-4/5 tw-h-2 tw-rounded-full tw-bg-theme-1"></div>
              <div class="tw-w-2/3 tw-h-2 tw-rounded-full tw-bg-theme-1"></div>
            </div>
          </div>

          <div class="tw-flex tw-justify-end tw-w-full">
            <div
              class="tw-flex tw-flex-col tw-items-end tw-p-2 tw-space-y-3 tw-w-2/6 tw-bg-theme"
            >
              <div class="tw-w-full tw-h-2 tw-rounded-full tw-bg-theme-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-w-full tw-flex tw-justify-center">
        <div class="tw-w-8 tw-h-1.5 tw-rounded-full tw-bg-theme"></div>
      </div>
    </div>

    <div
      class="tw-flex tw-space-x-2 tw-justify-between tw-items-center tw-px-2 tw-h-12 tw-border-t tw-border-theme-1"
    >
      <div class="tw-w-4/5">
        <loading-input full-width alt-bg></loading-input>
      </div>
      <div class="tw-w-1/5">
        <loading-button full-width alt-bg></loading-button>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingInput from '@components/Loading/LoadingInput.vue'
import LoadingButton from '@components/Loading/LoadingButton.vue'
export default {
  components: {
    LoadingInput,
    LoadingButton,
  },
}
</script>
