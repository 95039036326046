<template>
  <div
    class="tw-w-full tw-flex tw-flex-col tw-items-center"
    :class="{
      'tw-pt-6': !isWidget && !extraMargin,
      'tw-pt-4 xs:tw-pt-6 3xl:tw-pt-12': !isWidget && extraMargin,
      'tw-relative appear-over tw-bg-theme': appearOverHeader,
    }"
  >
    <div
      :class="[
        extraMargin
          ? 'tw-mx-4 xs:tw-mx-6 tw-w-[calc(100%-2rem)] xs:tw-w-[calc(100%-3rem)]'
          : [MARGIN_X, 'tw-w-[calc(100%-2rem)] xs:tw-w-[calc(100%-3rem)]'],
        { 'tw-container-max-width': !fullWidth },
      ]"
    >
      <div class="empty:tw-hidden tw-mb-6">
        <email-required />
        <modio-banners />
        <view-suggestions />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import EmailRequired from '@components/Profile/Panels/EmailRequired.vue'
import { isWidget as isWidgetRoute } from '@helpers/utils.js'
import ModioBanners from '@views/layouts/ModioBanners.vue'
import ViewSuggestions from '@views/ViewSuggestions.vue'

export const MARGIN_X = 'tw-mx-4 xs:tw-mx-6'

export default {
  components: {
    ViewSuggestions,
    EmailRequired,
    ModioBanners,
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    appearOverHeader: {
      type: Boolean,
      default: false,
    },
    extraMargin: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isWidget = isWidgetRoute()

    return { isWidget, MARGIN_X }
  },
}
</script>

<style scoped>
@media screen and (max-width: 767px) {
  .appear-over {
    z-index: 10;
  }
}
</style>
