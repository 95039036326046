<template>
  <div>
    <notification-dropdown-header />
    <div
      class="tw-max-h-mob--nav md:tw-max-h-full--nav tw-util-scrollbar tw-overflow-y-auto tw-input--text-size"
    >
      <loading-notifications v-if="loading" />

      <notification-card
        v-for="notification in notifications.data"
        v-else-if="notifications.data.length"
        :key="notification.id"
        :notification="notification"
      />
      <div v-else class="tw-py-3">
        <no-profile-items
          text="You do not have any notifications"
          class="tw-text-xs"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NotificationDropdownHeader from '@components/Notification/NotificationDropdownHeader.vue'
import NoProfileItems from '@components/Navbar/ProfileDropdown//NoProfileItems.vue'
import LoadingNotifications from '@components/Loading/LoadingNotifications.vue'
import NotificationCard from '@components/Notification/NotificationCard.vue'
import { getAllNotificationsRequest } from '@services'
import { notificationStore } from '@stores'
import { useAsync } from '@composables'
import { onMounted } from 'vue'

export default {
  components: {
    NotificationDropdownHeader,
    LoadingNotifications,
    NotificationCard,
    NoProfileItems,
  },
  setup() {
    const { notifications, addNotifications, canRefresh } = notificationStore()
    const { loading, error, data, run } = useAsync(
      getAllNotificationsRequest,
      'Failed to get notifications'
    )

    onMounted(async () => {
      if (canRefresh.value) {
        await run()

        if (!error.value) {
          addNotifications(data.value)
        }
      }
    })

    return {
      notifications,
      loading,
    }
  },
}
</script>
