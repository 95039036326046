import { KEYS_MEDIA_LIBRARY } from '@config/storeKeys.js'
import { store } from '@stores'

const { getStore, createStore } = store()

export default function () {
  function getMedia() {
    return getStore(KEYS_MEDIA_LIBRARY)
  }

  function addFolder(path, items) {
    const media = getStore(KEYS_MEDIA_LIBRARY)
    const newStore = { ...media.value, [path]: items }
    createStore(KEYS_MEDIA_LIBRARY, newStore)
  }

  function addMedia(path, media) {
    const _media = getStore(KEYS_MEDIA_LIBRARY)
    const newStore = { ..._media.value, [path]: [..._media.value[path], media] }
    createStore(KEYS_MEDIA_LIBRARY, newStore)
  }

  return {
    addFolder,
    addMedia,
    getMedia,
  }
}
