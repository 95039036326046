import { clone, isObjEmpty } from '@helpers/utils.js'
import { API_RESULT_LIMIT } from '@config'
import { computed } from 'vue'
import {
  normalizeSort,
  usePagination,
  useFilter,
  useSearch,
  useSort,
} from '@composables'

export default function (browseId) {
  const { getFilter } = useFilter(browseId)

  const { getSortQuery } = useSort(browseId)

  const { getSearch } = useSearch(browseId)

  const { getPagination, calculateOffset } = usePagination(browseId)

  const queryObject = computed(() => {
    const query = {
      ...getPagination.value,
      ...getSortQuery.value,
      ...getFilter.value,
      ...getSearch.value,
    }

    return query
  })

  function getStrippedQuery(query, defaultSort) {
    const queryObj = queryObject.value
    const strippedQuery = clone(query)

    if (isObjEmpty(query)) {
      return {}
    }

    const sortObj = query._sort && normalizeSort(query._sort)
    if (sortObj && defaultSort) {
      if (
        defaultSort.key === sortObj.sortBy &&
        defaultSort.direction === sortObj.order
      ) {
        delete strippedQuery._sort
      }
    }

    if (!queryObj._offset) {
      delete strippedQuery._offset
    } else {
      strippedQuery._offset = calculateOffset()
    }

    if (
      !strippedQuery._limit ||
      queryObj._limit === strippedQuery._limit ||
      strippedQuery._limit === API_RESULT_LIMIT
    ) {
      delete strippedQuery._limit
    }

    if (!strippedQuery['tags-in']) {
      delete strippedQuery['tags-in']
    }

    if (!strippedQuery['platforms']) {
      delete strippedQuery['platforms']
    }

    if (!strippedQuery._q) {
      delete strippedQuery._q
    }

    if (!strippedQuery.id) {
      delete strippedQuery.id
    }

    return strippedQuery
  }

  return { getStrippedQuery, queryObject }
}
