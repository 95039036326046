<template>
  <div class="tw-w-full">
    <slot name="header" :set-invalid="setInvalid" />

    <div v-bind="$attrs">
      <loading-table
        v-if="parsedError || loading"
        :cols="loadingCols"
        :rows="loadingRows"
        :small-row="smallRow"
        :background="background"
        :has-error="!!parsedError"
        :error-text="parsedError"
      />

      <div v-else-if="itemList.length" class="tw-overflow-x-visible">
        <table
          ref="target"
          class="tw-w-full tw-border-separate"
          style="border-spacing: 0px"
        >
          <thead class="tw-table-header-group">
            <tr class="tw-font-normal">
              <table-header
                v-for="{
                  headerTooltip,
                  cellClass,
                  sortable,
                  label,
                  type,
                  id,
                } in headers"
                :key="id"
                :sort="sort"
                :sort-options="getSortOption(label)"
                :sortable="sortable"
                :label="label"
                :tooltip="headerTooltip"
                :checkbox="type === 'checkbox'"
                :all-checked="allChecked"
                :class="cellClass"
                @update:sort="(sort) => updateTable('sort', sort)"
                @toggle:checked="toggleCheckAll"
              />
            </tr>
          </thead>
          <tbody>
            <table-row
              v-for="(item, index) in itemList"
              :key="item[rowId]"
              :class="backgroundClass"
              :item="item"
              :row-schema="rowSchema.config"
              :row-index="index"
              :small-row="smallRow"
              :clickable="clickable"
              @click="$emit('click:row', item)"
            />

            <tr
              v-show="
                showPagination &&
                itemList.length &&
                !loading &&
                (itemTotal > limit || simpleHasNext)
              "
              :class="backgroundClass"
            >
              <td :colspan="headers.length">
                <pagination-container
                  :id="tableId"
                  table-pagination
                  :limits="false"
                  @update="(action) => updateTable('pagination', action)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <no-table-data v-else :text="noDataText" />
    </div>

    <div
      v-if="bulkActions?.length"
      class="tw-fixed tw-bottom-0 tw-inset-x-0 md:tw-left-15 tw-flex tw-justify-center tw-px-4 tw-py-2 tw-bg-dark-1 tw-transition-transform"
      :class="checkedIds.length ? 'tw-translate-y-0' : 'tw-translate-y-full'"
    >
      <div
        class="tw-flex tw-w-full tw-gap-2 tw-max-w-[50rem] tw-overflow-x-auto"
      >
        <base-button hollow icon="times" @click="() => (checkedIds = [])">
          {{ checkedIds.length }} selected
        </base-button>
        <div class="tw-flex tw-gap-2 tw-ml-auto">
          <base-button
            v-for="a in bulkActions"
            :key="a.action"
            :auto-width="a.autoWidth"
            :primary="a.primary"
            :danger="a.danger"
            :icon="a.icon"
            @click="onBulkAction(a.action)"
          >
            {{ a.text }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiErrorsToArray, isFunction, isArray, clone } from '@helpers/utils.js'
import PaginationContainer from '@components/List/PaginationContainer.vue'
import { inject, ref, toRefs, computed, watch, provide } from 'vue'
import LoadingTable from '@components/Loading/LoadingTable.vue'
import NoTableData from '@components/Table/NoTableData.vue'
import TableHeader from '@components/Table/TableHeader.vue'
import { onClickOutside, onKeyUp } from '@vueuse/core'
import TableRow from '@components/Table/TableRow.vue'
import { useBrowse } from '@composables'
export default {
  components: {
    PaginationContainer,
    LoadingTable,
    NoTableData,
    TableHeader,
    TableRow,
  },
  props: {
    tableId: {
      type: String,
      required: true,
    },
    rowSchema: {
      type: Object,
      required: true,
    },
    // Required so devs explicitly confirm
    // they need to handle fetch events
    fetch: {
      type: Boolean,
      required: true,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    rowId: {
      type: String,
      default: 'id',
    },
    items: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
    },
    error: {
      type: [Array, Object, String],
      default: null,
    },
    noDataText: {
      type: String,
      default: 'No results found',
    },
    limit: {
      type: Number,
      default: 10,
    },
    itemTotal: {
      type: Number,
      default: 0,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    searchKey: {
      type: String,
      default: null,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    loadingCols: {
      type: Number,
      default: 4,
    },
    loadingRows: {
      type: Number,
      default: 6,
    },
    background: {
      type: String,
      default: null,
      validator: (value) => ['alt', 'alt-dark', null].includes(value),
    },
    smallRow: {
      type: Boolean,
      default: false,
    },
    bulkActions: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'fetch:paginate-next',
    'fetch:paginate-prev',
    'fetch:query',
    'bulk:action',
    'click:row',
  ],
  setup(props, { emit }) {
    const {
      background,
      searchKey,
      itemTotal,
      rowSchema,
      tableId,
      simple,
      items,
      fetch,
      error,
      query,
      limit,
    } = toRefs(props)

    const target = ref(null)
    const simpleHasNext = ref(false)
    const checkedIds = ref([])

    provide('checkedIds', checkedIds)
    provide('toggleChecked', (id) => {
      const index = checkedIds.value.indexOf(id)
      if (index >= 0) checkedIds.value.splice(index, 1)
      else checkedIds.value.push(id)
    })

    const headers = computed(() =>
      rowSchema.value.config.map((cell) => (isFunction(cell) ? cell({}) : cell))
    )

    const sortOptions = computed(() =>
      clone(headers.value).map((r) => ({
        label: r.label,
        key: r.key,
        default: r.key && r.key === rowSchema.value.defaultSort,
        ...r.sort,
      }))
    )

    const parsedError = computed(() =>
      (isArray(error.value) ? error.value : apiErrorsToArray(error.value)).join(
        ', '
      )
    )

    const allChecked = computed(() =>
      itemList.value.every((i) =>
        checkedIds.value.includes(i.resource_id ?? i.id)
      )
    )

    const { range, sort, itemList, updateTable, setInvalid } = useBrowse({
      id: tableId.value,
      items,
      total: itemTotal,
      sortOptions: sortOptions.value,
      limit,
      query: query.value,
      fetch,
      simple: simple.value,
      searchKey: searchKey.value,
    })

    if (fetch.value) {
      setInvalid()
    }

    const updateActiveId = inject('updateActiveId', () => {})
    onClickOutside(target, () => {
      updateActiveId(null)
    })

    const backgroundClass = background.value
      ? background.value === 'alt'
        ? 'tw-table-row-styles-alt'
        : 'tw-table-row-styles-alt-dark'
      : 'tw-table-row-styles'

    onKeyUp('Escape', () => {
      updateActiveId(null)
    })

    function getSortOption(label) {
      return sortOptions.value.find((option) => option.label === label)
    }

    watch(
      items,
      (_items) => {
        if (simple.value) simpleHasNext.value ||= _items?.nextPageUrl
        checkedIds.value = []
      },
      { immediate: true }
    )

    function onBulkAction(action) {
      emit('bulk:action', action, checkedIds.value)
      checkedIds.value = []
    }

    function toggleCheckAll() {
      checkedIds.value = allChecked.value
        ? checkedIds.value.filter(
            (id) =>
              !itemList.value.some(
                (item) => item.id === id || item.resource_id === id
              )
          )
        : [
            ...new Set([
              ...checkedIds.value,
              ...itemList.value.map((item) => item.resource_id || item.id),
            ]),
          ]
    }

    return {
      backgroundClass,
      toggleCheckAll,
      getSortOption,
      simpleHasNext,
      onBulkAction,
      updateTable,
      parsedError,
      checkedIds,
      setInvalid,
      allChecked,
      itemList,
      headers,
      target,
      // required for unit test
      range,
      sort,
    }
  },
}
</script>
