<template>
  <div
    class="tw-flex tw-items-center tw-justify-between tw-h-14 tw-px-4 tw-relative"
  >
    <span class="tw-font-medium">Notifications</span>
    <notification-read-all-button />
    <div
      class="tw-w-full tw-absolute tw-bottom-0 tw-left-0 tw-border-b tw-border-theme-2"
    />
  </div>
</template>

<script>
import NotificationReadAllButton from '@components/Notification/NotificationReadAllButton.vue'
export default {
  components: {
    NotificationReadAllButton,
  },
}
</script>
