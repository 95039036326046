import { GUIDE_COMMUNITY_ALLOW_COMMENTS } from '@config/options.js'
import { NEW_RESOURCE_ID } from '@config'

const guideDefaults = {
  id: NEW_RESOURCE_ID,
  game_id: null,
  logo: {
    filename: null,
    original: null,
    thumb_320x180: null,
    thumb_640x360: null,
    thumb_1280x720: null,
  },
  community_options: GUIDE_COMMUNITY_ALLOW_COMMENTS,
  user: {
    id: null,
    name_id: null,
    username: null,
    date_online: null,
    avatar: {
      filename: null,
      original: null,
      thumb_50x50: null,
      thumb_100x100: null,
    },
    timezone: null,
    language: null,
    profile_url: null,
  },
  date_added: null,
  date_updated: null,
  date_live: null,
  url: null,
  name: '',
  name_id: null,
  summary: '',
  description: '',
  tags: [],
}

export default guideDefaults
