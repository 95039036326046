import { ref, computed } from 'vue'
import { saveAs } from 'file-saver'
import { authStore } from '@stores'
import { DateTime } from 'luxon'
import {
  DASHBOARD_TAB_OPTIONS,
  PLATFORM_ORDER,
  STORAGE_TYPES,
} from '@config/chartOptions'
import JSZip from 'jszip'
import {
  useMetricsAsync,
  usePermissions,
  useMetricsMap,
  useStatus,
  useRoute,
} from '@composables'

const exportLoading = ref(true)
const isExporting = ref(false)
const { isSuperAdmin } = authStore()

const chartConfigs = ref(undefined)
const getChartData = ref(undefined)

export default function () {
  const { getStorage } = useMetricsAsync()
  const { hasPermissionByNameId, hasPermissionForMod } = usePermissions()
  const { getGameId, getModId } = useRoute()
  const modNameId = getModId()
  const gameNameId = getGameId()
  const isGameManager = computed(() =>
    hasPermissionByNameId({
      resource: 'game',
      nameId: gameNameId.value,
      scope: 'manager',
    })
  )
  const isModManager = computed(() =>
    hasPermissionForMod(modNameId.value, gameNameId.value, 'manager')
  )

  const canExport = computed(
    () => isModManager.value || isGameManager.value || isSuperAdmin()
  )
  const resourceName = computed(() =>
    modNameId.value
      ? modNameId.value
      : gameNameId.value
        ? gameNameId.value
        : 'mod.io'
  )

  const { status, updateStatus, statusType } = useStatus({
    loading: isExporting.value,
  })

  function getExportData(series) {
    const exportData = {}

    series
      .sort(
        (x, y) =>
          PLATFORM_ORDER.indexOf(x.name) - PLATFORM_ORDER.indexOf(y.name)
      )
      .forEach((group) => {
        const groupName = group.name
        group.data.forEach((record) => {
          if (Array.isArray(record)) {
            const [timestamp, value] = record
            if (!exportData[timestamp]) {
              exportData[timestamp] = {
                date: DateTime.fromMillis(timestamp).toLocaleString(),
              }
            }
            exportData[timestamp][groupName] = value
          }
        })
      })

    return Object.values(exportData)
  }

  function getCSVfromExportData(exportData) {
    if (!exportData.length) return ''

    let result = `${Object.keys(exportData[0]).join(',')}\n`
    exportData.slice(1).forEach((item) => {
      result += `${Object.values(item).join(',')}\n`
    })

    return result
  }

  function addExportFile(
    zip,
    storageType,
    chartType,
    config,
    geo,
    tabOverride
  ) {
    const _tab = tabOverride ?? config.chartTab
    const data = geo
      ? getStorage(storageType, _tab)
      : getChartData.value(storageType, _tab, config)
    if (!data) return

    geo && geo.formatMapDataSet(data)

    const exportData = geo ? geo.countryTotals.value : getExportData(data)

    const tabName = config.chartTab.replaceAll('_', ' ')
    zip.folder(tabName).file(`${chartType}.json`, JSON.stringify(exportData), {
      'utf-8': true,
    })
    zip
      .folder(tabName)
      .file(`${chartType}.csv`, getCSVfromExportData(exportData), {
        'utf-8': true,
      })
  }

  async function buildZip() {
    const zip = new JSZip()
    isExporting.value = true
    updateStatus(statusType.LOADING)
    if (!chartConfigs.value || !getChartData.value) {
      console.error('Export initialization failed.')
      updateStatus(statusType.ERROR)
      return
    }

    chartConfigs.value.forEach((config) => {
      if (config.chartTab === DASHBOARD_TAB_OPTIONS.AUTH) {
        addExportFile(
          zip,
          STORAGE_TYPES.DAILY,
          'daily logged in',
          {
            ...config,
            chartTab: DASHBOARD_TAB_OPTIONS.ACTIVE,
          },
          undefined,
          DASHBOARD_TAB_OPTIONS.AUTH
        )
      } else if (config.chartTab === DASHBOARD_TAB_OPTIONS.ANONYMOUS) {
        addExportFile(zip, STORAGE_TYPES.DAILY, 'daily anonymous', {
          ...config,
          chartTab: DASHBOARD_TAB_OPTIONS.ACTIVE,
        })
      } else {
        addExportFile(zip, STORAGE_TYPES.DAILY, 'daily', config)
        addExportFile(zip, STORAGE_TYPES.MONTHLY, 'monthly', config)
      }

      if (config.chartTab === DASHBOARD_TAB_OPTIONS.SUBSCRIPTIONS) {
        addExportFile(
          zip,
          STORAGE_TYPES.DAILY_SECONDARY_ONE,
          'daily gained',
          config
        )
        addExportFile(
          zip,
          STORAGE_TYPES.DAILY_SECONDARY_TWO,
          'daily lost',
          config
        )
      }

      if (!config.noGeo)
        addExportFile(
          zip,
          STORAGE_TYPES.GEO,
          'map',
          config,
          useMetricsMap(true)
        )
    })

    try {
      const generatedZip = await zip.generateAsync({ type: 'blob' })
      saveAs(generatedZip, `${resourceName.value.toLowerCase()} stats.zip`)
      updateStatus(statusType.SUCCESS)
      isExporting.value = false
    } catch (err) {
      console.error(err)
      updateStatus(statusType.ERROR)
    }
  }

  return {
    exportStatus: status,
    exportAll: buildZip,
    exportLoading,
    chartConfigs,
    getChartData,
    canExport,
  }
}
