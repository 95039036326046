import { STATUS_ACCEPTED, VISIBLE_HIDDEN } from '@config/options.js'
import { KEYS_GAMES, KEYS_ADMIN_GAMES } from '@config/storeKeys.js'
import gameSerializer from '@serializers/gameSerializer.js'
import { clone } from '@helpers/utils.js'
import { ref, computed } from 'vue'
import { store } from '@stores'

const { addToStoreData, getStore, updateStore } = store()
const adminGames = getStore(KEYS_ADMIN_GAMES)
const games = getStore(KEYS_GAMES)
const showAllGames = ref(false)

// Dynamic import to avoid circular dependency
let mergeApiResultData
import('@helpers/requestAll.js').then((data) => {
  mergeApiResultData = data.mergeApiResultData
})

export default function () {
  function getPublicGames() {
    return computed(() => {
      if (!games.value?.data) return null

      return {
        ...games.value,
        data: games.value.data.filter(
          (game) => game.status === STATUS_ACCEPTED
        ),
      }
    })
  }

  function getGameId(nameId) {
    nameId = nameId?.toLowerCase()
    const game = games.value?.data?.find(
      (g) => g.name_id.toLowerCase() === nameId
    )
    return game ? game.id : null
  }

  function getPublicGame(nameId) {
    nameId = nameId?.toLowerCase()
    return computed(() =>
      games.value?.data?.find((game) => game.name_id?.toLowerCase() === nameId)
    )
  }

  function addPublicGame(game) {
    const _game = gameSerializer.transform(game)
    addToStoreData(KEYS_GAMES, _game)
    return _game
  }

  function addPublicGames(data) {
    const hiddenGame = games.value?.data?.find(
      (g) => g.status === VISIBLE_HIDDEN
    )
    const gamesData = clone(data)

    // keep hidden game in list
    if (hiddenGame) {
      gamesData.data.push(hiddenGame)
    }

    updateStore(KEYS_GAMES, {
      ...gameSerializer.transform(gamesData),
      featured: games.value?.featured,
    })
  }

  function getFeaturedGames() {
    return computed(() => games.value?.featured)
  }

  function addFeaturedGames(data) {
    updateStore(KEYS_GAMES, {
      ...games.value,
      featured: gameSerializer.transform({ data }).data,
    })
  }

  function setAdminGames(data, append = false) {
    if (append) {
      updateStore(
        KEYS_ADMIN_GAMES,
        mergeApiResultData(adminGames.value, gameSerializer.transform(data))
      )
    } else {
      updateStore(KEYS_ADMIN_GAMES, gameSerializer.transform(data))
    }
  }

  return {
    addFeaturedGames,
    getFeaturedGames,
    addPublicGames,
    getPublicGames,
    setAdminGames,
    addPublicGame,
    getPublicGame,
    showAllGames,
    adminGames,
    getGameId,
  }
}
