import { MONETIZATION_MARKETPLACE, MOD_PAID } from '@config/monetization.js'
import { createFormRequest } from '@helpers/createFormRequest.js'
import { queryString } from '@helpers/utils.js'
import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { get, post, uploadFile, del, put } = createClient()

/* API request to get mods for game */
export async function getModsForGameRequest(gameNameId, query) {
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods${queryString(query)}`
  )

  return data
}

/* API request to get marketplace mods for game */
export async function getMarketplaceModsForGameRequest(gameNameId, query) {
  query['monetization_options-bitwise-and'] = MONETIZATION_MARKETPLACE
  query.revenue_type = MOD_PAID

  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods${queryString(query)}`
  )

  return data
}

/* API request to get mod */
export async function getModRequest(gameNameId, modNameId) {
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}`
  )
  return data
}

/* API request get mod details from cogs API */
export async function getCogsModRequest(gameNameId, modNameId) {
  const { data } = await get(`/games/@${gameNameId}/mods/@${modNameId}`)
  return data
}

/* API request get game stats */
export async function getModStatsRequest(gameNameId, modNameId) {
  const { data } = await get(`/games/@${gameNameId}/mods/@${modNameId}/stats`)
  return data
}

/* API request to add new mod */
export async function addNewModRequest(gameNameId, mod) {
  const formData = createFormRequest(mod)

  const { data } = await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

/* API request to update a mod */
export async function updateModRequest(
  gameNameId,
  modNameId,
  update,
  onUploadProgress
) {
  const formData = createFormRequest(update)

  const { data } = await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    }
  )

  return data
}

/* API request to update a mod's status as an admin */
export async function updateModStatusRequest(gameNameId, modNameId, update) {
  const { data } = await put(
    `/admin/games/@${gameNameId}/mods/@${modNameId}/status`,
    qs.stringify(update)
  )

  return data
}

/* API request to update mod tag */
export async function updateModTagsRequest(gameNameId, modNameId, update) {
  const { data } = await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/tags`,
    qs.stringify(update)
  )
  return data
}

/* API request to delete mod tag */
export async function deleteModTagsRequest(gameNameId, modNameId, update) {
  const { data } = await del(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/tags`,
    qs.stringify(update)
  )
  return data
}

/* API request to update mod media */
export async function updateModMediaRequest(
  gameNameId,
  modNameId,
  upload,
  onUploadProgress
) {
  const { data } = await uploadFile(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/media`,
    upload,
    onUploadProgress
  )
  return data
}

/* API request to update mod media ordering */
export async function updateModMediaOrderRequest(
  gameNameId,
  modNameId,
  images
) {
  const { data } = await put(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/media/reorder`,
    qs.stringify({ images })
  )
  return data
}

/* API request to update mod media link  */
export async function updateModMediaLinkRequest(gameNameId, modNameId, update) {
  const { data } = await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/media`,
    qs.stringify(update)
  )
  return data
}

/* API request to delete mod media */
export async function deleteModMediaRequest(gameNameId, modNameId, media) {
  const { data } = await del(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/media`,
    qs.stringify(media)
  )
  return data
}

/* API request to delete mod */
export async function deleteModRequest(gameNameId, modNameId, reason, hard) {
  const payload = { reason }
  if (hard) {
    payload.permanent = true
  }
  const { data } = await del(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}`,
    qs.stringify(payload)
  )
  return data
}

/* API request to get mod status logs */
export async function getModStatusLogsRequest(gameNameId, modNameId, query) {
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/mods/@${modNameId}/status-log${queryString(
      query
    )}`
  )
  return data
}
