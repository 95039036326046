<template>
  <loading-label v-if="inputLoading" />

  <label
    v-else
    class="tw-flex tw-flex-wrap sm:tw-items-center tw-leading-none"
    :class="{
      'tw-w-full': fullWidth,
      'tw-label-gap': marginBottom,
      'tw-justify-between': labelBetween,
    }"
    :for="idFor"
  >
    <span :class="opacity ? 'tw-opacity-70' : 'tw-font-medium'">
      {{ label }}
      <sup
        v-if="required"
        class="tw-relative tw-top-0 tw-input--text-size tw-text-danger"
      >
        *
      </sup>
    </span>

    <template v-if="tip">
      <tooltip-hover v-if="tipHover" tip-hover position="top" :text="tip" />
      <tip-button v-else :tip="tip" />
    </template>
  </label>
</template>
<script>
import TooltipHover from '@components/Message/TooltipHover.vue'
import LoadingLabel from '@components/Loading/LoadingLabel.vue'
import TipButton from '@components/Tip/TipButton.vue'
export default {
  components: {
    LoadingLabel,
    TooltipHover,
    TipButton,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    idFor: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    marginBottom: {
      type: Boolean,
      default: false,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: [Object, String],
      default: null,
    },
    opacity: {
      type: Boolean,
      default: true,
    },
    tip: {
      type: String,
      default: '',
    },
    tipHover: {
      type: Boolean,
      default: false,
    },
    labelBetween: {
      type: Boolean,
      default: true,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
