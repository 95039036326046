import { computed } from 'vue'
import Money from 'js-money'
import {
  SPLIT_MODIO_ID,
  SPLIT_GAME_ID,
  SPLIT_MOD_ID,
} from '@config/monetization.js'
import {
  monetizationStore,
  cogsGameStore,
  cogsModStore,
  splitsStore,
} from '@stores'

export default function (section, admin, modView) {
  const { splitsData } = splitsStore(section)
  const { monetization } = monetizationStore()
  const { cogsMod } = cogsModStore()
  const { cogsGame } = cogsGameStore()

  const basePrice = computed(() => {
    if (admin) {
      return monetization.value.default_marketplace
    }
    if (modView && cogsMod.value) {
      return cogsMod.value.price
    }
    if (cogsGame.value) {
      return cogsGame.value[`monetization_${section}`].default
    }
    return 0
  })

  const modCreator = computed(
    () =>
      splitsData.value?.splits.find((s) => s.field.includes(SPLIT_MOD_ID))
        ?.split
  )

  const platform = computed(
    () =>
      splitsData.value?.splits.find((s) => s.field.includes(SPLIT_MODIO_ID))
        ?.split
  )

  const gameCreator = computed(
    () =>
      splitsData.value?.splits.find((s) => s.field.includes(SPLIT_GAME_ID))
        ?.split
  )

  const shares = computed(() => {
    const price = Money.fromInteger(basePrice.value || 0, Money.USD)
    return price.allocate([
      modCreator.value ?? monetization.value.marketplace_default_mod_cut,
      platform.value ?? monetization.value.marketplace_default_platform_cut,
      gameCreator.value ?? monetization.value.marketplace_default_game_cut,
    ])
  })

  const modCreatorValue = computed(() => shares.value[0].amount)
  const platformValue = computed(() => shares.value[1].amount)
  const gameCreatorValue = computed(() => shares.value[2].amount)

  function calculateSplit(split) {
    return (
      (modView ? modCreatorValue.value : gameCreatorValue.value) * (split / 100)
    )
  }

  return {
    gameCreatorValue,
    modCreatorValue,
    calculateSplit,
    platformValue,
    gameCreator,
    modCreator,
    basePrice,
    platform,
  }
}
