<template>
  <base-button v-if="unreadCount" text-link-hover @click="readAll">
    Mark all as read
  </base-button>
</template>

<script>
import { markAllNotificationsAsReadRequest } from '@services'
import { notificationStore } from '@stores'
import { useAsync } from '@composables'
export default {
  setup() {
    const { unreadCount, markAllAsRead, rollback } = notificationStore()
    const { run, error } = useAsync(
      () => markAllNotificationsAsReadRequest(),
      'Failed to mark notifications as read'
    )

    async function readAll() {
      markAllAsRead()

      await run()

      if (error.value) {
        rollback()
      }
    }

    return {
      unreadCount,
      readAll,
    }
  },
}
</script>
