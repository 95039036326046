import { LS_REUSE_PERMISSIONS } from '@config/localstorage.js'
import useStorage from '@composables/useStorage.js'
import { computed } from 'vue'

const state = useStorage(LS_REUSE_PERMISSIONS, true)
export default function () {
  const toggled = computed(() => JSON.parse(state.value || '{}').toggled)

  function setToggle(value) {
    state.value = JSON.stringify({ toggled: value })
  }

  return {
    setToggle,
    toggled,
  }
}
