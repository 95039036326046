import { ref, computed } from 'vue'

// activeTip
const state = ref('')

export default function () {
  const activeTip = computed(() => state.value)

  function toggleTip(tip) {
    state.value = tip === state.value ? '' : tip
  }

  function clearTip() {
    state.value = ''
  }

  return {
    activeTip,
    toggleTip,
    clearTip,
  }
}
