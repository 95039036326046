import { ref, computed, readonly } from 'vue'
import { clone } from '@helpers/utils.js'

const siteTokenPacksState = ref(null)
const initialTokenPacks = ref({})
const tokenPacksState = ref({})

export default function (gameNameId) {
  const tokenPacks = computed(() => tokenPacksState.value[gameNameId])

  function setSiteTokenPacks(packs) {
    siteTokenPacksState.value = packs
  }

  function updateSiteTokenPack(id, pack) {
    const index = siteTokenPacksState.value.findIndex((p) => p.id === id)

    if (index >= 0) {
      siteTokenPacksState.value[index] = pack
    } else {
      siteTokenPacksState.value.push(pack)
    }
  }

  function deleteSiteTokenPack(id) {
    siteTokenPacksState.value = siteTokenPacksState.value.filter(
      (p) => p.id !== id
    )
  }

  function setTokenPacks(packs) {
    tokenPacksState.value[gameNameId] = clone(packs)
  }

  function resetTokenPacks() {
    tokenPacksState.value[gameNameId] = clone(
      initialTokenPacks.value[gameNameId]
    )
  }

  function updateTokenPack(id, pack) {
    const index = tokenPacks.value.findIndex((p) => p.id === id)

    if (index >= 0) {
      tokenPacksState.value[gameNameId][index] = pack
    } else {
      tokenPacksState.value[gameNameId].push(pack)
    }
  }

  return {
    siteTokenPacks: readonly(siteTokenPacksState),
    deleteSiteTokenPack,
    updateSiteTokenPack,
    initialTokenPacks,
    setSiteTokenPacks,
    updateTokenPack,
    resetTokenPacks,
    setTokenPacks,
    tokenPacks,
  }
}
