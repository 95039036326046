<template>
  <div
    v-if="show && !showMobMenu && !showMenu && !showOverlay"
    class="tw-fixed sm:tw-w-100 tw-bottom-3 tw-inset-x-3 md:tw-left-21 tw-z-10"
  >
    <div
      class="tw-border-2 tw-rounded tw-border-primary tw-p-4 tw-bg-theme-3 tw-text-theme"
    >
      <p class="tw-mb-4">
        mod.io uses essential cookies to make our site work. With your consent,
        we may also use non-essential cookies to enhance your experience and
        understand how you interact with our services. The latter will be set
        only upon approval.
        <button class="tw-link" @click="openSettingsModal">
          Manage your settings
        </button>
        or read our
        <base-link to="/cookies" target="_blank" @click="triggerModal">
          Cookies Policy</base-link
        >.
      </p>
      <base-grid :lg-cols="2" :x-gap="4" :y-gap="2">
        <base-button
          test-id="essential-cookies"
          full-width
          secondary
          @click="essentialOnly"
        >
          Essential Only
        </base-button>
        <base-button full-width primary @click="allowAll">
          Allow All
        </base-button>
      </base-grid>
    </div>
  </div>
</template>

<script>
import { MODAL_AGREEMENT, MODAL_COOKIE } from '@config/globalModals.js'
import { AGREEMENT_TYPE_COOKIES } from '@config/options.js'
import { setGlobalModal } from '@composables/useModal.js'
import { cookieState } from '@composables/useStorage'
import { useNavMenus, useRoute } from '@composables'
import { computed } from 'vue'

export const ESSENTIAL = 1
export const PREFERENCE = 2
export const TRACKING = 4

export default {
  setup() {
    const { showMobMenu, showMenu, showOverlay } = useNavMenus()
    const { routeMeta } = useRoute()

    const cookieValue = computed(() => parseInt(cookieState.value) || 0)
    const show = computed(
      () => cookieValue.value < ESSENTIAL && !routeMeta.value.noSidebar
    )

    function essentialOnly() {
      cookieState.value = ESSENTIAL
    }

    function allowAll() {
      cookieState.value = ESSENTIAL | PREFERENCE | TRACKING
    }

    function openSettingsModal() {
      setGlobalModal({ modal: MODAL_COOKIE })
    }

    function triggerModal(e) {
      e.preventDefault()
      setGlobalModal({
        modal: MODAL_AGREEMENT,
        data: {
          agreementType: AGREEMENT_TYPE_COOKIES,
        },
      })
    }

    return {
      openSettingsModal,
      essentialOnly,
      triggerModal,
      showMobMenu,
      showOverlay,
      allowAll,
      showMenu,
      show,
    }
  },
}
</script>
