import { LS_AGREEMENT_POPUP } from '@config/localstorage.js'
import { useLocalStorage } from '@vueuse/core'
import { ref, readonly } from 'vue'

const agreementPopupState = useLocalStorage(LS_AGREEMENT_POPUP, {})
const latestState = ref({})
const versionState = ref({})

export default function () {
  function addLatestAgreement(type, agreement) {
    latestState.value[type] = agreement
    addAgreementVersion(agreement.id, agreement)
  }

  function addAgreementVersion(id, agreement) {
    versionState.value[id] = agreement
  }

  return {
    latestAgreements: readonly(latestState),
    versionAgreements: readonly(versionState),
    agreementPopupState,
    addAgreementVersion,
    addLatestAgreement,
  }
}
