import { isObjEmpty, getQueryFromPath, clone } from '@helpers/utils.js'
import { SS_REDIRECT } from '@config/localstorage.js'
import { useInterrupted } from '@composables'
import { computed, ref, watch } from 'vue'
import { authStore } from '@stores'
import router from '@router'
import {
  GAME_BROWSE_ROUTE,
  FOUROHFOUR_ROUTE,
  GAME_ADMIN_ROUTE,
  HOME_ROUTE,
} from '@config/routeNames.js'

const redirectName = GAME_BROWSE_ROUTE

export default function (mockRouter) {
  const { interrupted } = useInterrupted()

  let route
  let $router

  if (mockRouter) {
    $router = mockRouter
    route = mockRouter.currentRoute
  } else {
    route = router.currentRoute
    $router = router
  }

  const active = ref(false)

  const routeParams = computed(() => route.value.params)
  const routeQuery = computed(() => route.value.query)
  const routePath = computed(() => route.value.path)
  const routeFullPath = computed(() => route.value.fullPath)
  const routeMeta = computed(() => route.value.meta)
  const routeError = computed(() => route.value.meta.error)
  const routeName = computed(() => route.value.name)
  const routeHash = computed(() => route.value.hash)

  const hasParams = computed(() => !isObjEmpty(routeParams.value))

  function resolve(to) {
    try {
      return $router.resolve(to)
    } catch {
      console.error('could not resolve route')
    }
    return null
  }

  function routeHasParams(routeName) {
    return Object.keys(getRouteNameParams(routeName)).length !== 0
  }

  function getRouteNameParams(routeName) {
    if (routeName) {
      const route = $router.resolve({ name: routeName })
      const params = route && Object.values(route.params)
      const _routeParams = clone(routeParams.value)
      Object.entries(_routeParams).forEach(([key, value]) => {
        if (!params.includes(value)) {
          delete _routeParams[key]
        }
      })
      return _routeParams
    } else {
      return {}
    }
  }

  function getPathname() {
    const url = new URL(window.location.href)
    return url.pathname
  }

  function _isActive(value, name) {
    if (name === GAME_BROWSE_ROUTE && value === HOME_ROUTE) {
      return true
    }
    if (
      name === GAME_ADMIN_ROUTE &&
      value &&
      value.includes(GAME_ADMIN_ROUTE)
    ) {
      return true
    }
    if (value === name) {
      return true
    }
    return false
  }

  function push(to) {
    $router.push(to)
  }

  function back() {
    $router.back()
  }

  function replace(to) {
    $router.replace(to)
  }

  const history = computed(() => $router.options.history.state)

  function watchActive(name) {
    watch(
      () => route.value.name,
      (value) => {
        active.value = _isActive(value, name)
      },
      {
        immediate: true,
      }
    )

    return computed(() => active.value)
  }

  function getGameId() {
    return computed(() => {
      const gameNameId =
        routePath.value && route.value.params && route.value.params.game

      // vue router takes awhile to populate its data objects
      // we parse the url for the game ID
      // this improves theme application speed
      // room to improve the algorithm
      if (!gameNameId) {
        const url = new URL(window.location.href)
        if (
          url.pathname &&
          url.pathname.startsWith('/g/') &&
          !url.pathname.startsWith('/g/add')
        ) {
          const pathname = url.pathname.replace('/g/', '').split('/')
          if (pathname && pathname[0]) {
            return pathname[0]
          }
        }
      } else {
        return gameNameId
      }

      return null
    })
  }

  function getWidgetProps() {
    return routeMeta.value.widget?.props
  }

  function fourOhFour() {
    if (interrupted(routePath.value)) {
      return
    }
    push({
      name: FOUROHFOUR_ROUTE,
      // preserve current path and remove the first char to avoid the target URL starting with `//`
      params: { pathMatch: route.value.path.substring(1).split('/') },
    })
  }

  function getModId() {
    return computed(() => {
      const modNameId = route.value?.params?.mod

      if (!modNameId) {
        const url = new URL(window.location.href)
        if (
          url.pathname &&
          url.pathname.includes('/m/') &&
          !url.pathname.includes('/m/add')
        ) {
          const pathname = url.pathname.match(/\/m\/(.*)/)
          if (pathname && pathname[1]) {
            return pathname[1]
          }
        }
      } else {
        return modNameId
      }

      return null
    })
  }

  function getUserNameId() {
    return computed(() => route.value?.params?.user)
  }

  function getGuideId() {
    return computed(() => route.value?.params?.guide)
  }

  function redirectToHome() {
    if (route.value.name === redirectName) {
      location.reload()
    } else {
      push({ name: redirectName })
    }
  }

  function redirectOnLogin() {
    const redirectURL = sessionStorage.getItem(SS_REDIRECT)
    if (redirectURL) {
      try {
        const url = new URL(redirectURL)
        // If no error than URL was complete
        sessionStorage.removeItem(SS_REDIRECT)
        redirectOnLogin()
        document.cookie = `Authorization=Bearer ${
          authStore().accessToken.value
        }; secure; samesite=strict; max-age=60`
        location.href = url.href
        return
      } catch {
        //
      }

      const query = getQueryFromPath(redirectURL)
      const hash = redirectURL.match('#.+')?.[0]
      sessionStorage.removeItem(SS_REDIRECT)
      replace({ path: redirectURL, query, hash })
    } else {
      replace({ name: redirectName })
    }
  }

  function redirectOnLogout() {
    if (!routeMeta.value.requiresAuth) {
      window.location.reload()
    } else if (routeParams.value.mod) {
      window.location = `/g/${routeParams.value.game}/m/${routeParams.value.mod}`
    } else if (routeParams.value.guide) {
      window.location = `/g/${routeParams.value.game}/r/${routeParams.value.guide}`
    } else if (routeParams.value.game) {
      window.location = `/g/${routeParams.value.game}`
    } else {
      window.location = '/g'
    }
  }

  function getInitialNav(navigation) {
    let match = navigation.find(
      (x) =>
        x.link === routeName.value || x.highlight?.includes(routeName.value)
    )
    if (match) return match.text

    for (const x of navigation) {
      if (x.subMenus) {
        match = x.subMenus.find(
          (x) =>
            x.link === routeName.value || x.highlight?.includes(routeName.value)
        )

        if (!match) {
          let navAlias
          match = x.subMenus.some((x) => {
            navAlias = x.navAlias?.find((alias) => alias === routeName.value)
            return !!navAlias
          })

          if (match) return navAlias
        }
      }

      if (match) return match.link
    }

    return ''
  }

  return {
    getRouteNameParams,
    redirectOnLogout,
    redirectOnLogin,
    redirectToHome,
    routeHasParams,
    getWidgetProps,
    getInitialNav,
    routeFullPath,
    getUserNameId,
    routeParams,
    watchActive,
    getPathname,
    fourOhFour,
    routeQuery,
    getGuideId,
    routeError,
    hasParams,
    routeName,
    routeMeta,
    routePath,
    routeHash,
    getGameId,
    getModId,
    history,
    resolve,
    replace,
    route,
    back,
    push,
  }
}
