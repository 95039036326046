import { createFormRequest } from '@helpers/createFormRequest.js'
import { STATUS_ACCEPTED } from '@config/options.js'
import { queryString } from '@helpers/utils.js'
import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { del, get, post } = createClient()

/* API request to get guides for game */
export async function getGuidesForGameRequest(
  gameNameId,
  queries,
  showHidden = false
) {
  if (!showHidden && queries && !queries['status-in']) {
    queries = { ...queries, status: STATUS_ACCEPTED }
  }

  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/guides${queryString(queries)}`
  )

  return { ...data }
}

/* API request to get guide */
export async function getGuideRequest(gameNameId, guideNameId) {
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/guides/@${guideNameId}`
  )
  return { ...data }
}

/* API request to get guide tags */
export async function getGuideTagsRequest(gameNameId) {
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/guides/tags`
  )
  return { ...data }
}

/* API request to add guide */
export async function addGuideRequest(gameNameId, guide) {
  const formData = createFormRequest(guide)

  const { data } = await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/guides`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

/* API request to edit guide */
export async function editGuideRequest(gameNameId, guideNameId, guide) {
  const formData = createFormRequest(guide)

  const { data } = await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/guides/@${guideNameId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

/* API request to delete guide */
export async function deleteGuideRequest(gameNameId, guideNameId, reason) {
  const { data } = await del(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/guides/@${guideNameId}`,
    qs.stringify({ reason })
  )
  return data
}

/* API request to get guide status logs */
export async function getGuideStatusLogsRequest(
  gameNameId,
  guideNameId,
  query
) {
  const { data } = await get(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/guides/@${guideNameId}/status-log${queryString(
      query
    )}`
  )
  return data
}
