<template>
  <!--
    Can't use component to generate router-link
    as 'to' does not get appended correctly resulting in
    missing href=""
  -->
  <router-link
    v-if="isLink && !aTag"
    :data-testid="UI_TEST_ENVS.includes(MODIO_ENV) ? testId : ''"
    :to="to"
    :target="target"
    :download="download"
    class="tw-space-x-1"
    :class="linkClass"
  >
    <slot />
  </router-link>

  <component
    :is="linkType"
    v-else
    :data-testid="UI_TEST_ENVS.includes(MODIO_ENV) ? testId : ''"
    :href="isLink && aTag ? to : null"
    :target="isLink ? target : null"
    :rel="aTag ? 'noopener noreferrer' : null"
    :download="download"
    class="tw-space-x-1"
    :class="isLink && linkClass"
  >
    <slot />
  </component>
</template>

<script>
import { MODIO_ENV, UI_TEST_ENVS } from '@config'
import { isString } from '@helpers/utils.js'
import { computed, toRefs } from 'vue'

export default {
  props: {
    isLink: {
      type: Boolean,
      default: true,
    },
    linkPrimary: {
      type: Boolean,
      default: true,
    },
    hoverPrimary: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      default: () => ({}),
    },
    target: {
      type: String,
      default: null,
      validator: function (value) {
        return ['_self', '_blank'].indexOf(value) !== -1
      },
    },
    download: {
      type: String,
      default: null,
    },
    testId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { isLink, to, linkPrimary, hoverPrimary } = toRefs(props)

    const aTag = computed(() => isLink.value && isString(to.value))
    const linkType = computed(() => (aTag.value ? 'a' : 'span'))

    const linkClass = computed(() => {
      const classList =
        'tw-button-transition tw-cursor-pointer tw-outline-none tw-border-none'
      if (hoverPrimary.value) {
        return `${classList} hover:tw-text-primary-hover focus:tw-text-primary-hover`
      }
      return linkPrimary.value
        ? `${classList} tw-text-primary hover:tw-text-primary-hover focus:tw-text-primary-hover`
        : `${classList}`
    })

    return {
      UI_TEST_ENVS,
      MODIO_ENV,
      linkClass,
      linkType,
      aTag,
    }
  },
}
</script>
