<template>
  <component
    :is="to ? 'BaseLink' : 'div'"
    :link-primary="false"
    :is-link="to !== ''"
    :to="to"
  >
    <div
      ref="target"
      class="tw-bg-theme-1 tw-justify-center"
      :class="classes"
      tabindex="0"
      @click="$emit('click')"
    >
      <div v-if="loading" class="tw-flex tw-items-center tw-justify-center">
        <base-loading :status="status" class="tw-text-theme tw-m-auto" />
      </div>
      <template v-else>
        <div class="tw-flex tw-items-center tw-justify-center">
          <font-awesome-icon
            v-if="icon !== ''"
            :icon="icon"
            size="lg"
            class="tw-text-grey dark:tw-text-white"
          />
        </div>
        <span :id="id">
          {{ label }}
        </span>
        <slot />
      </template>
    </div>
  </component>
</template>

<script>
import { useRoute, useNavMenus } from '@composables'
import { useEventListener } from '@vueuse/core'
import { ref } from 'vue'

const classes = `
  tw-flex
  tw-shrink-0
  tw-min-h-10
  tw-items-center
  tw-cursor-pointer
  tw-px-2
  tw-button-transition
  tw-relative
  tw-border-b
  last:tw-border-b-0
  tw-border-white
  tw-border-opacity-10
  tw-outline-none
  tw-bg-theme-2--hover
  focus-within:tw-bg-theme-1
`

export default {
  props: {
    icon: {
      type: [String, Array],
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    to: {
      type: [Object, String],
      default: () => {},
    },
  },
  emits: ['click'],
  setup(_, { emit }) {
    const target = ref(null)
    const { routeName } = useRoute()
    const { closeAllMenus } = useNavMenus()

    useEventListener(target, 'keypress', (e) => {
      if (
        e.code === 'Space' ||
        e.code === 'Enter' ||
        e.code === 'NumpadEnter'
      ) {
        emit('click')
      }
    })

    return {
      closeAllMenus,
      routeName,
      classes,
      target,
    }
  },
}
</script>
