import { msToSecs, cleanHTMLEntities } from '@helpers/utils.js'
import Serializer, { extractCardImage } from './Serializer.js'
import { PLACEHOLDER_GUIDE_CARD_IMAGE } from '@config'
import guideDefaults from '@types/guide.js'

const transformations = [
  _addGameNameId,
  _setDateLive,
  _renameUserFieldToSubmittedBy,
  _clean,
  extractCardImage(PLACEHOLDER_GUIDE_CARD_IMAGE),
]

const guideSerializer = new Serializer({
  transformations,
  defaults: guideDefaults,
})

// TODO: remove once api has made the field consistent with other resources
function _renameUserFieldToSubmittedBy(guide) {
  if (guide.user) {
    guide.submitted_by = guide.user
  }

  return guide
}

function _setDateLive(guide) {
  if (!('date_live' in guide)) {
    guide.date_live = msToSecs(Date.now())
  }
  return guide
}

function _addGameNameId(guide) {
  if (!guide.game_name_id) {
    guide.game_name_id = guide.url.match(/(\/g\/)([\w-]+)/)?.[2] || ''
  }
  return guide
}

function _clean(guide) {
  guide.name = guide.name ? cleanHTMLEntities(guide.name) : ''
  guide.summary = guide.summary ? cleanHTMLEntities(guide.summary) : ''
  return guide
}

export default guideSerializer
