export const BEHAVIOUR_TYPES = {
  ALLOW_DENY: 'allowdeny',
  THIRDPARTY: 'thirdparty',
  REPORT: 'report',
}

export const RULE_TYPES = {
  USER: 'user',
  USER_STATS: 'userstats',
  PAYLOAD: 'payload',
}

export const UNION_TYPES = {
  AND: 'AND',
  OR: 'OR',
  NONE: 'NONE',
}

export const FIELD_TYPES = {
  COUNTRY: 'country',
  NUMBER: 'number',
  INTEGER: 'integer',
  DATE: 'date',
  EMAIL: 'email',
  TEXT: 'text',
  STRING: 'string',
  SELECT: 'select',
  IP: 'ip',
  EXTERNAL_AUTH_METHOD: 'external_auth_method',
  USER_LOOKUP: 'userlookup',
}

export const WEBHOOK_TEST_NAME = 'Test'
export const NOT_APPLICABLE = 'N/A'

export const WEBHOOK_PAYLOAD_EXAMPLE = {
  event_name: 'ADD_MOD_COMMENT',
  event_timestamp: 1683876030,
  event_uuid: '5d9f92b8-ce56-42e8-8a1d-838f3c405981',
  request_method: 'POST',
  request_resource: {
    resource_type: 'comment',
    resource_id: 30,
    resource_url: 'https://mod.io/g/my-game/m/aepyceros-volitans#30',
  },
  request_secondary_resource: {
    resource_type: 'mod',
    resource_id: 10,
    resource_url: 'https://mod.io/g/my-game/m/aepyceros-volitans',
  },
  request_user: {
    user_id: 1,
    username: 'Admin',
    profile_url: 'https://mod.io/u/admin',
  },
  event_body: {
    content: 'I am a bad apple!',
  },
}
