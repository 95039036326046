import { watch, computed, ref } from 'vue'
import { useRoute } from '@composables'

const state = ref([])
const incomplete = new Map()
const currentLocation = ref(window.location.href)
const debugHeader = ref(true)

function parseChunk(chunk) {
  chunk = chunk.replace('chunked=', '')
  const [header, body] = chunk.split('|')
  const [key, page, end] = header.split(',')
  const message = window.atob(body)

  return {
    key,
    page: parseInt(page),
    end: parseInt(end),
    message,
  }
}

export default function () {
  const { routeName, routeParams } = useRoute()

  watch([routeName, routeParams], () => {
    currentLocation.value = ''
  })

  const debugState = computed(() => state.value || [])

  function setDebugState(base64String) {
    if (!base64String) return

    let parsed = parseChunk(base64String)

    if (incomplete.has(parsed.key)) {
      const item = incomplete.get(parsed.key)
      item.messages = item.messages.concat(parsed)
    } else {
      incomplete.set(parsed.key, { end: parsed.end, messages: [parsed] })
    }

    incomplete.forEach((value) => {
      if (value.messages.length - 1 === value.end) {
        const sorted = value.messages.sort((msg1, msg2) =>
          msg1.page > msg2.page ? 1 : -1
        )
        const buffer = sorted.reduce((prev, next) => prev + next.message, '')

        try {
          if (buffer.length === 0) return

          const queries = JSON.parse(buffer)
          if (currentLocation.value === window.location.href) {
            const pathRecord = state.value.find(
              (r) => r.path === currentLocation.value
            )
            if (pathRecord) {
              pathRecord.data.unshift({ ...queries })
              return
            }
          }
          state.value.unshift({
            path: window.location.href,
            data: [
              {
                ...queries,
              },
            ],
          })
          currentLocation.value = window.location.href
        } catch (error) {
          console.error(error)
        } finally {
          incomplete.delete(parsed.key)
        }
      }
    })
  }

  function clear() {
    state.value = []
  }

  function setDebugHeader(value) {
    debugHeader.value = value
  }

  return {
    setDebugHeader,
    setDebugState,
    debugHeader,
    debugState,
    clear,
  }
}
