<template>
  <span
    v-if="hasErrors"
    class="tw-text-danger tw-space-y-1"
    :class="[textAlign, textSize]"
  >
    <template v-if="isArray(errors)">
      <div v-for="(error, index) in errors" :key="index">
        {{ clean(error) }}
      </div>
    </template>
    <template v-else-if="isString(errors)">
      {{ errors }}
    </template>
  </span>
</template>

<script>
import { isString, isArray } from '@helpers/utils.js'
import { computed, toRefs } from 'vue'
export default {
  props: {
    errors: {
      type: [Array, String],
      default: null,
    },
    align: {
      type: String,
      default: 'right',
      validator: function (value) {
        return ['left', 'center', 'right'].indexOf(value) !== -1
      },
    },
    size: {
      type: String,
      default: 'xs',
      validator: function (value) {
        return ['xs', 'sm'].indexOf(value) !== -1
      },
    },
  },
  setup(props) {
    const { errors, align, size } = toRefs(props)
    const hasErrors = computed(() => {
      return (
        (isArray(errors.value) && errors.value.length) || isString(errors.value)
      )
    })

    const textAlign = computed(() => {
      if (align.value === 'center') {
        return 'tw-justify-center tw-text-center'
      } else if (align.value === 'right') {
        return 'tw-justify-end tw-text-right'
      } else {
        return 'tw-justify-start tw-text-left'
      }
    })

    const textSize = computed(() =>
      size.value === 'xs' ? 'tw-text-xs ' : 'tw-text-sm'
    )

    function clean(text) {
      return text.toString().replace(/_/g, ' ')
    }
    return {
      hasErrors,
      textAlign,
      isString,
      textSize,
      isArray,
      clean,
    }
  },
}
</script>
