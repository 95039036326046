<template>
  <tr :class="rowClassList">
    <td
      v-for="(cell, index) in cells"
      :key="index"
      :class="[
        cellClassList,
        cell.cellClass,
        cellStyles(cell),
        active && cell.inputCell ? 'tw-pr-4' : 'tw-px-3 md:tw-py-2',
        { 'tw-bg-primary': active },
      ]"
    >
      <row-cell
        :class="smallRow ? 'tw-min-h-8' : 'tw-min-h-10'"
        :active="active"
        :cell="cell"
        :row-index="rowIndex"
      />
    </td>
  </tr>
</template>

<script>
import RowCell from '@components/Table/TableCells/RowCell.vue'
import { inject, computed, toRefs } from 'vue'
import { isNumber } from '@helpers/utils.js'
import { useTable } from '@composables'
export default {
  components: {
    RowCell,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    rowSchema: {
      type: Array,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    rowIndex: {
      type: Number,
      default: null,
    },
    smallRow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { clickable, rowSchema, item, smallRow } = toRefs(props)
    const { getCells } = useTable()

    const activeId = inject('activeId', false)
    const active = computed(
      () => isNumber(item.value.id) && activeId.value === item.value.id
    )
    const defaultClass = `tw-table-row tw-button-transition tw-p-0 tw-pr-2 tw-relative`
    const rowClassList = computed(() => {
      return [
        defaultClass,
        {
          'tw-h-16 lg:tw-h-18': !smallRow.value,
          'tw-cursor-pointer': clickable.value,
        },
      ]
    })

    const cellClassList = 'tw-py-0 tw-table-cell tw-align-middle tw-min-h-10'

    const cells = getCells({ rowSchema, item })

    function cellStyles(cell) {
      if (cell.preStyleText) {
        if (cell.value === 'N/A') {
          return 'tw-text-warning'
        } else if (
          cell.value === 'Declined' ||
          cell.value === 'Hidden' ||
          cell.value === 'Deleted' ||
          cell.value === 'Banned' ||
          cell.value === 'Disabled'
        ) {
          return 'tw-text-danger'
        } else if (cell.value === 'Live' || cell.value === 'Enabled') {
          return 'tw-text-success'
        }
      }
      if (cell.code) {
        return 'tw-font-mono'
      }
      return ''
    }

    return {
      cellClassList,
      rowClassList,
      cellStyles,
      active,
      cells,
    }
  },
}
</script>
