import { createClient } from '@services'
import qs from 'qs'

const { get, post, put } = createClient()

/* API request to toggle Partner Program for a game */
export async function togglePartnerProgramRequest(gameNameId, update) {
  const { data } = await put(
    `/games/@${gameNameId}/monetization/partners/toggle`,
    qs.stringify({ effect: update })
  )
  return data
}

/* API request to get Partner Program criteria for a game */
export async function getGamePartnerProgramCriteriaRequest(gameNameId) {
  const { data } = await get(
    `/games/@${gameNameId}/monetization/partners/criteria`
  )
  return data
}

/* API request to get Partner Program progress for a game */
export async function getGamePartnerProgramProgressRequest(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}/monetization/partners/user`)
  return data
}

/* API request to set Partner Program criteria for a game */
export async function updateGamePartnerProgramCriteriaRequest(
  gameNameId,
  update
) {
  const { data } = await post(
    `/games/@${gameNameId}/monetization/partners/criteria`,
    qs.stringify(update)
  )
  return data
}

/* API request to apply for Partner Program for a game */
export async function applyForPartnerProgramRequest(gameNameId, update) {
  const { data } = await post(
    `/games/@${gameNameId}/monetization/partners`,
    qs.stringify(update)
  )
  return data
}

/* API request to get application for Partner Program for a game */
export async function getApplicationForPartnerProgramRequest(gameNameId) {
  const { data } = await get(`/games/@${gameNameId}/monetization/partners`)
  return data
}

/* API request to toggle user's application for Partner Program */
export async function toggleUserPartnerProgramRequest(
  gameNameId,
  { partnerId, userId },
  effect,
  reason
) {
  const update = { effect }
  if (reason) {
    update.rejectionreason = reason
  }
  const { data } = await put(
    `/games/@${gameNameId}/monetization/${partnerId ? 'partners' : 'users'}/${
      partnerId || userId
    }`,
    qs.stringify(update)
  )
  return data
}

/* API request to get user's application logs for Partner Program */
export async function getUserPartnerProgramLogsRequest(gameNameId, partnerId) {
  const { data } = await get(`/games/@${gameNameId}/partners/${partnerId}/logs`)
  return data
}

/* API request to get Partner Program progress for a game */
export async function getUsersPartnerProgramProgressRequest(
  gameNameId,
  userNameId
) {
  const { data } = await get(
    `/games/@${gameNameId}/monetization/partners/admin/@${userNameId}`
  )
  return data
}
