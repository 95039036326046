<template>
  <div
    class="tw-flex tw-flex-col tw-p-2 tw-min-h-12 tw-space-y-2 tw-bg-theme-3"
  >
    <div class="tw-flex tw-space-x-2 tw-justify-between tw-items-end">
      <div class="tw-w-4/5">
        <textarea
          ref="target"
          v-model="message"
          rows="1"
          placeholder="Type a message"
          class="tw-min-h-10 tw-w-full tw-p-2 tw-global--border-radius tw-button-transition tw-resize-none tw-outline-none tw-border-none tw-bg-theme-1 tw-bg-theme-2--hover tw-input--text-size focus:tw-bg-theme-2 tw-placeholder-input"
          :class="expandChatWindow ? 'tw-max-h-30' : 'tw-max-h-16'"
          :style="`height: ${textareaHeight}px`"
          :maxlength="formValidation.message.max"
          @input="textareaUpdate($event.target.scrollHeight)"
          @change="message = $event.target.value"
          @keydown.ctrl.enter="sendmessage"
        />
      </div>
      <div class="tw-w-1/5 tw-h-full">
        <base-button
          full-width
          auto-height
          class="tw-h-full"
          :status="status"
          icon="paper-plane"
          @click="sendmessage"
        />
      </div>
    </div>
    <base-input-errors :errors="hasError" />
  </div>
</template>
<script>
import { composeMessageRequest, postMessageToThreadRequest } from '@services'
import { messagingConfig as formValidation } from '@config/validation.js'
import { computed, ref, toRefs, onMounted, nextTick } from 'vue'
import { messageStore, authStore } from '@stores'
import {
  useMessagePoll,
  useMessenger,
  useThread,
  useStatus,
  addToast,
  useAsync,
} from '@composables'

const TEXTAREA_BASE_HEIGHT = 34

export default {
  props: {
    threadUsers: {
      type: Array,
      required: true,
    },
    threadId: {
      type: Number,
      default: null,
    },
  },
  emits: ['compose:message', 'new:message'],
  setup(props, { emit }) {
    const { threadId, threadUsers } = toRefs(props)
    const message = ref('')
    const target = ref(null)
    const textareaHeight = ref(TEXTAREA_BASE_HEIGHT)
    const { user } = authStore()

    const { buildNewThread } = useThread()
    const { updateThreadLastSend } = useMessagePoll()
    const { expandChatWindow } = useMessenger()
    const {
      addMessageToThread,
      updateInboxThread,
      addThreadToInbox,
      addMessageThread,
    } = messageStore()

    const {
      error: composeMessageError,
      loading: composeMessageLoading,
      run: runComposeMessage,
      data: composeMessageData,
    } = useAsync(
      (to, name, message) => composeMessageRequest(to, name, message),
      'Failed to send message'
    )

    const {
      run: runPostMessage,
      loading: postMessageLoading,
      error: postMessageError,
      data: postMessageData,
    } = useAsync(
      (message) => postMessageToThreadRequest(threadId.value, message),
      'Failed to send message'
    )

    const isLoading = computed(
      () => postMessageLoading.value || composeMessageLoading.value
    )

    const hasError = computed(
      () => postMessageError.value || composeMessageError.value
    )

    const { status, updateStatus, statusType } = useStatus({
      loading: isLoading,
      error: hasError,
    })

    function textareaUpdate() {
      textareaHeight.value = 'auto'
      nextTick(() => {
        textareaHeight.value = target.value.scrollHeight
      })
    }

    onMounted(() => {
      target.value.focus()
    })

    async function sendmessage() {
      if (!user.value?.info?.email || !user.value?.info?.status) {
        addToast({
          title:
            "You must verify your account before you're able to send messages",
          isWarning: true,
        })
        updateStatus(statusType.ERROR)
        return
      }

      if (!message.value.trim().length) return

      threadId.value ? await replyToThread() : await composeMessage()

      if (!postMessageError.value && !composeMessageError.value) {
        message.value = ''
        textareaHeight.value = TEXTAREA_BASE_HEIGHT
      }
    }

    async function composeMessage() {
      await runComposeMessage(threadUsers.value, 'Hey!', message.value)
      if (!composeMessageError.value) {
        const thread = buildNewThread(composeMessageData.value)

        addMessageThread(composeMessageData.value.thread_id, thread)
        addThreadToInbox(composeMessageData.value, thread.participants)
        updateThreadLastSend(
          composeMessageData.value.thread_id,
          composeMessageData.value.id
        )
        emit('compose:message', composeMessageData.value)
      }
    }

    async function replyToThread() {
      await runPostMessage({
        thread: threadId.value,
        thread_id: threadId.value,
        message: message.value,
      })

      if (!postMessageError.value) {
        addMessageToThread(
          postMessageData.value.thread_id,
          postMessageData.value
        )
        updateInboxThread(postMessageData.value)
        updateThreadLastSend(threadId.value, postMessageData.value.id)
        emit('new:message', postMessageData.value)
      }
    }

    return {
      expandChatWindow,
      formValidation,
      textareaUpdate,
      textareaHeight,
      sendmessage,
      isLoading,
      hasError,
      message,
      status,
      target,
    }
  },
}
</script>
