import { useRecentlyViewGames } from '@composables'
import { cogsGameStore, gameStore } from '@stores'
import { computed } from 'vue'

export default function () {
  const { getPublicGame } = gameStore()
  const { cogsGame } = cogsGameStore()

  function getTheme(gameNameId) {
    const game = getPublicGame(gameNameId)
    const { recentGames } = useRecentlyViewGames()
    return computed(() => {
      // ensures theme is not dropped when
      // redirecting from new game creation
      if (cogsGame.value?.name_id === gameNameId) {
        return cogsGame.value.theme
      }

      let theme = game.value?.theme

      // if no theme found in memory
      // check recently viewed games for theme data
      if (!theme) {
        theme = recentGames.value.find((i) => i.name_id === gameNameId)?.theme
      }

      return theme
    })
  }

  return {
    getTheme,
  }
}
