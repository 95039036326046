import { GAME_UGC_NAME_OPTIONS } from '@config/options.js'
import { NEW_RESOURCE_ID } from '@config'
import {
  GAME_MOD_SUBMISSION_TOOLS_AND_WEBSITE,
  GAME_PRESENTATION_GRID,
  STATUS_NOT_ACCEPTED,
  CURATION_NONE,
} from '@config/options.js'

const gameDefaults = {
  id: NEW_RESOURCE_ID,
  name: '',
  status: STATUS_NOT_ACCEPTED,
  summary: '',
  profile_url: '',
  agreement: 0,
  email: '',
  plan: 1,
  support_url: '',
  other_urls: [],
  icon: {
    preview: undefined,
    file: null,
    dirty: false,
  },
  logo: {
    preview: undefined,
    file: null,
    dirty: false,
  },
  header: {
    preview: undefined,
    file: null,
    dirty: false,
  },
  ugc_name: GAME_UGC_NAME_OPTIONS[0]?.value || '',
  presentation_option: GAME_PRESENTATION_GRID,
  curation_option: CURATION_NONE,
  submission_option: GAME_MOD_SUBMISSION_TOOLS_AND_WEBSITE,
  maturity_options: 0,
  instructions: '',
  tag_options: [],
  google_analytics: '',
  steam_ticket: '',
  gog_ticket: '',
  switch_app_id: '',
  oculus_rift_id: '',
  oculus_rift_secret: '',
  oculus_quest_id: '',
  oculus_quest_secret: '',
  direct_downloads: true,
  third_party_access: true,
  mod_share: 50,
  monetization_team: {
    team_id: null,
    onboarded: 'pending',
    type: null,
  },
  monetization_options: 0,
  platforms: {
    platforms_filter: false,
    windows: {
      is_supported: false,
      is_moderated: false,
      is_locked: false,
      label: 'Windows',
    },
    mac: {
      is_supported: false,
      is_moderated: false,
      is_locked: false,
      label: 'Mac',
    },
    linux: {
      is_supported: false,
      is_moderated: false,
      is_locked: false,
      label: 'Linux',
    },
    android: {
      is_supported: false,
      is_moderated: false,
      is_locked: false,
      label: 'Android',
    },
    ios: {
      is_supported: false,
      is_moderated: false,
      is_locked: false,
      label: 'iOS',
    },
    xboxone: {
      is_supported: false,
      is_moderated: false,
      is_locked: false,
      label: 'Xbox One',
    },
    xboxseriesx: {
      is_supported: false,
      is_moderated: false,
      is_locked: false,
      label: 'Xbox Series X/S',
    },
    ps4: {
      is_supported: false,
      is_moderated: false,
      is_locked: false,
      label: 'PlayStation 4',
    },
    ps5: {
      is_supported: false,
      is_moderated: false,
      is_locked: false,
      label: 'PlayStation 5',
    },
    switch: {
      is_supported: false,
      is_moderated: false,
      is_locked: false,
      label: 'Nintendo Switch',
    },
    oculus: {
      is_supported: false,
      is_moderated: false,
      is_locked: false,
      label: 'Oculus',
    },
    source: {
      is_supported: false,
      is_moderated: false,
      is_locked: false,
      label: 'Source',
    },
  },
}

export default gameDefaults
