import { ref, computed } from 'vue'

export default function useCopy() {
  const state = ref(false)

  const clicked = computed(() => state.value)

  function copyToClipboard(text) {
    const el = document.createElement('textarea')
    el.setAttribute('readonly', '')
    el.value = text
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    state.value = true
    setTimeout(() => (state.value = false), 100)
  }

  return {
    copyToClipboard,
    clicked,
  }
}
