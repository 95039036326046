import { MAP_DROPDOWN_SELECTIONS, REGION_TYPES } from '@config/chartOptions.js'
import { useMetricsDates } from '@composables'
import { ref, unref, computed } from 'vue'
import { DateTime } from 'luxon'
import {
  getSubContinentFromCountry,
  getContinentFromCountry,
  getCountryCode,
  getCountryName,
  isContinent,
} from '@helpers/utils.js'

const { dateRange } = useMetricsDates()

export default function (allTime = false) {
  const topListData = computed(() => {
    switch (grouping.value) {
      case REGION_TYPES.CONTINENTS:
        return regionTotals.value
      case REGION_TYPES.SUB_CONTINENTS:
        return subRegionTotals.value
      default:
        return countryTotals.value
    }
  })

  const subRegionTotals = ref([])
  const countryTotals = ref([])
  const regionTotals = ref([])
  const metricTotal = ref(0)

  const countryData = ref([])
  const regionData = ref([])
  const subRegionData = ref([])
  const grouping = ref(REGION_TYPES.COUNTRIES)
  const dropDownSelections = ref(Object.values(MAP_DROPDOWN_SELECTIONS))

  const mapData = computed(() => ({
    regionData: regionData.value,
    subRegionData: subRegionData.value,
    countryData: countryData.value,
    total: metricTotal.value,
  }))

  function clearValues() {
    countryData.value = []
    regionData.value = []
    subRegionData.value = []

    subRegionTotals.value = []
    countryTotals.value = []
    regionTotals.value = []
  }

  function getArraysToAssign(group) {
    let total = 0

    total = allTime
      ? group.series.map((x) => x.value).reduce((a, b) => a + b, 0)
      : group.series
          .filter((x) => {
            const time = DateTime.fromISO(x.label).toMillis()
            return (
              (time <= dateRange.value.endDate.toMillis() &&
                time >= dateRange.value.startDate.toMillis()) ||
              x.label === 'total'
            )
          })
          .map((x) => x.value)
          .reduce((a, b) => a + b, 0)

    let subRegionArray = subRegionTotals
    let countryArray = countryTotals
    let regionArray = regionTotals

    return {
      subRegionArray,
      countryArray,
      regionArray,
      total,
    }
  }

  function formatContinent(continent, continentArray, total, metricTotal) {
    const existingRecord = continentArray.value.find(
      (x) => x.code === continent.value
    )
    if (existingRecord) {
      existingRecord.total += total
      existingRecord.totalPercent = (existingRecord.total / metricTotal) * 100
    } else {
      continentArray.value.push({
        total: total,
        code: continent.value,
        text: continent.text,
        totalPercent: (total / metricTotal) * 100,
      })
    }
  }

  function formatCountries() {
    countryTotals.value.forEach((country) => {
      countryData.value.push({
        id: country.code,
        value: country?.total || 0,
      })
    })
  }

  function formatRegions() {
    const data = [regionTotals, subRegionTotals]
    const result = [regionData, subRegionData]

    for (let i = 0; i < data.length; i++) {
      data[i].value.forEach((region) => {
        const text = region.text.replace(' ', '')
        result[i].value.push({
          id: text[0].toLowerCase() + text.slice(1),
          value: region.total,
        })
      })
    }
  }

  // populates mapDataSets with data in GCharts expected format
  function populateMapSets(mapDataSet) {
    const data = unref(mapDataSet)

    data?.group_by?.forEach((group) => {
      const code = getCountryCode(group.label)
      const text = getCountryName(code)
      if (code) {
        const continent = getContinentFromCountry(code)
        const subContinent = getSubContinentFromCountry(code)

        const { subRegionArray, countryArray, regionArray, total } =
          getArraysToAssign(group)

        metricTotal.value = data.series[0].value

        countryArray.value.push({
          total,
          code,
          text,
          totalPercent: (total / metricTotal.value) * 100,
        })

        if (continent) {
          formatContinent(continent, regionArray, total, metricTotal.value)
        }

        if (subContinent) {
          formatContinent(
            subContinent,
            subRegionArray,
            total,
            metricTotal.value
          )
        }
      }
    })
  }

  // updates map based on location type selected (i.e. country / continent / sub-continent)
  function updateGrouping(code, switchFunc) {
    const options = Object.values(MAP_DROPDOWN_SELECTIONS)
    if (isContinent(code)) {
      grouping.value = REGION_TYPES.CONTINENTS
    } else {
      grouping.value = REGION_TYPES.COUNTRIES
      dropDownSelections.value = options
    }

    switchFunc(!isContinent(code))
  }

  function formatMapDataSet(mapDataSet) {
    clearValues()
    populateMapSets(mapDataSet)
    formatCountries()
    formatRegions()
  }

  return {
    dropDownSelections,
    formatMapDataSet,
    subRegionTotals,
    updateGrouping,
    subRegionData,
    countryTotals,
    regionTotals,
    countryData,
    topListData,
    regionData,
    grouping,
    mapData,
  }
}
