import { DISCUSSION_TYPE_MODS } from '@config/options.js'
import { queryString } from '@helpers/utils.js'
import { PUBLIC_BASE_URL } from '@config'
import { createClient } from '@services'
import qs from 'qs'

const { post, get, del, put } = createClient()

/* API request to get comments */
export async function getDiscussionsRequest(
  gameNameId,
  resourceNameId,
  resourceType,
  query
) {
  if (!query) {
    query = {}
  }
  query['_sort'] = '-id'
  const endpoint = `${PUBLIC_BASE_URL}/games/@${gameNameId}/${resourceType}/@${resourceNameId}/comments${queryString(
    query
  )}`

  const { data } = await get(
    endpoint,
    handleMetricsHeader(gameNameId, resourceType, resourceNameId)
  )

  return data
}

function handleMetricsHeader(gameNameId, type, resourceNameId) {
  const config = {
    metrics: {
      enabled: true,
      gameNameId,
    },
  }

  if (type === DISCUSSION_TYPE_MODS) {
    config.metrics.modNameId = resourceNameId
  } else {
    config.metrics.guideNameId = resourceNameId
  }

  return config
}
/* API request to add comment to a resource */
export async function addDiscussionRequest({
  resourceNameId,
  gameNameId,
  comment,
  replyId,
  resourceType,
}) {
  const endpoint = `${PUBLIC_BASE_URL}/games/@${gameNameId}/${resourceType}/@${resourceNameId}/comments`

  const { data } = await post(
    endpoint,
    qs.stringify({ content: comment, reply_id: replyId }),
    handleMetricsHeader(gameNameId, resourceType, resourceNameId)
  )

  return data
}

/* API request to delete a comment */
export function deleteDiscussionRequest(
  gameNameId,
  resourceNameId,
  resourceType,
  commentId
) {
  const endpoint = `${PUBLIC_BASE_URL}/games/@${gameNameId}/${resourceType}/@${resourceNameId}/comments/${commentId}`

  return del(
    endpoint,
    null,
    handleMetricsHeader(gameNameId, resourceType, resourceNameId)
  )
}

/* API request to edit a comment */
export async function editDiscussionRequest(
  gameNameId,
  resourceNameId,
  resourceType,
  commentId,
  content
) {
  const { data } = await put(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/${resourceType}/@${resourceNameId}/comments/${commentId}`,
    qs.stringify({ content }),
    handleMetricsHeader(gameNameId, resourceType, resourceNameId)
  )

  return data
}

/* API request to give karma for a comment */
export async function karmaDiscussionRequest(
  gameNameId,
  resourceNameId,
  resourceType,
  commentId,
  karma
) {
  const { data } = await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/${resourceType}/@${resourceNameId}/comments/${commentId}/karma`,
    qs.stringify({ karma })
  )

  return data
}

/* API request to edit options for a comment */
export async function discussionOptionsRequest(
  gameNameId,
  resourceNameId,
  resourceType,
  commentId,
  options
) {
  const { data } = await post(
    `${PUBLIC_BASE_URL}/games/@${gameNameId}/${resourceType}/@${resourceNameId}/comments/${commentId}/options`,
    qs.stringify({ options })
  )

  return data
}
