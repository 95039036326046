/**
 * Returns an object to send to updateModRequest
 * @param {CogsMod} mod
 */
function getModContentForm(mod) {
  const data = {
    name: mod.name,
    summary: mod.summary,
    description: mod.description,
    maturity_option: mod.maturity_option,
    community_options: mod.community_options,
    visible: mod.visible,
  }

  if (mod.homepage_url) {
    data['homepage_url'] = mod.homepage_url
  }

  return data
}

export default getModContentForm
