<template>
  <span
    class="tw-items-center tw-justify-start tw-text-sm"
    :class="[
      cellHighlight,
      cell.inputCell ? 'tw-flex tw-w-full' : 'tw-inline-flex',
      { 'tw-text-primary-text': active },
    ]"
    style="text-align: initial"
  >
    <base-link
      :is-link="isLink"
      :to="linkTo"
      :target="linkTarget"
      :link-primary="!active"
      :class="{ 'tw-w-full': cell.inputCell }"
    >
      <component
        :is="componentName"
        v-if="active && cell.active"
        :index="rowIndex"
        :item="cell.item"
        :cell="cell.active"
      />
      <component
        :is="componentName"
        v-else
        :item="cell.item"
        :index="rowIndex"
        :cell="cell"
        :active="active"
      />
      <font-awesome-icon v-if="cell.appendIcon" :icon="cell.appendIcon" />
    </base-link>
  </span>
</template>

<script>
import { defineAsyncComponent, computed, toRefs } from 'vue'
import { isFunction, proper } from '@helpers/utils.js'
export default {
  components: {
    PermissionsTickCell: defineAsyncComponent(
      () =>
        import('@components/Monetization/Components/PermissionsTickCell.vue')
    ),
    MonetizationCell: defineAsyncComponent(
      () => import('@components/Table/TableCells/MonetizationCell.vue')
    ),
    StaffRoleTableInput: defineAsyncComponent(
      () => import('@components/ModAdmin/Team/StaffRoleTableInput.vue')
    ),
    PositionTableInput: defineAsyncComponent(
      () => import('@components/ModAdmin/Team/PositionTableInput.vue')
    ),
    RealNameTableInput: defineAsyncComponent(
      () => import('@components/ModAdmin/Team/RealNameTableInput.vue')
    ),
    ProgressCell: defineAsyncComponent(
      () => import('@components/Table/TableCells/ProgressCell.vue')
    ),
    CheckboxCell: defineAsyncComponent(
      () => import('@components/Table/TableCells/CheckboxCell.vue')
    ),
    RoleTableInput: defineAsyncComponent(
      () => import('@components/ModAdmin/Team/RoleTableInput.vue')
    ),
    ActionCell: defineAsyncComponent(
      () => import('@components/Table/TableCells/ActionCell.vue')
    ),
    AvatarCell: defineAsyncComponent(
      () => import('@components/Table/TableCells/AvatarCell.vue')
    ),
    StringCell: defineAsyncComponent(
      () => import('@components/Table/TableCells/StringCell.vue')
    ),
    ImageCell: defineAsyncComponent(
      () => import('@components/Table/TableCells/ImageCell.vue')
    ),
    GroupCell: defineAsyncComponent(
      () => import('@components/Table/TableCells/GroupCell.vue')
    ),
    EmptyCell: defineAsyncComponent(
      () => import('@components/Table/TableCells/EmptyCell.vue')
    ),
    HtmlCell: defineAsyncComponent(
      () => import('@components/Table/TableCells/HtmlCell.vue')
    ),
    IconCell: defineAsyncComponent(
      () => import('@components/Table/TableCells/IconCell.vue')
    ),
  },
  props: {
    cell: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
    },
    rowIndex: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const { cell, active } = toRefs(props)

    const isLink = computed(
      () =>
        !cell.value.attrs?.disabled &&
        !!cell.value.to &&
        isFunction(cell.value.to)
    )
    const linkTo = computed(() =>
      cell.value.item && isLink.value ? cell.value.to(cell.value.item) : {}
    )
    const linkTarget = computed(() =>
      cell.value.item && isLink.value ? cell.value.target : null
    )

    const componentName = computed(() => {
      const _cell =
        active.value && cell.value.active ? cell.value.active : cell.value
      return _cell.type !== 'component'
        ? `${proper(_cell.type)}Cell`
        : _cell.component
    })

    const cellHighlight = computed(() =>
      cell.value.highlight
        ? 'tw-text-primary'
        : 'tw-text-opacity-70 group-hover:tw-text-opacity-100'
    )

    return {
      componentName,
      cellHighlight,
      linkTarget,
      linkTo,
      isLink,
    }
  },
}
</script>
